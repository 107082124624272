import { useCompanyGroupedOptions } from 'hooks/useCompanyGroupedOptions';
import React from 'react';
import { TFilterProps } from '../../FilterComponents.type';

import { SelectFilterItem } from '../SelectFilterItem';

const CompanyFilter: React.FC<TFilterProps> = ({
  onSelected,
  selectedValues,
}) => {
  const { options } = useCompanyGroupedOptions();
  return (
    <SelectFilterItem
      label="Company"
      onSelected={onSelected}
      selectedValues={selectedValues}
      options={options}
    />
  );
};

export { CompanyFilter };
