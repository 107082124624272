import { Colors } from 'core/CssVariables';
import { PhaseTaskStatusEnumUi } from 'data/types/phase.type';
import { TPhaseTaskStatusMapperColor } from './PhaseTasks.type';

export const PhaseTaskStatusMapperColor: TPhaseTaskStatusMapperColor = {
  [PhaseTaskStatusEnumUi.New]: Colors.SecondaryColor,
  [PhaseTaskStatusEnumUi.Closed]: Colors.LightGrey,
  [PhaseTaskStatusEnumUi.Completed]: Colors.SuccessColor,
  [PhaseTaskStatusEnumUi.Scheduled]: Colors.Orange,
  [PhaseTaskStatusEnumUi['In Progress']]: Colors.WarningColor,
  [PhaseTaskStatusEnumUi.Assigned]: Colors.InfoColor,
};
