import React, { FC, ReactNode } from 'react';
import { NoResult, TNoResult } from 'components/PageWrapper/NoResult/NoResult';

type TNoResultWrapper = TNoResult & {
  children?: ReactNode;
};

const NoResultWrapper: FC<TNoResultWrapper> = ({
  noResult,
  children,
  ...props
}) => {
  return (
    <>
      {!noResult && children}
      <NoResult noResult={noResult} {...props} />
    </>
  );
};

export default NoResultWrapper;
