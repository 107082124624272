import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAuthUserUi } from 'data/types';
import { TUserRoleUi } from 'data/types/user.types';

export type TAuthSliceState = {
  user: null | TAuthUserUi;
  logedInfirebase: boolean;
};

const initialState: TAuthSliceState = {
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') as string)
    : null,
  logedInfirebase: false,
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<any>) {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    removeUser(state) {
      state.user = null;
      localStorage.removeItem('user');
    },
    setLogedInfirebase(state, action: PayloadAction<boolean>) {
      state.logedInfirebase = action.payload;
    },
    setTimeZone(state, action: PayloadAction<string>) {
      if (state.user?.organization) {
        state.user = {
          ...state.user,
          organization: {
            ...state.user.organization,
            timeZone: action.payload,
          },
        };
      }
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    setUserRole(state, action: PayloadAction<TUserRoleUi>) {
      if (state.user?.organization) {
        state.user = {
          ...state.user,
          role: action.payload,
        };
      }
      localStorage.setItem('user', JSON.stringify(state.user));
    },
  },
  // extraReducers: builder => {
  //   builder.addMatcher(
  //     getUserApi.endpoints.getUser.matchFulfilled,
  //     (state, { payload }) => {
  //       state.user = payload;
  //       localStorage.setItem('user', JSON.stringify(payload));
  //     },
  //   );
  // },
});

export default authSlice;
