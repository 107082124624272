import { authSplitApi } from 'redux/helpers/slice.helpers';
import { TIssue, TIssueSubType, TIssueSubTypeUi, TIssueUi } from 'data/types';
import { issueConverter } from 'data/converters';
import {
  createIssueConverter,
  createIssueSubtypeConverter,
  inactiveIssueConverter,
  IssueSubTypeForFilterConverter,
} from 'data/converters/issues.converters';
import {
  TInactiveIssueUi,
  TIssueCreateUi,
  TIssueEditUi,
  TIssueSubtypeCreateUi,
  TIssueSubtypeNameEditUi,
  TIssueSubtypeWithoutIssueType,
  TIssueSubtypeWithoutIssueTypeUi,
} from 'data/types/issues.types';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { pagableDataConverter } from '../../data/converters/pagableData.converters';
import { TOptionType } from '../../data/types/generalDataTypes';
import { selectOptionCreateConverter } from '../../data/converters/general.converters';

const ISSUES_URL = 'api/issuetypes';
const SUB_ISSUES_URL = 'subtypes';
const EDIT_NAME_URL = 'name';

export const issuesApi = authSplitApi('issuesApi', ['Issues']).injectEndpoints({
  endpoints: build => ({
    getIssues: build.query<
      TPageableDataWithContentUi<TIssueUi[]>,
      {
        search?: string;
        showHidden?: boolean;
        sort?: string;
        queueIds?: string[];
      }
    >({
      query({ sort, search, queueIds }) {
        let urlWithParams = ISSUES_URL;
        // Todo: change logic of sort and filter with generic filters logic
        if (sort) {
          urlWithParams = urlWithParams.concat(`?sort=${sort}`);
        }
        if (search) {
          urlWithParams = urlWithParams.concat(`&search=${search}`);
        }
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(queueIds?.length && { queueIds }),
          },
        };
      },
      providesTags: result =>
        result
          ? [
              { type: 'Issues', id: 'LIST' },
              ...result.content.map(({ id }) => ({
                type: 'Issues' as const,
                id,
              })),
            ]
          : [{ type: 'Issues', id: 'LIST' }],
      transformResponse: (data: TPageableDataWithContent<TIssue[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(issue => issueConverter.fromDb(issue))
            : [],
        };
      },
    }),
    getIssue: build.query<any, any>({
      query(resourceId) {
        return {
          url: `${ISSUES_URL}/${resourceId}`,
          method: 'GET',
        };
      },
    }),
    inactiveIssue: build.mutation<TIssue, TInactiveIssueUi>({
      query(body) {
        const { id, hidden } = inactiveIssueConverter.toDb(body);
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: `${ISSUES_URL}/${id}/hidden`,
          method: 'PATCH',
          body: hidden,
        };
      },
      invalidatesTags: [{ type: 'Issues', id: 'LIST' }],
    }),
    createIssue: build.mutation<TIssue, TIssueCreateUi>({
      query(body) {
        return {
          url: ISSUES_URL,
          method: 'POST',
          body: {
            ...createIssueConverter.toDb(body),
          },
        };
      },
      invalidatesTags: [{ type: 'Issues', id: 'LIST' }],
    }),
    deleteIssue: build.mutation<TIssue, TIssueUi>({
      query({ id }) {
        return {
          url: `${ISSUES_URL}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, issue) => [
        { type: 'Issues', id: issue.id },
      ],
    }),
    deleteSubIssue: build.mutation<TIssueSubType, TIssueSubTypeUi>({
      query({ id, issueTypeId }) {
        return {
          url: `${ISSUES_URL}/${issueTypeId}/${SUB_ISSUES_URL}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, issue) => [
        { type: 'Issues', id: issue.issueTypeId },
      ],
    }),
    createSubIssue: build.mutation<TIssueUi, TIssueSubtypeCreateUi>({
      query(body) {
        return {
          url: `${ISSUES_URL}/${body.issueTypeId}/${SUB_ISSUES_URL}/`,
          method: 'POST',
          body: {
            ...createIssueSubtypeConverter.toDb(body),
          },
        };
      },
      transformResponse: (data: TIssue) => issueConverter.fromDb(data),
      invalidatesTags: (result, error, issue) => [
        { type: 'Issues', id: issue.issueTypeId },
      ],
    }),
    editIssueName: build.mutation<TIssueUi, TIssueEditUi>({
      query(body) {
        const { id, name } = body;
        return {
          url: `${ISSUES_URL}/${id}/${EDIT_NAME_URL}`,
          method: 'PATCH',
          body: name,
        };
      },
      transformResponse: (data: TIssue) => issueConverter.fromDb(data),
      invalidatesTags: (result, error, issue) => [
        { type: 'Issues', id: issue.id },
      ],
    }),
    editSubIssueName: build.mutation<TIssueUi, TIssueSubtypeNameEditUi>({
      query(body) {
        const { issueTypeId, id, name } = body;
        return {
          url: `${ISSUES_URL}/${issueTypeId}/${SUB_ISSUES_URL}/${id}/${EDIT_NAME_URL}`,
          method: 'PATCH',
          body: name,
        };
      },
      transformResponse: (data: TIssue) => issueConverter.fromDb(data),
      invalidatesTags: (result, error, issue) => [
        { type: 'Issues', id: issue.issueTypeId },
      ],
    }),
    updateIssueQueues: build.mutation<
      TIssueUi,
      { id: string; queues: TOptionType[] }
    >({
      query({ id, queues }) {
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: `${ISSUES_URL}/${id}/queues`,
          method: 'PATCH',
          body: queues.map(queue => selectOptionCreateConverter.toDb(queue)),
          transformResponse: (data: TIssue) => issueConverter.fromDb(data),
        };
      },
      invalidatesTags: (result, error, issue) => [
        { type: 'Issues', id: issue.id },
      ],
    }),
    getIssueSubTypesWithoutIssueType: build.query<
      TPageableDataWithContentUi<TIssueSubtypeWithoutIssueTypeUi[]>,
      {
        page: number;
        pageSize: number;
        search?: string;
        sort?: string;
      }
    >({
      query({ page, pageSize, search, sort }) {
        // TODO: create a converter for this that make a url from the query params
        const urlWithParams = ISSUES_URL.concat(
          `/${SUB_ISSUES_URL}?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            sort: sort || '',
            ...(search && { search }),
          },
        };
      },
      transformResponse: (
        data: TPageableDataWithContent<TIssueSubtypeWithoutIssueType[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(issueSubType =>
                IssueSubTypeForFilterConverter.fromDb(issueSubType),
              )
            : [],
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetIssuesQuery,
  useUpdateIssueQueuesMutation,
  useGetIssueQuery,
  useCreateIssueMutation,
  useCreateSubIssueMutation,
  useInactiveIssueMutation,
  useDeleteIssueMutation,
  useDeleteSubIssueMutation,
  useEditIssueNameMutation,
  useEditSubIssueNameMutation,
  useGetIssueSubTypesWithoutIssueTypeQuery,
} = issuesApi;
