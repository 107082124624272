import React, { FC, useEffect, useState } from 'react';
import { Colors } from 'core/CssVariables';
import { SecondaryText } from 'components/Typography';
import SSelect from './Select.style';
import { TSelectProps } from './Select.type';
import { Icon, TIconNames } from '../Icon';

const Select: FC<TSelectProps> = props => {
  const { options, title, addNewOption, labelPrefix, ...otherProps } = props;
  const [clonedOptions, setClonedOptions] = useState(options);
  useEffect(() => {
    setClonedOptions(options);
  }, [options]);
  return (
    <>
      {title && (
        <SecondaryText className="mb-1" fontlevel={6}>
          {title}
        </SecondaryText>
      )}
      <SSelect
        menuItemSelectedIcon={
          <Icon
            size={16}
            color={Colors.SuccessColor}
            icon={TIconNames.CHECK_ROUNDED}
          />
        }
        removeIcon={
          <Icon
            size={16}
            color={Colors.White}
            icon={TIconNames.HIGHLIGHT_OFF}
          />
        }
        suffixIcon={
          <Icon
            icon={TIconNames.ARROW_DROPDOWN}
            size={16}
            color={Colors.LightGrey}
          />
        }
        getPopupContainer={trigger => trigger.parentNode}
        filterOption={(input, option) => {
          if (typeof option?.label === 'string') {
            return (
              option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }
          if (typeof (option?.label as any)?.key === 'string') {
            return (
              (option?.label as any).key
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
          }
          return false;
        }}
        {...(addNewOption && {
          onSearch: searchValue => {
            if (options) {
              if (options?.find(i => i.value === searchValue) || !searchValue) {
                setClonedOptions([...options]);
              } else {
                setClonedOptions([
                  ...options,
                  {
                    label: labelPrefix
                      ? `${labelPrefix} ${searchValue}`
                      : searchValue,
                    value: searchValue,
                  },
                ]);
              }
            }
          },
        })}
        options={clonedOptions}
        {...otherProps}
      />
    </>
  );
};

export default Select;
