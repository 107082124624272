import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'components/Grid/Grid';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { removeStoredFiltersByPath, setStoredFilters } from 'redux/slices';
import { GaugesEnum, TGaugesUi } from 'data/types/gauges.types';
import {
  AllTicketsPath,
  ETicketSpecialFlagsUi,
  TicketFilters,
} from 'data/types/ticket.types';
import { AllTasksPath, TaskFilters } from 'data/types/task.type';
import { TQuickFiltersStorageNames } from 'helpers/storage';
import { AllCompaniesPath } from 'data/types/company.types';
import { CompanyFilters } from 'pages/private/Customers/Organizations/AllCompanies/AllCompanies.type';
import useGetGauges from 'api/hooks/useGetGauges';
import { useGetGaugesMutation } from 'redux/apiSlices/workFeed.slice';
import Can from 'components/Can/Can';
import { PermissionNames } from 'components/Can/Permissions/Permissions.type';
import GaugeItem from './components/GaugeItem';
import { SLine } from './Gauges.style';
import GaugeReset from './components/GaugeReset';

const Gauges = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isHeaderCollapsed = useAppSelector(
    state => state.appSettings.header.collapsed,
  );
  const [gauges, setGauges] = useState<TGaugesUi[] | undefined | null>([]);
  const user = useAppSelector(state => state.authSlice.user);
  const [getGauges, { data: gaugesData, isLoading: getGaugesLoading }] =
    useGetGaugesMutation();
  const { data, refetch } = useGetGauges();

  useEffect(() => {
    setGauges(data);
  }, [data]);

  useEffect(() => {
    if (gaugesData) setGauges(gaugesData);
  }, [gaugesData]);

  useEffect(() => {
    if (user?.organization?.id) {
      refetch(user?.organization?.id || '');
    }
  }, [user]);
  const redirectOverdueTicket = () => {
    dispatch(
      removeStoredFiltersByPath({
        keyStorageName: TQuickFiltersStorageNames.ticketFilterStorageName,
        path: AllTicketsPath.OPEN,
      }),
    );
    dispatch(
      setStoredFilters({
        keyStorageName: TQuickFiltersStorageNames.ticketFilterStorageName,
        path: AllTicketsPath.OPEN,
        filter: TicketFilters.SPECIAL_FLAGS,
        ids: [ETicketSpecialFlagsUi.IS_OVERDUE],
      }),
    );
    navigate('/app/tickets?tab=open');
  };

  const redirectOverdueTask = () => {
    dispatch(
      removeStoredFiltersByPath({
        keyStorageName: TQuickFiltersStorageNames.taskFilterStorageName,
        path: AllTasksPath.OPEN,
      }),
    );
    dispatch(
      setStoredFilters({
        keyStorageName: TQuickFiltersStorageNames.taskFilterStorageName,
        path: AllTasksPath.OPEN,
        filter: TaskFilters.OVERDUE,
        ids: ['Overdue'],
      }),
    );
    navigate('/app/tasks?tab=open');
  };

  const redirectNewTickets = () => {
    dispatch(
      removeStoredFiltersByPath({
        keyStorageName: TQuickFiltersStorageNames.ticketFilterStorageName,
        path: AllTicketsPath.UNASSIGNED,
      }),
    );
    navigate('/app/tickets?tab=unassigned&&sort=firstResponseSla,DESC');
  };

  const redirectSla = () => {
    dispatch(
      removeStoredFiltersByPath({
        keyStorageName: TQuickFiltersStorageNames.ticketFilterStorageName,
        path: AllTicketsPath.OPEN,
      }),
    );
    navigate('/app/tickets?tab=open&&sort=resolutionSla,ASC');
  };

  const redirectOpenTickets = () => {
    dispatch(
      removeStoredFiltersByPath({
        keyStorageName: TQuickFiltersStorageNames.ticketFilterStorageName,
        path: AllTicketsPath.OPEN,
      }),
    );
    navigate('/app/tickets?tab=open&&sort=firstResponseSla,DESC');
  };
  const redirectAvgArgency = () => {
    dispatch(
      removeStoredFiltersByPath({
        keyStorageName: TQuickFiltersStorageNames.ticketFilterStorageName,
        path: AllTicketsPath.OPEN,
      }),
    );
    navigate('/app/tickets?tab=open&&sort=score,DESC');
  };
  const redirectCustomers = () => {
    dispatch(
      setStoredFilters({
        keyStorageName: TQuickFiltersStorageNames.companyStorageName,
        path: AllCompaniesPath.CUSTOMERS,
        filter: CompanyFilters.SPECIAL_FLAGS,
        ids: [
          'NEEDS_INTERACTION',
          'NEEDS_SCHEDULED_INTERACTION',
          'BLOCK_HOURS_LOW',
          'BLOCK_HOURS_DEPLETED',
          'BLOCK_HOURS_EXPIRED',
        ],
      }),
    );
    navigate('/app/customers/companies?tab=customers');
  };

  const handleItemClick = (type: GaugesEnum) => {
    switch (type) {
      case GaugesEnum.NEW_TICKETS:
        return redirectNewTickets;
      case GaugesEnum.SLA_CLOSE_TO_FAIL:
        return redirectSla;
      case GaugesEnum.OVERDUE_TICKETS:
        return redirectOverdueTicket;
      case GaugesEnum.OVERDUE_TASKS:
        return redirectOverdueTask;
      case GaugesEnum.OPEN_TICKETS:
        return redirectOpenTickets;
      case GaugesEnum.CUSTOMERS_NEEDING_INTERACTION:
        return redirectCustomers;
      case GaugesEnum.AVG_URGENCY:
        return redirectAvgArgency;
      default:
        return undefined;
    }
  };

  const gaugeData = useMemo(() => {
    return gauges?.map((gauge: TGaugesUi) => {
      return {
        iconName: gauge.gaugeContent.icon,
        tooltipData: gauge.metaData,
        color: gauge.color,
        name: gauge.gaugeContent.name,
        description: gauge.gaugeContent.description,
        type: gauge.type,
        ...(handleItemClick(gauge.type) && {
          onClick: handleItemClick(gauge.type),
        }),
      };
    });
  }, [gauges]);
  const onRefresh = async () => {
    await getGauges().unwrap();
  };
  useEffect(() => {
    // get gauges from BE, when there aren`t gauges collection in firebase yet
    if (!!data && !data.length) {
      onRefresh();
    }
  }, [data]);
  return (
    <Row id="dashbar_gauges" gutter={[12, 12]} align="top" wrap>
      {gaugeData?.map((gauge, index) => (
        <React.Fragment key={gauge.iconName}>
          <Col>
            <GaugeItem
              onClick={gauge.onClick}
              tooltipData={gauge.tooltipData}
              backgroundColor={gauge?.color}
              iconName={gauge.iconName}
              type={gauge.type}
              name={gauge.name}
              description={gauge.description}
            />
          </Col>

          {index === 0 && isHeaderCollapsed && (
            <Col>
              <SLine />
            </Col>
          )}
        </React.Fragment>
      ))}
      <Can permission={PermissionNames.RESET_GAUGE_DATA}>
        {gaugeData?.length && (
          <GaugeReset isLoading={getGaugesLoading} onRefresh={onRefresh} />
        )}
      </Can>
    </Row>
  );
};

export default Gauges;
