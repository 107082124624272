import { TValidationTags } from 'components/PasswordProgress/types';
import Tag from 'components/Tag/Tag';
import React, { FC } from 'react';
import {
  TPasswordValidationTag,
  TPasswordValidationTags,
} from 'components/PasswordProgress/PasswordValidationTags/types';
import { Icon, TIconNames } from 'components/Icon';
import { Colors } from 'core/CssVariables';
import SPasswordValidationTags from './style';

export const ValidationTag: FC<TPasswordValidationTag> = ({
  isValid,
  validationTag,
}) => {
  return (
    <Tag
      key={validationTag}
      icon={
        isValid ? (
          <Icon icon={TIconNames.DONE} size={12} color={Colors.SuccessColor} />
        ) : (
          <Icon icon={TIconNames.BLOCK} size={12} color={Colors.ErrorColor} />
        )
      }
      outline
    >
      {validationTag}
    </Tag>
  );
};

export const PasswordValidationTags: FC<TPasswordValidationTags> = ({
  passedValidations,
}) => {
  return (
    <SPasswordValidationTags>
      {Object.values(TValidationTags).map(item => (
        <ValidationTag
          key={item}
          isValid={passedValidations && passedValidations[item]}
          validationTag={item}
        />
      ))}
    </SPasswordValidationTags>
  );
};
