import { useCallback, useEffect, useState } from 'react';
import useDidUpdate from 'hooks/useDidUpdate';
import { ApiHookType } from 'api/hooks/hook.types';
import { TGauges, GaugesEnum, TGaugesUi } from 'data/types/gauges.types';
import firebase from 'configs/firebase.config';
import { allGaugesConverter } from 'data/converters/gauges.converter';
import { useAppSelector } from 'redux/store';
import { useGetGaugeFirebaseMutation } from 'redux/apiSlices/workFeed.slice';

const MSPS_COLLECTION = 'msps';
const GAUGES_COLLECTION = 'dashbarItems';
const GAUGES_DOC = 'item';
const GAUGES_LAST_UPDATE_DOC = 'lastUpdate';

const db = firebase.firestore();

const shouldUpdateFirebaseGuageData = async (id: string, delta: number): Promise<boolean> => {

  const doc = await db    
    .collection(MSPS_COLLECTION)
    .doc(id)
    .collection(GAUGES_COLLECTION)
    .doc(GAUGES_LAST_UPDATE_DOC)
    .get();

  if (doc && doc.data()) {
    const lastUpdate = ((doc.data() as any).date as number);
    if (lastUpdate) {
      if ((Date.now() - delta) < lastUpdate) {
        return false;
      }
    }
  }

  return true;
};

const updateGaugeDataInFirestore = async (id: string, data: any): Promise<void> => {
  if (id === data.data.organizationId) {
    await db
    .collection(MSPS_COLLECTION)
    .doc(id)
    .collection(GAUGES_COLLECTION)
    .doc(GAUGES_DOC)
    .set(data.data.dashbarItems);

    await db
    .collection(MSPS_COLLECTION)
    .doc(id)
    .collection(GAUGES_COLLECTION)
    .doc(GAUGES_LAST_UPDATE_DOC)
    .set({date: Date.now()});
  }
};

const useGetGauges = (
  onSuccess?: (result: TGaugesUi[]) => void,
  onError?: (error: Error) => void,
): ApiHookType<string, TGaugesUi[]> => {
  const [data, setData] = useState<TGaugesUi[] | null | undefined>(undefined);
  const [error, setError] = useState<Error | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dependency, setDependency] = useState<string>('');
  const logedInfirebase = useAppSelector(
    state => state.authSlice.logedInfirebase,
  );
  const refetch = useCallback(dep => {
    setDependency(dep);
  }, []);
  const [getGaugeFirebase] = useGetGaugeFirebaseMutation();
  useEffect(() => {
    const id = setInterval(async () => {
      if (await shouldUpdateFirebaseGuageData(dependency, 25000)) {
        updateGaugeDataInFirestore(dependency, await getGaugeFirebase());
      }
    }, 30000);

    return () => clearInterval(id);
  } , [dependency]);
  useDidUpdate(() => {
    let unsubscribe: () => void;
    if (dependency || logedInfirebase) {
      (async (): Promise<void> => {
        setIsLoading(true);
        unsubscribe = await db
          .collection(MSPS_COLLECTION)
          .doc(dependency)
          .collection(GAUGES_COLLECTION)
          .doc(GAUGES_DOC)
          .onSnapshot(
            snapshot => {
              const dbData = snapshot.data() as {
                [key in GaugesEnum]: TGauges;
              };
              const convertedData = allGaugesConverter.fromDb(dbData);
              setData(convertedData);
              setError(null);
              setIsSuccess(true);
              setIsLoading(false);
              if (onSuccess) {
                onSuccess(convertedData);
              }
            },
            err => {
              const errorObj = err as Error;
              setError(errorObj);
              setData(null);
              setIsSuccess(false);
              setIsLoading(false);
              if (onError) {
                onError(errorObj);
              }
            },
          );
      })();
    }
    return () => {
      unsubscribe();
    };
  }, [dependency, logedInfirebase]);
  return { data, error, isLoading, refetch, isSuccess };
};
export default useGetGauges;
