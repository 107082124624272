import React, { FC } from 'react';
import type { ColProps, ColSize } from 'components/Grid/Grid';
import SScrollableContainer from './ScrollableContainer.style';

const ScrollableContainer: FC<ColProps & ColSize> = ({
  className = '',
  ...props
}) => {
  return (
    <SScrollableContainer className={`customScroll ${className}`} {...props} />
  );
};

export default ScrollableContainer;
