import React, { FC, Key, useEffect, useMemo, useState } from 'react';
import { useTable } from 'hooks/useTable';
import { ColumnType } from 'antd/es/table';
import { AllTasksPath, TGetTaskUi, TTaskStatus } from 'data/types/task.type';
import { Link } from 'react-router-dom';
import Tag from 'components/Tag/Tag';
import { Icon, TIconNames } from 'components/Icon';
import { TAvailableFilter } from 'components/FilterComponents/FilterComponents.type';
import { useFilterTableHook } from 'components/FilterComponents/Hooks/useFilterTableHook';
import { Colors } from 'core/CssVariables';
import {
  useDeleteTaskByIdMutation,
  useGetAllTasksByTabQuery,
} from 'redux/apiSlices/task.slices';
import { ModalDeleteConfirmation } from 'components/Modal/Modal';
import { ModalWidthEnum } from 'components/Modal/Modal.types';
import OverdueLabel from 'components/OverdueLabel/OverdueLabel';
import { workFeedApi } from 'redux/apiSlices/workFeed.slice';
import { toast } from 'components/Notification';
import { useAppDispatch } from 'redux/store';
import { TQuickFiltersStorageNames } from 'helpers/storage';
import NameWithAvatar from 'components/Table/components/NameWithAvatar';
import moment from 'moment';
import { ETableNames } from 'components/Table/Table.types';
import { getDateTimeFormats } from 'helpers/utils';
import TaskTableWrapper from './TaskTableWrapper/TaskTableWrapper';
import { taskFilterConverter, TTaskStatusMapperColor } from '../AllTasks.type';
import { SStatusWrapper } from '../AllTasks.style';

export const TaskStatusMapperColor: TTaskStatusMapperColor = {
  [TTaskStatus.New]: Colors.SecondaryColor,
  [TTaskStatus.Closed]: Colors.LightGrey,
  [TTaskStatus.Completed]: Colors.SuccessColor,
  [TTaskStatus.Scheduled]: Colors.Orange,
  [TTaskStatus['In Progress']]: Colors.WarningColor,
};
type TTaskTableProps = {
  hasBulkAction: boolean;
  path: AllTasksPath;
  availabelFilters: TAvailableFilter[];
  tableName: ETableNames;
};
const TaskTable: FC<TTaskTableProps> = ({
  hasBulkAction,
  path,
  availabelFilters,
  tableName,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const [visibleConfirmationDelete, setVisibleConfirmationDelete] =
    useState<string>('');

  const {
    filters,
    filtersShow,
    setFilterShow,
    availableFilterList,
    onFilterSelect,
    onFilterShow,
    onClearAllSelectedFilters,
    selectedFiltersCount,
  } = useFilterTableHook({
    initialFilters: availabelFilters,
    path,
    keyStorageName: TQuickFiltersStorageNames.taskFilterStorageName,
  });

  const {
    page,
    pageSize,
    selectedRowKeys,
    sortColumn,
    setPageSize,
    setPage,
    setSelectedRowKeys,
    handleTableChange,
  } = useTable<TGetTaskUi>('creationDateTime,DESC');

  const { data, isLoading, isSuccess, isFetching } = useGetAllTasksByTabQuery({
    filters: taskFilterConverter(filters),
    sort: sortColumn,
    page,
    pageSize,
    path,
    search,
  });
  const [deleteTaskById, { isLoading: deleteTaskLoading }] =
    useDeleteTaskByIdMutation();

  const handleRowSelection = (keys: Key[]) => {
    setSelectedRowKeys(keys);
  };

  const handleDeleteTask = async () => {
    try {
      await deleteTaskById(visibleConfirmationDelete).unwrap();
      dispatch(workFeedApi.util.invalidateTags(['gauges']));
      toast({
        description: 'Task is deleted successfully',
        title: 'Deleting task',
        type: 'success',
      });
    } catch (error) {
      toast({
        description: (error as any)?.data.message,
        title: 'Deleting task',
        type: 'error',
      });
    }
    setVisibleConfirmationDelete('');
  };

  const columns: ColumnType<TGetTaskUi>[] = useMemo(
    () => [
      {
        title: 'Task Title',
        dataIndex: 'title',
        key: 'title',
        render: (text: string, record: TGetTaskUi) => (
          <Link to={`${record.id}`} className="bold-td">
            {record.number} &nbsp; {text}
          </Link>
        ),
      },
      {
        title: 'Primary resource',
        dataIndex: 'assignee',
        key: 'assignee',
        render: (assignee: string, record: TGetTaskUi) => (
          <NameWithAvatar
            name={record?.assignee?.name}
            logoUrl={record?.assignee?.photoUrl}
            firstLetters={record?.assignee?.firstLetters}
            size={24}
          />
        ),
      },
      {
        title: 'Created date',
        dataIndex: 'creationDateTime',
        key: 'creationDateTime',
        sorter: true,
        render: (date: string) =>
          moment(date).format(getDateTimeFormats().dateFormat),
      },
      {
        title: 'Reporter',
        dataIndex: 'reporter',
        render: (reporter: string, record: TGetTaskUi) => (
          <NameWithAvatar
            name={record?.reporter?.name}
            logoUrl={record?.reporter?.photoUrl}
            firstLetters={record?.reporter?.firstLetters}
            size={24}
          />
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status: TTaskStatus, record: TGetTaskUi) => (
          <SStatusWrapper>
            <Tag size="md" fontlevel={5} color={TaskStatusMapperColor[status]}>
              {status}
            </Tag>
            {record.overdueSince && <OverdueLabel />}
          </SStatusWrapper>
        ),
      },
      {
        key: 'action',
        dataIndex: '',
        width: 50,
        render: (row: any) => {
          return (
            <Icon
              onClick={() => setVisibleConfirmationDelete(row.id)}
              size={16}
              color={Colors.InfoColor}
              icon={TIconNames.DELETE}
              className="pointer"
            />
          );
        },
      },
    ],
    [sortColumn],
  );

  useEffect(() => {
    if (data && data.totalElements > 0 && data?.content.length === 0) {
      setPage(p => p - 1);
    }
  }, [data]);
  const onSearch = (str: string) => {
    setSearch(str);
  };
  const onClear = () => {
    setSearch('');
  };

  return (
    <>
      <TaskTableWrapper
        hasBulkAction={hasBulkAction}
        isSuccess={isSuccess}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        selectedRowKeys={selectedRowKeys}
        pageSize={pageSize}
        setPageSize={setPageSize}
        columns={columns}
        handleRowSelection={handleRowSelection}
        handleTableChange={handleTableChange}
        onSearch={onSearch}
        onClearSearch={onClear}
        searchText={search}
        onFilterSelect={onFilterSelect}
        onFilterShow={onFilterShow}
        filterList={availableFilterList}
        filtersShow={filtersShow}
        setFilterShow={setFilterShow}
        path={path}
        selectedFilterValues={filters}
        currentPage={page}
        onClearAllSelectedFilters={onClearAllSelectedFilters}
        selectedFiltersCount={selectedFiltersCount}
        tableName={tableName}
      />
      <ModalDeleteConfirmation
        visible={!!visibleConfirmationDelete}
        onOk={handleDeleteTask}
        onCancel={() => setVisibleConfirmationDelete('')}
        title="Delete task"
        okText="Delete"
        cancelText="Cancel"
        destroyOnClose
        confirmLoading={deleteTaskLoading}
        size={ModalWidthEnum.Small}
      />
    </>
  );
};

export default TaskTable;
