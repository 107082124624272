import { authSplitApi } from 'redux/helpers/slice.helpers';
import { TAuthUserUi, TAuthUser } from 'data/types';
import { userConverter } from 'data/converters/user.converters';

const GET_AUTH_USER = 'api/users';

export const getUserApi = authSplitApi('authUser', ['User']).injectEndpoints({
  endpoints: build => ({
    getUser: build.query<TAuthUserUi, any>({
      query() {
        return {
          url: GET_AUTH_USER,
          method: 'GET',
        };
      },
      providesTags: ['User'],
      keepUnusedDataFor: 60 * 60 * 24,
      transformResponse: (data: TAuthUser) => userConverter.fromDb(data),
    }),
    changeTheme: build.mutation<void, { resourceId: string; theme: string }>({
      query({ resourceId, theme }) {
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: GET_AUTH_USER.concat(`/${resourceId}/theme`),
          method: 'PATCH',
          body: { value: theme },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserQuery, useChangeThemeMutation } = getUserApi;
