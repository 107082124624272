import { OnboardState } from 'pages/Onboarding/Onboarding.type';

export type TAddressUi = {
  country: string;
  state: string;
  city: string;
  street: string;
  zipCode: string;
};
export type TAddressData = {
  country: string;
  state: string;
  city: string;
  street: string;
  zipCode: string;
};

export enum weekDays {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export type TWorkingHourDataUI = {
  dayOfWeek: weekDays;
  start: string;
  end: string;
}[];

export type TWorkingHourData = {
  dayOfWeek: weekDays;
  start: string;
  end: string;
}[];

export type TOrganizationDataUi = {
  id?: string;
  onboardState?: OnboardState;
  name: string;
  logoUrl?: string;
  timeZone: string;
  workingHours: TWorkingHourDataUI;
  size: string;
  website?: string;
  address: string;
  firstLetters: string;
  country: ECountryUI;
  nonWorkingDays?: TNonWorkingDaysUi[];
};

export type TOrganizationData = {
  id?: string;
  onboardState?: OnboardState;
  name: string;
  logoUrl?: string;
  timeZone: string;
  workingHours: TWorkingHourData;
  size: string;
  website?: string;
  country: ECountry;
  address: string;
  nonWorkingDays?: TNonWorkingDays[];
};

export type TPortalSettingsDnsRecords = {
  type: string;
  name: string;
  value: string;
};

export type TPortalSettingsUi = {
  domain: string;
  isVerified: boolean;
  isMisconfigured: boolean;
  isDomainNeedsVerified: boolean;
  dnsRecords: TPortalSettingsDnsRecords[];
};
export type TPortalSettings = {
  domain: string;
  isVerified: boolean;
  isMisconfigured: boolean;
  isDomainNeedsVerified: boolean;
  dnsRecords: TPortalSettingsDnsRecords[];
};

export type TEditPortalSubdomainUi = {
  value: string | null;
};
export type TEditPortalSubdomain = {
  value: string | null;
};

export type TDomainSuffixUi = {
  value: string;
};
export type TDomainSuffix = {
  value: string;
};

export type TEditOrganizationLogoUi = {
  value: string;
};
export type TEditOrganizationLogo = {
  value: string;
};

export type TEditOrganizationNameUi = {
  value: string;
};
export type TEditOrganizationName = {
  value: string;
};

export type TEditOrganizationWebsiteUi = {
  value: string;
};
export type TEditOrganizationWebsite = {
  value: string;
};

export type TNonWorkingDaysUi = {
  id?: string;
  day: string;
  repeatable: boolean;
};
export type TNonWorkingDays = {
  id?: string;
  date: string;
  repeatAnnually: boolean;
};
export enum ECountry {
  USA = 'USA',
  UK = 'UK',
  AUSTRALIA = 'Australia',
  CANADA = 'Canada',
  NEW_ZEALAND = 'New Zealand',
}
export enum ECountryUI {
  USA = 'USA',
  UK = 'UK',
  AUSTRALIA = 'Australia',
  CANADA = 'Canada',
  NEW_ZEALAND = 'New Zealand',
}
