import React from 'react';
import { Colors } from 'core/CssVariables';
import SStep from './Step.style';
import { TStepProps } from './Step.type';
import { Icon, TIconNames } from '../../Icon';

const Step = (props: TStepProps) => {
  const { current, index, step } = props;
  return (
    <SStep
      key={step}
      title={step}
      icon={
        current > index && (
          <Icon icon={TIconNames.DONE} size={16} color={Colors.White} />
        )
      }
      {...props}
    />
  );
};

export default Step;
