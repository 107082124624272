import React from 'react';
import useForm from 'hooks/useForm';
import Modal from 'components/Modal/Modal';
import { changeContactAddModal } from 'redux/slices';
import { ModalWidthEnum } from 'components/Modal/Modal.types';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useCreateContactMutation } from 'redux/apiSlices/contact.slice';
import { TContactPostUi } from 'data/types/contact.type';
import { toast } from 'components/Notification';
import { TRtkErrorType } from 'data/types/generalDataTypes';
import { AddContactForm } from './AddContactForm';

const AddContactModal = () => {
  const { form } = useForm();
  const dispatch = useAppDispatch();
  const { addContactModal } = useAppSelector(state => ({
    addContactModal: state.appSettings.addContactModal,
  }));

  const [createContact, { isLoading }] = useCreateContactMutation();

  const handleSubmitNewContact = async () => {
    const formValues = form.getFieldsValue() as TContactPostUi;
    if (addContactModal.companyDefaultId) {
      formValues.companyId = addContactModal.companyDefaultId;
    }
    try {
      await form.validateFields();
      await createContact(formValues).unwrap();
      dispatch(
        changeContactAddModal({
          visibleModal: false,
        }),
      );
      toast({
        description: 'Contact has been created successfully',
        title: 'Creating contact',
        type: 'success',
      });
    } catch (e) {
      toast({
        description: (e as TRtkErrorType)?.data.message,
        title: 'Creating contact',
        type: 'error',
      });
      dispatch(
        changeContactAddModal({
          visibleModal: false,
        }),
      );
    }
  };
  const handleCancelNewContact = () => {
    dispatch(
      changeContactAddModal({
        visibleModal: false,
      }),
    );
  };

  return (
    <Modal
      visible={addContactModal.visibleModal}
      onOk={handleSubmitNewContact}
      onCancel={handleCancelNewContact}
      title="Add new contact"
      okText="Create"
      cancelText="Cancel"
      destroyOnClose
      confirmLoading={isLoading}
      size={ModalWidthEnum.Medium}
    >
      <AddContactForm
        form={form}
        defaultCompanyId={addContactModal.companyDefaultId}
      />
    </Modal>
  );
};

export { AddContactModal };
