import { TAuthConverter } from 'data/types';
import { TPSLGet, TPSLGetUi, TPSLPost, TPSLPostUi } from 'data/types/psl.types';
import moment from 'moment';
import { currencyFormatter, getDateTimeFormats } from 'helpers/utils';
import { getCategoryConverter } from './category.converter';

export const pslConverter: TAuthConverter<
  TPSLPostUi,
  TPSLPost,
  TPSLGetUi,
  TPSLGet
> = {
  toDb: data => {
    return {
      name: data.name,
      cost: data.cost,
      price: data.price,
      type: data.type,
      ...(data.categoryId && { categoryId: data.categoryId }),
      ...(data.partNumber && { partNumber: data.partNumber }),
      ...(data.description && { description: data.description }),
    };
  },
  fromDb: data => {
    const priceString = currencyFormatter(data.price);
    const costString = currencyFormatter(data.cost);
    return {
      id: data.id,
      name: data.name,
      cost: data.cost,
      price: data.price,
      type: data.type,
      lastUpdated: moment(data.lastUpdated).format(
        getDateTimeFormats().dateFormat,
      ),
      ...(data.partNumber && { partNumber: data.partNumber }),
      priceString,
      costString,
      ...(data.category && {
        category: getCategoryConverter.fromDb(data.category),
      }),
      description: data.description,
    };
  },
};
