import {
  paymentHistoryConverter,
  paymentMethodConverter,
  paymentSubscriptionConverter,
} from 'data/converters/payment.converter';
import {
  TCreatePaymentMethod,
  TPaymentHistory,
  TPaymentHistoryUi,
  TPaymentMethod,
  TPaymentMethodUi,
  TPaymentSubscription,
  TPaymentSubscriptionUi,
} from 'data/types/payment.type';
import { authSplitApi } from 'redux/helpers/slice.helpers';

const PAYMENT_URL = 'api/zest-payments';

export const paymentApi = authSplitApi('paymentApi', [
  'paymentMethod',
  'paymentSubscription',
  'paymentHistory',
]).injectEndpoints({
  endpoints: build => ({
    addPaymentMethod: build.mutation<TPaymentMethodUi, TCreatePaymentMethod>({
      query(body) {
        return {
          url: `${PAYMENT_URL}/payment-method`,
          method: 'PUT',
          body: paymentMethodConverter.toDb(body),
        };
      },
      invalidatesTags: ['paymentMethod', 'paymentSubscription'],
      transformResponse: (data: TPaymentMethod): TPaymentMethodUi =>
        data && paymentMethodConverter.fromDb(data),
    }),
    getPaymentMethod: build.query<TPaymentMethodUi, void>({
      query() {
        return {
          url: `${PAYMENT_URL}/payment-method`,
          method: 'GET',
        };
      },
      providesTags: ['paymentMethod'],
      transformResponse: (data: TPaymentMethod) =>
        data && paymentMethodConverter.fromDb(data),
    }),
    getPaymentSubscription: build.query<TPaymentSubscriptionUi, void>({
      query() {
        return {
          url: `${PAYMENT_URL}/subscription`,
          method: 'GET',
        };
      },
      providesTags: ['paymentSubscription'],
      transformResponse: (data: TPaymentSubscription) =>
        data && paymentSubscriptionConverter.fromDb(data),
    }),
    cancelPaymentSubscription: build.mutation<void, void>({
      query() {
        return {
          url: `${PAYMENT_URL}/subscription`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['paymentSubscription'],
    }),
    retractPaymentSubscription: build.mutation<void, void>({
      query() {
        return {
          url: `${PAYMENT_URL}/subscription/retract`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['paymentSubscription'],
    }),
    subscribePayment: build.mutation<void, void>({
      query() {
        return {
          url: `${PAYMENT_URL}/subscription`,
          method: 'POST',
        };
      },
      invalidatesTags: [
        'paymentSubscription',
        'paymentSubscription',
        'paymentHistory',
      ],
    }),
    getPaymentHistory: build.query<TPaymentHistoryUi, void>({
      query() {
        return {
          url: `${PAYMENT_URL}/history`,
          method: 'GET',
        };
      },
      providesTags: ['paymentHistory'],
      transformResponse: (data: TPaymentHistory) =>
        paymentHistoryConverter.fromDb(data),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddPaymentMethodMutation,
  useGetPaymentMethodQuery,
  useGetPaymentSubscriptionQuery,
  useCancelPaymentSubscriptionMutation,
  useRetractPaymentSubscriptionMutation,
  useSubscribePaymentMutation,
  useGetPaymentHistoryQuery,
} = paymentApi;
