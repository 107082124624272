import { TFetchConverter, TCreateConverter } from 'data/types/converter.types';
import { TIssue, TIssueSubType, TIssueSubTypeUi, TIssueUi } from 'data/types';
import {
  TInactiveIssue,
  TInactiveIssueUi,
  TIssueCreate,
  TIssueCreateUi,
  TIssueSubtypeCreate,
  TIssueSubtypeCreateUi,
  TIssueSubtypeWithoutIssueType,
  TIssueSubtypeWithoutIssueTypeUi,
} from 'data/types/issues.types';
import { TCreatedType, EStatusUi } from 'data/types/generalDataTypes';

export const subIssueConverter: TFetchConverter<
  TIssueSubTypeUi,
  TIssueSubType
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      type: data.systemCreated ? TCreatedType.DEFAULT : TCreatedType.CREATED,
      status: data.hidden ? EStatusUi.INACTIVE : EStatusUi.ACTIVE,
      issueTypeId: data.issueTypeId,
    };
  },
};

export const issueConverter: TFetchConverter<TIssueUi, TIssue> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      type: data.systemCreated ? TCreatedType.DEFAULT : TCreatedType.CREATED,
      status: data.hidden ? EStatusUi.INACTIVE : EStatusUi.ACTIVE,
      queues:
        data.queues?.map(item => ({ value: item.id, label: item.name })) || [],
      subTypes: data.subTypes?.length
        ? data.subTypes.map(subIssue => subIssueConverter.fromDb(subIssue))
        : [],
    };
  },
};

export const createIssueConverter: TCreateConverter<
  TIssueCreateUi,
  TIssueCreate
> = {
  toDb: data => {
    return {
      name: data.name,
      queueIds: data.queues ? data.queues.map(item => item.value) : [],
      subTypes: data.subTypes.map(item => {
        return { name: item };
      }),
    };
  },
};
export const createIssueSubtypeConverter: TCreateConverter<
  TIssueSubtypeCreateUi,
  TIssueSubtypeCreate
> = {
  toDb: data => {
    return {
      name: data.name,
      issueTypeId: data.issueTypeId,
    };
  },
};

export const inactiveIssueConverter: TCreateConverter<
  TInactiveIssueUi,
  TInactiveIssue
> = {
  toDb: data => {
    return {
      id: data.id,
      hidden: data.status !== EStatusUi.INACTIVE,
    };
  },
};
export const IssueSubTypeForFilterConverter: TFetchConverter<
  TIssueSubtypeWithoutIssueTypeUi,
  TIssueSubtypeWithoutIssueType
> = {
  fromDb: data => {
    return {
      id: data.id,
      issueTypeName: data.issueTypeName,
      name: data.name,
    };
  },
};
