import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TFilter,
  TFilterPath,
} from 'components/FilterComponents/FilterComponents.type';
import { TQuickFiltersStorageNames, zestLocalStorage } from 'helpers/storage';

type TFiltersState = {
  filters: {
    [key in TQuickFiltersStorageNames]: {
      [path: string]: {
        [filter in TFilter]?: string[];
      };
    };
  };
};

const initialState: TFiltersState = {
  filters: {
    [TQuickFiltersStorageNames.devicesStorageName]:
      zestLocalStorage.getItem(TQuickFiltersStorageNames.devicesStorageName) ||
      {},
    [TQuickFiltersStorageNames.ticketFilterStorageName]:
      zestLocalStorage.getItem(
        TQuickFiltersStorageNames.ticketFilterStorageName,
      ) || {},
    [TQuickFiltersStorageNames.taskFilterStorageName]:
      zestLocalStorage.getItem(
        TQuickFiltersStorageNames.taskFilterStorageName,
      ) || {},
    [TQuickFiltersStorageNames.timeEntriesStorageName]:
      zestLocalStorage.getItem(
        TQuickFiltersStorageNames.timeEntriesStorageName,
      ) || {},
    [TQuickFiltersStorageNames.interactionsStorageName]:
      zestLocalStorage.getItem(
        TQuickFiltersStorageNames.interactionsStorageName,
      ) || {},
    [TQuickFiltersStorageNames.companyStorageName]:
      zestLocalStorage.getItem(TQuickFiltersStorageNames.companyStorageName) ||
      {},
    [TQuickFiltersStorageNames.invoicesStorageName]:
      zestLocalStorage.getItem(TQuickFiltersStorageNames.invoicesStorageName) ||
      {},
    [TQuickFiltersStorageNames.projectsStorageName]:
      zestLocalStorage.getItem(TQuickFiltersStorageNames.projectsStorageName) ||
      {},
    [TQuickFiltersStorageNames.companyItGoalsStorageName]:
      zestLocalStorage.getItem(
        TQuickFiltersStorageNames.companyItGoalsStorageName,
      ) || {},
    [TQuickFiltersStorageNames.allItGoalsStorageName]:
      zestLocalStorage.getItem(
        TQuickFiltersStorageNames.allItGoalsStorageName,
      ) || {},
    [TQuickFiltersStorageNames.contactsStorageName]:
      zestLocalStorage.getItem(TQuickFiltersStorageNames.contactsStorageName) ||
      {},
  },
};

export const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {
    setStoredFilters(
      state,
      action: PayloadAction<{
        keyStorageName: TQuickFiltersStorageNames;
        path: TFilterPath;
        filter: TFilter;
        ids: string[];
      }>,
    ) {
      const newState: TFiltersState = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.keyStorageName]: {
            ...state.filters[action.payload.keyStorageName],
            [action.payload.path]: {
              ...state.filters?.[action.payload.keyStorageName]?.[
                action.payload.path
              ],
              [action.payload.filter]: action.payload.ids,
            },
          },
        },
      };
      zestLocalStorage.setItem(
        action.payload.keyStorageName,
        newState.filters[action.payload.keyStorageName],
      );
      return newState;
    },
    removeStoredFiltersByPath(
      state,
      action: PayloadAction<{
        keyStorageName: TQuickFiltersStorageNames;
        path: TFilterPath;
      }>,
    ) {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.keyStorageName]: {
            ...state.filters[action.payload.keyStorageName],
            [action.payload.path]: {},
          },
        },
      };
      zestLocalStorage.setItem(
        action.payload.keyStorageName,
        newState.filters[action.payload.keyStorageName],
      );
      return newState;
    },
    removeAllFilters(state) {
      const newState = state;
      Object.keys(state.filters).forEach(keyStorageName => {
        newState.filters[keyStorageName as TQuickFiltersStorageNames] = {};
        zestLocalStorage.removeItem(
          keyStorageName as TQuickFiltersStorageNames,
        );
      });
      return newState;
    },
  },
});

export default filtersSlice;
