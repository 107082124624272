import styled from 'styled-components';
import { Colors, Screens } from 'core/CssVariables';

const OnboardingWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  .onboarding-header {
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 135px 0 120px;
  }

  .onboarding-main {
    flex: 1;
    overflow: auto;
    padding: 0 135px 40px 120px;

    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    ::-webkit-scrollbar {
      width: 4px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background: ${Colors.LightBorderColor};
    }

    ::-webkit-scrollbar-thumb {
      background: ${Colors.LightGrey};
      border-radius: 8px;
    }
  }
  .onboarding-footer {
    display: flex;
    padding: 0 135px 40px 120px;
    justify-content: end;
  }
  @media (max-width: ${Screens.ScreensXL}) {
    .onboarding-header,
    .onboarding-main {
      padding: 0 20px 40px;
      .ant-btn-link {
        justify-content: flex-start;
      }
    }
    .onboarding-footer {
      padding: 5px 20px 5px;
    }
  }

  @media (max-width: ${Screens.ScreensMD}) {
    .onboarding-main {
      .title {
        text-align: center;
      }
    }
    .onboarding-footer {
      button {
        width: 48%;
      }
    }
  }

  @media (max-width: ${Screens.ScreensSM}) {
    .onboarding-header {
      height: 160px;
      padding-top: 40px;
    }
  }
`;

export default OnboardingWrapper;
