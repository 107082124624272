export enum CompanyFilters {
  SPECIAL_FLAGS = 'specialFlags',
}
export enum CompanyFilterNames {
  'Special flags' = 'Special flags',
}

export const companyFilterConverter = (filters: {
  [key in CompanyFilters]?: string[];
}) => {
  const companyFilterToDB = {
    ...(filters?.specialFlags?.length && {
      [CompanyFilters.SPECIAL_FLAGS]: filters?.specialFlags,
    }),
  };
  return companyFilterToDB;
};
