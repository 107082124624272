import { TAuthConverter, TFetchConverter } from 'data/types/converter.types';
import {
  EPaymentHistoryItemStatus,
  EPaymentHistoryItemStatusUi,
  EPaymentInfoStatus,
  EPaymentInfoStatusUi,
  EPaymentSubscriptionActionStatus,
  EPaymentSubscriptionActionStatusUi,
  TCreatePaymentMethod,
  TCreatePaymentMethodUi,
  TPaymentHistory,
  TPaymentHistoryItem,
  TPaymentHistoryItemUi,
  TPaymentHistoryUi,
  TPaymentInfo,
  TPaymentInfoUi,
  TPaymentMethod,
  TPaymentMethodUi,
  TPaymentSubscription,
  TPaymentSubscriptionAction,
  TPaymentSubscriptionActionUi,
  TPaymentSubscriptionUi,
} from 'data/types/payment.type';
import { formatterUSD, getDateTimeFormats } from 'helpers/utils';
import moment from 'moment';

export const paymentInfoStatusConverter: TFetchConverter<
  EPaymentInfoStatusUi,
  EPaymentInfoStatus
> = {
  fromDb: data => {
    const status: { [key in EPaymentInfoStatus]: EPaymentInfoStatusUi } = {
      [EPaymentInfoStatus.TRIALING]: EPaymentInfoStatusUi.TRIALING,
      [EPaymentInfoStatus.ACTIVE]: EPaymentInfoStatusUi.ACTIVE,
      [EPaymentInfoStatus.PAST_DUE]: EPaymentInfoStatusUi.PAST_DUE,
      [EPaymentInfoStatus.CANCELED]: EPaymentInfoStatusUi.CANCELED,
    };
    return status[data];
  },
};
export const paymentInfoConverter: TFetchConverter<
  TPaymentInfoUi,
  TPaymentInfo
> = {
  fromDb: data => {
    return {
      paymentMethodExists: data?.paymentMethodExists,
      paymentStatus:
        data?.paymentStatus &&
        paymentInfoStatusConverter.fromDb(data?.paymentStatus),
      expirationDate: data?.expirationDate && data.expirationDate * 1000,
      pmValidDate: data.pmValidDate
        ? moment(data.pmValidDate).format(getDateTimeFormats().dateFormat)
        : '',
      cancelAtPeriodEnd: data.cancelAtPeriodEnd,
      isAccessLimited: data.paymentStatus === EPaymentInfoStatus.CANCELED,
    };
  },
};

export const paymentMethodConverter: TAuthConverter<
  TCreatePaymentMethodUi,
  TCreatePaymentMethod,
  TPaymentMethodUi,
  TPaymentMethod
> = {
  toDb: data => {
    return {
      value: data.value,
    };
  },
  fromDb: data => {
    return {
      endingNumber: data.endingNumber,
      name: data.name,
      brand: data.brand,
      expMonth: data.expMonth,
      expYear: data.expYear,
      expDate: moment()
        .month(data.expMonth - 1)
        .year(data.expYear)
        .format('MM/YY'),
      expFullDate: moment()
        .month(data.expMonth - 1)
        .endOf('month')
        .year(data.expYear)
        .format(getDateTimeFormats().dateFormat),
    };
  },
};

export const paymentSubscriptionActionStatusConverter: TFetchConverter<
  EPaymentSubscriptionActionStatusUi,
  EPaymentSubscriptionActionStatus
> = {
  fromDb: data => {
    const status: {
      [key in EPaymentSubscriptionActionStatus]: EPaymentSubscriptionActionStatusUi;
    } = {
      [EPaymentSubscriptionActionStatus.SUBSCRIBE]:
        EPaymentSubscriptionActionStatusUi.SUBSCRIBE,
      [EPaymentSubscriptionActionStatus.CANCEL_SUBSCRIPTION]:
        EPaymentSubscriptionActionStatusUi.CANCEL_SUBSCRIPTION,
      [EPaymentSubscriptionActionStatus.RETRACT_CANCELLATION]:
        EPaymentSubscriptionActionStatusUi.RETRACT_CANCELLATION,
    };
    return status[data];
  },
};

export const paymentSubscriptionActionConverter: TFetchConverter<
  TPaymentSubscriptionActionUi,
  TPaymentSubscriptionAction
> = {
  fromDb: data => {
    return {
      action: paymentSubscriptionActionStatusConverter.fromDb(data.action),
      possible: data.possible,
      reason: data.reason,
    };
  },
};

export const paymentSubscriptionConverter: TFetchConverter<
  TPaymentSubscriptionUi,
  TPaymentSubscription
> = {
  fromDb: data => {
    return {
      status: paymentInfoStatusConverter.fromDb(data.status),
      startDateTime: data.startDateTime,
      endDateTime: data.endDateTime,
      cancelAtPeriodEnd: data.cancelAtPeriodEnd,
      trialPeriodEndDateTime: data.trialPeriodEndDateTime,
      quantity: data.quantity,
      amountDue: formatterUSD.format(data.amountDue),
      nextPaymentDateTime:
        data.nextPaymentDateTime &&
        moment(data.nextPaymentDateTime).format('DD MMM, YYYY'),
      allowedActions: (data.allowedActions || []).map(action =>
        paymentSubscriptionActionConverter.fromDb(action),
      ),
    };
  },
};

export const paymentHistoryItemStatusConverter: TFetchConverter<
  EPaymentHistoryItemStatusUi,
  EPaymentHistoryItemStatus
> = {
  fromDb: data => {
    const status: {
      [key in EPaymentHistoryItemStatus]: EPaymentHistoryItemStatusUi;
    } = {
      [EPaymentHistoryItemStatus.DRAFT]: EPaymentHistoryItemStatusUi.DRAFT,
      [EPaymentHistoryItemStatus.PAID]: EPaymentHistoryItemStatusUi.PAID,
      [EPaymentHistoryItemStatus.OPEN]: EPaymentHistoryItemStatusUi.OPEN,
      [EPaymentHistoryItemStatus.UNCOLLECTIBLE]:
        EPaymentHistoryItemStatusUi.UNCOLLECTIBLE,
      [EPaymentHistoryItemStatus.VOID]: EPaymentHistoryItemStatusUi.VOID,
    };
    return status[data];
  },
};

export const paymentHistoryItemConverter: TFetchConverter<
  TPaymentHistoryItemUi,
  TPaymentHistoryItem
> = {
  fromDb: data => {
    const formatedStartDate = data.startDateTime
      ? moment(data.startDateTime).format(getDateTimeFormats().dateFormat)
      : '';
    const formatedEndDate = data.endDateTime
      ? moment(data.endDateTime).format(getDateTimeFormats().dateFormat)
      : '';
    const formattedLastAttemptDateTime = data.lastAttemptDateTime
      ? moment(data.lastAttemptDateTime).format(getDateTimeFormats().dateFormat)
      : '';
    const formattedNextAttemptDateTime = data.nextAttemptDateTime
      ? moment(data.nextAttemptDateTime).format(getDateTimeFormats().dateFormat)
      : '';
    return {
      creationDateTime: data.creationDateTime,
      amountDue: formatterUSD.format(data.amountDue),
      quantity: data.quantity,
      status:
        data.status && paymentHistoryItemStatusConverter.fromDb(data.status),
      invoiceId: data.invoiceId,
      invoiceUrl: data.invoiceUrl,
      lastAttemptDateTime: formattedLastAttemptDateTime,
      lastAttemptErrorMessage: data.lastAttemptErrorMessage,
      nextAttemptDateTime: formattedNextAttemptDateTime,
      billingPeriod: `${formatedStartDate} - ${formatedEndDate}`,
      lastAttemptCardLast4: data.lastAttemptCardLast4,
    };
  },
};

export const paymentHistoryConverter: TFetchConverter<
  TPaymentHistoryUi,
  TPaymentHistory
> = {
  fromDb: data => {
    return {
      items: data.items.length
        ? data.items.map(item => paymentHistoryItemConverter.fromDb(item))
        : [],
    };
  },
};
