import React from 'react';
import { DateRangePicker } from 'components/DatePicker/DatePicker';
import moment from 'moment';
import { getDateTimeFormats } from 'helpers/utils';
import { FilterWrapperItem } from '../FilterComponents.style';
import { TFilterProps } from '../FilterComponents.type';

const LocalDateRangeFilter: React.FC<TFilterProps> = ({
  onSelected,
  selectedValues,
  label,
}) => {
  const onChange = (values: any) => {
    if (values && values[0] && values[1]) {
      onSelected([
        `${values[0].format('YYYY-MM-DD')}`,
        `${values[1].format('YYYY-MM-DD')}`,
      ]);
    } else {
      onSelected([]);
    }
  };
  return (
    <FilterWrapperItem width="260px">
      <label htmlFor="date">{label || 'Date'}</label>
      <DateRangePicker
        onChange={value => onChange(value)}
        format={getDateTimeFormats().dateFormat}
        showNow
        {...(selectedValues.length && {
          value: [moment(selectedValues[0]), moment(selectedValues[1])],
        })}
      />
    </FilterWrapperItem>
  );
};

export default LocalDateRangeFilter;
