import { DefaultOptionType } from 'antd/lib/select';
import React from 'react';
import { TFilterProps } from '../../FilterComponents.type';

import { SelectFilterItem } from '../SelectFilterItem';

const ReporterFilter: React.FC<
  TFilterProps & { options?: DefaultOptionType[] }
> = ({ onSelected, selectedValues, options, label }) => {
  return (
    <SelectFilterItem
      options={options}
      label={label || 'Reporter'}
      onSelected={onSelected}
      selectedValues={selectedValues}
    />
  );
};

export { ReporterFilter };
