import Tag from 'antd/es/tag';
import styled from 'styled-components';
import { fontSizes, FontWeights } from 'core/CssVariables';
import { TPaddings, TTag } from './Tag.types';

const paddings: TPaddings = {
  sm: '2px 8px',
  md: '4px 8px',
  lg: '4px 12px',
};

const STag = styled(Tag)<TTag>`
  width: fit-content;
  font-weight: ${({ fontWeight }) => fontWeight || FontWeights.Regular};
  line-height: ${({ $lineHeight }) => $lineHeight || 16}px;
  padding: ${({ outline, size }) =>
    (outline && 0) || (size ? paddings[size] : paddings.sm)};
  border: ${({ outline }) => outline && 0};
  background-color: ${({ color }) => color || 'transparent'};
  font-size: ${({ fontlevel }) =>
    fontlevel ? fontSizes[fontlevel - 1] : 12}px;
  margin-right: 4px;
  .icon {
    margin-right: 2px;
    margin-left: 7px;
  }
  .ant-tag-close-icon {
    font-size: 12px;
    .icon {
      margin-right: 0;
      vertical-align: text-top;
    }
  }
`;
export default STag;
