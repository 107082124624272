import { TIconNames } from 'components/Icon';
import React from 'react';
import { changeSidebarState } from 'redux/slices';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { SSidebarExpandIcon } from '../Sidebar.style';

const CollapseIcons = () => {
  const dispatch = useAppDispatch();
  const collapsed = useAppSelector(
    state => state.appSettings.sidebar.collapsed,
  );
  return (
    <>
      {collapsed ? (
        <SSidebarExpandIcon
          size={24}
          icon={TIconNames.EXPAND_RIGHT}
          onClick={() =>
            dispatch(
              changeSidebarState({
                collapsed: false,
              }),
            )
          }
        />
      ) : (
        <SSidebarExpandIcon
          size={24}
          icon={TIconNames.COLLAPSE_LEFT}
          onClick={() =>
            dispatch(
              changeSidebarState({
                collapsed: true,
              }),
            )
          }
        />
      )}
    </>
  );
};

export default CollapseIcons;
