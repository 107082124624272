import Table from 'antd/es/table';
import { Menu } from 'components/Menu/Menu';
import { Colors, FontWeights, Spacing } from 'core/CssVariables';
import {
  Priority,
  TTicketPriorityMapperColor,
} from 'pages/private/Tickets/AllTickets/AllTickets.type';
import styled, { css, ThemeProps } from 'styled-components';
import { TThemeProps } from 'theme/theme';
import { TTableProps } from './Table.types';

const getRowClassBasedOnPriority = () => {
  const TicketPriorityMapperColor: TTicketPriorityMapperColor = {
    [Priority.Low]: Colors.LightGrey,
    [Priority.Medium]: Colors.InfoColor,
    [Priority.High]: Colors.WarningColor,
    [Priority.Urgent]: Colors.ErrorColor,
  };
  const listOfPriority = Object.keys(TicketPriorityMapperColor);
  return listOfPriority.map(priority => {
    return `.${priority} {
       .ant-table-cell  {
         &:first-child{
            &::before {
               content: '';
                height: 85%;
                width: 2px;
                background-color: ${
                  TicketPriorityMapperColor[priority as Priority]
                };
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
          }
        }
      }
    }`;
  });
};

const tableCheckboxCss = () => css`
  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 4px;
      border: 1px solid
        ${({ theme }: ThemeProps<TThemeProps>) =>
          theme.table.checkboxBorderColor};
      background-color: transparent;
      &::after {
        border-width: 3px;
      }
    }
    &.ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        border: 1px solid ${() => Colors.InfoColor};
        background-color: ${() => Colors.InfoColor};
        &::after {
          width: 8px;
          height: 3px;
          background-color: ${({ theme }: ThemeProps<TThemeProps>) =>
            theme.table.backgroundColor};
        }
      }
    }
    &.ant-checkbox-checked {
      &::after {
        border: none;
        border-color: ${({ theme }: ThemeProps<TThemeProps>) =>
          theme.table.backgroundColor};
      }
      .ant-checkbox-inner {
        &::after {
          border-color: ${({ theme }: ThemeProps<TThemeProps>) =>
            theme.table.backgroundColor};
        }
        border: 1px solid ${() => Colors.InfoColor};
        background-color: ${() => Colors.InfoColor};
      }
    }
  }
`;
const STable = styled(Table)<Omit<TTableProps, 'totalDataCount' | 'tableName'>>`
  .ant-table-content {
    &::-webkit-scrollbar {
      width: 4px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background: ${(props: ThemeProps<TThemeProps>) =>
        props.theme.scrollbar.track};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: ${(props: ThemeProps<TThemeProps>) =>
        props.theme.scrollbar.thumb};
    }
  }

  .ant-table {
    background-color: ${() => Colors.Transparent};
    table {
      .ant-table-thead tr th {
        white-space: nowrap;
        color: ${({ theme }) => theme.table.textColor};
        background-color: transparent;
        border-bottom: 1px solid ${({ theme }) => theme.table.borderColor};
        &.ant-table-selection-column {
          min-width: 50px;
          text-align: unset;
          ${({ selectHasOptions }) =>
            !selectHasOptions &&
            ` &::after {
              content: 'All';
              font-weight: 400;
              font-size: 14px;
              position: absolute;
              margin-left: ${Spacing.SpacingXS}px;
                min-height: 15px;
                border-bottom: 1px solid transparent;
              }
            `}
        }
        .ant-table-column-sorters {
          width: fit-content;
        }
        .ant-table-filter-column {
          width: fit-content;
        }
        &.ant-table-column-has-sorters {
          .ant-table-column-sorter-up {
            color: ${Colors.Grey};
          }
          .ant-table-column-sorter-down {
            color: ${Colors.Grey};
          }
        }
        &.ant-table-column-sort {
          .ant-table-column-sorters {
            .ant-table-column-title {
              color: ${({ theme }) => theme.table.sortersColor};
            }

            .ant-table-column-sorter-up.active,
            .ant-table-column-sorter-down.active {
              color: ${({ theme }) => theme.table.sortersColor};
            }
          }
        }
      }
      .ant-table-tbody {
        ${getRowClassBasedOnPriority()}
        tr {
          &:nth-child(odd) {
            background-color: ${({ theme }) => theme.table.backgroundColor};
          }
          background-color: ${({ theme }) => theme.secondaryBackgroundColor};
          &.ant-table-row {
            &:hover {
              td {
                background: transparent;
              }
            }
          }
          td {
            font-weight: ${FontWeights.Light};
            &.ant-table-cell-row-hover {
              background-color: ${({ theme }) =>
                theme.table.hoverRowBackgroundColor};
            }
            border-bottom: 1px solid ${({ theme }) => theme.table.borderColor};
            color: ${({ theme }) => theme.table.textColor};
            .editable-text {
              .ant-typography {
                font-weight: ${FontWeights.Light};
                color: ${({ theme }) => theme.table.textColor};
              }
            }
            &.ant-table-selection-column {
              text-align: left;
            }
            &.ant-table-cell {
              &:hover {
                background-color: ${({ theme }) =>
                  theme.table.hoverRowBackgroundColor};
              }
            }
            .bold-td {
              font-weight: 500;
              font-size: 14px;
              color: ${({ theme }) => theme.table.textColor};
            }
            .link-td {
              color: ${({ theme }) => theme.table.textColor};
            }
            .ant-table-wrapper:only-child .ant-table {
              margin: -8px -8px 0px 33px;
            }
          }
          &.ant-table-expanded-row > td {
            background-color: ${({ theme }) => theme.backgroundColor};
            &:hover {
              background-color: ${({ theme }) => theme.backgroundColor};
            }
          }
        }
      }
      ${tableCheckboxCss()}
    }
  }
  .table-collapsible-icon {
    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.table.expendedMenu};
    }
  }
  .expand-icon {
    &::before {
      height: 2px;
      background-color: white;
    }
    &::after {
      width: 2px;
    }
    &::before,
    &::after {
      background-color: ${({ theme }) => theme.table.expendedMenuIcon};
    }
    width: 18px;
    height: 18px;
    background-color: ${({ theme }) => theme.table.expendedMenu};
    border-color: ${({ theme }) => theme.table.expendedMenu};
    border-radius: 0;
    &.ant-table-row-expand-icon-expanded {
      background-color: ${Colors.SecondaryColor};
      border-color: ${Colors.SecondaryColor};
      &::before,
      &::after {
        background-color: ${Colors.White};
      }
    }
  }
  .ant-pagination {
    display: flex;
    align-items: center;
    .ant-pagination-total-text {
      color: ${({ theme }) => theme.table.textColor};
      font-weight: 400;
      font-size: 16px;
    }
    .ant-pagination-item-link {
      border: none;
      background-color: ${() => Colors.Transparent};
      color: ${({ theme }) => theme.table.textColor};
    }
    .ant-pagination-item {
      border: none;
      background-color: ${() => Colors.Transparent};
      border-radius: 4px;
      a {
        color: ${({ theme }) => theme.table.textColor};
      }
      &.ant-pagination-item-active {
        background-color: ${() => Colors.InfoColor};
        a {
          color: ${() => Colors.White};
        }
      }
    }
    .ant-pagination-options {
      .ant-select-selector {
        border-radius: 4px;
        background-color: ${({ theme }) => theme.table.pagesizeBackgroundColor};
        border: 1px solid ${({ theme }) => theme.table.borderColor};
        color: ${({ theme }) => theme.table.textColor};
      }
      .ant-select-arrow {
        .anticon-down {
          color: ${({ theme }) => theme.table.textColor};
        }
      }
    }
  }
  // table footer style
  .ant-table-summary tr td {
    background-color: ${({ theme }) => theme.backgroundColor};
    border-bottom: 0px;
  }

  .invalid-row-table {
    background-color: ${Colors.RedOpacity} !important;
    &:first-child {
      td:first-child {
        border-top: 1px solid ${Colors.ErrorColor} !important;
        /* border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-left: 1px solid blue !important; */
      }
    }
    td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left: 1px solid ${Colors.ErrorColor} !important;
    }
    td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid ${Colors.ErrorColor} !important;
    }
    td {
      &.ant-table-cell-row-hover {
        background-color: ${Colors.RedOpacity} !important;
      }
      border-bottom: 1px solid ${Colors.ErrorColor} !important;
      border-top: 1px solid ${Colors.ErrorColor} !important;
      /* border-top: 1px solid blue !important; */
      /* color: ${({ theme }) => theme.table.textColor}; */
      /* &.ant-table-selection-column {
        text-align: left;
      } */
      &.ant-table-cell {
        &:hover {
          background-color: ${Colors.RedOpacity} !important;
        }
      }
    }
  }
`;

const SFilterWrapper = styled.div`
  padding: 10px 10px;
  width: 250px;
  background: ${({ theme }) => theme.table.filterBackgroundColor};
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  position: relative;
  .result-list {
    list-style-type: none;
    padding: 10px 5px;
    li {
      padding: 8px 0;
      .ant-checkbox-wrapper {
        color: ${({ theme }) => theme.table.checkboxLabelColor};
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          border-radius: 4px;
          border: 1px solid ${({ theme }) => theme.table.checkboxBorderColor};
          background-color: transparent;
          &::after {
            border-width: 3px;
          }
        }
        &.ant-checkbox-indeterminate {
          .ant-checkbox-inner {
            border: 1px solid ${() => Colors.InfoColor};
            background-color: ${() => Colors.InfoColor};
            &::after {
              width: 8px;
              height: 3px;
              background-color: ${({ theme }) => theme.table.backgroundColor};
            }
          }
        }
        &.ant-checkbox-checked {
          &::after {
            border: none;
            border-color: ${({ theme }) => theme.table.backgroundColor};
          }
          .ant-checkbox-inner {
            &::after {
              border-color: ${({ theme }) => theme.table.backgroundColor};
            }
            border: 1px solid ${() => Colors.InfoColor};
            background-color: ${() => Colors.InfoColor};
          }
        }
      }
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    .reset-button {
      margin-right: 10px;
    }
    .save-button {
      width: 59px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
type TFilterIconWrapperProps = {
  active: boolean;
};
const SFilterIconWrapper = styled.div<TFilterIconWrapperProps>`
  color: ${({ active }) => (active ? Colors.Orange : Colors.Grey)};
  .icon {
    fill: ${({ active }) => (active ? Colors.Orange : Colors.Grey)};
  }
  span {
    margin-left: 6px;
  }
`;
const SSelectedRowWrapper = styled.div`
  display: flex;
  height: 40px;
  margin: ${Spacing.SpacingLG}px 0;
  background-color: ${Colors.LightPurple};
  padding: 0 ${Spacing.SpacingSM}px;
  align-items: center;
`;
const SColumnWrapper = styled.div<{
  width?: number;
  minWidth?: number;
  preline?: boolean;
}>`
  width: ${({ width }) => width && `${width}px`};
  min-width: ${({ minWidth }) => minWidth && `${minWidth}px`};
  white-space: ${({ preline }) => (preline ? 'pre-line' : '')};
`;

const SDeleteIconWrapper = styled.div`
  padding: 16px;
  cursor: pointer;
`;

const SSettingMenu = styled(Menu)`
  border: 1px solid
    ${({ theme }: ThemeProps<TThemeProps>) => theme.table.borderColor};
  .ant-menu-item,
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${({ theme }: ThemeProps<TThemeProps>) =>
      theme.dropdown.backgroundColor};
    &:hover {
      .ant-checkbox-wrapper {
        background-color: ${Colors.LightPurple};
        & > span {
          color: ${Colors.SecondaryColor};
        }
      }
    }
  }
  .ant-checkbox-wrapper {
    width: 100%;
    padding: 0px 12px;
    background-color: ${({ theme }: ThemeProps<TThemeProps>) =>
      theme.dropdown.backgroundColor};
    & > span {
      color: ${({ theme }: ThemeProps<TThemeProps>) => theme.color};
    }
    ${tableCheckboxCss()}
  }
`;

export {
  STable,
  SFilterWrapper,
  SFilterIconWrapper,
  SSelectedRowWrapper,
  SColumnWrapper,
  SDeleteIconWrapper,
  SSettingMenu,
};
