import { lazy } from 'react';
import { TIconNames } from 'components/Icon';
import { Outlet } from 'react-router-dom';
import { IntegrationsRoutes } from 'routes/SubRoutes/Integrations';
import { PrivateRouteType, PublicRouteType, RoutesPaths } from './Routes.types';
import { TUserRoleUi } from '../data/types/user.types';
import { CustomerSubRoutes } from './SubRoutes/Customers';
import { ConfigurationSubRoutes } from './SubRoutes/Configurations';
import { TeamManagementSubRoutes } from './SubRoutes/TeamManagement';
import { TicketRoutes } from './SubRoutes/Tickets';
import { TaskRoutes } from './SubRoutes/Tasks';
import { ProjectRoutes } from './SubRoutes/Projects';
import { ReportsSubroutes } from './SubRoutes/Reoprts';

const UiKit = lazy(() => import('pages/public/ComponentForTest'));
const Login = lazy(() => import('pages/public/Login/Login'));
const SignUp = lazy(() => import('pages/public/SignUp/SignUp'));

const EmailVerification = lazy(
  () => import('pages/public/EmailVerification/EmailVerification'),
);
const ConfirmInvitation = lazy(
  () => import('pages/public/ConfirmInvitation/ConfirmInvitation'),
);
const ResetPassword = lazy(
  () => import('pages/public/ResetPassword/ResetPassword'),
);
const Workfeed = lazy(() => import('pages/private/WorkFeed'));
const Timesheet = lazy(() => import('pages/private/Timesheet/Timesheet'));
const TimeEntries = lazy(() => import('pages/private/TimeEntries/TimeEntries'));

const Profile = lazy(() => import('pages/private/Profile/Profile'));
const Invoices = lazy(
  () => import('pages/private/Invoices/AllInvoices/AllInvoices'),
);
const Integrations = lazy(() => import('pages/private/Integrations'));
const Payment = lazy(() => import('pages/private/Payment/Payment'));

const PageNotFound = lazy(() => import('components/PageNotFound/PageNotFound'));
const AccessLimitedTech = lazy(
  () => import('components/AccessLimited/AccessLimitedTech'),
);
export const privateRoutes: PrivateRouteType[] = [
  {
    title: 'Workfeed',
    component: Workfeed,
    path: RoutesPaths.Workfeed,
    index: true,
    icon: TIconNames.DASHBOARD,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
  {
    title: 'Tickets',
    component: Outlet,
    path: RoutesPaths.Tickets,
    icon: TIconNames.TICKET,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    subRoutes: TicketRoutes,
  },
  {
    title: 'Tasks',
    component: Outlet,
    path: RoutesPaths.Tasks,
    icon: TIconNames.TASK,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    subRoutes: TaskRoutes,
  },
  {
    title: 'Customers',
    component: Outlet,
    path: RoutesPaths.Customers,
    icon: TIconNames.PEOPLE,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    subRoutes: CustomerSubRoutes,
  },
  {
    title: 'Timesheet',
    component: Timesheet,
    path: RoutesPaths.Timesheet,
    icon: TIconNames.CLOCK_FILLED,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
  {
    title: 'Time Entries',
    component: TimeEntries,
    path: RoutesPaths.TIME_ENTRIES,
    icon: TIconNames.TIME_ENTRY,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  },
  {
    title: 'Projects',
    component: Outlet,
    path: RoutesPaths.Projects,
    icon: TIconNames.LAPTOP,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    subRoutes: ProjectRoutes,
  },
  {
    title: 'Reports',
    component: Outlet,
    path: RoutesPaths.Reports,
    icon: TIconNames.REPORT,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    subRoutes: ReportsSubroutes,
  },
  {
    title: 'Team Management',
    component: Outlet,
    path: RoutesPaths.TeamManagement,
    icon: TIconNames.MANAGEMENT,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
    subRoutes: TeamManagementSubRoutes,
  },
  {
    title: 'Invoices',
    component: Invoices,
    path: RoutesPaths.Invoices,
    icon: TIconNames.GENERATE_INVOICE,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  },
  {
    title: 'Payment',
    component: Payment,
    path: RoutesPaths.Payment,
    icon: TIconNames.PAYMENT,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  },
  {
    title: 'Configurations',
    component: Outlet,
    path: RoutesPaths.Configurations,
    icon: TIconNames.SETTINGS,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    subRoutes: ConfigurationSubRoutes,
  },
  {
    title: 'Integrations',
    component: Integrations,
    path: RoutesPaths.Integrations,
    icon: TIconNames.INTEGRATION,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
    subRoutes: IntegrationsRoutes,
  },

  // add notInSidebar true in case it mustn't be in sidebar
  {
    component: Profile,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    title: 'Profile',
    notInSidebar: true,
    path: RoutesPaths.Profile,
  },
  {
    component: PageNotFound,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    title: 'PageNotFound',
    notInSidebar: true,
    path: RoutesPaths.PageNotFound,
  },
  {
    component: AccessLimitedTech,
    roles: [TUserRoleUi.Technician],
    title: 'AccessLimitedTechnician',
    notInSidebar: true,
    path: RoutesPaths.AccessLimitedTechnician,
  },
];

export const publicRoutes: PublicRouteType[] = [
  {
    component: Login,
    path: RoutesPaths.Login,
  },
  {
    component: SignUp,
    path: RoutesPaths.SignUp,
  },
  {
    component: UiKit,
    path: RoutesPaths.UiKit,
  },
  {
    component: EmailVerification,
    path: RoutesPaths.EmailVerification,
  },
  {
    component: ConfirmInvitation,
    path: RoutesPaths.ConfirmInvitation,
  },
  {
    component: ResetPassword,
    path: RoutesPaths.ResetPassword,
  },
];
