import React, { FC, useMemo } from 'react';
import Table from 'components/Table/Table';
import { Col, Row } from 'components/Grid/Grid';
import ScrollableContainer from 'components/Layout/ScrollableContainer/ScrollableContainer';
import noResultImg from 'assets/images/noResult.svg';
import { Icon, TIconNames } from 'components/Icon';
import noTicketImg from 'assets/images/noTicketImg.svg';
import NoResultWrapper from 'components/NoResultWrapper/NoResultWrapper';
import { DropdownMenuItem } from 'components/Dropdown/Dropdown';
import { QuickFilterButton } from 'components/QuickFilterButton/QuickFilterButton';
import LocalDateRangeFilter from 'components/FilterComponents/components/LocalDateRangeFilter';
import { CompanyFilter } from 'components/FilterComponents/components/CompanyFilter/CompanyFilter';
import { PageSpinner } from 'components/Spinner/Spinner';
import { TaskFilters } from 'data/types/task.type';
import { Search } from 'components/Search/Search';
import { FilterWrapper } from 'components/FilterComponents/FilterComponents.style';
import AssignedTaskFilter from 'components/FilterComponents/components/AssignedFilter/AssignedTaskFilter';
import StatusTaskFilter from 'components/FilterComponents/components/StatusFilter/StatusTaskFilter';
import { TFilter } from 'components/FilterComponents/FilterComponents.type';
import { OverdueFilter } from 'components/FilterComponents/components/OverdueFilter';
import ReporterTaskFilter from 'components/FilterComponents/components/ReporterFilter/ReporterTaskFilter';
import { FilterTaskWrapper } from '../../AllTasks.style';
import { TTaskTableWrapperProps } from './TaskTableWrapper.type';

const TaskTableWrapper: FC<TTaskTableWrapperProps> = ({
  data,
  isLoading,
  isSuccess,
  isFetching,
  // selectedRowKeys,
  pageSize,
  setPageSize,
  columns,
  // handleRowSelection,
  handleTableChange,
  hasBulkAction,
  onClearSearch,
  onSearch,
  searchText,
  onFilterSelect,
  onFilterShow,
  filterList,
  setFilterShow,
  filtersShow,
  path,
  selectedFilterValues,
  selectedFiltersCount,
  onClearAllSelectedFilters,
  tableName,
}) => {
  const FiltersMapper: { [key in TFilter]?: any } = {
    [TaskFilters.ASSIGNED]: AssignedTaskFilter,
    [TaskFilters.STATUS]: StatusTaskFilter,
    [TaskFilters.CREATION_DATE]: LocalDateRangeFilter,
    [TaskFilters.COMPANY]: CompanyFilter,
    [TaskFilters.OVERDUE]: OverdueFilter,
    [TaskFilters.REPORTER]: ReporterTaskFilter,
  };

  const renderFilters = useMemo(() => {
    if (filtersShow && filterList?.length) {
      const result = filterList.reduce((res: JSX.Element[], item) => {
        if (item.show) {
          const Component = FiltersMapper[item.filter as TaskFilters];
          res.push(
            <Component
              key={item.filter}
              onSelected={onFilterSelect(item.filter)}
              selectedValues={selectedFilterValues?.[item.filter] || []}
              path={path}
            />,
          );
        }
        return res;
      }, []);

      return (
        <FilterWrapper>
          {result}
          <DropdownMenuItem
            trigger={['click']}
            buttonIcon={<Icon icon={TIconNames.PLUS} />}
            buttonTitle="Add more"
            buttonClassName="mt-5"
            onClickItem={item => {
              onFilterShow(item);
            }}
            items={filterList.map(item => ({
              key: item.filter,
              name: item.filterName,
              selected: item.show,
            }))}
          />
        </FilterWrapper>
      );
    }
    return undefined;
  }, [filtersShow, selectedFilterValues, filterList]);

  if (!isSuccess && isLoading) {
    return <PageSpinner size="large" />;
  }

  return (
    <Row>
      <ScrollableContainer xs={24} sm={24} md={24} lg={24} xxl={24}>
        <NoResultWrapper
          noResult={
            !data?.content.length &&
            !isLoading &&
            !Object.keys(selectedFilterValues).length &&
            !searchText &&
            !isFetching
          }
          text="You don’t have any tasks yet"
          img={noTicketImg}
        >
          <Row align="middle" className="mt-2" gutter={[16, 16]}>
            <FilterTaskWrapper hasBulkAction={hasBulkAction}>
              <div className="button-wrapper">
                {/* <Button
                  className="ml-2 mr-4"
                  outline
                  disabled={selectedRowKeys.length === 0}
                  color={TButtonTypes.Info}
                  icon={<Icon icon={TIconNames.DELETE} />}
                  onClick={() => {
                    console.log('click delete btn');
                  }}
                  size="small"
                >
                  Delete
                </Button> */}

                {/* {hasBulkAction && (
                  <DropdownMenuItem
                    trigger={['click']}
                    buttonColor={TButtonTypes.Info}
                    buttonSize="small"
                    buttonDownIcon
                    buttonType="primary"
                    buttonTitle="Change ticket properties"
                    onClickItem={item =>
                      console.log(item, 'selected bulk action name')
                    }
                    items={[
                      {
                        key: 'Primary resource',
                        name: 'Primary resource',
                        selected: false,
                      },
                      {
                        key: 'Status',
                        name: 'Status',
                        selected: false,
                      },
                    ]}
                  />
                )} */}

                <QuickFilterButton
                  selected={filtersShow}
                  onClick={() => setFilterShow(!filtersShow)}
                  className="ml-4 quick-filter-button"
                  onResetFilters={onClearAllSelectedFilters}
                  selectedFiltersCount={selectedFiltersCount}
                />
              </div>
              <Search
                placeholder="Search"
                onClear={onClearSearch}
                onSearch={onSearch}
                hasFilter={!!searchText}
                className="search-task"
              />
            </FilterTaskWrapper>
          </Row>
          <Row>
            <Col span={24}>{renderFilters}</Col>
          </Row>
          <Table
            scroll={{ x: true }}
            totalDataCount={data?.totalElements || 0}
            pageSize={pageSize}
            setPageSize={setPageSize}
            columns={columns}
            dataSource={data?.content}
            // rowSelection={{
            //   onChange: handleRowSelection,
            //   // selectedRowKeys,
            //   columnWidth: 45,
            // }}
            onChange={handleTableChange}
            locale={{
              emptyText: (
                <NoResultWrapper
                  noResult={!isFetching}
                  text="No Results found"
                  img={noResultImg}
                />
              ),
            }}
            loading={isFetching}
            tableName={tableName}
          />
        </NoResultWrapper>
      </ScrollableContainer>
    </Row>
  );
};

export default TaskTableWrapper;
