import styled from 'styled-components';
import { Screens } from 'core/CssVariables';

const SSidebarSwitcher = styled.div`
  display: none;
  .icon {
    fill: ${props => props.theme.color};
  }
  @media (max-width: ${Screens.ScreensMD}) {
    display: block;
  }
`;

export default SSidebarSwitcher;
