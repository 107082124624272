import { Colors } from 'core/CssVariables';
import styled, { ThemeProps } from 'styled-components';
import { TThemeProps } from 'theme/theme';
import { TSWorkingHours } from './WorkingHours.type';

const SWorkingHours = styled.div<TSWorkingHours>`
  padding-right: ${({ hasCloseIcon, disabled }) =>
    !disabled && hasCloseIcon && 40}px;
  position: relative;
  margin-bottom: 24px;
  .ant-picker {
    background-color: ${({ theme }: ThemeProps<TThemeProps>) =>
      theme.datePicker.backgroundColor};
    border-color: ${({ theme }: ThemeProps<TThemeProps>) =>
      `1px ${theme.datePicker.borderColor}`};
    .ant-picker-suffix {
      color: ${({ disabled }) =>
        disabled ? Colors.DisabledGrey : Colors.LightGrey};
    }
    .ant-picker-range-separator {
      .ant-picker-separator {
        color: ${({ disabled }) =>
          disabled ? Colors.DisabledGrey : Colors.LightGrey};
      }
    }
  }
  .remove-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

export default SWorkingHours;
