import { lazy } from 'react';
import { TIconNames } from 'components/Icon';
import { TUserRoleUi } from 'data/types/user.types';
import { PrivateRouteType, RoutesPaths } from '../Routes.types';

const ServiceQueue = lazy(
  () => import('pages/private/Reports/ServiceQueue/ServiceQueue'),
);
const TechPerf = lazy(() => import('pages/private/Reports/TechPerf/TechPerf'));
const CustomerPerf = lazy(
  () => import('pages/private/Reports/CustomerPerf/CustomerPerf'),
);
const MspPerf = lazy(() => import('pages/private/Reports/MspPerf/MspPerf'));

export const ReportsSubroutes: PrivateRouteType[] = [
  {
    title: 'MSP Perf',
    component: MspPerf,
    path: RoutesPaths.ReportsMsp,
    index: true,
    icon: TIconNames.REPORT_MSP,
    roles: [TUserRoleUi.Owner],
  },
  {
    title: 'Customer Perf',
    component: CustomerPerf,
    path: RoutesPaths.ReportsCustomer,
    index: true,
    icon: TIconNames.REPORT_CUSTOMER,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
  {
    title: 'Service Queue Perf',
    component: ServiceQueue,
    path: RoutesPaths.ReportsServiceQueue,
    index: true,
    icon: TIconNames.REPORT_QUEUE,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
  {
    title: 'Tech Perf',
    component: TechPerf,
    path: RoutesPaths.ReportsTech,
    index: true,
    icon: TIconNames.REPORT_TECH,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
];
