import { authSplitApi } from 'redux/helpers/slice.helpers';
import {
  assignmentTypeAsParamConverter,
  timeEntryAssignmentConverter,
  timeEntryFiltersConverter,
  timeEntryGetConverter,
  timeEntryPerAssignmentRootConverter,
  timeEntryPostConverter,
  timesheetInfoGetConverter,
  timeTrackerConverter,
} from 'data/converters/timeEntry.converters';
import {
  AssignmentEnumUi,
  TTimeEntryAssignments,
  TTimeEntryAssignmentsUi,
  TTimeEntryFiltersUi,
  TTimeEntryGet,
  TTimeEntryGetUi,
  TTimeEntryPerAssignmentRoot,
  TTimeEntryPerAssignmentRootUi,
  TTimeEntryPost,
  TTimeEntryPostUi,
  TTimeEntryUpdateUi,
  TTimeSheetInfo,
  TTimeSheetInfoUi,
  TTimeTracker,
  TTimeTrackerUi,
} from 'data/types/timeEntry.types';
import { workTypesConverters } from 'data/converters/workTypes.converters';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import { WorkTypeEnum, WorkTypeEnumUi } from 'data/types/workTypes.types';

const TIME_ENTRY = 'api/time-entry';
const TIME_ENTRY_WORK_TYPES = `${TIME_ENTRY}/work-types`;

export const timeEntryApi = authSplitApi('TimeSheetInfo', [
  'TimeSheetInfo',
  'TimeTracker',
  'TimeSheetPerAssignment',
]).injectEndpoints({
  endpoints: build => ({
    createTimeEntry: build.mutation<TTimeEntryPost, TTimeEntryPostUi>({
      query(body) {
        return {
          url: TIME_ENTRY,
          method: 'POST',
          body: timeEntryPostConverter.toDb(body),
        };
      },
      invalidatesTags: ['TimeSheetInfo', 'TimeSheetPerAssignment'],
    }),
    editTimeEntry: build.mutation<TTimeEntryPost, TTimeEntryUpdateUi>({
      query(body) {
        return {
          url: `${TIME_ENTRY}/${body.id}`,
          method: 'PATCH',
          body: timeEntryPostConverter.toDb(body),
        };
      },
      invalidatesTags: ['TimeSheetInfo', 'TimeSheetPerAssignment'],
    }),
    deleteTimeEntry: build.mutation<void, string>({
      query(id) {
        return {
          url: `${TIME_ENTRY}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['TimeSheetInfo', 'TimeSheetPerAssignment'],
    }),
    getAllTimeEntries: build.query<
      TPageableDataWithContentUi<TTimeEntryGetUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        filters?: TTimeEntryFiltersUi;
        search?: string;
      }
    >({
      query({ page, pageSize, sort, filters, search }) {
        const urlWithParams = TIME_ENTRY.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(filters && { ...timeEntryFiltersConverter.toDb(filters) }),
            ...(search && { search }),
          },
        };
      },
      transformResponse: (data: TPageableDataWithContent<TTimeEntryGet[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(entry => timeEntryGetConverter.fromDb(entry))
            : [],
        };
      },
      providesTags: ['TimeSheetInfo'],
    }),
    getTimesheetInfo: build.query<
      TTimeSheetInfoUi,
      { startTime: string; endTime: string }
    >({
      query({ startTime, endTime }) {
        return {
          url: `${TIME_ENTRY}/timesheet/current-user`,
          method: 'GET',
          params: {
            from: startTime,
            to: endTime,
          },
        };
      },
      transformResponse: (data: TTimeSheetInfo) =>
        timesheetInfoGetConverter.fromDb(data),
      providesTags: ['TimeSheetInfo'],
    }),
    getAdminTimesheetInfo: build.query<
      TTimeSheetInfoUi,
      { startTime: string; endTime: string; ownerId: string }
    >({
      query({ startTime, endTime, ownerId }) {
        return {
          url: `${TIME_ENTRY}/timesheet`,
          method: 'GET',
          params: {
            'dateInterval.to': endTime,
            'dateInterval.from': startTime,
            ownerId,
          },
        };
      },
      transformResponse: (data: TTimeSheetInfo) =>
        timesheetInfoGetConverter.fromDb(data),
      providesTags: ['TimeSheetInfo'],
    }),
    getWorkTypes: build.query<WorkTypeEnumUi[], void>({
      query() {
        return {
          url: TIME_ENTRY_WORK_TYPES,
          method: 'GET',
        };
      },
      transformResponse: (data: WorkTypeEnum[]) => {
        return data?.length
          ? data.map(workType => workTypesConverters.fromDb(workType))
          : [];
      },
    }),
    stopTimeTracker: build.mutation<TTimeEntryPost, TTimeEntryPostUi>({
      query(body) {
        return {
          url: `${TIME_ENTRY}/time-tracker/stop`,
          method: 'POST',
          body: timeEntryPostConverter.toDb(body),
        };
      },
      invalidatesTags: ['TimeTracker', 'TimeSheetInfo'],
    }),
    endTimeTracker: build.mutation<TTimeEntryPost, void>({
      query() {
        return {
          url: `${TIME_ENTRY}/time-tracker/cancel`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['TimeTracker', 'TimeSheetInfo'],
    }),
    startTimeTracker: build.mutation<void, void>({
      query() {
        return {
          url: `${TIME_ENTRY}/time-tracker/start`,
          method: 'POST',
        };
      },
      invalidatesTags: ['TimeTracker', 'TimeSheetInfo'],
    }),
    getTimeTracker: build.query<TTimeTrackerUi | null, void>({
      query() {
        return {
          url: `${TIME_ENTRY}/time-tracker`,
          method: 'GET',
        };
      },
      providesTags: ['TimeTracker'],
      transformResponse: (data: TTimeTracker) =>
        data ? timeTrackerConverter.fromDb(data) : null,
    }),
    getAllTimeEntriesPerAssignment: build.query<
      TTimeEntryPerAssignmentRootUi,
      {
        filters?: TTimeEntryFiltersUi;
      }
    >({
      query({ filters }) {
        const urlWithParams = TIME_ENTRY.concat('/per-assignment');

        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(filters && { ...timeEntryFiltersConverter.toDb(filters) }),
          },
        };
      },
      transformResponse: (data: TTimeEntryPerAssignmentRoot) => {
        return timeEntryPerAssignmentRootConverter.fromDb(data);
      },
      providesTags: ['TimeSheetPerAssignment'],
    }),
    getTimeEntryAssignments: build.query<
      TTimeEntryAssignmentsUi[],
      {
        search?: string;
        companyIds?: string[];
        assignmentType?: AssignmentEnumUi;
      }
    >({
      query({ search, companyIds, assignmentType }) {
        return {
          url: `${TIME_ENTRY}/assignments`,
          method: 'GET',
          params: {
            ...(search && { search }),
            ...(companyIds && { companyIds }),
            ...(assignmentType && {
              assignmentType:
                assignmentTypeAsParamConverter.toDb(assignmentType),
            }),
          },
        };
      },
      transformResponse: (data: TTimeEntryAssignments[]) =>
        data?.map(item => timeEntryAssignmentConverter.fromDb(item)) || [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTimeEntriesQuery,
  useGetTimesheetInfoQuery,
  useGetAdminTimesheetInfoQuery,
  useEditTimeEntryMutation,
  useDeleteTimeEntryMutation,
  useCreateTimeEntryMutation,
  useGetWorkTypesQuery,
  useStartTimeTrackerMutation,
  useStopTimeTrackerMutation,
  useEndTimeTrackerMutation,
  useGetTimeTrackerQuery,
  useGetAllTimeEntriesPerAssignmentQuery,
  useGetTimeEntryAssignmentsQuery,
} = timeEntryApi;
