import { TCreateConverter, TFetchConverter } from '../types/converter.types';
import {
  TCategory,
  TCategoryCreate,
  TCategoryCreateUi,
  TCategoryUi,
} from '../types/category.types';

export const createCategoryConverter: TCreateConverter<
  TCategoryCreateUi,
  TCategoryCreate
> = {
  toDb: data => {
    return {
      name: data.name,
      ...(data.glCode && { asProductName: data?.glCode }),
      workTypes: data.workTypes || [],
      taxCode: data.taxCode
    };
  },
};

export const getCategoryConverter: TFetchConverter<TCategoryUi, TCategory> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      glCode: data?.asProductName || undefined,
      workTypes:
        data.workTypes?.map(item => ({ value: item, label: item })) || [],
      active: data.active,
      taxCode: data.taxCode
    };
  },
};
