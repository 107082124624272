import {
  TAvailableFilter,
  TFilter,
  TFilterName,
  TFilterPath,
} from 'components/FilterComponents/FilterComponents.type';
import { TQuickFiltersStorageNames } from 'helpers/storage';
import { useQuery } from 'hooks/useQueryParam';
import { useEffect, useState } from 'react';
import { setStoredFilters } from 'redux/slices';
import { useAppDispatch, useAppSelector } from 'redux/store';

type TFilterHookProps = {
  initialFilters: TAvailableFilter<TFilter, TFilterName>[];
  path: TFilterPath;
  keyStorageName: TQuickFiltersStorageNames;
};
const useFilterTableHook = ({
  initialFilters,
  path,
  keyStorageName,
}: TFilterHookProps) => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const tab = query.get('tab');

  const storedFilters =
    useAppSelector(state => state.filtersSlice.filters[keyStorageName][path]) ||
    {};

  const [filtersShow, setFilterShow] = useState(false);
  const [availableFilterList, setAvailableFilterList] =
    useState<TAvailableFilter<TFilter, TFilterName>[]>(initialFilters);

  useEffect(() => {
    if (storedFilters && Object.keys(storedFilters).length) {
      const newAvailableFilterList = [...availableFilterList];
      Object.keys(storedFilters).forEach(item => {
        const findFilter = newAvailableFilterList.find(s => s.filter === item);
        if (findFilter) {
          findFilter.show = true;
          if (Object.values(storedFilters).flat().length) {
            setFilterShow(true);
          }
        }
      });
      setAvailableFilterList(newAvailableFilterList);
    }
  }, [storedFilters]);

  useEffect(() => {
    if (!Object.values(storedFilters).flat().length && filtersShow) {
      setFilterShow(false);
    }
  }, [tab, keyStorageName]);

  const onFilterSelect = (filter: TFilter) => (ids: string[]) => {
    dispatch(setStoredFilters({ keyStorageName, path, filter, ids }));
  };

  const onClearAllSelectedFilters = () => {
    Object.keys(storedFilters).forEach(el => {
      dispatch(
        setStoredFilters({
          keyStorageName,
          path,
          filter: el as TFilter,
          ids: [],
        }),
      );
    });
  };

  const onFilterShow = (filter: TFilter) => {
    const currentFilter = availableFilterList.find(
      item => item.filter === filter,
    );
    if (currentFilter) {
      setAvailableFilterList(
        availableFilterList.map(item => ({
          ...item,
          show: item.filter === filter ? !currentFilter.show : item.show,
        })),
      );
      if (currentFilter.show) {
        setStoredFilters({ keyStorageName, path, filter, ids: [] });
      }
    }
  };
  const selectedFiltersCount = Object.values(storedFilters).filter(
    el => el.length,
  ).length;

  return {
    filters: storedFilters,
    filtersShow,
    availableFilterList,
    onFilterSelect,
    onFilterShow,
    setFilterShow,
    onClearAllSelectedFilters,
    selectedFiltersCount,
  };
};

export { useFilterTableHook };
