import { Key, useState } from 'react';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';

type TUseTable<T> = {
  page: number;
  pageSize: number;
  selectedRowKeys: Key[];
  sortColumn: string;
  records: T[];
  setRecords: React.Dispatch<React.SetStateAction<T[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<Key[]>>;
  setSortColumn: React.Dispatch<React.SetStateAction<string>>;
  handleTableChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => void;
  expendedRows: readonly Key[];
  onExpendedRowsChange: (expandedKeys: readonly React.Key[]) => void;
  onAddExpendedRow: (expendedRow: React.Key) => void;
};

const useTable = <T>(defaultSortColumn = ''): TUseTable<T> => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [records, setRecords] = useState<T[]>([]);
  const [sortColumn, setSortColumn] = useState<string>(defaultSortColumn);
  const [expendedRows, setExpendedRows] = useState<readonly Key[]>([]);

  const onExpendedRowsChange = (expendedRowsData: readonly Key[]) => {
    setExpendedRows(expendedRowsData);
  };

  const onAddExpendedRow = (expendedRow: React.Key) => {
    if (expendedRows.indexOf(expendedRow) === -1) {
      setExpendedRows(prev => [expendedRow, ...prev]);
    }
  };
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => {
    if (pagination.pageSize !== pageSize) {
      setPageSize(pagination.pageSize!);
    }
    if (pagination.current! - 1 !== page) {
      setPage(pagination.current! - 1);
    }
    if (!Array.isArray(sorter)) {
      if (sorter?.order && sorter?.field) {
        setSortColumn(
          `${sorter.field},${sorter.order === 'ascend' ? 'ASC' : 'DESC'}`,
        );
      } else {
        setSortColumn(defaultSortColumn);
      }
    }
  };

  return {
    page,
    pageSize,
    selectedRowKeys,
    sortColumn,
    records,
    expendedRows,
    setRecords,
    setPage,
    setPageSize,
    setSelectedRowKeys,
    setSortColumn,
    handleTableChange,
    onExpendedRowsChange,
    onAddExpendedRow,
  };
};

export { useTable };
