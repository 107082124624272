import styled, { ThemeProps } from 'styled-components';
import { TThemeProps } from 'theme/theme';
import Search from 'antd/es/input/Search';
import { createInputStyles } from 'components/Input/style';
import { Colors } from 'core/CssVariables';
import { TAntdSearchProps, TSearchProps } from './Search.type';

export const SSearch = styled(Search)`
  width: 373px;
  min-width: 100%;
  max-width: 100%;
  &.ant-input-group-wrapper {
    padding-right: 13px;
  }
  .ant-btn-icon-only {
    width: 40px;
    position: absolute;
    top: 0;
    z-index: 1;
    left: -25px;
    background-color: ${Colors.SecondaryColor};
    svg {
      width: 18px;
    }
  }
  &.ant-input-search {
    & .ant-input {
      padding: 8px 30px 8px 12px;
    }
    .ant-input-group {
      .ant-input:first-child,
      .ant-input-group-addon:first-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .ant-input-group-addon {
        background-color: transparent;
        &:last-child .ant-input-search-button {
          border: none;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          z-index: 1;
          top: 0;
          left: -25px;
          position: absolute;
          color: ${Colors.White};
          background-color: ${Colors.SecondaryColor};

          &:hover {
            background-color: ${Colors.SecondaryColor};
          }

          &:disabled,
          &:hover:disabled {
            background-color: ${Colors.PrimaryColor}4D;
            color: ${Colors.White}B2;
          }
        }
      }
    }
  }
  ${(props: TSearchProps & TAntdSearchProps & ThemeProps<TThemeProps>) => {
    return createInputStyles(props.theme);
  }}
`;
