import styled from 'styled-components';
import Avatar from 'antd/es/avatar';
import { Colors, FontWeights } from 'core/CssVariables';
import { TAvatar } from './Avatar.types';

const SAvatar = styled(Avatar)<TAvatar>`
  background-color: ${({ color, src }) =>
    src ? Colors.White : color || Colors.PrimaryColor};
  color: ${({ color }) => color || Colors.White};
  font-weight: ${FontWeights.Medium};  
  min-height: ${({ size }) => `${size}px`};
  min-width: ${({ size }) => `${size}px`};
  border: 1px solid ${({ theme }) => theme.input.borderColor};
    path {
      fill: ${({ color }) => (color ? Colors.White : Colors.PrimaryColor)};
    }
  }
`;

export default SAvatar;
