import { Screens } from 'core/CssVariables';
import styled from 'styled-components';

const SCreateButtonWrapper = styled.div`
  @media (max-width: ${Screens.ScreensMD}) {
    button {
      padding: 8px;
    }
    span {
      display: none;
    }
    .icon {
      margin-right: 0px;
    }
  }
`;

export { SCreateButtonWrapper };
