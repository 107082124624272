import React, { useEffect } from 'react';
import { Paragraph, Title } from 'components/Typography';
import Button from 'components/Button/Button';
import { InvitationMemberForm } from 'pages/private/TeamManagement/Invitations/inviteMembers/InviteMembersForm';
import { useNavigate } from 'react-router-dom';
import { RoutesPaths } from 'routes/Routes.types';
import { sendGTMDataLayer } from 'helpers/utils';
import { SInviteStep } from './InviteStep.style';
import { TInviteStepProps } from './InviteStep.type';

const InviteStep = ({ goNext, form, isSuccess, authData }: TInviteStepProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      if (isSuccess) {
        goNext();
      }
    };
  }, [isSuccess]);

  const handleSkipBtn = async () => {
    await goNext();
    sendGTMDataLayer({
        event: 'skip_invite_users',
        emailAddress: authData?.email,
        fullName: authData?.fullName,
      }
    );
      
    navigate(`/${RoutesPaths.Workfeed}`);
  };

  return (
    <SInviteStep>
      <div className="title auth-align">
        <Title fontlevel={3}>Invite your members</Title>
        <Paragraph fontlevel={5} subtitle className="mt-2">
          Get started by adding members to your team.
        </Paragraph>
      </div>
      <InvitationMemberForm form={form} />
      <Button
        type="link"
        htmlType="button"
        className="link-btn text-left mb-6"
        onClick={handleSkipBtn}
      >
        Skip for now
      </Button>
    </SInviteStep>
  );
};

export default InviteStep;
