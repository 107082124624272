import { TOrganizationData } from 'data/types';
import {
  ECountry,
  TNonWorkingDays,
  TWorkingHourData,
  weekDays,
} from 'data/types/organization.types';
import { OnboardState } from 'pages/Onboarding/Onboarding.type';

const nonWorkingDays: TNonWorkingDays[] = [
  {
    id: '725b6d7a-fd8f-4414-8eb0-d7e8c7a51521',
    date: '2023-03-08',
    repeatAnnually: true,
  },
  {
    id: '725b6d7a-fd8f-4414-8eb0-d7e8c7a51521',
    date: '2023-04-07',
    repeatAnnually: false,
  },
];

const workingHours: TWorkingHourData = [
  {
    dayOfWeek: weekDays.MONDAY,
    start: '09:00:00',
    end: '13:00:00',
  },
  {
    dayOfWeek: weekDays.MONDAY,
    start: '14:00:00',
    end: '18:00:00',
  },
  {
    dayOfWeek: weekDays.TUESDAY,
    start: '09:00:00',
    end: '13:00:00',
  },
  {
    dayOfWeek: weekDays.TUESDAY,
    start: '14:00:00',
    end: '18:00:00',
  },
  {
    dayOfWeek: weekDays.WEDNESDAY,
    start: '09:00:00',
    end: '13:00:00',
  },
  {
    dayOfWeek: weekDays.WEDNESDAY,
    start: '14:00:00',
    end: '18:00:00',
  },
  {
    dayOfWeek: weekDays.THURSDAY,
    start: '09:00:00',
    end: '13:00:00',
  },
  {
    dayOfWeek: weekDays.THURSDAY,
    start: '14:00:00',
    end: '18:00:00',
  },
  {
    dayOfWeek: weekDays.FRIDAY,
    start: '09:00:00',
    end: '13:00:00',
  },
  {
    dayOfWeek: weekDays.FRIDAY,
    start: '14:00:00',
    end: '18:00:00',
  },
];

const mockedOrganzation: TOrganizationData = {
  id: '725b6d7a-fd8f-4414-8eb0-d7e8c7a51521',
  name: 'LimeTree Labs',
  size: '1-10',
  timeZone: 'US/Aleutian',
  website: 'limeTreeLabs.com',
  onboardState: OnboardState.ONBOARDED,
  logoUrl:
    'https://www.limetree.com/wp-content/uploads/2019/10/LimeTree-Logo-2019-1.png',
  address: 'USA New York,5th Avenue,10001',
  nonWorkingDays,
  workingHours,
  country: ECountry.USA,
};

export { mockedOrganzation };
