import ScrollableContainer from 'components/Layout/ScrollableContainer/ScrollableContainer';
import styled from 'styled-components';

const SNoResaltWrapper = styled(ScrollableContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  .no-result {
    color: ${({ theme }) => theme.noResult.color};
  }
`;
export default SNoResaltWrapper;
