import styled from 'styled-components';
import Steps from 'antd/es/steps';
import { Screens } from 'core/CssVariables';

const SSteps = styled(Steps)`
  @media (max-width: ${Screens.ScreensSM}) {
    &.ant-steps-vertical {
      > .ant-steps-item {
        height: 60px;

        .ant-steps-item-container > {
          .ant-steps-item-tail {
            left: 12px;
          }
        }
        .ant-steps-item-content {
          min-height: auto;
        }
      }
    }
  }
`;

export default SSteps;
