import { lazy } from 'react';
import { TUserRoleUi } from 'data/types/user.types';
import { PrivateRouteType, RoutesPaths } from '../Routes.types';

const PhaseTaskDetails = lazy(
  () =>
    import(
      'pages/private/Projects/PhaseTasks/PhaseTaskDetails/PhaseTaskDetails'
    ),
);
const AllProjects = lazy(
  () => import('pages/private/Projects/AllProjects/AllProjects'),
);
const ProjectDetails = lazy(
  () => import('pages/private/Projects/ProjectDetails/ProjectDetails'),
);

export const ProjectRoutes: PrivateRouteType[] = [
  {
    component: AllProjects,
    path: RoutesPaths.Projects,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
  {
    component: ProjectDetails,
    path: RoutesPaths.ProjectDetails,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
  {
    component: PhaseTaskDetails,
    path: RoutesPaths.PhaseTaskDetails,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
];
