import { TFetchConverter } from 'data/types/converter.types';
import {
  TScheduleItem,
  TScheduleItemRedirectMapper,
  TScheduleItemType,
  TScheduleItemUi,
} from 'data/types/workFeed.types';
import { slaRemainingConverter } from './sla.converter';

export const ScheduleItemRedirectMapper: TScheduleItemRedirectMapper = {
  [TScheduleItemType.Ticket]: '/app/tickets/',
  [TScheduleItemType.Task]: '/app/tasks/',
  [TScheduleItemType.ProjectTask]: '/app/projects/tasks/',
  [TScheduleItemType.CompanyInteraction]: '/app/customers/companies/',
};

export const scheduleItemConverter: TFetchConverter<
  TScheduleItemUi,
  TScheduleItem
> = {
  fromDb: data => {
    return {
      description: data.description,
      scheduledTime: data.scheduledTime,
      remainingTime: slaRemainingConverter.fromDb(data.remainingTime),
      parentResourceId: data.parentResourceId,
      parentResourceType: data.parentResourceType,
      parentResourceNumber: data.parentResourceNumber,
      overdue: data.overdue,
    };
  },
};
