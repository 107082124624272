import { TSignUpData, TSignUpDataUi } from 'data/types';
import { TCreateConverter } from 'data/types/converter.types';

const signupConverter: TCreateConverter<TSignUpDataUi, TSignUpData> = {
  toDb: data => {
    return {
      fullName: data.fullName,
      email: data.email,
      password: data.password,
      refId: data.refId,
    };
  },
};

export default signupConverter;
