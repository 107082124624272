export enum WorkTypeEnumUi {
  ADMINISTRATIVE = 'Administrative',
  BREAK_LUNCH = 'Break/Lunch',
  INTERNAL_MEETING = 'Internal Meeting',
  IT_MANAGEMENT = 'IT Management',
  MARKETING = 'Marketing',
  NETWORK_ADMIN = 'Network Admin',
  ON_SITE_SUPPORT = 'On-site Support',
  PAID_TIME_OFF = 'Paid time off',
  PROJECT = 'Project',
  REMOTE_SUPPORT = 'Remote Support',
  SALES = 'Sales',
  SECURITY = 'Security',
  TRAINING = 'Training',
  PROCUREMENT = 'Procurement',
}

export enum WorkTypeEnum {
  'Administrative' = 'Administrative',
  'Break/Lunch' = 'Break/Lunch',
  'Internal Meeting' = 'Internal Meeting',
  'IT Management' = 'IT Management',
  'Marketing' = 'Marketing',
  'Network Admin' = 'Network Admin',
  'On-site Support' = 'On-site Support',
  'Paid time off' = 'Paid time off',
  'Project' = 'Project',
  'Remote Support' = 'Remote Support',
  'Sales' = 'Sales',
  'Security' = 'Security',
  'Training' = 'Training',
  'Procurement' = 'Procurement',
}
