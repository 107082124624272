import React, { FC } from 'react';
import { Col } from 'components/Grid/Grid';
import { Colors } from 'core/CssVariables';
import { Spinner } from 'components/Spinner/Spinner';
import ToolTip from 'components/ToolTip/ToolTip';
import { Icon, TIconNames } from 'components/Icon';
import { useIsResetAllowedQuery } from 'redux/apiSlices/demoControllerApi.slice';

type TGaugeResetProps = {
  isLoading?: boolean;
  onRefresh: () => void;
};

const GaugeReset: FC<TGaugeResetProps> = ({ isLoading, onRefresh }) => {
  const { data: isResetAllowed } = useIsResetAllowedQuery();

  if (!isResetAllowed?.value) {
    return null;
  }

  return (
    <Col className="mt-1">
      {isLoading ? (
        <Spinner width={20} color={Colors.InfoColor} className="ml-2" />
      ) : (
        <ToolTip title="Update gauges data!">
          <span>
            <Icon
              isLoading={isLoading}
              size={20}
              icon={TIconNames.RELOAD}
              onClick={onRefresh}
              className="pointer ml-2"
              color={Colors.InfoColor}
            />
          </span>
        </ToolTip>
      )}
    </Col>
  );
};

export default GaugeReset;
