import React, { FC } from 'react';
import { Title } from 'components/Typography';
import { Col, Row } from 'components/Grid/Grid';
import { Colors, FontWeights } from 'core/CssVariables';
import { Icon, TIconNames } from 'components/Icon';
import Tag from 'components/Tag/Tag';
import { TOptionType } from 'data/types/generalDataTypes';
import { TCustomSelect } from './CustomSelect.type';
import SCustomMultiSelectWrapper from './CustomMultiSelect.style';

const CustomSelect: FC<TCustomSelect> = ({
  onChange,
  title,
  className = '',
  value: selectedValues = [],
  selectOptions,
}) => {
  const onSelect = (val: TOptionType) => {
    const index = selectedValues.findIndex(item => item.value === val.value);
    if (index === -1) {
      onChange([val, ...selectedValues]);
    } else {
      onChange(selectedValues.filter(item => item.value !== val.value));
    }
  };
  return (
    <SCustomMultiSelectWrapper className={className}>
      {title && (
        <Title
          fontlevel={4}
          fontWeight={FontWeights.SemiBold}
          className="title mb-3"
        >
          {title}
        </Title>
      )}
      <Row justify="start" gutter={[4, 4]}>
        {selectOptions.map(item => {
          return (
            <Col key={item.value}>
              <Tag
                className="pointer"
                onClick={() => onSelect(item)}
                color={
                  selectedValues.findIndex(
                    selectedValue => selectedValue.label === item.label,
                  ) !== -1
                    ? Colors.InfoColor
                    : Colors.GreyOpacity
                }
                size="md"
                icon={
                  selectedValues.findIndex(
                    selectedValue => selectedValue.label === item.label,
                  ) !== -1 && (
                    <Icon
                      size={15}
                      icon={TIconNames.DONE}
                      color={Colors.White}
                    />
                  )
                }
              >
                {item.label}
              </Tag>
            </Col>
          );
        })}
      </Row>
    </SCustomMultiSelectWrapper>
  );
};
export default CustomSelect;
