import {
  AssignmentEnum,
  AssignmentEnumUi,
  AssignmentTypeAsParam,
  TDurationsByDay,
  TDurationsByDayUi,
  TimeEntriesFiltersEnum,
  TimeEntryPerAssignment,
  TimeEntryPerAssignmentUi,
  TOwner,
  TOwnerUi,
  TTimeEntryAssignments,
  TTimeEntryAssignmentsUi,
  TTimeEntryFilters,
  TTimeEntryFiltersUi,
  TTimeEntryGet,
  TTimeEntryGetUi,
  TTimeEntryPerAssignmentRoot,
  TTimeEntryPerAssignmentRootUi,
  TTimeEntryPost,
  TTimeEntryPostUi,
  TTimeSheetInfo,
  TTimeSheetInfoUi,
  TTimeTracker,
  TTimeTrackerUi,
} from 'data/types/timeEntry.types';
import moment from 'moment';
import { getDateTimeFormats } from 'helpers/utils';
import {
  TAuthConverter,
  TConverter,
  TCreateConverter,
  TFetchConverter,
} from '../types/converter.types';
import { convertMinsToHrsMins, GetFirstLetters } from './general.converters';
import { workTypesConverters } from './workTypes.converters';
import { taskStatusConverter } from './task.converters';
import { TTicketStatus } from '../types/ticket.types';
import { TTaskStatus } from '../types/task.type';
import { PhaseTaskStatusEnum } from '../types/phase.type';
import { ticketStatusConverter } from './ticket.converters';
import { phaseTaskStatusConverter } from './phase.converters';

export const assignmentTypeConverter: TConverter<
  AssignmentEnumUi,
  AssignmentEnum
> = {
  fromDb: data => {
    return AssignmentEnumUi[
      AssignmentEnum[data] as keyof typeof AssignmentEnumUi
    ];
  },
  toDb: data => {
    return AssignmentEnum[
      AssignmentEnumUi[data] as keyof typeof AssignmentEnum
    ];
  },
};

export const assignmentTypeAsParamConverter: TCreateConverter<
  AssignmentEnumUi,
  AssignmentTypeAsParam
> = {
  toDb: data => {
    return AssignmentTypeAsParam[
      AssignmentEnumUi[data] as keyof typeof AssignmentTypeAsParam
    ];
  },
};

export const entryOwnerConverter: TFetchConverter<TOwnerUi, TOwner> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
      nameFirstLetter: GetFirstLetters.fromDb(data.name),
    };
  },
};

export const timeEntryGetConverter: TFetchConverter<
  TTimeEntryGetUi,
  TTimeEntryGet
> = {
  fromDb: data => {
    const { timeFormat } = getDateTimeFormats();
    const startDate = moment(data.actualStartTime || data.startTime).format(
      'MMM, ddd DD',
    );
    const endDate = moment(data.actualEndTime || data.endTime).format(
      'MMM, ddd DD',
    );
    const startTime = moment(data.actualStartTime || data.startTime).format(
      timeFormat,
    );
    const endTime = moment(data.actualEndTime).format(timeFormat);
    let range = `${startDate} ${startTime} - ${endTime}`;
    if (startDate !== endDate) {
      range = `${startDate} ${startTime} - ${endDate} ${endTime}`;
    }
    const entryData: TTimeEntryGetUi = {
      id: data.id,
      note: data.note,
      workType: workTypesConverters.fromDb(data.workType),
      startTime: data.actualStartTime || data.startTime,
      endTime: data.actualEndTime || data.endTime,
      startPointer: data.startTime,
      endPointer: data.endTime,
      actualEndTime: data.actualEndTime,
      actualStartTime: data.actualEndTime,
      duration: convertMinsToHrsMins.fromDb(data.duration),
      companyId: data.companyId,
      companyName: data.companyName,
      companyLogo: data.companyLogo,
      companyShortName: data.companyShortName,
      companyNameWithAbbreviation: data.companyShortName
        ? `${data.companyShortName} - ${data.companyName}`
        : data.companyName,
      companyFirstLetter: GetFirstLetters.fromDb(data.companyName),
      assignmentType: data.assignmentType
        ? assignmentTypeConverter.fromDb(data.assignmentType)
        : undefined,
      assignmentId: data.assignmentId ? data.assignmentId : undefined,
      assignmentNumber: data.assignmentNumber
        ? data.assignmentNumber
        : undefined,
      range,
    };
    if (data.owner) {
      entryData.owner = entryOwnerConverter.fromDb(data.owner);
    }
    return entryData;
  },
};

export const timeEntryPostConverter: TCreateConverter<
  TTimeEntryPostUi,
  TTimeEntryPost
> = {
  toDb: data => {
    const obj: TTimeEntryPost = {
      note: data.note,
      startTime: data.startTime.format('YYYY-MM-DDTHH:mm'),
      endTime: data.endTime.format('YYYY-MM-DDTHH:mm'),
      workType: workTypesConverters.toDb(data.workType),
      companyId: data.companyId,
    };
    if (data.assignmentId && data.assignmentType) {
      obj.assignmentId = data.assignmentId;
      obj.assignmentType = assignmentTypeConverter.toDb(data?.assignmentType);
    }
    return obj;
  },
};
export const durationsByDayConverter: TFetchConverter<
  TDurationsByDayUi,
  TDurationsByDay
> = {
  fromDb: data => {
    const durationsByDayObj: TDurationsByDayUi = {};
    Object.keys(data).forEach((day: string) => {
      durationsByDayObj[day] = convertMinsToHrsMins.fromDb(data[day]);
    });
    return durationsByDayObj;
  },
};
export const timeTrackerConverter: TFetchConverter<
  TTimeTrackerUi,
  TTimeTracker
> = {
  fromDb: data => {
    return {
      id: 'timeTrackerId',
      startTimeTimestamp: data.startTimeTimestamp,
      startTime: data.startTime,
      endTime: data.endTime,
      duration: convertMinsToHrsMins.fromDb(
        (new Date(data.endTime).getTime() -
          new Date(data.startTime).getTime()) /
          1000,
      ),
      title: 'Time Tracker',
    };
  },
};

export const timesheetInfoGetConverter: TFetchConverter<
  TTimeSheetInfoUi,
  TTimeSheetInfo
> = {
  fromDb: data => {
    if (data) {
      return {
        id: data.id,
        allEventsDuration: convertMinsToHrsMins.fromDb(data.totalDuration),
        timeTracker: data.timeTracker
          ? data.timeTracker.map(item => timeTrackerConverter.fromDb(item))
          : null,
        timeEntries: data.timeEntries.length
          ? data.timeEntries.map(timeEntry =>
              timeEntryGetConverter.fromDb(timeEntry),
            )
          : [],
        durationsByDay: durationsByDayConverter.fromDb(data.durationsByDay),
      };
    }
    return null;
  },
};
export const timeEntryFiltersConverter: TCreateConverter<
  TTimeEntryFiltersUi,
  TTimeEntryFilters
> = {
  toDb: filters => {
    return {
      ...(filters?.startDateBetween?.[0] && {
        [TimeEntriesFiltersEnum.START_DATE_BETWEEN_FROM]: moment(
          filters?.startDateBetween?.[0],
        ).format('YYYY-MM-DD'),
      }),
      ...(filters?.startDateBetween?.[1] && {
        [TimeEntriesFiltersEnum.START_DATE_BETWEEN_TO]: moment(
          filters?.startDateBetween?.[1],
        ).format('YYYY-MM-DD'),
      }),
      ...(filters?.companyIds?.length && {
        [TimeEntriesFiltersEnum.ORGANIZATIONS]: filters?.companyIds,
      }),
      ...(filters?.ownerIds?.length && {
        [TimeEntriesFiltersEnum.MEMBERS]: filters?.ownerIds,
      }),
      ...(filters?.assignmentIds?.length && {
        [TimeEntriesFiltersEnum.ASSIGNMENTS]: filters?.assignmentIds,
      }),
      ...(filters?.workTypes?.length && {
        [TimeEntriesFiltersEnum.WORK_TYPES]: filters?.workTypes.map(item =>
          item.replace(/[\s/-]/g, '_').toUpperCase(),
        ),
      }),
    };
  },
};

export const timeEntriesCsvConverter: TAuthConverter<
  { params: TTimeEntryFiltersUi },
  { params: TTimeEntryFilters },
  Blob,
  Blob
> = {
  fromDb: data => {
    return data;
  },
  toDb: data => {
    return { params: timeEntryFiltersConverter.toDb(data.params) };
  },
};

export const timeEntryPerAssignmentConverter: TFetchConverter<
  TimeEntryPerAssignmentUi,
  TimeEntryPerAssignment
> = {
  fromDb: data => {
    const entryData: TimeEntryPerAssignmentUi = {
      id: data.id,
      note: data.note,
      workType: workTypesConverters.fromDb(data.workType),
      startTime: data.startTime,
      endTime: data.endTime,
      duration: convertMinsToHrsMins.fromDb(data.duration),
      companyId: data.companyId,
      companyName: data.companyName,
      companyLogo: data.companyLogo,
      companyFirstLetter: GetFirstLetters.fromDb(data.companyName),
      assignmentType: data.assignmentType
        ? assignmentTypeConverter.fromDb(data.assignmentType)
        : undefined,
      assignmentId: data.assignmentId,
      assignmentNumber: data.assignmentNumber
        ? data.assignmentNumber
        : undefined,
    };
    if (data.owner) {
      entryData.owner = entryOwnerConverter.fromDb(data.owner);
    }
    return entryData;
  },
};
export const timeEntryPerAssignmentRootConverter: TFetchConverter<
  TTimeEntryPerAssignmentRootUi,
  TTimeEntryPerAssignmentRoot
> = {
  fromDb: data => {
    const entryData: TTimeEntryPerAssignmentRootUi = {
      id: data.id,
      timeEntries: data.timeEntries.map(item =>
        timeEntryPerAssignmentConverter.fromDb(item),
      ),
      totalDuration: convertMinsToHrsMins.fromDb(data.totalDuration),
    };
    return entryData;
  },
};

export const timeEntryAssignmentConverter: TFetchConverter<
  TTimeEntryAssignmentsUi,
  TTimeEntryAssignments
> = {
  fromDb: data => {
    return {
      name: data.name,
      assignmentType: assignmentTypeConverter.fromDb(data.assignmentType),
      assignmentId: data.assignmentId,
      status:
        (data.assignmentType === AssignmentEnum.Task &&
          taskStatusConverter.fromDb(data.status as TTaskStatus)) ||
        (data.assignmentType === AssignmentEnum.Ticket &&
          ticketStatusConverter.fromDb(data.status as TTicketStatus)) ||
        (data.assignmentType === AssignmentEnum['Project Task'] &&
          phaseTaskStatusConverter.fromDb(data.status as PhaseTaskStatusEnum)),
    };
  },
};
