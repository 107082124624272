import { TCategory } from 'data/types/category.types';

const category: TCategory = {
  id: 'e16b424b-1bc5-4278-a2cf-2ae32b43fcdb',
  name: 'Service',
  asProductName: '987',
  workTypes: ['Break/Lunch', 'Administrative'],
  taxCode: 'NON',
  active: true,
};

export { category };
