import {
  TAuthConverter,
  TInvitationGetData,
  TInvitationGetDataUi,
  TInvitationsPostData,
  TInvitationsPostDataUi,
} from 'data/types';
import { TInvitation } from 'data/types/invitation.types';

export const invitationConverter: TAuthConverter<
  TInvitationsPostDataUi,
  TInvitationsPostData,
  TInvitationGetDataUi,
  TInvitationGetData
> = {
  toDb: (data: TInvitationsPostDataUi): TInvitationsPostData => {
    const convertedObj: TInvitationsPostData = data.map(
      (invitation: TInvitation) => ({
        email: invitation.email,
        role: invitation.role,
        groupIds: invitation.groupIds,
      }),
    );
    return convertedObj;
  },
  fromDb: (data: TInvitationGetData): TInvitationGetDataUi => {
    let userGroupsString = '';
    if (data.groups?.length) {
      data.groups.forEach((item, index) => {
        if (index !== data.groups.length - 1) {
          userGroupsString += `${item.name}, `;
        } else {
          userGroupsString += item.name;
        }
      });
    }
    const convertedObj: TInvitationGetDataUi = {
      id: data.id,
      email: data.email,
      expirationDate: data.expirationDate,
      expired: data.expired,
      failReason: data.failReason,
      role: data.role,
      status: data.status,
      groups: data.groups,
      userGroupsString,
    };

    return convertedObj;
  },
};
