import { Route, Routes, Navigate, useNavigate, Outlet } from 'react-router-dom';
import React, { Suspense, useEffect, FC } from 'react';
import Layout from 'components/Layout/Layout';
import { useAppDispatch } from 'redux/store';
import { setLogedInfirebase } from 'redux/slices';
import { RoutesPaths } from './Routes.types';
import useGetPrivateRoutesByRole from '../hooks/useGetPrivateRoutesByRole';
import {
  useAuthToFirebaseQuery,
  useGetFirebaseTokenQuery,
} from '../redux/apiSlices/firebase.slice';

export const PrivateRoutes: FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: token } = useGetFirebaseTokenQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const { isSuccess } = useAuthToFirebaseQuery(token || '', {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    dispatch(setLogedInfirebase(isSuccess));
  }, [isSuccess]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn]);
  const privateRoutes = useGetPrivateRoutesByRole();
  return (
    <Suspense fallback={<Layout />}>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="*"
            element={<Navigate replace to={RoutesPaths.PageNotFound} />}
          />
          {privateRoutes.map(({ path, component, subRoutes }) => {
            const Component = component;
            if (!subRoutes?.length) {
              return (
                <Route key={path} path={`${path}`} element={<Component />} />
              );
            }
            return (
              <Route
                key={path}
                path={`${path}/*`}
                element={
                  <Routes>
                    <Route path="/" element={<Outlet />}>
                      <Route path="*" element={<Navigate to="/app/404" />} />
                      {subRoutes?.map(
                        ({ path: subRoute, component: subComponent }) => {
                          const SubComponent = subComponent;
                          const subPath = subRoute.replace(path, '');
                          return (
                            <Route
                              key={subPath}
                              path={`${subPath}`}
                              element={<SubComponent />}
                            />
                          );
                        },
                      )}
                      )
                    </Route>
                  </Routes>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </Suspense>
  );
};
