import { authSplitApi } from 'redux/helpers/slice.helpers';

import {
  TattachDevicePostUi,
  TbulkAttachDevicesPostUI,
  TdeleteDeviceUi,
  TdetachDevicePostUi,
  TdeviceActivePostUi,
  TdeviceManagedPostUi,
  TDevicePostUi,
  TDevicesGet,
  TDevicesGetUi,
  TUpdateDeviceTypeUi,
  TUpdateDeviceNameUi,
  TUpdateDeviceModelUi,
} from 'data/types/devices.type';
import {
  attachDeviceConverter,
  bulkAttachDevicesConverter,
  deviceActiveConverter,
  deviceTypeConverter,
  deviceCreateConverter,
  deviceManagedConverter,
  devicesConverter,
  deviceUpdateNameConverter,
  deviceModelConverter,
} from 'data/converters/devices.converter';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import { integrationsApi } from './integrations.slice';
import { companiesApi } from './company.slice';

export const DEVICES_URL = 'api/company-devices';

export const devicesApi = authSplitApi('devices', ['devices']).injectEndpoints({
  endpoints: build => ({
    getDevices: build.query<
      TPageableDataWithContentUi<TDevicesGetUi[]>,
      {
        page: number;
        size: number;
        sort?: string[];
        filters?: {
          companyIds?: string[];
          contactIds?: string[];
          sources?: string[];
          isActive?: boolean;
          isManaged?: boolean;
        };
        search?: string;
      }
    >({
      query({ size, search, filters, sort, page }) {
        const urlWithParams = DEVICES_URL.concat(`?page=${page}&size=${size}`);
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
        };
      },
      transformResponse: (data: TPageableDataWithContent<TDevicesGet[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(device => devicesConverter.fromDb(device))
            : [],
        };
      },
      providesTags: ['devices'],
    }),
    getDevice: build.query<TDevicesGetUi, string>({
      query(id) {
        return {
          url: `${DEVICES_URL}/${id}`,
          method: 'GET',
        };
      },
      transformResponse(data: TDevicesGet) {
        return devicesConverter.fromDb(data);
      },
      providesTags: ['devices'],
    }),
    createDevice: build.mutation<void, TDevicePostUi>({
      query(data) {
        return {
          url: DEVICES_URL,
          method: 'POST',
          body: deviceCreateConverter.toDb(data),
        };
      },
      invalidatesTags: ['devices'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(integrationsApi.util.invalidateTags(['company_mappings']));
            dispatch(companiesApi.util.invalidateTags(['Company']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    bulkAttachDevices: build.mutation<void, TbulkAttachDevicesPostUI>({
      query(data) {
        return {
          url: `${DEVICES_URL}/bulk-attach-to-contact`,
          method: 'PUT',
          body: bulkAttachDevicesConverter.toDb(data),
        };
      },
      invalidatesTags: ['devices'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(integrationsApi.util.invalidateTags(['company_mappings']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    attachDevice: build.mutation<void, TattachDevicePostUi>({
      query(data) {
        const { deviceId } = data;
        return {
          url: `${DEVICES_URL}/${deviceId}/attach-to-contact`,
          method: 'PUT',
          body: attachDeviceConverter.toDb(data),
        };
      },
      invalidatesTags: ['devices'],
      onCacheEntryAdded(arg, { dispatch }) {
        dispatch(integrationsApi.util.invalidateTags(['company_mappings']));
      },
    }),
    detachDevice: build.mutation<void, TdetachDevicePostUi>({
      query(data) {
        const { deviceId } = data;
        return {
          url: `${DEVICES_URL}/${deviceId}/detach-from-contact`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['devices'],
      onCacheEntryAdded(arg, { dispatch }) {
        dispatch(integrationsApi.util.invalidateTags(['company_mappings']));
      },
    }),
    updateDeviceManagedStatus: build.mutation<void, TdeviceManagedPostUi>({
      query(data) {
        const { deviceId } = data;
        return {
          url: `${DEVICES_URL}/${deviceId}/managed`,
          method: 'PATCH',
          body: deviceManagedConverter.toDb(data),
        };
      },
      invalidatesTags: ['devices'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(companiesApi.util.invalidateTags(['Company']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    updateDeviceActiveStatus: build.mutation<void, TdeviceActivePostUi>({
      query(data) {
        const { deviceId } = data;
        return {
          url: `${DEVICES_URL}/${deviceId}/active`,
          method: 'PATCH',
          body: deviceActiveConverter.toDb(data),
        };
      },
      invalidatesTags: ['devices'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(companiesApi.util.invalidateTags(['Company']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    deleteDevice: build.mutation<void, TdeleteDeviceUi>({
      query(data) {
        const { deviceId } = data;
        return {
          url: `${DEVICES_URL}/${deviceId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['devices'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(integrationsApi.util.invalidateTags(['company_mappings']));
            dispatch(companiesApi.util.invalidateTags(['Company']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    updateDeviceName: build.mutation<void, TUpdateDeviceNameUi>({
      query(data) {
        const { id } = data;
        return {
          url: `${DEVICES_URL}/${id}/updateName`,
          method: 'PATCH',
          body: deviceUpdateNameConverter.toDb(data),
        };
      },
      invalidatesTags: ['devices'],
      onCacheEntryAdded(arg, { dispatch }) {
        dispatch(integrationsApi.util.invalidateTags(['company_mappings']));
      },
    }),
    updateDeviceType: build.mutation<void, TUpdateDeviceTypeUi>({
      query(data) {
        const { id } = data;
        return {
          url: `${DEVICES_URL}/${id}/updateDeviceType`,
          method: 'PATCH',
          body: deviceTypeConverter.toDb(data),
        };
      },
      invalidatesTags: ['devices'],
    }),
    updateDeviceModel: build.mutation<void, TUpdateDeviceModelUi>({
      query(data) {
        const { id } = data;
        return {
          url: `${DEVICES_URL}/${id}/updateModel`,
          method: 'PATCH',
          body: deviceModelConverter.toDb(data),
        };
      },
      invalidatesTags: ['devices'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAttachDeviceMutation,
  useDetachDeviceMutation,
  useBulkAttachDevicesMutation,
  useUpdateDeviceActiveStatusMutation,
  useUpdateDeviceManagedStatusMutation,
  useDeleteDeviceMutation,
  useGetDevicesQuery,
  useGetDeviceQuery,
  useCreateDeviceMutation,
  useUpdateDeviceNameMutation,
  useUpdateDeviceTypeMutation,
  useUpdateDeviceModelMutation,
} = devicesApi;
