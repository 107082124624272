import styled from 'styled-components';

export const SInviteSentStep = styled.div`
  text-align: center;
  img {
    margin: 60px 0 60px 0;
  }
  .wrap-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: fit-content;
    }
  }
`;
