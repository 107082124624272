import React from 'react';
import { TButtonTypes } from 'components/Button/Button.types';
import { useDispatch } from 'react-redux';
import { Icon, TIconNames } from 'components/Icon';
import Button from 'components/Button/Button';
import DashbarDropDownMenu from 'components/DashbarDropDownMenu/DashbarDropDownMenu';
import {
  changeTaskAddModal,
  changeticketAddModal,
  changeTimeEntryAddModal,
  changeProjectAddModal,
  changeContactAddModal,
} from 'redux/slices';
import { SCreateButtonWrapper } from './CreateButton.style';

const createMenuItems = [
  {
    title: 'Ticket',
    actionType: 'ticket',
    icon: TIconNames.TICKET,
  },
  {
    title: 'Task',
    actionType: 'task',
    icon: TIconNames.TASK,
  },
  {
    title: 'Time Entry',
    actionType: 'timeEntry',
    icon: TIconNames.TIME_ENTRY,
  },
  {
    title: 'Project',
    actionType: 'project',
    icon: TIconNames.LAPTOP,
  },
  {
    title: 'Contact',
    actionType: 'contact',
    icon: TIconNames.CONTACTS,
  },
];

const CreateButton = () => {
  const dispatch = useDispatch();

  const handleItemClick = (actionType: string) => {
    switch (actionType) {
      case 'ticket':
        return dispatch(changeticketAddModal(true));
      case 'task':
        return dispatch(changeTaskAddModal(true));
      case 'timeEntry':
        return dispatch(changeTimeEntryAddModal(true));
      case 'project':
        return dispatch(changeProjectAddModal(true));
      case 'contact':
        return dispatch(
          changeContactAddModal({
            visibleModal: true,
          }),
        );

      default:
        return () => {
          return null;
        };
    }
  };

  return (
    <DashbarDropDownMenu menuItems={createMenuItems} onClick={handleItemClick}>
      <SCreateButtonWrapper>
        <Button
          size="small"
          color={TButtonTypes.Info}
          icon={<Icon icon={TIconNames.PLUS} />}
        >
          Create
        </Button>
      </SCreateButtonWrapper>
    </DashbarDropDownMenu>
  );
};

export default CreateButton;
