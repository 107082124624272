import {
  emailConfigConverter,
  emailConverter,
} from 'data/converters/email.converters';
import {
  TEmailConfigGet,
  TEmailGet,
  TEmailPost,
  TEmailPostUi,
} from 'data/types/email.types';
import { authSplitApi } from 'redux/helpers/slice.helpers';

const EMAIL_URL = 'api/queue-attached-email';

export const settingsApi = authSplitApi('settings').injectEndpoints({
  endpoints: build => ({
    createEmail: build.mutation<TEmailPost, TEmailPostUi>({
      query(body) {
        return {
          url: EMAIL_URL,
          method: 'POST',
          body: emailConverter.toDb(body),
        };
      },
      invalidatesTags: ['email'],
    }),
    getEmail: build.query<TEmailGet[], void>({
      query() {
        return {
          url: `${EMAIL_URL}`,
          method: 'GET',
        };
      },
      providesTags: ['email'],
      transformResponse: (data: TEmailGet[]) => emailConverter.fromDb(data),
    }),

    getEmailConfig: build.query<TEmailConfigGet, void>({
      query() {
        return {
          url: `${EMAIL_URL}/config`,
          method: 'GET',
        };
      },
      transformResponse: (data: TEmailConfigGet) =>
        emailConfigConverter.fromDb(data),
    }),
    editEmail: build.mutation<void, { id: string; value: string }>({
      query({ value, id }) {
        return {
          url: `${EMAIL_URL}/${id}/email`,
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['email'],
    }),

    deleteEmailById: build.mutation<void, string>({
      query(id) {
        return {
          url: `${EMAIL_URL}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['email'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateEmailMutation,
  useGetEmailQuery,
  useGetEmailConfigQuery,
  useDeleteEmailByIdMutation,
  useEditEmailMutation,
} = settingsApi;
