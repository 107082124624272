import React, { FC } from 'react';
import { TIconNames } from 'components/Icon';
import Avatar from 'components/Avatar/Avatar';
import { signAmplifySignOut } from 'api/services';
import {
  clearAllCachedData,
  useAppDispatch,
  useAppSelector,
} from 'redux/store';
import {
  removeAllFilters,
  removePaymentInfo,
  removeUser,
  setTheme,
} from 'redux/slices';
import { useNavigate } from 'react-router-dom';
import { RoutesPaths } from 'routes/Routes.types';
import DashbarDropDownMenu from 'components/DashbarDropDownMenu/DashbarDropDownMenu';
import { GetFirstLetters } from 'data/converters/general.converters';
import { useGetMemberByIdQuery } from 'redux/apiSlices/members.slices';
import { getAuth } from 'firebase/auth';
import { zestLocalStorage } from 'helpers/storage';
import { SProfileWrapper } from './ProfileMenu.style';

type TProfileMenuProps = {
  isAccessLimited?: boolean;
};

const profileMenuItems = [
  {
    title: 'Profile',
    actionType: RoutesPaths.Profile,
    icon: TIconNames.PROFILE,
  },
  {
    title: 'Logout',
    actionType: 'logout',
    icon: TIconNames.LOGOUT,
  },
];

const ProfileMenu: FC<TProfileMenuProps> = ({ isAccessLimited }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useAppSelector(state => state.authSlice.user?.id);

  const { data: memberData } = useGetMemberByIdQuery(
    { id: userId || '' },
    {
      skip: !userId,
    },
  );

  const logout = async () => {
    signAmplifySignOut().then(() => {
      dispatch(removeUser());
      dispatch(setTheme('light'));
      clearAllCachedData();
      navigate('/login');
      dispatch(removeAllFilters());
      getAuth().signOut();
      zestLocalStorage.removeItem('ReportFilters');
      dispatch(removePaymentInfo());
    });
  };

  const handleItemClick = (actionType: string) => {
    switch (actionType) {
      case RoutesPaths.Profile:
        return navigate(RoutesPaths.Profile);
      case 'logout':
        return logout();

      default:
        return () => {
          return null;
        };
    }
  };
  const menuItems = isAccessLimited
    ? profileMenuItems.filter(item => item.actionType === 'logout')
    : profileMenuItems;
  return (
    <DashbarDropDownMenu menuItems={menuItems} onClick={handleItemClick}>
      <SProfileWrapper className="pointer">
        <Avatar src={memberData?.photoUrl} className="mr-3">
          {!memberData?.photoUrl &&
            memberData?.fullName &&
            GetFirstLetters.fromDb(memberData?.fullName)}
        </Avatar>
      </SProfileWrapper>
    </DashbarDropDownMenu>
  );
};

export default ProfileMenu;
