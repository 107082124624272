import { useState } from 'react';
import GoogleAnalytics from 'react-ga';
import { useAppSelector } from 'redux/store';

export const useGAEvent = () => {
  const user = useAppSelector(state => state.authSlice.user);
  const [event, setEvent] = useState<{
    action: string;
    category: string;
    label: string;
    value: number;
    nonInteraction: boolean;
  }>();

  const sendGAEvent = (
    action: string,
    label: string,
    value: number,
    nonInteraction: boolean,
  ) => {
    if (!user) return;
    const category = `MSP: ${user?.organization?.id} ${user?.organization?.name}`;
    GoogleAnalytics.event({
      action,
      category,
      label,
      value,
      nonInteraction,
    });

    setEvent({
      action,
      category,
      label,
      value,
      nonInteraction,
    });
  };

  return { event, sendGAEvent };
};
