export enum TQuickFiltersStorageNames {
  taskFilterStorageName = 'TaskFilters',
  ticketFilterStorageName = 'TicketFilters',
  timeEntriesStorageName = 'TimeEntriesFilters',
  interactionsStorageName = 'InteractionsFilters',
  companyStorageName = 'CompanyFilters',
  invoicesStorageName = 'InvoiceFilters',
  projectsStorageName = 'ProjectFilters',
  companyItGoalsStorageName = 'companyItGoalsStorageName',
  allItGoalsStorageName = 'allItGoalsStorageName',
  devicesStorageName = 'devicesStorageName',
  contactsStorageName = 'contactsStorageName',
}
export enum EUserSettingsStorageNames {
  headerCollapsed = 'headerCollapsed',
  sidebarCollapsed = 'sidebarCollapsed',
}

export type TStorageNames =
  | TQuickFiltersStorageNames
  | 'Tresholds'
  | 'ReportFilters'
  | 'TableSettings'
  | 'PaymentInfo'
  | 'user'
  | 'userSettings';

type TZestStorage = {
  getItem: (key: TStorageNames) => any;
  setItem: (key: TStorageNames, value: any) => void;
  removeItem: (key: TStorageNames) => void;
  clear: () => void;
};

const zestSessionStorage: TZestStorage = {
  getItem: key => {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  setItem: (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  },
  removeItem: key => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.log(error);
    }
  },
  clear: () => {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.log(error);
    }
  },
};
const zestLocalStorage: TZestStorage = {
  getItem: key => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  },
  removeItem: key => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.log(error);
    }
  },
  clear: () => {
    try {
      localStorage.clear();
    } catch (error) {
      console.log(error);
    }
  },
};

export { zestLocalStorage, zestSessionStorage };
