import { authSplitApi } from 'redux/helpers/slice.helpers';
import {
  TCategory,
  TCategoryCreate,
  TCategoryCreateUi,
  TCategoryUi,
  TInactiveCategoryUi,
} from 'data/types/category.types';
import {
  createCategoryConverter,
  getCategoryConverter,
} from 'data/converters/category.converter';
import { WorkTypeEnum, WorkTypeEnumUi } from 'data/types/workTypes.types';
import { workTypesConverters } from 'data/converters/workTypes.converters';

const CATEGORY_URL = 'api/product-categories';

export const categoriesApi = authSplitApi('categoryApi', [
  'categories',
  'workTypes',
  'taxCodes'
]).injectEndpoints({
  endpoints: build => ({
    getCategories: build.query<
      TCategoryUi[],
      { search?: string; active?: boolean }
    >({
      query({ search, active }) {
        return {
          url: `${CATEGORY_URL}`,
          method: 'GET',
          params: {
            ...(active && { active }),
            ...(search && { search }),
          },
        };
      },
      providesTags: ['categories'],
      transformResponse: (data: TCategory[]) =>
        data?.map(item => getCategoryConverter.fromDb(item)) || [],
    }),
    createCategory: build.mutation<TCategoryCreate, TCategoryCreateUi>({
      query(data) {
        return {
          url: `${CATEGORY_URL}`,
          method: 'POST',
          body: createCategoryConverter.toDb(data),
        };
      },
      invalidatesTags: ['categories', 'workTypes'],
    }),
    updateCategoryName: build.mutation<
      void,
      {
        categoryId: string;
        value: string;
      }
    >({
      query({ categoryId, value }) {
        return {
          url: CATEGORY_URL.concat(`/${categoryId}/name`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['categories'],
    }),
    updateCategoryGlCode: build.mutation<
      void,
      {
        categoryId: string;
        value: string;
      }
    >({
      query({ categoryId, value }) {
        return {
          url: CATEGORY_URL.concat(`/${categoryId}/as-product-name`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['categories'],
    }),
    updateCategoryTaxCode: build.mutation<
      void,
      {
        categoryId: string;
        value: string;
      }
    >({
      query({ categoryId, value }) {
        return {
          url: CATEGORY_URL.concat(`/${categoryId}/tax-code`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['categories'],
    }),
    updateCategoryWorkTypes: build.mutation<
      void,
      {
        categoryId: string;
        value: string[];
      }
    >({
      query({ categoryId, value }) {
        return {
          url: CATEGORY_URL.concat(`/${categoryId}/work-types`),
          method: 'PATCH',
          body: value,
        };
      },
      invalidatesTags: ['categories', 'workTypes'],
    }),
    getAvailableWorkTypes: build.query<WorkTypeEnumUi[], void>({
      query() {
        return {
          url: CATEGORY_URL.concat(`/available-work-types`),
          method: 'GET',
        };
      },
      transformResponse: (data: WorkTypeEnum[]) => {
        return data?.length
          ? data.map(workType => workTypesConverters.fromDb(workType))
          : [];
      },
      providesTags: ['workTypes'],
    }),
    inactiveCategory: build.mutation<TCategoryUi, TInactiveCategoryUi>({
      query({ id, value }) {
        return {
          url: `${CATEGORY_URL}/${id}/active`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['categories', 'workTypes'],
    }),
    getAvailableTaxCodes: build.query<string[], void>({
      query() {
        return {
          url: CATEGORY_URL.concat(`/available-tax-codes`),
          method: 'GET',
        };
      },
      transformResponse: (data: string[]) => {
        return data?.length
          ? data
          : [];
      },
      providesTags: ['taxCodes'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryNameMutation,
  useUpdateCategoryGlCodeMutation,
  useUpdateCategoryWorkTypesMutation,
  useGetAvailableWorkTypesQuery,
  useInactiveCategoryMutation,
  useGetAvailableTaxCodesQuery,
  useUpdateCategoryTaxCodeMutation
} = categoriesApi;
