import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useGetUserQuery } from 'redux/apiSlices';
import { setUser } from 'redux/slices';
import { isEqual } from 'lodash';
import useGetPaymentInfo from 'api/hooks/useGetPaymentInfo';
import { PublicRoutes } from './PublicRoutes';
import { PrivateRoutes } from './PrivateRoutes';
import Onboarding from '../pages/Onboarding/Onboarding';

const RoutesPage = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.authSlice.user);
  const { data } = useGetUserQuery(true);
  useEffect(() => {
    if (data && user && !isEqual(data, user)) {
      dispatch(setUser(data));
    }
  }, [data]);
  const { refetch } = useGetPaymentInfo();
  useEffect(() => {
    if (user?.organization?.id) {
      refetch(user?.organization?.id);
    }
  }, [user?.organization?.id]);

  if (
    user &&
    (!user.organization || user.organization?.onboardState !== 'ONBOARDED')
  ) {
    return (
      <Suspense fallback={null}>
        <Routes>
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="*" element={<Navigate to="/onboarding" />} />
        </Routes>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/*" element={<PublicRoutes isLoggedIn={!!user} />} />
        <Route path="app/*" element={<PrivateRoutes isLoggedIn={!!user} />} />
      </Routes>
    </Suspense>
  );
};
export default RoutesPage;
