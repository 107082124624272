import { TUserRoleUi } from 'data/types/user.types';
import { lazy } from 'react';
import { PrivateRouteType, RoutesPaths } from '../Routes.types';

const AllTasks = lazy(() => import('pages/private/Tasks/AllTasks/AllTasks'));
const TaskDetails = lazy(
  () => import('pages/private/Tasks/TaskDetails/TaskDetails'),
);

export const TaskRoutes: PrivateRouteType[] = [
  {
    component: AllTasks,
    path: RoutesPaths.Tasks,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
  {
    component: TaskDetails,
    path: RoutesPaths.TaskDetails,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
];
