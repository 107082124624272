import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const aiApi = createApi({
  reducerPath: 'aiApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_AI_URL,
  }),
  endpoints: build => ({
    generateTitle: build.mutation({
      query(description) {
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: '/ticket/title',
          method: 'POST',
          body: JSON.stringify({ description }),
        };
      },
    }),
  }),
});

export const { useGenerateTitleMutation } = aiApi;
