import styled from 'styled-components';
import { Colors, FontSizes } from 'core/CssVariables';
import Steps from 'antd/es/steps';

const { Step } = Steps;

const SStep = styled(Step)`
  &.ant-steps-item-finish, &.ant-steps-item-process {
    &.ant-steps-item {
      .ant-steps-item-icon {
        background-color: ${Colors.DarkPurple};
      }
    }
  }

  &.ant-steps-item-wait {
    .ant-steps-item-title {
      color: ${Colors.LightGrey};
    }

    .ant-steps-item-icon {
      background: ${Colors.LightGrey};
      border: ${Colors.LightGrey};

      .ant-steps-icon {
        color: ${Colors.White};
      }
    }
  }

  .ant-steps-item-container {
    display: flex;
    align-items: center;

    .ant-steps-item-icon {
      height: 24px;
      line-height: 24px;
      width: 24px;
      min-width: 24px;
      border-radius: 50%;
      font-size: ${FontSizes.FontMD}px;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-steps-icon {
        top: 0;
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-steps-item-tail {
      top: 9px;
      padding-left: 8px;
      padding-right: 8px;

      &:after {
        background-color: ${Colors.LightGrey};
      }
    }

    .ant-steps-item-title {
      font-size: ${FontSizes.FontXS}px;
    }
`;

export default SStep;
