import { materialConverter } from 'data/converters/material.converter';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import {
  createPhaseConverter,
  createPhaseTaskConverter,
  phaseSingleTextEditConverter,
  phaseTaskAssignConverter,
  phaseTaskAssigneeConverter,
  phaseTaskCommentConverter,
  phaseTaskConverter,
  phaseTaskReassignConverter,
  phaseTaskResolveConverter,
  phaseTaskScheduleConverter,
  phaseTaskSingleDateEditConverter,
  phaseTaskSingleTextEditConverter,
  phaseTaskStartWorkConverter,
  projectMaterialCategoryEditConverter,
} from 'data/converters/phase.converters';
import {
  projectCommentConverter,
  projectCompactConverter,
  projectConverter,
  projectDetailsConverter,
  projectItemsForInvoiceFilterConverter,
} from 'data/converters/project.converter';
import {
  TMaterial,
  TMaterialPostData,
  TMaterialPostDataUi,
} from 'data/types/material.type';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import {
  ProjectStatus,
  TProjectComment,
  TProjectCommentUi,
  TProjectCompact,
  TProjectCompactUi,
  TProjectDetails,
  TProjectDetailsUi,
  TProjectItemsForInvoiceFilterUi,
  TProjectPostData,
  TProjectPostDataUi,
} from 'data/types/project.type';

import { authSplitApi } from 'redux/helpers/slice.helpers';
import {
  TCreatePhaseTaskUi,
  TCreatePhaseUi,
  TPhase,
  TPhaseTask,
  TPhaseTaskAssignee,
  TPhaseTaskAssigneeUi,
  TPhaseTaskAssignUi,
  TPhaseTaskComment,
  TPhaseTaskCommentUi,
  TPhaseTaskDateEditUi,
  TPhaseTaskReassignUi,
  TPhaseTaskResolveUi,
  TPhaseTaskScheduleUi,
  TPhaseTaskStartWorkUi,
  TPhaseTaskTextEditUi,
  TPhaseTaskUi,
  TPhaseTextEditUi,
  TProjectMaterialCategoryEditUi,
} from 'data/types/phase.type';
import { TQuotedItemsUi, TQuoteUi } from 'data/types/quote.type';
import {
  materialForQuoteConverter,
  quoteConverter,
} from 'data/converters/quote.converter';
import {
  TInvoiceProductItem,
  TInvoiceProductItemUi,
} from 'data/types/invoice.type';
import { invoiceProductItemConverter } from 'data/converters/invoice.converter';
import { TUpdateAttachmentUi } from 'data/types/generalDataTypes';
import { attachmentsUpdateConverter } from 'data/converters/general.converters';
import { patchResponseToToastConverter } from 'data/converters/responses.converter';
import { TPatchResponse, TPatchResponseUi } from 'data/types/responses.type';

const PROJECT_URL = 'api/projects';
const PROJECT_TASKS_URL = 'api/project-tasks';

export const projectApi = authSplitApi('projectApi', [
  'projectsCompact',
  'project',
  'ProjectComments',
  'phase-task',
  'phaseTasksComments',
  'ProjectMaterialForQuote',
  'ProjectMaterialForInvoice',
  'ProjectTimeEntriesForInvoice',
]).injectEndpoints({
  endpoints: build => ({
    createProject: build.mutation<TProjectPostDataUi, TProjectPostData>({
      query(body) {
        return {
          url: PROJECT_URL,
          method: 'POST',
          body: projectConverter.toDb(body),
        };
      },
      invalidatesTags: ['projectsCompact'],
    }),
    getProjects: build.query<
      TPageableDataWithContentUi<TProjectCompactUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        filters?: any;
        search?: string;
      }
    >({
      query({ page, pageSize, sort, filters, search }) {
        const urlWithParams = PROJECT_URL?.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
        };
      },
      providesTags: ['projectsCompact'],
      transformResponse: (data: TPageableDataWithContent<TProjectCompact[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(project => projectCompactConverter.fromDb(project))
            : [],
        };
      },
    }),
    deleteProject: build.mutation<TPhase, string>({
      query(projectId) {
        return {
          url: `${PROJECT_URL}/${projectId}`,
          method: 'Delete',
        };
      },
      invalidatesTags: ['project', 'projectsCompact'],
    }),
    getProjectById: build.query<TProjectDetailsUi, string>({
      query(id) {
        return {
          url: `${PROJECT_URL}/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TProjectDetails) =>
        projectDetailsConverter.fromDb(data),
      providesTags: ['project'],
    }),

    getProjectComments: build.query<
      TProjectCommentUi[],
      {
        projectId: string;
      }
    >({
      query({ projectId }) {
        const urlWithParams = PROJECT_URL.concat(`/${projectId}/comments`);
        return {
          url: urlWithParams,
          method: 'GET',
        };
      },
      providesTags: ['ProjectComments'],
      transformResponse: (data: TProjectComment[]) => {
        return data.map(comment => projectCommentConverter.fromDb(comment));
      },
      keepUnusedDataFor: 0,
    }),

    saveProjectComment: build.mutation<
      void,
      {
        projectId: string;
        text: string;
        replyToCustomer: boolean;
        additionalRecipients?: string[];
        isHtml: boolean
      }
    >({
      query({ projectId, text, replyToCustomer, additionalRecipients, isHtml }) {
        return {
          url: PROJECT_URL.concat(`/${projectId}/comment`),
          method: 'PATCH',
          body: {
            text,
            replyToCustomer,
            additionalRecipients,
            isHtml
          },
        };
      },
      invalidatesTags: ['ProjectComments'],
    }),

    editProjectComment: build.mutation<
      void,
      {
        commentId: string;
        value: string;
      }
    >({
      query({ commentId, value }) {
        return {
          url: PROJECT_URL.concat(`/comments/${commentId}/text`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['ProjectComments'],
    }),
    markUsReadProjectComment: build.mutation<
      void,
      {
        commentId: string;
      }
    >({
      query({ commentId }) {
        return {
          url: PROJECT_URL.concat(`/comments/${commentId}/mark-as-read`),
          method: 'PATCH',
        };
      },
      invalidatesTags: ['ProjectComments'],
    }),

    deleteProjectCommentById: build.mutation<
      void,
      {
        commentId: string;
      }
    >({
      query({ commentId }) {
        return {
          url: PROJECT_URL.concat(`/comments/${commentId}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['ProjectComments'],
    }),

    editTitle: build.mutation<void, { value: string; id: string }>({
      query({ value, id }) {
        return {
          url: `${PROJECT_URL}/${id}/title`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['projectsCompact', 'project'],
    }),
    editDescription: build.mutation<void, { value: string; id: string }>({
      query({ value, id }) {
        return {
          url: `${PROJECT_URL}/${id}/description`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['projectsCompact', 'project'],
    }),
    editAssignee: build.mutation<void, { value: string; id: string }>({
      query({ value, id }) {
        return {
          url: `${PROJECT_URL}/${id}/assignee`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['projectsCompact', 'project'],
    }),
    editEstimatedHours: build.mutation<void, { value: number; id: string }>({
      query({ value, id }) {
        return {
          url: `${PROJECT_URL}/${id}/estimatedHours`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['projectsCompact', 'project'],
    }),
    editStatus: build.mutation<void, { value: ProjectStatus; id: string }>({
      query({ value, id }) {
        return {
          url: `${PROJECT_URL}/${id}/status`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['projectsCompact', 'project'],
    }),
    editCompany: build.mutation<void, { value: string; id: string }>({
      query({ value, id }) {
        return {
          url: `${PROJECT_URL}/${id}/company`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['projectsCompact', 'project'],
    }),
    editStartTime: build.mutation<void, { value: string; id: string }>({
      query({ value, id }) {
        return {
          url: `${PROJECT_URL}/${id}/startTime`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['projectsCompact', 'project'],
    }),
    editEndTime: build.mutation<void, { value: string; id: string }>({
      query({ value, id }) {
        return {
          url: `${PROJECT_URL}/${id}/endTime`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['projectsCompact', 'project'],
    }),
    editReporter: build.mutation<void, { value: string; id: string }>({
      query({ value, id }) {
        return {
          url: `${PROJECT_URL}/${id}/reporter`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['projectsCompact', 'project'],
    }),
    editBillingMethod: build.mutation<void, { value: string; id: string }>({
      query({ value, id }) {
        return {
          url: `${PROJECT_URL}/${id}/billingMethod`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['projectsCompact', 'project'],
    }),
    createMaterialProject: build.mutation<
      TMaterialPostDataUi,
      TMaterialPostData & {
        projectId: string;
      }
    >({
      query(body) {
        return {
          url: PROJECT_URL.concat(`/${body.projectId}/materials`),
          method: 'PATCH',
          body: materialConverter.toDb(body),
        };
      },
      invalidatesTags: ['project'],
    }),
    updateMaterialStatusProject: build.mutation<
      void,
      {
        materialId: string;
        projectId: string;
        value: string;
      }
    >({
      query({ materialId, projectId, value }) {
        return {
          url: PROJECT_URL.concat(
            `/${projectId}/materials/${materialId}/status`,
          ),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['project', 'projectsCompact'],
    }),
    updateMaterialNameProject: build.mutation<
      void,
      {
        materialId: string;
        projectId: string;
        value: string;
      }
    >({
      query({ materialId, projectId, value }) {
        return {
          url: PROJECT_URL.concat(`/${projectId}/materials/${materialId}/name`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['project'],
    }),
    updateMaterialCategoryProject: build.mutation<
      void,
      TProjectMaterialCategoryEditUi
    >({
      query(body) {
        const { materialId, projectId } = body;
        return {
          url: PROJECT_URL.concat(
            `/${projectId}/materials/${materialId}/category`,
          ),
          method: 'PATCH',
          body: projectMaterialCategoryEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['project'],
    }),
    updateMaterialQtyProject: build.mutation<
      void,
      {
        materialId: string;
        projectId: string;
        value: number;
      }
    >({
      query({ materialId, projectId, value }) {
        return {
          url: PROJECT_URL.concat(
            `/${projectId}/materials/${materialId}/quantity`,
          ),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['project'],
    }),
    updateMaterialCostProject: build.mutation<
      void,
      {
        materialId: string;
        projectId: string;
        value: number;
      }
    >({
      query({ materialId, projectId, value }) {
        return {
          url: PROJECT_URL.concat(`/${projectId}/materials/${materialId}/cost`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['project'],
    }),
    updateMaterialPriceProject: build.mutation<
      void,
      {
        materialId: string;
        projectId: string;
        value: number;
      }
    >({
      query({ materialId, projectId, value }) {
        return {
          url: PROJECT_URL.concat(
            `/${projectId}/materials/${materialId}/price`,
          ),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['project'],
    }),
    updateMaterialDescriptionProject: build.mutation<
      void,
      {
        materialId: string;
        projectId: string;
        value: string;
      }
    >({
      query({ materialId, projectId, value }) {
        return {
          url: PROJECT_URL.concat(
            `/${projectId}/materials/${materialId}/description`,
          ),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['project'],
    }),
    updateMaterialOrderProject: build.mutation<
      TProjectDetails,
      {
        materialId: string;
        projectId: string;
        value: number;
      }
    >({
      query({ materialId, projectId, value }) {
        return {
          url: PROJECT_URL.concat(
            `/${projectId}/materials/${materialId}/order`,
          ),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const convertedData = projectDetailsConverter.fromDb(data);
        dispatch(
          projectApi.util.updateQueryData(
            'getProjectById',
            projectId,
            draft => {
              Object.assign(draft, convertedData);
            },
          ),
        );
      },
    }),
    deleteMaterial: build.mutation<
      TPhase,
      { projectId: string; materialId: string }
    >({
      query({ projectId, materialId }) {
        return {
          url: `${PROJECT_URL}/${projectId}/materials/${materialId}`,
          method: 'Delete',
        };
      },
      invalidatesTags: ['project', 'projectsCompact'],
    }),
    createPhase: build.mutation<TPhase, TCreatePhaseUi>({
      query(body) {
        const { id } = body;
        return {
          url: `${PROJECT_URL}/${id}/phase/create`,
          method: 'PATCH',
          body: createPhaseConverter.toDb(body),
        };
      },
      invalidatesTags: ['project'],
    }),
    createTask: build.mutation<TPhase, TCreatePhaseTaskUi>({
      query(body) {
        const { id, phaseId } = body;
        return {
          url: `${PROJECT_URL}/${id}/phases/${phaseId}/create-task`,
          method: 'PATCH',
          body: createPhaseTaskConverter.toDb(body),
        };
      },
      invalidatesTags: ['project'],
    }),
    deletePhase: build.mutation<TPhase, { projectId: string; phaseId: string }>(
      {
        query({ projectId, phaseId }) {
          return {
            url: `${PROJECT_URL}/${projectId}/phases/${phaseId}`,
            method: 'DELETE',
          };
        },
        invalidatesTags: ['project'],
      },
    ),
    deletePhaseTask: build.mutation<
      TPhase,
      { projectId: string; taskId: string }
    >({
      query({ projectId, taskId }) {
        return {
          url: `${PROJECT_URL}/${projectId}/tasks/${taskId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['project', 'phase-task'],
    }),
    updatePhaseTaskOrder: build.mutation<
      TPhase,
      { projectId: string; phaseId: string; taskId: string; order: number }
    >({
      query({ projectId, phaseId, taskId, order }) {
        return {
          url: `${PROJECT_URL}/${projectId}/phases/${phaseId}/tasks/${taskId}/order`,
          method: 'PATCH',
          body: { value: order },
        };
      },
      invalidatesTags: ['project'],
    }),
    getPhaseTask: build.query<TPhaseTaskUi, string>({
      query(id) {
        return {
          url: `${PROJECT_TASKS_URL}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['phase-task'],
      transformResponse: (data: TPhaseTask) => phaseTaskConverter.fromDb(data),
    }),

    getPhaseTaskComments: build.query<
      TPhaseTaskCommentUi[],
      {
        taskId: string;
      }
    >({
      query({ taskId }) {
        const urlWithParams = PROJECT_TASKS_URL.concat(`/${taskId}/comments`);
        return {
          url: urlWithParams,
          method: 'GET',
        };
      },
      providesTags: ['phaseTasksComments'],
      transformResponse: (data: TPhaseTaskComment[]) => {
        return data.map(comment => phaseTaskCommentConverter.fromDb(comment));
      },
      keepUnusedDataFor: 0,
    }),

    savePhaseTaskComment: build.mutation<
      void,
      {
        taskId: string;
        value: string;
      }
    >({
      query({ taskId, value }) {
        return {
          url: PROJECT_TASKS_URL.concat(`/${taskId}/comments`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['phaseTasksComments'],
    }),

    editPhaseTaskComment: build.mutation<
      void,
      {
        commentId: string;
        value: string;
      }
    >({
      query({ commentId, value }) {
        return {
          url: PROJECT_TASKS_URL.concat(`/comments/${commentId}/text`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['phaseTasksComments'],
    }),

    deletePhaseTaskCommentById: build.mutation<
      void,
      {
        commentId: string;
      }
    >({
      query({ commentId }) {
        return {
          url: PROJECT_TASKS_URL.concat(`/comments/${commentId}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['phaseTasksComments'],
    }),
    getProjectTasksAssignee: build.query<TPhaseTaskAssigneeUi[], void>({
      query() {
        return {
          url: `${PROJECT_TASKS_URL}/assignee`,
          method: 'GET',
        };
      },
      transformResponse: (data: TPhaseTaskAssignee[]) =>
        data.map(item => phaseTaskAssigneeConverter.fromDb(item)),
    }),
    assignPhaseTask: build.mutation<TPhaseTaskUi, TPhaseTaskAssignUi>({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/assign`,
          method: 'PATCH',
          body: phaseTaskAssignConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    reassignPhaseTask: build.mutation<TPatchResponseUi, TPhaseTaskReassignUi>({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/reassign`,
          method: 'PATCH',
          body: phaseTaskReassignConverter.toDb(body),
        };
      },
      transformResponse(data: TPatchResponse, meta) {
        return patchResponseToToastConverter.fromDb({
          ...data,
          status: meta?.response?.status,
        });
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    startWorkPhaseTask: build.mutation<TPhaseTaskUi, TPhaseTaskStartWorkUi>({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/start-work`,
          method: 'PATCH',
          body: phaseTaskStartWorkConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    schedulePhaseTask: build.mutation<TPatchResponseUi, TPhaseTaskScheduleUi>({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/schedule`,
          method: 'PATCH',
          body: phaseTaskScheduleConverter.toDb(body),
        };
      },
      transformResponse(data: TPatchResponse, meta) {
        return patchResponseToToastConverter.fromDb({
          ...data,
          status: meta?.response?.status,
        });
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    reschedulePhaseTask: build.mutation<TPatchResponseUi, TPhaseTaskScheduleUi>(
      {
        query(body) {
          const { taskId } = body;
          return {
            url: `${PROJECT_TASKS_URL}/${taskId}/reschedule`,
            method: 'PATCH',
            body: phaseTaskScheduleConverter.toDb(body),
          };
        },
        transformResponse(data: TPatchResponse, meta) {
          return patchResponseToToastConverter.fromDb({
            ...data,
            status: meta?.response?.status,
          });
        },
        invalidatesTags: ['phase-task', 'project'],
      },
    ),
    resolvePhaseTask: build.mutation<TPhaseTaskUi, TPhaseTaskResolveUi>({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/resolve`,
          method: 'PATCH',
          body: phaseTaskResolveConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    editPhaseTitle: build.mutation<TPhaseTaskUi, TPhaseTextEditUi>({
      query(body) {
        const { phaseId, projectId } = body;
        return {
          url: `${PROJECT_URL}/${projectId}/phases/${phaseId}/title`,
          method: 'PATCH',
          body: phaseSingleTextEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    editPhaseDescription: build.mutation<TPhaseTaskUi, TPhaseTextEditUi>({
      query(body) {
        const { phaseId, projectId } = body;
        return {
          url: `${PROJECT_URL}/${projectId}/phases/${phaseId}/description`,
          method: 'PATCH',
          body: phaseSingleTextEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['project'],
    }),
    editPhaseTaskTitle: build.mutation<TPhaseTaskUi, TPhaseTaskTextEditUi>({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/title`,
          method: 'PATCH',
          body: phaseTaskSingleTextEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    editPhaseTaskDescription: build.mutation<
      TPhaseTaskUi,
      TPhaseTaskTextEditUi
    >({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/description`,
          method: 'PATCH',
          body: phaseTaskSingleTextEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    editPhaseTaskNextActionDescription: build.mutation<
      TPhaseTaskUi,
      TPhaseTaskTextEditUi
    >({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/next-action-item-description`,
          method: 'PATCH',
          body: phaseTaskSingleTextEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    editPhaseTaskStartDate: build.mutation<TPhaseTaskUi, TPhaseTaskDateEditUi>({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/start-date`,
          method: 'PATCH',
          body: phaseTaskSingleDateEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    editPhaseTaskEstimatedHours: build.mutation<
      TPhaseTaskUi,
      TPhaseTaskTextEditUi
    >({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/estimated-hours`,
          method: 'PATCH',
          body: phaseTaskSingleTextEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    editPhaseTaskEndDate: build.mutation<TPhaseTaskUi, TPhaseTaskDateEditUi>({
      query(body) {
        const { taskId } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/end-date`,
          method: 'PATCH',
          body: phaseTaskSingleDateEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'project'],
    }),
    createTaskMaterial: build.mutation<
      TMaterialPostDataUi,
      TMaterialPostData & {
        taskId: string;
      }
    >({
      query(body) {
        return {
          url: PROJECT_TASKS_URL.concat(`/${body.taskId}/materials`),
          method: 'PATCH',
          body: materialConverter.toDb(body),
        };
      },
      invalidatesTags: ['phase-task', 'ProjectTaskMaterialForQuote'],
    }),
    updateTaskMaterialStatus: build.mutation<
      void,
      {
        materialId: string;
        taskId: string;
        value: string;
      }
    >({
      query({ materialId, taskId, value }) {
        return {
          url: PROJECT_TASKS_URL.concat(
            `/${taskId}/materials/${materialId}/status`,
          ),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['phase-task', 'ProjectTaskMaterialForQuote'],
    }),
    updateTaskMaterialName: build.mutation<
      void,
      {
        materialId: string;
        taskId: string;
        value: string;
      }
    >({
      query({ materialId, taskId, value }) {
        return {
          url: PROJECT_TASKS_URL.concat(
            `/${taskId}/materials/${materialId}/name`,
          ),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['phase-task', 'ProjectTaskMaterialForQuote'],
    }),
    updateTaskMaterialQty: build.mutation<
      void,
      {
        materialId: string;
        taskId: string;
        value: number;
      }
    >({
      query({ materialId, taskId, value }) {
        return {
          url: PROJECT_TASKS_URL.concat(
            `/${taskId}/materials/${materialId}/quantity`,
          ),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['phase-task', 'ProjectTaskMaterialForQuote'],
    }),
    updateTaskMaterialCost: build.mutation<
      void,
      {
        materialId: string;
        taskId: string;
        value: number;
      }
    >({
      query({ taskId, materialId, value }) {
        return {
          url: PROJECT_TASKS_URL.concat(
            `/${taskId}/materials/${materialId}/cost`,
          ),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['phase-task', 'ProjectTaskMaterialForQuote'],
    }),
    updateTaskMaterialPrice: build.mutation<
      void,
      {
        materialId: string;
        taskId: string;
        value: number;
      }
    >({
      query({ materialId, taskId, value }) {
        return {
          url: PROJECT_TASKS_URL.concat(
            `/${taskId}/materials/${materialId}/price`,
          ),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['phase-task', 'ProjectTaskMaterialForQuote'],
    }),
    deleteTaskMaterial: build.mutation<
      TPhase,
      { taskId: string; materialId: string }
    >({
      query({ taskId, materialId }) {
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/materials/${materialId}`,
          method: 'Delete',
        };
      },
      invalidatesTags: ['phase-task', 'ProjectTaskMaterialForQuote'],
    }),

    taskMaterialsExport: build.mutation<Blob, string>({
      query(taskId) {
        return {
          url: `${PROJECT_TASKS_URL}/${taskId}/materials/export`,
          method: 'POST',
          responseHandler: response => response.text(),
        };
      },
    }),
    projectMaterialsExport: build.mutation<Blob, string>({
      query(projectId) {
        return {
          url: `${PROJECT_URL}/${projectId}/materials/export`,
          method: 'POST',
          responseHandler: response => response.text(),
        };
      },
    }),
    getProjectMaterialsForQuote: build.query<
      TQuotedItemsUi[],
      { projectId?: string }
    >({
      query({ projectId }) {
        return {
          url: `${PROJECT_URL}/${projectId}/materials-for-quote`,
          method: 'GET',
        };
      },
      providesTags: ['ProjectMaterialForQuote'],
      transformResponse: (data: TMaterial[]) => {
        return data.map(quoteItem =>
          materialForQuoteConverter.fromDb(quoteItem),
        );
      },
      keepUnusedDataFor: 0,
    }),
    generateProjectQuote: build.mutation<
      Blob,
      TQuoteUi & { projectId: string }
    >({
      query(body) {
        const { projectId, ...payload } = body;
        return {
          url: `${PROJECT_URL}/${projectId}/quote`,
          method: 'POST',
          body: quoteConverter.toDb(payload),
          responseHandler: response => response.blob(),
        };
      },
      invalidatesTags: ['project'],
    }),
    updateProjectAttachments: build.mutation<
      void,
      {
        projectId: string;
        attachments: TUpdateAttachmentUi[];
      }
    >({
      query({ projectId, attachments }) {
        return {
          url: `${PROJECT_URL}/${projectId}/attachments`,
          method: 'PATCH',
          body: attachmentsUpdateConverter.toDb(attachments),
        };
      },
      invalidatesTags: ['project'],
    }),
    deleteProjectAttachment: build.mutation<
      void,
      {
        projectId: string;
        attachmentId: string;
      }
    >({
      query({ projectId, attachmentId }) {
        return {
          url: `${PROJECT_URL}/${projectId}/attachments/${attachmentId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['project'],
    }),
    getProjectTaskMaterialsForQuote: build.query<
      TQuotedItemsUi[],
      { projectTaskId?: string }
    >({
      query({ projectTaskId }) {
        return {
          url: `${PROJECT_TASKS_URL}/${projectTaskId}/materials-for-quote`,
          method: 'GET',
        };
      },
      providesTags: ['ProjectTaskMaterialForQuote'],
      transformResponse: (data: TMaterial[]) => {
        return data.map(quoteItem =>
          materialForQuoteConverter.fromDb(quoteItem),
        );
      },
      keepUnusedDataFor: 0,
    }),
    generateProjectTaskQuote: build.mutation<
      Blob,
      TQuoteUi & { projectTaskId: string }
    >({
      query(body) {
        const { projectTaskId, ...payload } = body;
        return {
          url: `${PROJECT_TASKS_URL}/${projectTaskId}/quote`,
          method: 'POST',
          body: quoteConverter.toDb(payload),
          responseHandler: response => response.blob(),
        };
      },
      invalidatesTags: ['phase-task'],
    }),
    getProjectItemsForInvoice: build.query<
      TInvoiceProductItemUi[],
      TProjectItemsForInvoiceFilterUi & { projectId?: string }
    >({
      query({ projectId, ...filters }) {
        return {
          url: `${PROJECT_URL}/${projectId}/invoice-eligible-items`,
          method: 'GET',
          params: projectItemsForInvoiceFilterConverter.toDb(filters),
        };
      },
      providesTags: ['ProjectMaterialForInvoice'],
      transformResponse: (data: TInvoiceProductItem[]) => {
        return data.map(invoiceItem =>
          invoiceProductItemConverter.fromDb(invoiceItem),
        );
      },
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const {
  useUpdateMaterialStatusProjectMutation,
  useUpdateMaterialPriceProjectMutation,
  useUpdateMaterialCostProjectMutation,
  useUpdateMaterialNameProjectMutation,
  useUpdateMaterialCategoryProjectMutation,
  useUpdateMaterialQtyProjectMutation,
  useUpdateMaterialDescriptionProjectMutation,
  useUpdateMaterialOrderProjectMutation,
  useCreateMaterialProjectMutation,
  useCreateProjectMutation,
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useCreatePhaseMutation,
  useCreateTaskMutation,
  useGetProjectCommentsQuery,
  useSaveProjectCommentMutation,
  useEditProjectCommentMutation,
  useDeleteProjectCommentByIdMutation,
  useEditTitleMutation,
  useEditDescriptionMutation,
  useEditAssigneeMutation,
  useEditEstimatedHoursMutation,
  useEditStatusMutation,
  useEditCompanyMutation,
  useEditStartTimeMutation,
  useEditEndTimeMutation,
  useEditReporterMutation,
  useEditBillingMethodMutation,
  useDeletePhaseMutation,
  useDeleteMaterialMutation,
  useUpdatePhaseTaskOrderMutation,
  useGetPhaseTaskQuery,
  useGetPhaseTaskCommentsQuery,
  useSavePhaseTaskCommentMutation,
  useEditPhaseTaskCommentMutation,
  useDeletePhaseTaskCommentByIdMutation,
  useGetProjectTasksAssigneeQuery,
  useAssignPhaseTaskMutation,
  useReassignPhaseTaskMutation,
  useStartWorkPhaseTaskMutation,
  useReschedulePhaseTaskMutation,
  useSchedulePhaseTaskMutation,
  useResolvePhaseTaskMutation,
  useEditPhaseTaskTitleMutation,
  useEditPhaseTaskDescriptionMutation,
  useEditPhaseTaskNextActionDescriptionMutation,
  useEditPhaseTaskEndDateMutation,
  useEditPhaseTaskStartDateMutation,
  useEditPhaseTaskEstimatedHoursMutation,
  useEditPhaseTitleMutation,
  useEditPhaseDescriptionMutation,
  useUpdateTaskMaterialStatusMutation,
  useUpdateTaskMaterialPriceMutation,
  useUpdateTaskMaterialCostMutation,
  useUpdateTaskMaterialNameMutation,
  useUpdateTaskMaterialQtyMutation,
  useCreateTaskMaterialMutation,
  useDeleteTaskMaterialMutation,
  useDeleteProjectMutation,
  useDeletePhaseTaskMutation,
  useTaskMaterialsExportMutation,
  useProjectMaterialsExportMutation,
  useGenerateProjectQuoteMutation,
  useGenerateProjectTaskQuoteMutation,
  useLazyGetProjectMaterialsForQuoteQuery,
  useGetProjectTaskMaterialsForQuoteQuery,
  useLazyGetProjectItemsForInvoiceQuery,
  useUpdateProjectAttachmentsMutation,
  useDeleteProjectAttachmentMutation,
  useMarkUsReadProjectCommentMutation,
} = projectApi;
