import {
  AllTicketsPath,
  Commenter,
  CommenterUi,
  ETicketSpecialFlags,
  ETicketSpecialFlagsUi,
  TAssignee,
  TAssigneeUi,
  TChecklistItem,
  TCheckListItemCreator,
  TCheckListItemCreatorUi,
  TChecklistItemUi,
  TCreateTicket,
  TCreateTicketUi,
  TEditTicketAssignee,
  TEditTicketCompany,
  TEditTicketDescription,
  TEditTicketIssueSubType,
  TEditTicketIssueSubTypeUi,
  TEditTicketIssueType,
  TEditTicketPriority,
  TEditTicketQueue,
  TEditTicketReporter,
  TEditTicketScheduleDescription,
  TEditTicketStatus,
  TEditTicketTitle,
  TEditTicketUi,
  TicketActionEnum,
  TicketFilters,
  TPrimaryTitleType,
  TPrimaryTitleTypeUI,
  TTicket,
  TTicketAction,
  TTicketActionUi,
  TTicketAssigned,
  TTicketAssignedUi,
  TTicketComment,
  TTicketCommentUi,
  TTicketCompany,
  TTicketCompanyUi,
  TTicketDetails,
  TTicketDetailsUi,
  TTicketReporter,
  TTicketReporterUi,
  TTicketSpecialFlags,
  TTicketSpecialFlagsUi,
  TTicketStatus,
  TTicketStatusUi,
  TTicketTableDefaultSortMapper,
  TTicketUi,
  TTitleGrade,
  TTitleGradeUI,
} from 'data/types/ticket.types';
import { getDateTimeFormats } from 'helpers/utils';
import moment from 'moment';
import {
  TAuthConverter,
  TConverter,
  TCreateConverter,
  TFetchConverter,
} from '../types/converter.types';
import {
  contactShortDataConverter,
  devicesShortDataConverter,
} from './shortDataConverter';
import {
  attachmentsConverter,
  attachmentsGetConverter,
  GetFirstLetters,
  TimeConverter,
} from './general.converters';
import {
  materialConverter,
  materialTotalsConverter,
} from './material.converter';
import { slaConverter } from './sla.converter';
import { TIconNames } from '../../components/Icon';
import { Colors } from '../../core/CssVariables';
import { commentAdditionalRecipientsConverter } from './commentAdditionalRecipients.converter';

export const assigneeConverter: TFetchConverter<TAssigneeUi, TAssignee> = {
  fromDb: data => {
    return data
      ? {
          id: data.id,
          name: data.name,
          firstLetters: GetFirstLetters.fromDb(data.name),
          url: data.photoUrl || '',
        }
      : null;
  },
};

export const ticketStatusConverter: TConverter<TTicketStatusUi, TTicketStatus> =
  {
    fromDb: data => {
      return TTicketStatusUi[
        TTicketStatus[data] as keyof typeof TTicketStatusUi
      ];
    },
    toDb: data => {
      return TTicketStatus[TTicketStatusUi[data] as keyof typeof TTicketStatus];
    },
  };

export const ticketGradeConverter: TConverter<TTitleGradeUI, TTitleGrade> = {
  fromDb: data => {
    return TTitleGradeUI[TTitleGrade[data] as keyof typeof TTitleGradeUI];
  },
  toDb: data => {
    return TTitleGrade[TTitleGradeUI[data] as keyof typeof TTitleGrade];
  },
};

export const ticketPrimaryTitleTypeConverter: TConverter<
  TPrimaryTitleTypeUI,
  TPrimaryTitleType
> = {
  fromDb: data => {
    return TPrimaryTitleTypeUI[
      TPrimaryTitleType[data] as keyof typeof TPrimaryTitleTypeUI
    ];
  },
  toDb: data => {
    return TPrimaryTitleType[
      TPrimaryTitleTypeUI[data] as keyof typeof TPrimaryTitleType
    ];
  },
};

const ticketCompanyConverter: TFetchConverter<
  TTicketCompanyUi,
  TTicketCompany
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      logoUrl: data.logoUrl,
      mainContact:
        data.mainContact && contactShortDataConverter.fromDb(data.mainContact),
      firstLetters: GetFirstLetters.fromDb(data.name),
      phoneNumber: data.phoneNumber || '',
      shortName: data.shortName,
      nameWithAbbreviation: data.shortName
        ? `${data.shortName} - ${data.name}`
        : data.name,
    };
  },
};
const ticketSpecialFlagsConverter: TFetchConverter<
  TTicketSpecialFlagsUi,
  TTicketSpecialFlags
> = {
  fromDb: data => {
    return {
      invoicePaid: data.invoicePaid,
      invoiceSent: data.invoiceSent,
      overdueSince: data.overdueSince
        ? moment(data.overdueSince).format(getDateTimeFormats().timeFormat)
        : null,
      unreadComments: data.unreadComments,
      rmmAlertResolved: data.rmmAlertResolved,
    };
  },
};

export const ticketConverter: TAuthConverter<
  TCreateTicketUi,
  TCreateTicket,
  TTicketUi,
  TTicket
> = {
  fromDb: (data: TTicket) => {
    const { dateTimeFormat, dateFormat } = getDateTimeFormats();
    return {
      id: data.id,
      title: data.title,
      ...(data.aiTitle && {
        aiTitle: data.aiTitle,
      }),
      ...(data.primaryTitleType && {
        primaryTitleType: ticketPrimaryTitleTypeConverter.fromDb(
          data.primaryTitleType,
        ),
      }),
      ...(data.titleGrade && {
        titleGrade: ticketGradeConverter.fromDb(data.titleGrade),
      }),
      number: data.number,
      priority: data.priority,
      queue: data.queue,
      resSLA: TimeConverter.fromDb(data.resSLA),
      status: ticketStatusConverter.fromDb(data.status),
      assignee: assigneeConverter.fromDb(data.assignee),
      company: ticketCompanyConverter.fromDb(data.company),
      compilationDateTime:
        data.compilationDateTime &&
        moment(data.compilationDateTime).format(dateTimeFormat),
      contactId: data.contactId,
      creationDateTime:
        data.creationDateTime &&
        moment(data.creationDateTime).format(dateTimeFormat),
      creationDate:
        data.creationDateTime &&
        moment(data.creationDateTime).format(dateFormat),
      description: data.description,
      frSLA: TimeConverter.fromDb(data.frSLA),
      issueSubType: data.issueSubType,
      issueType: data.issueType,
      score: data.score,
      resolutionSla: slaConverter.fromDb(data.resolutionSla),
      firstResponseSla: slaConverter.fromDb(data.firstResponseSla),
      nextActionItem: data.nextActionItem,
      htmlContent: data.htmlContent,
      specialFlags: ticketSpecialFlagsConverter.fromDb(data.specialFlags),
    };
  },
  toDb: (data: TCreateTicketUi) => {
    return {
      title: data.title,
      description: data.description,
      status: data.status,
      priority: data.priority,
      queueId: data.queueId,
      issueTypeId: data.issueTypeId,
      issueSubTypeId: data.issueSubTypeId,
      assigneeId: data.assigneeId,
      companyId: data.companyId,
      reporterId: data.reporterId,
      attachments: attachmentsConverter.toDb(data.attachments),
      deviceId: data.deviceId,
    };
  },
};
export const ticketAssignedConverter: TFetchConverter<
  TTicketAssignedUi,
  TTicketAssigned
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
    };
  },
};

const ticketCheckListItemCreator: TFetchConverter<
  TCheckListItemCreatorUi,
  TCheckListItemCreator
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
    };
  },
};
export const ticketCheckListItem: TFetchConverter<
  TChecklistItemUi,
  TChecklistItem
> = {
  fromDb: data => {
    return {
      id: data.id,
      isDone: data.isChecked,
      creationDateTime: data.creationDateTime,
      creator: ticketCheckListItemCreator.fromDb(data.creator),
      description: data.description,
      parentId: data.parentId,
    };
  },
};
const ticketActionConverter: TFetchConverter<TTicketActionUi, TTicketAction> = {
  fromDb: data => {
    return {
      action: data.action,
      nextPossibleStatuses: data.nextPossibleStatuses,
    };
  },
};
const ticketReporterConverter: TFetchConverter<
  TTicketReporterUi,
  TTicketReporter
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber || '',
      isVip: data.isVip,
      phoneExtension: data.phoneExtension,
      jobTitle: data.jobTitle,
      personalAddress: data.personalAddress,
      cellPhoneNumber: data.cellPhoneNumber || '',
      firstLetters: GetFirstLetters.fromDb(data.name),
    };
  },
};

export const ticketDetailsConverter: TFetchConverter<
  TTicketDetailsUi,
  TTicketDetails
> = {
  fromDb: data => {
    const arrTicketActions = Object.keys(TicketActionEnum);
    return {
      attachments: attachmentsGetConverter.fromDb(data.attachments),
      ticket: ticketConverter.fromDb(data.ticket),
      company: ticketCompanyConverter.fromDb(data.company),
      checklistItems: data.checklistItems.map(checklistItem =>
        ticketCheckListItem.fromDb(checklistItem),
      ),
      actions:
        data?.actions
          ?.sort((a, b) => {
            return (
              arrTicketActions.indexOf(a.action) -
              arrTicketActions.indexOf(b.action)
            );
          })
          .map(action => ticketActionConverter.fromDb(action)) || [],
      reporter: data.reporter && ticketReporterConverter.fromDb(data.reporter),
      materialTotals:
        data.materialTotals &&
        materialTotalsConverter.fromDb(data.materialTotals),
      materials:
        data.materials &&
        data.materials.map(material => materialConverter.fromDb(material)),
      device: data.device && devicesShortDataConverter.fromDb(data.device),
    };
  },
};

// edit converters  strat
export const ticketTitleEditConverter: TCreateConverter<
  TEditTicketUi,
  TEditTicketTitle
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};

export const ticketDescriptionEditConverter: TCreateConverter<
  TEditTicketUi,
  TEditTicketDescription
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const ticketPriorityEditConverter: TCreateConverter<
  TEditTicketUi,
  TEditTicketPriority
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const ticketStatusEditConverter: TCreateConverter<
  TEditTicketUi,
  TEditTicketStatus
> = {
  toDb: data => ({
    id: data.id,
    status: data.value,
  }),
};

export const ticketAssigneeEditConverter: TCreateConverter<
  TEditTicketUi,
  TEditTicketAssignee
> = {
  toDb: data => ({
    id: data.id,
    assigneeId: data.value,
  }),
};

export const ticketComapnyEditConverter: TCreateConverter<
  TEditTicketUi,
  TEditTicketCompany
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};

export const ticketQueueEditConverter: TCreateConverter<
  TEditTicketUi,
  TEditTicketQueue
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};

export const ticketIssueTypeEditConverter: TCreateConverter<
  TEditTicketUi,
  TEditTicketIssueType
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};

export const ticketIssueSubTypeEditConverter: TCreateConverter<
  TEditTicketIssueSubTypeUi,
  TEditTicketIssueSubType
> = {
  toDb: data => ({
    id: data.id,
    issueTypeId: data.issueTypeId,
    issueSubtypeId: data.issueSubTypeId,
  }),
};

export const ticketScheduleDescriptionEditConverter: TCreateConverter<
  TEditTicketUi,
  TEditTicketScheduleDescription
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const ticketReporterEditConverter: TCreateConverter<
  TEditTicketUi,
  TEditTicketReporter
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};

export const commenterConverter: TFetchConverter<CommenterUi, Commenter> = {
  fromDb: data => {
    return {
      fullName: data.fullName,
      photoUrl: data.photoUrl,
      userId: data.userId,
      userType: data.userType,
      firstLetters: GetFirstLetters.fromDb(data.fullName),
    };
  },
};
export const ticketCommentConverter: TFetchConverter<
  TTicketCommentUi,
  TTicketComment
> = {
  fromDb: data => {
    return {
      commenter: commenterConverter.fromDb(data.commenter),
      creationTime: data.creationTime,
      edited: data.edited,
      fromEmail: data.fromEmail,
      id: data.id,
      lastModifiedTime: data.lastModifiedTime,
      text: data.text,
      ticketId: data.ticketId,
      replyToCustomer: data.replyToCustomer,
      fromCustomer: data.fromCustomer,
      unread: data.unread,
      additionalRecipients: commentAdditionalRecipientsConverter.fromDb(data.additionalRecipients),
      isHtml: data.isHtml
    };
  },
};

export const ticketTableDefaultSortMapper: TTicketTableDefaultSortMapper = {
  [AllTicketsPath.UNASSIGNED]: 'score,DESC',
  [AllTicketsPath.OPEN]: 'score,DESC',
  [AllTicketsPath.CLOSE]: 'creationDateTime,DESC',
  [AllTicketsPath.ALL]: 'creationDateTime,DESC',
};

export const ticketSpecialFlagsEnumConverter: TCreateConverter<
  ETicketSpecialFlagsUi,
  ETicketSpecialFlags
> = {
  toDb: data => {
    const status: { [key in ETicketSpecialFlagsUi]: ETicketSpecialFlags } = {
      [ETicketSpecialFlagsUi.IS_OVERDUE]: ETicketSpecialFlags.IS_OVERDUE,
      [ETicketSpecialFlagsUi.HAS_UNREAD_COMMENT]:
        ETicketSpecialFlags.HAS_UNREAD_COMMENT,
      [ETicketSpecialFlagsUi.INVOICE_SENT]: ETicketSpecialFlags.INVOICE_SENT,
      [ETicketSpecialFlagsUi.INVOICE_PAID]: ETicketSpecialFlags.INVOICE_PAID,
      [ETicketSpecialFlagsUi.RMM_ALERT_RESOLVED]:
        ETicketSpecialFlags.RMM_ALERT_RESOLVED,
    };
    return status[data];
  },
};

export const ticketFilterConverter = (filters: {
  [key in TicketFilters]?: string[];
}) => {
  const ticketFilterToDB = {
    ...(filters?.creationDate?.[0] && {
      'creationDate.from': filters?.creationDate?.[0],
    }),
    ...(filters?.creationDate?.[1] && {
      'creationDate.to': filters?.creationDate?.[1],
    }),
    ...(filters?.completionDate?.[0] && {
      'completionDate.from': filters?.completionDate?.[0],
    }),
    ...(filters?.completionDate?.[1] && {
      'completionDate.to': filters?.completionDate?.[1],
    }),
    ...(filters?.queueIds?.length && {
      [TicketFilters.QUEUE]: filters?.queueIds,
    }),
    ...(filters?.priorities?.length && {
      [TicketFilters.PRIORITY]: filters?.priorities,
    }),
    ...(filters?.statuses?.length && {
      [TicketFilters.STATUS]: filters?.statuses.map(status =>
        status.toUpperCase(),
      ),
    }),
    ...(filters?.assigneeIds?.length && {
      [TicketFilters.ASSIGNED]: filters?.assigneeIds,
    }),
    ...(filters?.reporterIds?.length && {
      [TicketFilters.REPORTER]: filters?.reporterIds,
    }),
    ...(filters?.issueTypeIds?.length && {
      [TicketFilters.ISSUE_TYPE]: filters?.issueTypeIds,
    }),
    ...(filters?.issueSubTypeIds?.length && {
      [TicketFilters.ISSUE_SUBTYPE]: filters?.issueSubTypeIds,
    }),
    ...(filters?.companyIds?.length && {
      [TicketFilters.COMPANY]: filters?.companyIds,
    }),
    ...(filters?.specialFlags?.length && {
      [TicketFilters.SPECIAL_FLAGS]: filters.specialFlags.map(flag =>
        ticketSpecialFlagsEnumConverter.toDb(flag as ETicketSpecialFlagsUi),
      ),
    }),
  };
  return ticketFilterToDB;
};

export const ticketGradeMapper: {
  [key in TTitleGradeUI]: {
    icon: TIconNames;
    color: Colors;
  };
} = {
  [TTitleGradeUI.BAD]: {
    icon: TIconNames.RATE_LOW,
    color: Colors.ErrorColor,
  },
  [TTitleGradeUI.GOOD]: {
    icon: TIconNames.RATE_MEDIUM,
    color: Colors.InfoColor,
  },
  [TTitleGradeUI.EXCELLENT]: {
    icon: TIconNames.RATE_HIGH,
    color: Colors.SuccessColor,
  },
};
