import { AssignmentEnumUi } from 'data/types/timeEntry.types';
import { TTicketStatusUi } from 'data/types/ticket.types';
import { TTaskStatusUi } from 'data/types/task.type';
import { PhaseTaskStatusEnumUi } from 'data/types/phase.type';
import { TaskStatusMapperColor } from 'pages/private/Tasks/AllTasks/Tables/TaskTable';
import { TicketStatusMapperColor } from 'pages/private/Tickets/AllTickets/Tables/ColumnData';
import { PhaseTaskStatusMapperColor } from 'pages/private/Projects/PhaseTasks/PhaseTasks.data';

export const getStatusColor = (
  assignmentType: AssignmentEnumUi,
  status: false | TTicketStatusUi | TTaskStatusUi | PhaseTaskStatusEnumUi,
) => {
  const obj = {
    [AssignmentEnumUi.Ticket]:
      TicketStatusMapperColor[status as TTicketStatusUi],
    [AssignmentEnumUi.Task]: TaskStatusMapperColor[status as TTaskStatusUi],
    [AssignmentEnumUi['Project Task']]:
      PhaseTaskStatusMapperColor[status as PhaseTaskStatusEnumUi],
  };
  return obj[assignmentType];
};
