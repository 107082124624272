import {
  TAuthConverter,
  TConverter,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import {
  TaskActionEnum,
  TCreateTask,
  TCreateTaskUi,
  TEditNextActionItemDesc,
  TEditTaskAssignee,
  TEditTaskCompany,
  TEditTaskDescription,
  TEditTaskReporter,
  TEditTaskStatus,
  TEditTaskTitle,
  TEditTaskUi,
  TGetTask,
  TGetTaskUi,
  TTaskAction,
  TTaskActionUi,
  TTaskAssignee,
  TTaskAssigneeUI,
  TTaskComment,
  TTaskCommenter,
  TTaskCommenterUi,
  TTaskCommentUi,
  TTaskCompany,
  TTaskCompanyUi,
  TTaskDetails,
  TTaskDetailsUi,
  TTaskReporter,
  TTaskReporterUI,
  TTaskStatus,
  TTaskStatusUi,
} from 'data/types/task.type';
import moment from 'moment';
import { getDateTimeFormats } from 'helpers/utils';
import { contactShortDataConverter } from './shortDataConverter';
import {
  attachmentsConverter,
  attachmentsGetConverter,
  GetFirstLetters,
} from './general.converters';

export const taskReporterConverter: TFetchConverter<
  TTaskReporterUI,
  TTaskReporter
> = {
  fromDb: data => ({
    id: data.id,
    name: data.name,
    photoUrl: data.photoUrl,
    firstLetters: GetFirstLetters.fromDb(data.name),
  }),
};

export const taskAssigneeConverter: TFetchConverter<
  TTaskAssigneeUI,
  TTaskAssignee
> = {
  fromDb: data => ({
    id: data.id,
    name: data.name,
    photoUrl: data.photoUrl,
    firstLetters: GetFirstLetters.fromDb(data.name),
  }),
};

export const taskStatusConverter: TConverter<TTaskStatusUi, TTaskStatus> = {
  fromDb: data => {
    return TTaskStatusUi[TTaskStatus[data] as keyof typeof TTaskStatusUi];
  },
  toDb: data => {
    return TTaskStatus[TTaskStatusUi[data] as keyof typeof TTaskStatus];
  },
};

export const taskConverter: TAuthConverter<
  TCreateTaskUi,
  TCreateTask,
  TGetTaskUi,
  TGetTask
> = {
  toDb: data => {
    return {
      title: data.title.trim(),
      description: data.description.trim(),
      assigneeId: data.assigneeId,
      companyId: data.companyId,
      attachments: attachmentsConverter.toDb(data.attachments),
    };
  },
  fromDb: data => {
    const { dateTimeFormat } = getDateTimeFormats();
    return {
      id: data.id,
      number: data.number,
      title: data.title,
      status: taskStatusConverter.fromDb(data.status),
      creationDateTime:
        data.creationDateTime &&
        moment(data.creationDateTime).format(dateTimeFormat),
      assignee: taskAssigneeConverter.fromDb(data.assignee),
      reporter: taskReporterConverter.fromDb(data.reporter),
      nextActionItem: data.nextActionItem,
      overdueSince: data.overdueSince
        ? moment(data.overdueSince).format(dateTimeFormat)
        : null,
    };
  },
};

export const taskCompanyConverter: TFetchConverter<
  TTaskCompanyUi,
  TTaskCompany
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      shortName: data.shortName,
      nameWithAbbreviation: data.shortName
        ? `${data.shortName} - ${data.name}`
        : data.name,
      logoUrl: data.logoUrl,
      mainContact:
        data.mainContact && contactShortDataConverter.fromDb(data.mainContact),
      firstLetters: GetFirstLetters.fromDb(data.name),
      phoneNumber: data.phoneNumber || '',
    };
  },
};

const taskActionConverter: TFetchConverter<TTaskActionUi, TTaskAction> = {
  fromDb: data => {
    return {
      action: data.action,
      nextPossibleStatuses: data.nextPossibleStatuses,
    };
  },
};
export const taskDetailsConverter: TFetchConverter<
  TTaskDetailsUi,
  TTaskDetails
> = {
  fromDb: data => {
    const arrTaskActions = Object.keys(TaskActionEnum);
    return {
      attachments: attachmentsGetConverter.fromDb(data.attachments),
      task: taskConverter.fromDb(data.task),
      description: data.description,
      compilationDateTime: data.compilationDateTime,
      company: taskCompanyConverter.fromDb(data.company),
      actions:
        data?.actions
          ?.sort((a, b) => {
            return (
              arrTaskActions.indexOf(a.action) -
              arrTaskActions.indexOf(b.action)
            );
          })
          .map(action => taskActionConverter.fromDb(action)) || [],
    };
  },
};

// edit converters  strat
export const taskTitleEditConverter: TCreateConverter<
  TEditTaskUi,
  TEditTaskTitle
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const taskDescriptionEditConverter: TCreateConverter<
  TEditTaskUi,
  TEditTaskDescription
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const taskAssigneeEditConverter: TCreateConverter<
  TEditTaskUi,
  TEditTaskAssignee
> = {
  toDb: data => ({
    id: data.id,
    assigneeId: data.value,
  }),
};
export const taskStatusEditConverter: TCreateConverter<
  TEditTaskUi,
  TEditTaskStatus
> = {
  toDb: data => ({
    id: data.id,
    status: data.value,
  }),
};
export const taskReporterEditConverter: TCreateConverter<
  TEditTaskUi,
  TEditTaskReporter
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const taskComapnyEditConverter: TCreateConverter<
  TEditTaskUi,
  TEditTaskCompany
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
export const taskNextActionItemDescEditConverter: TCreateConverter<
  TEditTaskUi,
  TEditNextActionItemDesc
> = {
  toDb: data => ({
    id: data.id,
    value: data.value,
  }),
};
// edit converters  end

export const taskCommenterConverter: TFetchConverter<
  TTaskCommenterUi,
  TTaskCommenter
> = {
  fromDb: data => {
    return {
      fullName: data.fullName,
      photoUrl: data.photoUrl,
      userId: data.userId,
      firstLetters: GetFirstLetters.fromDb(data.fullName),
    };
  },
};
export const taskCommentConverter: TFetchConverter<
  TTaskCommentUi,
  TTaskComment
> = {
  fromDb: data => {
    return {
      commenter: taskCommenterConverter.fromDb(data.commenter),
      creationTime: data.creationTime,
      edited: data.edited,
      id: data.id,
      lastModifiedTime: data.lastModifiedTime,
      text: data.text,
      taskId: data.taskId,
      isHtml: data.isHtml
    };
  },
};
