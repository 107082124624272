import type { ModalProps } from 'antd/es/modal';
import React from 'react';

export enum ModalWidthEnum {
  Small = 406,
  Medium = 636,
  Large = 828,
  ExtraLarge = 1035,
}
export type TModalProps = ModalProps & {
  onClose?: () => void;
  size: ModalWidthEnum;
  mode?: 'dark' | 'light';
};
export type TModalDeleteProps = TModalProps & {
  warningText?: string;
  informationText?: string;
  subtitleText?: string;
};
export type TModalEvent =
  | ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void)
  | undefined;
