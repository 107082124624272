import React, { FC } from 'react';
import { Title } from 'components/Typography';
import Button from 'components/Button/Button';
import InvitationSentImg from 'assets/images/auth/InvitationSent.svg';
import { SInviteSentStep } from './InviteSentStep.style';
import { TInviteSentStep } from './InviteSentStep.type';

const InviteSentStep: FC<TInviteSentStep> = ({ refetch, isLoading }) => {
  const handleGotoWorkfeed = () => {
    refetch();
  };
  return (
    <SInviteSentStep>
      <Title fontlevel={3}>Invitations have been sent!</Title>
      <img src={InvitationSentImg} alt="" />
      <div className="wrap-btn">
        <Button onClick={handleGotoWorkfeed} type="primary" loading={isLoading}>
          Go to workfeed
        </Button>
      </div>
    </SInviteSentStep>
  );
};

export default InviteSentStep;
