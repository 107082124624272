import {
  contactPersonalAddressEditConverter,
  contactConverter,
  contactCsvPreviewConverter,
  contactPhoneEditConverter,
  contactUpdateActiveConverter,
  contactManageConverter,
  contactUpdateLocationConverter,
  updateCpUserRoleConverter,
  GrantCpLoginConverter,
  contactForOptionsConverter,
} from 'data/converters/contact.converter';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import { TCsvTypeEnum } from 'data/types/company.types';
import {
  TContactCsvPreview,
  TContactCsvPreviewUi,
  TContactPersonalAddressEditUi,
  TContactPhoneEditUi,
  TContactPostUi,
  TContactsBulkUpdateResponse,
  TContactsBulkUpdateLocationUi,
  TContactsGet,
  TContactsGetUi,
  TUpdateContactActiveUi,
  TUpdateContactLocationUi,
  TUpdateContactManageUi,
  TContactsBulkChangeActiveStatusUi,
  TContactsBulkChangeManagedStatusUi,
  TContactsBulkUpdateResponseUi,
  TUpdateCpRoleUi,
  TGrantCpLoginPostUi,
  TContactsForOptionsGetUi,
  TContactsForOptionsGet,
} from 'data/types/contact.type';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import { companiesApi } from './company.slice';
import {
  contactsBulkUpdateActiveStatusConverter,
  contactsBulkUpdateManagedStatusConverter,
  contactsBulkUpdateResponseConverter,
  locationBulkUpdateLocationConverter,
} from '../../data/converters/location.converter';

export const CONTACT_URL = 'api/company-contacts';

export const contactsApi = authSplitApi('contactsApi', [
  'Contacts',
  'ContactsForOptions',
  'Contact',
]).injectEndpoints({
  endpoints: build => ({
    createContact: build.mutation<TContactsGet, TContactPostUi>({
      query(body) {
        return {
          url: CONTACT_URL,
          method: 'POST',
          body: contactConverter.toDb(body),
        };
      },
      invalidatesTags: ['Contacts', 'ContactsForOptions'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(companiesApi.util.invalidateTags(['Company']));
        });
      },
    }),
    createContactCSVUpload: build.mutation<
      Blob,
      { file: File; csvType: TCsvTypeEnum }
    >({
      query({ file, csvType }) {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: CONTACT_URL.concat(`/csv-upload?csvType=${csvType}`),
          method: 'POST',
          responseHandler: response =>
            response.status === 200 ? response.text() : response.json(),
          body: formData,
        };
      },
      invalidatesTags: ['Contacts', 'ContactsForOptions'],
    }),
    contactCSVPreview: build.mutation<
      TContactCsvPreviewUi,
      { file: File; csvType: TCsvTypeEnum }
    >({
      query({ file, csvType }) {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: CONTACT_URL.concat('/csv-preview'),
          method: 'POST',
          params: {
            csvType,
          },
          responseHandler: response =>
            response.status === 200 ? response.text() : response.json(),
          body: formData,
        };
      },
      transformResponse: (data: string) => {
        const parsedData: TContactCsvPreview = JSON.parse(data);
        return contactCsvPreviewConverter.fromDb(parsedData);
      },
    }),
    getContacts: build.query<
      TPageableDataWithContentUi<TContactsGetUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        search?: string;
        filters?: {
          companies?: string[];
          active?: boolean;
          managed?: boolean;
          vip?: boolean;
          locations?: string[];
        };
      }
    >({
      query({ page, pageSize, sort, filters, search }) {
        const urlWithParams = CONTACT_URL.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
        };
      },
      providesTags: ['Contacts'],
      transformResponse: (data: TPageableDataWithContent<TContactsGet[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(contact => contactConverter.fromDb(contact))
            : [],
        };
      },
    }),
    getContactsForOptions: build.query<
      TContactsForOptionsGetUi[],
      {
        sort?: string;
        search?: string;
        filters?: {
          companies?: string[];
          active?: boolean;
          managed?: boolean;
          vip?: boolean;
          locations?: string[];
        };
      }
    >({
      query({ sort, filters, search }) {
        const urlWithParams = CONTACT_URL.concat(
          `/compact`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
        };
      },
      providesTags: ['ContactsForOptions'],
      transformResponse: (data: TContactsForOptionsGet[]) => data.map(x => contactForOptionsConverter.fromDb(x))
    }),
    getContactById: build.query<TContactsGetUi, string>({
      query(contactId) {
        return {
          url: `${CONTACT_URL}/${contactId}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TContactsGet) => contactConverter.fromDb(data),
      providesTags: ['Contact'],
    }),
    editName: build.mutation<TContactsGetUi, { id: string; value: string }>({
      query({ id, value }) {
        return {
          url: `${CONTACT_URL}/${id}/name`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Contacts', 'Contact', 'ContactsForOptions'],
    }),
    editEmail: build.mutation<TContactsGetUi, { id: string; value: string }>({
      query({ id, value }) {
        return {
          url: `${CONTACT_URL}/${id}/email`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Contacts', 'Contact'],
    }),
    editPhone: build.mutation<TContactsGetUi, TContactPhoneEditUi>({
      query(body) {
        const { id, value } = contactPhoneEditConverter.toDb(body);
        return {
          url: `${CONTACT_URL}/${id}/phone`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Contacts', 'Contact'],
    }),
    editCellPhone: build.mutation<TContactsGetUi, TContactPhoneEditUi>({
      query(body) {
        const { id, value } = contactPhoneEditConverter.toDb(body);
        return {
          url: `${CONTACT_URL}/${id}/cell-phone`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Contacts', 'Contact'],
    }),
    editPhoneExtension: build.mutation<TContactsGetUi, TContactPhoneEditUi>({
      query({ id, value }) {
        return {
          url: `${CONTACT_URL}/${id}/phone-extension`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Contacts', 'Contact'],
    }),
    editPersonalAddress: build.mutation<
      TContactsGetUi,
      TContactPersonalAddressEditUi
    >({
      query(body) {
        const { id, value } = contactPersonalAddressEditConverter.toDb(body);
        return {
          url: `${CONTACT_URL}/${id}/personal-address`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Contacts', 'Contact'],
    }),
    editJobTitle: build.mutation<TContactsGetUi, { id: string; value: string }>(
      {
        query({ id, value }) {
          return {
            url: `${CONTACT_URL}/${id}/job-title`,
            method: 'PATCH',
            body: { value },
          };
        },
        invalidatesTags: ['Contacts', 'Contact'],
      },
    ),
    editVipContact: build.mutation<
      TContactsGetUi,
      { id: string; value: boolean }
    >({
      query({ id, value }) {
        return {
          url: `${CONTACT_URL}/${id}/vip`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Contacts', 'Contact', 'ContactsForOptions'],
    }),
    editContactActive: build.mutation<void, TUpdateContactActiveUi>({
      query(data) {
        const { id } = data;
        return {
          url: `${CONTACT_URL}/${id}/active`,
          method: 'PATCH',
          body: contactUpdateActiveConverter.toDb(data),
        };
      },
      invalidatesTags: ['Contacts', 'Contact', 'ContactsForOptions'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(companiesApi.util.invalidateTags(['Company']));
        });
      },
    }),
    editContactManaged: build.mutation<void, TUpdateContactManageUi>({
      query(data) {
        const { id } = data;
        return {
          url: `${CONTACT_URL}/${id}/managed`,
          method: 'PATCH',
          body: contactManageConverter.toDb(data),
        };
      },
      invalidatesTags: ['Contacts', 'Contact', 'ContactsForOptions'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(companiesApi.util.invalidateTags(['Company']));
        });
      },
    }),
    editContactLocation: build.mutation<void, TUpdateContactLocationUi>({
      query(data) {
        const { id } = data;
        return {
          url: `${CONTACT_URL}/${id}/location`,
          method: 'PATCH',
          body: contactUpdateLocationConverter.toDb(data),
        };
      },
      invalidatesTags: ['Contacts', 'Contact'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(companiesApi.util.invalidateTags(['Company']));
        });
      },
    }),
    deleteContactById: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `${CONTACT_URL}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Contacts', 'ContactsForOptions'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(companiesApi.util.invalidateTags(['Company']));
        });
      },
    }),
    grantCpLogin: build.mutation<void, TGrantCpLoginPostUi>({
      query(data) {
        const { id } = data;
        const body = GrantCpLoginConverter.toDb(data);
        return {
          url: `${CONTACT_URL}/${id}/grant-cp-login`,
          body,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Contact', 'Contacts'],
    }),
    revokeCpLogin: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `${CONTACT_URL}/${id}/revoke-cp-login`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Contact', 'Contacts'],
    }),
    updateCpRole: build.mutation<void, TUpdateCpRoleUi>({
      query(data) {
        return {
          url: `${CONTACT_URL}/${data.id}/update-cp-role`,
          body: updateCpUserRoleConverter.toDb(data),
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Contact'],
    }),
    contactsBulkUpdateLocation: build.mutation<
      TContactsBulkUpdateResponseUi,
      TContactsBulkUpdateLocationUi
    >({
      query(data) {
        return {
          url: `${CONTACT_URL}/bulk-update-location`,
          method: 'POST',
          body: locationBulkUpdateLocationConverter.toDb(data),
        };
      },
      invalidatesTags: ['Contacts', 'Contact'],
      transformResponse: (data: TContactsBulkUpdateResponse) => {
        return contactsBulkUpdateResponseConverter.fromDb(data);
      },
    }),
    contactsBulkUpdateActiveStatus: build.mutation<
      TContactsBulkUpdateResponseUi,
      TContactsBulkChangeActiveStatusUi
    >({
      query(data) {
        return {
          url: `${CONTACT_URL}/bulk-update-active`,
          method: 'POST',
          body: contactsBulkUpdateActiveStatusConverter.toDb(data),
        };
      },
      transformResponse: (data: TContactsBulkUpdateResponse) => {
        return contactsBulkUpdateResponseConverter.fromDb(data);
      },
      invalidatesTags: ['Contacts', 'Contact', 'ContactsForOptions'],
    }),
    contactsBulkUpdateManagedStatus: build.mutation<
      TContactsBulkUpdateResponseUi,
      TContactsBulkChangeManagedStatusUi
    >({
      query(data) {
        return {
          url: `${CONTACT_URL}/bulk-update-managed`,
          method: 'POST',
          body: contactsBulkUpdateManagedStatusConverter.toDb(data),
        };
      },
      transformResponse: (data: TContactsBulkUpdateResponse) => {
        return contactsBulkUpdateResponseConverter.fromDb(data);
      },
      invalidatesTags: ['Contacts', 'Contact', 'ContactsForOptions'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateContactCSVUploadMutation,
  useCreateContactMutation,
  useGetContactsQuery,
  useGetContactsForOptionsQuery,
  useGetContactByIdQuery,
  useEditNameMutation,
  useEditEmailMutation,
  useEditPhoneMutation,
  useDeleteContactByIdMutation,
  useContactCSVPreviewMutation,
  useEditCellPhoneMutation,
  useEditPhoneExtensionMutation,
  useEditPersonalAddressMutation,
  useEditJobTitleMutation,
  useEditVipContactMutation,
  useEditContactActiveMutation,
  useEditContactManagedMutation,
  useEditContactLocationMutation,
  useContactsBulkUpdateLocationMutation,
  useContactsBulkUpdateActiveStatusMutation,
  useContactsBulkUpdateManagedStatusMutation,
  useGrantCpLoginMutation,
  useUpdateCpRoleMutation,
  useRevokeCpLoginMutation,
} = contactsApi;
