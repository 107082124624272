import { ChangeEvent } from 'react';
import { Rule, RuleObject, RuleRender } from 'antd/es/form';
import type { FormItemProps } from 'antd/es/form';

type TFormItemMargin = {
  marginBottom?: number | string;
};
export type TFormItemProps = FormItemProps & TFormItemMargin;

export enum TFormFieldTypes {
  INPUT = 'input',
  PASSWORD = 'password',
  POSSWORD_CONFIRM = 'passwordConfirm',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  MULTIINPUT = 'multi-input',
  CUSTOM_MULTI_SELECT = 'custom-multi-select',
}

export type TFormFieldsMap = {
  [key in TFormFieldTypes]: { Component: React.FC<any>; custom?: boolean };
};

// export type TFromFieldInputProps = TInputProps | TPasswordProps | TTextAreaProps | TSearchProps | TGroupProps;

export type TFormFieldProps = TFormItemProps & {
  rules?: (Rule | RuleObject | RuleRender)[];
  title?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  selectOptions?: any;
  label?: string | JSX.Element;
  formItemLabel?: string | JSX.Element;
  placeholder?: string;
  fieldType?: TFormFieldTypes;
  setCustomFieldValue?: (fieldName: string, customValue?: any) => void;
};
