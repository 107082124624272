import React, { FC, memo } from 'react';
import { TGaugeMetaDataUi } from 'data/types/gauges.types';
import { Row } from 'components/Grid/Grid';

type TGaugeTooltipContent = {
  tooltipData: TGaugeMetaDataUi[];
};

const GaugeTooltipContent: FC<TGaugeTooltipContent> = ({ tooltipData }) => {
  return (
    <>
      {tooltipData.map(item => (
        <Row>
          {item.title}: {item.value}
        </Row>
      ))}
    </>
  );
};

export default memo(GaugeTooltipContent);
