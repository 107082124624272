import React, { FC, useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import useForm from 'hooks/useForm';
import { ModalWidthEnum, TModalEvent } from 'components/Modal/Modal.types';
import { Moment } from 'moment';
import CreateTimeEntryForm from 'pages/private/Timesheet/components/CreateTimeEntry/CreateTimeEntryForm';
import { TCreateTimeEntryInitialValues } from 'pages/private/Timesheet/components/CreateTimeEntry/CreateTimeEntryForm.type';
import { AssignmentEnumUi, TTimeEntryPostUi } from 'data/types/timeEntry.types';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { setRedirectWorkfeed } from 'redux/slices';
import { useNavigate } from 'react-router-dom';
import { RoutesPaths } from 'routes/Routes.types';
import { projectApi } from 'redux/apiSlices/project.slice';

const TimeEntryFormModal: FC<{
  visible: boolean;
  onClose: () => void;
  isSuccess: boolean;
  isLoading: boolean;
  reset: () => void;
  selectedDates?: { startTime: Moment; endTime: Moment } | null;
  onSubmit: (values: TTimeEntryPostUi) => Promise<void>;
}> = ({
  visible,
  onClose,
  selectedDates = null,
  reset,
  isLoading,
  isSuccess,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const shouldRedirectWorkfeed = useAppSelector(
    state => state.appSettings.redirectWorkfeed,
  );
  const { form, setCustomFieldValue } = useForm();
  const { setFieldsValue } = form;
  const currentAssignment = useAppSelector(
    state => state.appSettings.currentAssignment,
  );
  const initialValues: TCreateTimeEntryInitialValues = {
    note: undefined,
    companyId: currentAssignment ? currentAssignment.company : undefined,
    startTime: selectedDates ? selectedDates.startTime : null,
    endTime: selectedDates ? selectedDates.endTime : null,
    assignmentId: currentAssignment
      ? currentAssignment.assignmentId
      : undefined,
    workType: undefined,
    assignmentType: currentAssignment
      ? currentAssignment.assignmentType
      : undefined,
  };
  const onCancel = () => {
    if (onClose) onClose();
    form.resetFields();
    reset();
    if (shouldRedirectWorkfeed) {
      navigate(`/app/${RoutesPaths.Workfeed}`);
      dispatch(setRedirectWorkfeed(false));
    }
  };
  const onOk: TModalEvent = async () => {
    try {
      const values: TTimeEntryPostUi = await form.validateFields();
      await onSubmit({
        ...values,
        note: values.note?.trim(),
      });
      if (values.assignmentType === AssignmentEnumUi['Project Task']) {
        dispatch(projectApi.util.invalidateTags(['project']));
      }
    } catch (err) {
      // Todo: toast
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onCancel();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (visible) {
      setFieldsValue(initialValues);
    }
  }, [currentAssignment, visible]);

  useEffect(() => {
    if (visible) {
      setFieldsValue({
        startTime: selectedDates?.startTime || null,
        endTime: selectedDates?.endTime || null,
      });
    }
  }, [selectedDates?.startTime, selectedDates?.endTime, visible]);

  return (
    <Modal
      size={ModalWidthEnum.Large}
      visible={visible}
      title="Add time entry"
      onCancel={onCancel}
      onOk={onOk}
      okText="Add entry"
      confirmLoading={isLoading}
      destroyOnClose
    >
      <CreateTimeEntryForm
        formObj={{ form, setCustomFieldValue }}
        initialValues={initialValues}
      />
    </Modal>
  );
};

export default TimeEntryFormModal;
