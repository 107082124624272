import {
  TAuthConverter,
  TConverter,
  TForgotPasswordData,
  TForgotPasswordSubmit,
  TForgotPasswordUI,
} from '../types';

export const forgotPasswordConverter: TConverter<
  TForgotPasswordUI,
  TForgotPasswordData
> = {
  fromDb: (data: TForgotPasswordData) => {
    return {
      email: data.username,
    };
  },
  toDb: (data: TForgotPasswordUI) => {
    return {
      username: data.email,
    };
  },
};

export const forgotPasswordSubmitConverter: TAuthConverter<
  TForgotPasswordSubmit,
  TForgotPasswordSubmit,
  string,
  string
> = {
  fromDb: (data: string) => {
    return data;
  },
  toDb: (data: TForgotPasswordSubmit) => {
    return data;
  },
};
