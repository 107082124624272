export enum EPaymentInfoStatus {
  TRIALING = 'TRIALING',
  CANCELED = 'CANCELED',
  PAST_DUE = 'PAST_DUE',
  ACTIVE = 'ACTIVE',
}
export enum EPaymentInfoStatusUi {
  TRIALING = 'Trialing',
  CANCELED = 'Canceled',
  PAST_DUE = 'Past due',
  ACTIVE = 'Active',
}

export type TPaymentInfo = {
  paymentMethodExists?: boolean;
  paymentStatus: EPaymentInfoStatus;
  expirationDate: number;
  pmValidDate?: string;
  cancelAtPeriodEnd: boolean;
};
export type TPaymentInfoUi = {
  paymentMethodExists?: boolean;
  paymentStatus: EPaymentInfoStatusUi;
  expirationDate: number;
  pmValidDate?: string;
  cancelAtPeriodEnd: boolean;
  isAccessLimited?: boolean;
};

export type TCreatePaymentMethod = {
  value: string;
};
export type TCreatePaymentMethodUi = {
  value: string;
};

export type TPaymentMethod = {
  endingNumber: string;
  name: string;
  brand: string;
  expMonth: number;
  expYear: number;
};
export type TPaymentMethodUi = {
  endingNumber: string;
  name: string;
  brand: string;
  expMonth: number;
  expYear: number;
  expDate: string;
  expFullDate: string;
};

export enum EPaymentSubscriptionActionStatus {
  SUBSCRIBE = 'SUBSCRIBE',
  RETRACT_CANCELLATION = 'RETRACT_CANCELLATION',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
}
export enum EPaymentSubscriptionActionStatusUi {
  SUBSCRIBE = 'SUBSCRIBE',
  RETRACT_CANCELLATION = 'RETRACT_CANCELLATION',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
}

export type TPaymentSubscriptionAction = {
  action: EPaymentSubscriptionActionStatus;
  possible: boolean;
  reason: string | null;
};

export type TPaymentSubscriptionActionUi = {
  action: EPaymentSubscriptionActionStatusUi;
  possible: boolean;
  reason: string | null;
};
export type TPaymentSubscription = {
  status: EPaymentInfoStatus;
  startDateTime: string;
  endDateTime: string;
  cancelAtPeriodEnd: true;
  trialPeriodEndDateTime: string;
  quantity: number;
  amountDue: number;
  nextPaymentDateTime: string;
  allowedActions: TPaymentSubscriptionAction[];
};
export type TPaymentSubscriptionUi = {
  status: EPaymentInfoStatusUi;
  startDateTime: string;
  endDateTime: string;
  cancelAtPeriodEnd: true;
  trialPeriodEndDateTime: string;
  quantity: number;
  amountDue: string;
  nextPaymentDateTime: string;
  allowedActions: TPaymentSubscriptionActionUi[];
};

export enum EPaymentHistoryItemStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID',
}
export enum EPaymentHistoryItemStatusUi {
  DRAFT = 'Draft',
  PAID = 'Paid',
  UNCOLLECTIBLE = 'Failed',
  OPEN = 'Retrying',
  VOID = 'Void',
}
export type TPaymentHistoryItem = {
  creationDateTime: string;
  startDateTime: string;
  endDateTime: string;
  amountDue: number;
  quantity: number;
  status: EPaymentHistoryItemStatus;
  invoiceUrl: string;
  invoiceId: string;
  lastAttemptDateTime: string;
  lastAttemptErrorMessage: string;
  nextAttemptDateTime: string;
  lastAttemptCardLast4: string;
};
export type TPaymentHistoryItemUi = {
  creationDateTime: string;
  amountDue: string;
  quantity: number;
  status: EPaymentHistoryItemStatusUi;
  invoiceUrl: string;
  invoiceId: string;
  lastAttemptDateTime: string;
  lastAttemptErrorMessage: string;
  nextAttemptDateTime: string;
  billingPeriod: string;
  lastAttemptCardLast4: string;
};
export type TPaymentHistory = {
  items: TPaymentHistoryItem[];
};
export type TPaymentHistoryUi = {
  items: TPaymentHistoryItemUi[];
};
