import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import {
  TLineChartDetails,
  TLineChartDetailsUi,
  TLineChartItems,
  TLineChartItemsUi,
  TReportPeriod,
  TReportPeriodToLabelMapper,
  TReportPeriodUi,
  TServiceQueueGet,
  TServiceQueueGetUi,
  TServiceQueueItemGet,
  TServiceQueueItemGetUi,
  TLineChartData,
  TLineChartDataUi,
  TTechPerfCommonFilter,
  TTechPerfCommonFilterUi,
  TWorktypeReportFilter,
  TWorktypeReportFilterUi,
  TCustomerPerfCommonFilterUi,
  TCustomerPerfCommonFilter,
  TMspPerfCommonFilterUi,
  TMspPerfCommonFilter,
  TCustomerReportFilterUi,
  TCustomerReportFilter,
  TServiceQueueCommonFilterUi,
  TServiceQueueCommonFilter,
} from 'data/types/report.type';

export const serviceQueueItemConverter: TFetchConverter<
  TServiceQueueItemGetUi,
  TServiceQueueItemGet
> = {
  fromDb: data => ({
    isHiddenQueue: data.isHiddenQueue,
    queueName: data.queueName,
    queueId: data.queueId,
    openedTickets: data.openedTickets,
    closedTickets: data.closedTickets,
    frSlaMet: data.frSlaMet,
    resSlaMet: data.resSlaMet,
  }),
};

export const serviceQueueConverter: TFetchConverter<
  TServiceQueueGetUi,
  TServiceQueueGet
> = {
  fromDb: data => ({
    allClosedTickets: data.allClosedTickets,
    allOpenedTickets: data.allOpenedTickets,
    avgFrSlaMet: data.avgFrSlaMet,
    avgResSlaMet: data.avgResSlaMet,
    sqpReportChartItems:
      data.sqpReportChartItems &&
      data.sqpReportChartItems.map(item =>
        serviceQueueItemConverter.fromDb(item),
      ),
  }),
};

export const lineChartItemsConverter: TFetchConverter<
  TLineChartItemsUi,
  TLineChartItems
> = {
  fromDb: data => ({
    period: data.period,
    ...data.data,
  }),
};
export const lineChartDetailsConverter: TFetchConverter<
  TLineChartDetailsUi,
  TLineChartDetails
> = {
  fromDb: data => ({
    id: data.id,
    name: data.name,
    color: data.color,
  }),
};

export const lineChartConverter: TFetchConverter<
  TLineChartDataUi,
  TLineChartData
> = {
  fromDb: data => ({
    lineChartItems: (data.periods || []).map(item =>
      lineChartItemsConverter.fromDb(item),
    ),
    metadata: (data.metadata || []).map(item =>
      lineChartDetailsConverter.fromDb(item),
    ),
  }),
};
export const reportPeriodConverter: TCreateConverter<
  TReportPeriodUi,
  TReportPeriod
> = {
  toDb: data => {
    const status: { [key in TReportPeriodUi]: TReportPeriod } = {
      [TReportPeriodUi.ANNUALLY]: TReportPeriod.ANNUALLY,
      [TReportPeriodUi.MONTHLY]: TReportPeriod.MONTHLY,
      [TReportPeriodUi.QUARTERLY]: TReportPeriod.QUARTERLY,
      [TReportPeriodUi.WEEKLY]: TReportPeriod.WEEKLY,
      [TReportPeriodUi.SEMI_ANNUALLY]: TReportPeriod.SEMI_ANNUALLY,
    };
    return status[data];
  },
};

export const serviceQueueCommonFilterConverter: TCreateConverter<
  TServiceQueueCommonFilterUi | undefined,
  TServiceQueueCommonFilter | undefined
> = {
  toDb: data =>
    data && {
      from: data.range[0],
      to: data.range[1],
    },
};
export const techPerfCommonFilterConverter: TCreateConverter<
  TTechPerfCommonFilterUi | undefined,
  TTechPerfCommonFilter | undefined
> = {
  toDb: data =>
    data && {
      from: data.range[0],
      to: data.range[1],
      period: reportPeriodConverter.toDb(data.period),
      technicianIds: data.technicianIds,
    },
};
export const worktypeReportFilterConverter: TCreateConverter<
  TWorktypeReportFilterUi | undefined,
  TWorktypeReportFilter | undefined
> = {
  toDb: data =>
    data && {
      workTypes: (data.workTypes || []).map(worktype =>
        worktype.replace(/[\s/-]/g, '_').toUpperCase(),
      ),
    },
};
export const customerReportFilterConverter: TCreateConverter<
  TCustomerReportFilterUi | undefined,
  TCustomerReportFilter | undefined
> = {
  toDb: data =>
    data && {
      companyIds: data.companyIds,
    },
};

export const reportPeriodToLabelMapper: TReportPeriodToLabelMapper = {
  [TReportPeriodUi.WEEKLY]: 'W',
  [TReportPeriodUi.MONTHLY]: 'M',
  [TReportPeriodUi.QUARTERLY]: 'Q',
  [TReportPeriodUi.SEMI_ANNUALLY]: 'SA',
  [TReportPeriodUi.ANNUALLY]: 'Y',
};

export const customerPerfCommonFilterConverter: TCreateConverter<
  TCustomerPerfCommonFilterUi | undefined,
  TCustomerPerfCommonFilter | undefined
> = {
  toDb: data =>
    data && {
      from: data.range[0],
      to: data.range[1],
      period: reportPeriodConverter.toDb(data.period),
      companyIds: data.companyIds,
    },
};
export const mspPerfCommonFilterConverter: TCreateConverter<
  TMspPerfCommonFilterUi | undefined,
  TMspPerfCommonFilter | undefined
> = {
  toDb: data =>
    data && {
      from: data.range[0],
      to: data.range[1],
      period: reportPeriodConverter.toDb(data.period),
    },
};
