import { Colors } from 'core/CssVariables';
import { Moment } from 'moment';
import { TCompanyStatusUi } from './company.types';
import { TCompanyShortData, TCompanyShortDataUi } from './shortData.type';

export enum EItGoalStatus {
  POTENTIAL = 'Potential',
  GOAL = 'Goal',
  SCHEDULED = 'Scheduled',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
}

export enum EItGoalStatusAsParam {
  POTENTIAL = 'POTENTIAL',
  GOAL = 'GOAL',
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}

export type TItGoalReporterUi = {
  id: string;
  name: string;
  photoUrl: string;
  firstLetters: string;
};
export type TItGoalReporter = {
  id: string;
  name: string;
  photoUrl: string;
};

export type TItGoalPostUi = {
  companyId: string;
  yearAndQuarter: Moment;
  status: EItGoalStatus;
  description: string;
};

export type TItGoalPost = {
  companyId: string;
  yearAndQuarter: string;
  status: EItGoalStatus;
  description: string;
};

export type TItGoalUi = {
  id: string;
  company: TCompanyShortDataUi;
  status: EItGoalStatus;
  description: string;
  yearAndQuarter: string;
  formattedYearAndQuarter: string;
  reporter: TItGoalReporterUi;
};
export type TItGoal = {
  id: string;
  company: TCompanyShortData;
  status: EItGoalStatus;
  description: string;
  yearAndQuarter: string;
  reporter: TItGoalReporter;
};

export type TCreateItGoalForm = Omit<TItGoalPostUi, 'companyId'>;

export type TItGoalMapperColor = {
  [key in EItGoalStatus]: Colors;
};

export enum ItGoalFilters {
  RANGE = 'range',
  REPORTER = 'reporterIds',
  STATUS = 'statuses',
  COMPANY = 'companyIds',
}
export enum ItGoalFilterNames {
  'Year and quarter' = 'Year and quarter',
  'Reporter' = 'Reporter',
  'Status' = 'Status',
  'Company' = 'Company',
}

export type TShowItGoalTab = {
  [key in TCompanyStatusUi]?: boolean;
};
