import { TContactsForOptionsGet, TContactsGet } from 'data/types/contact.type';
import { TPageableDataWithContent } from 'data/types/pagableData.type';
import { pageableData } from './general.data';
import { companyAMockedShortData, companyShortData } from './company.data';

const contact: TContactsGet = {
  id: '976da261-4503-4f44-915e-b22045c9f39d',
  name: 'Toyota Main contact',
  company: companyShortData,
  email: 'toyoyta.sales@gmail.com',
  phoneNumber: '+17778889996',
  cellPhoneNumber: '+10107898998',
  phoneExtension: '878',
  isMainContact: true,
  jobTitle: 'Sales Director',
  personalAddress: 'USA, CA, Los Angeles',
  isVip: true,
};
const vipContact: TContactsGet = {
  id: '1111a261-4503-4f44-915e-b22045c9f39d',
  name: 'Toyota Vip contact',
  company: companyShortData,
  email: 'toyoytaVip.sales@gmail.com',
  phoneNumber: '+1779410996',
  cellPhoneNumber: '+10789898998',
  phoneExtension: '635',
  isMainContact: false,
  jobTitle: 'Sales Manager',
  personalAddress: 'USA, CA, Vashington',
  isVip: true,
};
const usualContact: TContactsGet = {
  id: '222a261-4503-4f44-915e-b22045c9f39d',
  name: 'Toyota usual contact',
  company: companyShortData,
  email: 'toyoytaUsual.sales@gmail.com',
  phoneNumber: '+17794100000',
  cellPhoneNumber: '+10789898000',
  phoneExtension: '005',
  isMainContact: false,
  jobTitle: 'Sales Manager',
  personalAddress: 'USA, CA, Vashington',
  isVip: false,
};

const mockedPageableContacts: TPageableDataWithContent<TContactsGet[]> = {
  ...pageableData,
  totalElements: 3,
  totalPages: 1,
  numberOfElements: 3,
  empty: false,
  content: [contact, vipContact, usualContact],
};

const mockedCompactContacts: TContactsForOptionsGet[] = 
  [contact, vipContact, usualContact]
;

const mockedCompactEmptyContacts: TContactsForOptionsGet[] = 
  []
;

const mockedContact1 = {
  id: 'mockedContact1ForDeviceMapping',
  name: 'Contact 1',
  company: companyAMockedShortData,
  email: 'contact1@gmail.com',
  phoneNumber: '+17794100000',
  cellPhoneNumber: '+10789898000',
  phoneExtension: '005',
  isMainContact: false,
  jobTitle: 'Sales Manager',
  personalAddress: 'USA, CA, Vashington',
  isVip: false,
  isActive: true,
  isManaged: true,
};
const mockedContact2 = {
  id: 'mockedContact2ForDeviceMapping',
  name: 'Contact 2',
  company: companyAMockedShortData,
  email: 'contact2@gmail.com',
  phoneNumber: '+17794100000',
  cellPhoneNumber: '+10789898000',
  phoneExtension: '005',
  isMainContact: false,
  jobTitle: 'Sales Manager',
  personalAddress: 'USA, CA, Vashington',
  isVip: false,
  isActive: true,
  isManaged: true,
};

const mockedPagableContactsMapping: TPageableDataWithContent<TContactsGet[]> = {
  ...pageableData,
  totalElements: 2,
  totalPages: 1,
  numberOfElements: 2,
  empty: false,
  content: [mockedContact1, mockedContact2],
};
const mockedCompactContactsMapping: TContactsGet[] =  [mockedContact1, mockedContact2];

export { contact, mockedPageableContacts, mockedPagableContactsMapping, mockedCompactContacts, mockedCompactEmptyContacts, mockedCompactContactsMapping };
