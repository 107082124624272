import React from 'react';
import { Text } from 'components/Typography';
import { Colors } from 'core/CssVariables';
import { Row } from 'components/Grid/Grid';

type TLabelWithStatusProps<T> = {
  title: string;
  status: T;
  statusColor: Colors;
};

const LabelWithStatus = <T,>({
  title,
  status,
  statusColor,
}: TLabelWithStatusProps<T>) => {
  return (
    <Row wrap={false} justify="space-between">
      <Text ellipsis={{ tooltip: true }} fontlevel={5}>
        {title}
      </Text>
      <Text color={statusColor} fontlevel={5} className="pr-2 pl-1">
        {status}
      </Text>
    </Row>
  );
};

export default LabelWithStatus;
