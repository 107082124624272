import { TConverter } from 'data/types/converter.types';
import { WorkTypeEnum, WorkTypeEnumUi } from 'data/types/workTypes.types';

export const workTypesConverters: TConverter<WorkTypeEnumUi, WorkTypeEnum> = {
  fromDb: data => {
    return WorkTypeEnumUi[
      data.replace(/[\s/-]/g, '_').toUpperCase() as keyof typeof WorkTypeEnumUi
    ];
  },
  toDb: data => {
    return WorkTypeEnum[data as keyof typeof WorkTypeEnum];
  },
};
