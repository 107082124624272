import React, { useEffect, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { Col, Row } from 'components/Grid/Grid';
import { TIconNames } from 'components/Icon';
import moment, { Moment } from 'moment';
import {
  useEndTimeTrackerMutation,
  useGetTimeTrackerQuery,
  useStartTimeTrackerMutation,
  useStopTimeTrackerMutation,
} from 'redux/apiSlices/timeEntry.slice';
import { TTimeEntryPostUi } from 'data/types/timeEntry.types';
import TimeEntryFormModal from 'pages/private/Timesheet/components/CreateTimeEntry/TimeEntryFormModal';
import { useAppSelector } from 'redux/store';
import { useDispatch } from 'react-redux';
import { changeTimeTrackerModal } from 'redux/slices';
import { getDateWithCorrectZone } from 'helpers/utils';
import { toast } from 'components/Notification';
import TimeTrackerTimer from './TimeTrackerTimer';
import TimeTrackerAction from './TimeTrackerAction';

const TimeTracker = () => {
  const [endTime, setEndTime] = useState<Moment>(moment());
  const [startTime, setStartTime] = useState<Moment>(moment());
  const showTimeTracker = useAppSelector(
    state => state.appSettings.timeTrackerModal,
  );
  const dispatch = useDispatch();
  const { data } = useGetTimeTrackerQuery();
  const [stopTimeTracker, { isSuccess, isLoading, reset }] =
    useStopTimeTrackerMutation();
  const [startTimeTracker, { isLoading: startIsLoading }] =
    useStartTimeTrackerMutation();
  const [endTimeTracker, { isLoading: endLoading }] =
    useEndTimeTrackerMutation();
  const {
    seconds,
    minutes,
    hours,
    days,
    reset: resetTimer,
    pause,
  } = useStopwatch({
    autoStart: false,
  });
  const onStart = () => {
    resetTimer(new Date());
    startTimeTracker();
  };
  const onSubmit = async (values: TTimeEntryPostUi) => {
    try {
      await stopTimeTracker(values).unwrap();
      resetTimer();
    } catch (err) {
      toast({
        description: (err as any)?.data?.message,
        title: 'Adding time entry',
        type: 'error',
      });
    }
  };
  const onClose = () => {
    dispatch(changeTimeTrackerModal(false));
  };
  const onTrackerStopClick = () => {
    dispatch(changeTimeTrackerModal(true));
  };

  const onTrackerEndClick = async () => {
    await endTimeTracker().unwrap();
    resetTimer();
    pause();
  };

  useEffect(() => {
    if (data) {
      const dateNow = new Date();
      dateNow.setSeconds(
        dateNow.getSeconds() +
          (dateNow.getTime() - Date.parse(data.startTimeTimestamp)) / 1000,
      );
      resetTimer(dateNow, true);
    }
  }, [data]);

  useEffect(() => {
    if (showTimeTracker && data) {
      const newDateWithCorrectTimeZone = getDateWithCorrectZone(
        data.startTimeTimestamp,
        data.startTime,
      );
      setStartTime(moment(data.startTime));
      setEndTime(moment(newDateWithCorrectTimeZone));
    }
  }, [showTimeTracker]);
  return (
    <Row gutter={[8, 8]} align="middle" wrap={false}>
      {data?.startTimeTimestamp && (
        <TimeEntryFormModal
          onSubmit={onSubmit}
          isSuccess={isSuccess}
          isLoading={isLoading}
          reset={reset}
          visible={showTimeTracker}
          onClose={onClose}
          selectedDates={{
            startTime,
            endTime,
          }}
        />
      )}
      <Col>
        <TimeTrackerTimer
          hours={hours + days * 24}
          minutes={minutes}
          seconds={seconds}
        />
      </Col>
      {!data ? (
        <Col>
          <TimeTrackerAction
            onClick={onStart}
            loading={startIsLoading}
            iconName={TIconNames.TIMER_ON}
          />
        </Col>
      ) : (
        <>
          <Col>
            <TimeTrackerAction
              disabled={!data}
              onClick={onTrackerStopClick}
              iconName={TIconNames.STOP}
            />
          </Col>
          <Col>
            <TimeTrackerAction
              loading={endLoading}
              onClick={onTrackerEndClick}
              iconName={TIconNames.TIMER_OFF}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default TimeTracker;
