import React from 'react';
import { TFilterProps } from '../FilterComponents.type';

import { SelectFilterItem } from './SelectFilterItem';

const OverdueFilter: React.FC<TFilterProps> = ({
  onSelected,
  selectedValues,
}) => {
  return (
    <SelectFilterItem
      label="Overdue"
      onSelected={onSelected}
      selectedValues={selectedValues}
      options={[
        {
          value: 'Overdue',
          label: 'Overdue',
        },
      ]}
    />
  );
};

export { OverdueFilter };
