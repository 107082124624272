import React, { FC } from 'react';
import SButton from 'components/Button/Button.style';
import { TButtonProps, TButtonTypes } from 'components/Button/Button.types';

const Button: FC<TButtonProps> = props => {
  const { disabled, loading, outline } = props;
  return (
    <SButton
      type="primary"
      color={TButtonTypes.Primary}
      {...props}
      {...(outline ? { outline: 'true' } : undefined)}
      disabled={!!loading || disabled}
    />
  );
};

export default Button;
