import { Moment } from 'moment';
import { WorkTypeEnum, WorkTypeEnumUi } from './workTypes.types';
import { TTicketStatus, TTicketStatusUi } from './ticket.types';
import { TTaskStatus, TTaskStatusUi } from './task.type';
import { PhaseTaskStatusEnum, PhaseTaskStatusEnumUi } from './phase.type';

type TTimeEntryGeneral = {
  note: string;
  workType: WorkTypeEnum;
  assignmentId?: string;
  assignmentType?: AssignmentEnum;
  companyId: string;
  owner?: TOwner;
};

export enum AssignmentEnum {
  Ticket = 'Ticket',
  Task = 'Task',
  'Project Task' = 'Project Task',
}
export enum AssignmentTypeAsParam {
  Ticket = 'TICKET',
  Task = 'TASK',
  'Project Task' = 'PROJECT_TASK',
}

export enum AssignmentEnumUi {
  Ticket = 'Ticket',
  Task = 'Task',
  'Project Task' = 'Project Task',
}

type TTimeEntryGeneralUi = {
  note: string;
  workType: WorkTypeEnumUi;
  assignmentId?: string;
  assignmentType?: AssignmentEnumUi;
  companyId: string;
  owner?: TOwnerUi;
};

export type TTimeEntryGet = TTimeEntryGeneral & {
  id: string;
  duration: number;
  startTime: string;
  endTime: string;
  companyName: string;
  companyLogo: string;
  companyShortName: string;
  assignmentNumber?: number;
  actualEndTime: string;
  actualStartTime: string;
};

export type TTimeEntryGetUi = TTimeEntryGeneralUi & {
  id: string;
  duration: string;
  startTime: string;
  endTime: string;
  startPointer: string;
  endPointer: string;
  range: string;
  companyName: string;
  companyLogo: string;
  companyShortName: string;
  companyNameWithAbbreviation: string;
  assignmentNumber?: number;
  companyFirstLetter: string;
  actualEndTime: string;
  actualStartTime: string;
  companyId: string;
  type?: 'timeTracker';
};

export type TTimeEntryPost = TTimeEntryGeneral & {
  companyId: string;
  startTime: string;
  endTime: string;
};

export type TTimeEntryPostUi = TTimeEntryGeneralUi & {
  companyId: string;
  startTime: Moment;
  endTime: Moment;
};

export type TTimeEntryUpdateUi = TTimeEntryPostUi & { id: string };
export type TDurationsByDay = {
  [key: string]: number;
};

export type TDurationsByDayUi = {
  [key: string]: string;
};

export type TTimeSheetInfo = {
  id: string;
  timeEntries: TTimeEntryGet[];
  timeTracker: TTimeTracker[] | null;
  durationsByDay: TDurationsByDay;
  totalDuration: number;
} | null;

export type TTimeSheetInfoUi = {
  id: string;
  timeEntries: TTimeEntryGetUi[];
  timeTracker?: TTimeTrackerUi[] | null;
  durationsByDay: TDurationsByDayUi;
  allEventsDuration: string;
} | null;

export type TOwner = {
  id: string;
  name: string;
  photoUrl: string;
};

export type TOwnerUi = {
  id: string;
  name: string;
  photoUrl: string;
  nameFirstLetter: string;
};

export enum TimeEntriesFiltersEnumUi {
  DATE_RANGE = 'startDateBetween',
  MEMBERS = 'ownerIds',
  WORK_TYPES = 'workTypes',
  ORGANIZATIONS = 'companyIds',
  ASSIGNMENTS = 'assignmentIds',
}

export enum TimeEntriesFiltersEnum {
  START_DATE_BETWEEN_FROM = 'startDateBetween.from',
  START_DATE_BETWEEN_TO = 'startDateBetween.to',
  MEMBERS = 'ownerIds',
  WORK_TYPES = 'workTypes',
  ORGANIZATIONS = 'companyIds',
  ASSIGNMENTS = 'assignmentIds',
}

export type TTimeEntryFiltersUi = {
  [key in TimeEntriesFiltersEnumUi]?: string[];
};

export type TTimeEntryFilters = {
  [key in TimeEntriesFiltersEnum]?: string[] | string | undefined;
};

export type TTimeTracker = {
  id: string;
  startTimeTimestamp: string;
  startTime: string;
  endTime: string;
};
export type TTimeTrackerUi = {
  id: string;
  startTimeTimestamp: string;
  startTime: string;
  endTime: string;
  duration: string;
  title: string;
};

export type TTimeEntryPerAssignmentRoot = {
  id: string;
  totalDuration: number;
  timeEntries: TimeEntryPerAssignment[];
};

export type TimeEntryPerAssignment = {
  id: string;
  note: string;
  workType: WorkTypeEnum;
  assignmentType: AssignmentEnum;
  assignmentId: string;
  assignmentNumber?: string;
  startTime: string;
  endTime: string;
  duration: number;
  companyId: string;
  companyLogo: string;
  companyName: string;
  owner: TOwner;
};
export type TTimeEntryPerAssignmentRootUi = {
  id: string;
  totalDuration: string;
  timeEntries: TimeEntryPerAssignmentUi[];
};

export type TimeEntryPerAssignmentUi = {
  id: string;
  note: string;
  workType: WorkTypeEnumUi;
  assignmentType?: AssignmentEnumUi;
  assignmentId: string;
  assignmentNumber?: string;
  startTime: string;
  endTime: string;
  duration: string;
  companyId: string;
  companyLogo: string;
  companyName: string;
  owner?: TOwnerUi;
  companyFirstLetter: string;
};

export type TTimeEntryAssignments = {
  assignmentType: AssignmentEnum;
  assignmentId: string;
  name: string;
  status: TTicketStatus | TTaskStatus | PhaseTaskStatusEnum | false;
};

export type TTimeEntryAssignmentsUi = {
  assignmentType: AssignmentEnumUi;
  assignmentId: string;
  name: string;
  status: TTicketStatusUi | TTaskStatusUi | PhaseTaskStatusEnumUi | false;
};
