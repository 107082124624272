import Menu from 'antd/es/menu';
import styled from 'styled-components';
import { TMenuItem } from './MenuItem.types';

const { Item, SubMenu } = Menu;
export const SMenuItem = styled(Item)<TMenuItem>`
  border-radius: ${(props: TMenuItem) =>
    props.previous === 'true' && '0px 0 20px 0px'};
  pointer-events: ${props => props.unselectable === 'on' && 'none'};
`;

export const SSubMenu = styled(SubMenu)`
  border-radius: ${(props: TMenuItem) =>
    props.previous === 'true' && '0px 0 20px 0px'};
`;
