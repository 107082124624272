import { useEffect, useState } from 'react';
import { zestLocalStorage } from 'helpers/storage';
import { xorBy } from 'lodash';
import { ETableNames, TTableSettings } from './Table.types';

type TUseTableSettingsProps = {
  initialData: TTableSettings[];
  tableName: ETableNames;
};
type TTUseTableSettingsPropsReturn = [
  TTableSettings[],
  React.Dispatch<React.SetStateAction<TTableSettings[]>>,
];

const useTableSettings = ({
  initialData,
  tableName,
}: TUseTableSettingsProps): TTUseTableSettingsPropsReturn => {
  const [settings, setSettings] = useState<TTableSettings[]>(
    zestLocalStorage.getItem('TableSettings')?.[tableName] || initialData,
  );

  useEffect(() => {
    const currStorage = zestLocalStorage.getItem('TableSettings');
    const currTableStorage: TTableSettings[] =
      zestLocalStorage.getItem('TableSettings')?.[tableName];
    const newStorageObj = {
      ...currStorage,
      [tableName]: settings,
    };
    // compare initial settings with data in storage,
    // detect changes and update storage data based on initial data
    if (
      !!currTableStorage &&
      xorBy(initialData, currTableStorage, 'title').length
    ) {
      const newTableStorageObj = initialData.map(el => {
        const findItemInStorage = currTableStorage.findIndex(
          item => item.title === el.title,
        );
        if (findItemInStorage > -1) {
          return currTableStorage[findItemInStorage];
        }
        return el;
      });
      setSettings(newTableStorageObj);
    }
    zestLocalStorage.setItem('TableSettings', newStorageObj);
  }, [settings, initialData]);

  return [settings, setSettings];
};

export default useTableSettings;
