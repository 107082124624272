import { useMemo } from 'react';
import { AllCompaniesPath } from 'data/types/company.types';
import { TOptionType } from 'data/types/generalDataTypes';
import { useGetAllCompaniesByTabQuery } from 'redux/apiSlices/company.slice';

export const useCompanyGroupedOptions = () => {
  const { data: customersData, isSuccess: isCustomerSuccess } =
    useGetAllCompaniesByTabQuery({
      sort: 'name,ASC',
      page: 0,
      pageSize: 2000,
      path: AllCompaniesPath.CUSTOMERS,
    });
  const { data: formerCustomersData, isSuccess: isFormerSuccess } =
    useGetAllCompaniesByTabQuery({
      sort: 'name,ASC',
      page: 0,
      pageSize: 2000,
      path: AllCompaniesPath.FORMER_CUSTOMERS,
    });

  const customersOptions: TOptionType[] = useMemo(
    () =>
      (customersData?.content || []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [customersData],
  );
  const formerCustomersOptions: TOptionType[] = useMemo(
    () =>
      (formerCustomersData?.content || []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [formerCustomersData],
  );

  const groupedOptions = [];
  if (customersOptions.length) {
    groupedOptions.push({
      label: 'Customers',
      options: customersOptions,
    });
  }
  if (formerCustomersOptions.length) {
    groupedOptions.push({
      label: 'Former customers',
      options: formerCustomersOptions,
    });
  }
  return { options: groupedOptions, isCustomerSuccess, isFormerSuccess };
};
