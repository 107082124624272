import React, { FC } from 'react';
import Button from 'components/Button/Button';
import { Icon, TIconNames } from 'components/Icon';
import { Text } from 'components/Typography';
import { Colors } from 'core/CssVariables';
import { TButtonTypes } from 'components/Button/Button.types';
import {
  SQuickFilterButton,
  SQuickFilterButtonWrapper,
} from './QuickFilterButton.style';
import { TQuickFilterButtonProps } from './QuickFilterButton.type';

export const QuickFilterButton: FC<TQuickFilterButtonProps> = ({
  selected,
  onClick,
  className,
  selectedFiltersCount,
  onResetFilters,
}) => {
  return (
    <SQuickFilterButtonWrapper align="middle" wrap={false}>
      <SQuickFilterButton
        selected={selected}
        onClick={onClick}
        className={className}
        role="button"
      >
        <Text fontlevel={5} className="text">
          Quick Filter
        </Text>
        {!!selectedFiltersCount && (
          <Text
            fontlevel={5}
            className="px-1"
            color={selected ? Colors.White : Colors.Orange}
          >
            ({selectedFiltersCount})
          </Text>
        )}
        <Icon
          className="ml-1"
          icon={TIconNames.QUICKFILTER}
          color={selected ? Colors.White : Colors.PrimaryColor}
        />
      </SQuickFilterButton>
      {!!selectedFiltersCount && (
        <Button
          className="ml-1"
          type="link"
          color={TButtonTypes.Orange}
          htmlType="button"
          onClick={onResetFilters}
          icon={
            <Icon
              color={Colors.Orange}
              icon={TIconNames.HIGHLIGHT_OFF}
              className="mr-1"
            />
          }
        >
          Clear all filters
        </Button>
      )}
    </SQuickFilterButtonWrapper>
  );
};
