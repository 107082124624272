import { rest } from 'msw';
import { CONTRACT_TYPES_URL } from 'redux/apiSlices/contractTypes.slices';
import { mockedContractTypes } from '../data/contractType.data';

export const contractTypeHandlers = [
  rest.post(CONTRACT_TYPES_URL, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get(`${CONTRACT_TYPES_URL}/all`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedContractTypes));
  }),
  rest.delete(`${CONTRACT_TYPES_URL}/:id1`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.patch(`${CONTRACT_TYPES_URL}/:id1/name`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
];

export const mockedEmptyGetContractTypes = () => {
  return rest.get(`${CONTRACT_TYPES_URL}/all`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  });
};
