import { TConverter } from '../types';
import { TFetchConverter } from '../types/converter.types';
import {
  TCompanyShortData,
  TCompanyShortDataUi,
  TContactShortData,
  TContactShortDataUi,
  TDevicesShortData,
  TDevicesShortDataUi,
} from '../types/shortData.type';
import { GetFirstLetters } from './general.converters';
import { deviceSourceConverter } from './devicesEnum.converter';

export const contactShortDataConverter: TConverter<
  TContactShortDataUi,
  TContactShortData
> = {
  toDb: data => {
    return {
      id: data.id,
      name: data.name,
      phoneNumber: data.phoneNumber || '',
      email: data.email,
      isVip: data.isVip,
      cellPhoneNumber: data.cellPhoneNumber,
      isActive: data.isActive,
      isManaged: data.isManaged,
      jobTitle: data.jobTitle,
      personalAddress: data.personalAddress,
      phoneExtension: data.phoneExtension,
    };
  },
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      phoneNumber: data.phoneNumber || '',
      email: data.email,
      isVip: data.isVip,
      cellPhoneNumber: data.cellPhoneNumber,
      isActive: data.isActive,
      isManaged: data.isManaged,
      jobTitle: data.jobTitle,
      personalAddress: data.personalAddress,
      phoneExtension: data.phoneExtension,
    };
  },
};
export const companyShortDataConverter: TFetchConverter<
  TCompanyShortDataUi,
  TCompanyShortData
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      logoUrl: data.logoUrl,
      phoneNumber: data.phoneNumber || '',
      mainContact:
        data.mainContact && contactShortDataConverter.fromDb(data.mainContact),
      firstLetters: GetFirstLetters.fromDb(data.name),
      shortName: data.shortName,
      nameWithAbbreviation: data.shortName
        ? `${data.shortName} - ${data.name}`
        : data.name,
      isMSP: data.isMSP,
    };
  },
};

export const devicesShortDataConverter: TFetchConverter<
  TDevicesShortDataUi,
  TDevicesShortData
> = {
  fromDb: data => {
    return {
      id: data.id,
      isActive: data.isActive,
      isDeleted: data.isDeleted,
      isManaged: data.isManaged,
      source: data.source && deviceSourceConverter.fromDb(data.source),
      name: data.name,
    };
  },
};
