import React, { FC, memo } from 'react';
import { Icon, TIconNames } from 'components/Icon';
import { Colors } from 'core/CssVariables';
import { RangePicker } from 'components/TimePicker/TimePicker';
import moment from 'moment';
import { getDateTimeFormats } from 'helpers/utils';
import SWorkingHours from './WorkingHours.style';
import { TWorkingHours } from './WorkingHours.type';

const format = 'hh:mm A';

const WorkingHours: FC<TWorkingHours> = ({
  index,
  onChange,
  onDeleteOption,
  availableHoursCount,
  availableHour,
  disabled,
}) => {
  return (
    <SWorkingHours hasCloseIcon={availableHoursCount > 1} disabled={disabled}>
      <RangePicker
        onChange={(value, dataString) => onChange(dataString, index)}
        disabled={disabled}
        format={getDateTimeFormats().timeFormat}
        showNow={false}
        {...(availableHour.start
          ? {
              value: [
                moment(availableHour.start, format),
                moment(availableHour.end, format),
              ],
              placeholder: [
                availableHour.start.slice(0, format.length),
                availableHour.end.slice(0, format.length),
              ],
            }
          : {})}
      />
      {availableHoursCount > 1 && !disabled && (
        <Icon
          className="remove-btn"
          icon={TIconNames.HIGHLIGHT_OFF}
          onClick={() => onDeleteOption(index)}
          size={20}
          color={Colors.ErrorColor}
        />
      )}
    </SWorkingHours>
  );
};

export default memo(WorkingHours);
