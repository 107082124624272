import React, { FC } from 'react';
import { Text } from 'components/Typography';
import { FontWeights } from 'core/CssVariables';
import SSeconds from './TimeTracker.style';

type TTimeTrackerTimerProps = {
  hours: number;
  minutes: number;
  seconds: number;
};
const TimeTrackerTimer: FC<TTimeTrackerTimerProps> = ({
  hours,
  minutes,
  seconds,
}) => {
  return (
    <Text
      fontlevel={5}
      fontWeight={FontWeights.SemiBold}
      className="mx-2"
      style={{ whiteSpace: 'nowrap' }}
    >
      {hours < 10 && 0}
      {hours}:{minutes < 10 && 0}
      {minutes}
      <SSeconds fontlevel={5}>
        :{seconds < 10 && 0}
        {seconds}
      </SSeconds>
    </Text>
  );
};

export default TimeTrackerTimer;
