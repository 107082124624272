import { Colors } from 'core/CssVariables';
import { TComapnyGetData, TComapnyGetDataUi } from './company.types';
import { TPhase, TPhaseUi } from './phase.type';
import {
  TMaterial,
  TMaterialTotals,
  TMaterialTotalsUi,
  TMaterialUi,
} from './material.type';
import { TGetAttachment, TGetAttachmentUi } from './generalDataTypes';
import { TCommentAdditionalRecipients, TCommentAdditionalRecipientsUi } from './commentAdditionalRecipients.types';

export enum TBillingMethodEnum {
  NON_BILLABLE = 'Non billable',
  HOURLY_RATE = 'Hourly rate',
  FLAT_FEE = 'Flat fee',
  NOT_TO_EXCEED = 'Not to exceed',
}

export type TProjectReporterUi = {
  id: string;
  email: string;
  name: string;
  phoneNumber: string;
  firstLetters: string;
  isVip?: boolean;
  jobTitle?: string;
  personalAddress?: string;
  phoneExtension?: string;
  cellPhoneNumber?: string;
};

export type TProjectReporter = {
  id: string;
  email: string;
  name: string;
  phoneNumber: string;
  isVip?: boolean;
  jobTitle?: string;
  personalAddress?: string;
  phoneExtension?: string;
  cellPhoneNumber?: string;
};
export type TTotalTimeEntriesUi = {
  HOURS: number;
  MINUTES?: number;
};
export type TTotalTimeEntries = {
  HOURS: number;
  MINUTES?: number;
};

export type TProjectProgressUi = {
  startDate: string;
  endDate: string;
  progressPercentage: number;
  totalTimeEntries: TTotalTimeEntriesUi;
};
export type TProjectProgress = {
  startDate: string;
  endDate: string;
  progressPercentage: number;
  totalTimeEntries: TTotalTimeEntries;
};

export type TTaskBasedEstimatedHoursUi = {
  HOURS: number;
  MINUTES?: number;
};
export type TTaskBasedEstimatedHours = {
  HOURS: number;
  MINUTES?: number;
};
export type TProjectSpecialFlagsUi = {
  invoicePaid?: boolean;
  invoiceSent?: boolean;
  unreadComments: number;
};
export type TProjectSpecialFlags = {
  invoicePaid?: boolean;
  invoiceSent?: boolean;
  unreadComments: number;
};

export type TProject = {
  id: string;
  title: string;
  description: string;
  startTime: string;
  endTime: string;
  company: TComapnyGetData;
  billingMethod: TBillingMethodEnum;
  primaryResource: TPrimaryResource;
  estimatedHours: number;
  status: string;
  budget: number;
  number: string;
  reporter: TProjectReporter;
  creationTime: string;
  progress: TProjectProgress;
  taskBasedEstimatedHours: TTaskBasedEstimatedHours;
  specialFlags: TProjectSpecialFlags;
};

export type TProjectCompact = {
  id: string;
  title: string;
  startTime: string;
  companyShortName?: string
  companyName: string
  companyLogoUrl: string;
  billingMethod: TBillingMethodEnum;
  primaryResource: TPrimaryResource;
  estimatedHours: number;
  status: string;
  budget: number;
  number: string;
  specialFlags: TProjectSpecialFlagsUi;
};

export type TPrimaryResource = {
  id: string;
  name: string;
  photoUrl: string;
};

export type TProjectUi = {
  id: string;
  title: string;
  description: string;
  startTime: string;
  endTime: string;
  company: TComapnyGetDataUi;
  billingMethod: TBillingMethodEnum;
  primaryResource: TPrimaryResourceUi;
  estimatedHours: number;
  status: string;
  budget: string;
  number: string;
  reporter: TProjectReporterUi;
  creationTime: string;
  progress: TProjectProgressUi;
  taskBasedEstimatedHours: TTaskBasedEstimatedHoursUi;
  specialFlags: TProjectSpecialFlagsUi;
};
export type TProjectCompactUi = {
  id: string;
  title: string;
  startTime: string;
  companyShortName?: string
  companyName: string
  companyLogoUrl: string;
  companyFirstLetters: string
  billingMethod: TBillingMethodEnum;
  primaryResource: TPrimaryResourceUi;
  estimatedHours: number;
  status: string;
  budget: string;
  number: string;
  specialFlags: TProjectSpecialFlagsUi;
};
export type TPrimaryResourceUi = {
  id: string;
  name: string;
  photoUrl: string;
  firstLetters: string;
};

export type TProjectPostData = {
  title: string;
  description: string;
  startTime: string;
  endTime: string;
  companyId: string;
  billingMethod: string;
  primaryResourceId: string;
  estimatedHours: number;
  reporterId: string;
};
export type TProjectPostDataUi = {
  title: string;
  description: string;
  startTime: string;
  endTime: string;
  companyId: string;
  billingMethod: string;
  primaryResourceId: string;
  estimatedHours: number;
  reporterId: string;
};

export enum ProjectStatus {
  DRAFT = 'Draft',
  PROPOSED = 'Proposed',
  APPROVED = 'Approved',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
}

export type TProjectStatusMapperColor = {
  [key in ProjectStatus]: Colors;
};

export type TProjectDetailsUi = {
  project: TProjectUi;
  materials: TMaterialUi[];
  phases: TPhaseUi[];
  materialTotals: TMaterialTotalsUi;
  attachments: TGetAttachmentUi[];
};

export type TProjectDetails = {
  project: TProject;
  materials: TMaterial[];
  phases: TPhase[];
  materialTotals: TMaterialTotals;
  attachments: TGetAttachment[];
};

export type TProjectCommenterUi = {
  userId: string;
  fullName: string;
  photoUrl: string;
  firstLetters: string;
};

export type TProjectCommenter = {
  userId: string;
  fullName: string;
  photoUrl: string;
  firstLetters: string;
};

export type TProjectComment = {
  id: string;
  text: string;
  creationTime: string;
  commenter: TProjectCommenter;
  projectId: string;
  edited: boolean;
  lastModifiedTime: string;
  replyToCustomer: boolean;
  fromCustomer: boolean;
  unread: boolean;
  additionalRecipients: TCommentAdditionalRecipients[];
  isHtml: boolean;
};

export type TProjectCommentUi = {
  id: string;
  text: string;
  creationTime: string;
  commenter: TProjectCommenterUi;
  projectId: string;
  edited: boolean;
  lastModifiedTime: string;
  replyToCustomer: boolean;
  fromCustomer: boolean;
  unread: boolean;
  additionalRecipients: TCommentAdditionalRecipientsUi[];
  isHtml: boolean;
};

export enum ProjectFilterNamesEnum {
  COMPANY = 'Company',
  PRIMARY_RESOURCE = 'Primary resource',
  STATUS = 'Status',
  BILLING_METHODS = 'Billing type',
  DATE = 'Date',
  SPECIAL_FLAGS = 'Special flags',
}

export enum ProjectFiltersEnum {
  COMPANY = 'companyIds',
  PRIMARY_RESOURCE = 'primaryResourceIds',
  STATUS = 'statuses',
  BILLING_METHODS = 'billingMethods',
  CREATION_DATE = 'creationDate',
  SPECIAL_FLAGS = 'specialFlags',
}

export enum EProjectSpecialFlagsUi {
  HAS_UNREAD_COMMENT = 'Unread comment',
  INVOICE_SENT = 'Invoice sent',
  INVOICE_PAID = 'Invoice paid',
}
export enum EProjectSpecialFlags {
  HAS_UNREAD_COMMENT = 'HAS_UNREAD_COMMENT',
  INVOICE_SENT = 'INVOICE_SENT',
  INVOICE_PAID = 'INVOICE_PAID',
}

export type TProjectItemsForInvoiceFilter = {
  includeMaterials: boolean;
  includeTimeEntries: boolean;
  timeEntriesFrom?: string;
  timeEntriesTo?: string;
};
export type TProjectItemsForInvoiceFilterUi = {
  includeMaterials: boolean;
  includeTimeEntries: boolean;
  timeEntriesPeriod?: string[];
};
