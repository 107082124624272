import React, { memo } from 'react';
import { Row } from 'components/Grid/Grid';
import PaymentAlert from './PaymentAlert/PaymentAlert';
import CardExpirationAlert from './CardExpirationAlert/CardExpirationAlert';

const HeaderAlert = () => {
  return (
    <Row gutter={[0, 2]}>
      <PaymentAlert />
      <CardExpirationAlert />
    </Row>
  );
};

export default memo(HeaderAlert);
