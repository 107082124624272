import { lazy } from 'react';
import { TIconNames } from 'components/Icon';
import { TUserRoleUi } from 'data/types/user.types';
import { PrivateRouteType, RoutesPaths } from '../Routes.types';

const Invitations = lazy(
  () => import('pages/private/TeamManagement/Invitations/Invitations'),
);
const Members = lazy(
  () => import('pages/private/TeamManagement/Members/Members'),
);
const MembersDetails = lazy(
  () => import('pages/private/TeamManagement/Members/MembersDetails'),
);
const UserGroups = lazy(
  () => import('pages/private/TeamManagement/UserGroups/UserGroups'),
);

export const TeamManagementSubRoutes: PrivateRouteType[] = [
  {
    title: 'Invitations',
    component: Invitations,
    path: RoutesPaths.Invitations,
    index: true,
    icon: TIconNames.GROUP_ADD,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  },
  {
    title: 'Members',
    component: Members,
    path: RoutesPaths.Members,
    icon: TIconNames.PEOPLE,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  },
  {
    title: 'User Groups',
    component: UserGroups,
    path: RoutesPaths.UserGroups,
    icon: TIconNames.GROUP,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  },
  {
    title: 'Member Details',
    component: MembersDetails,
    path: RoutesPaths.MemberDetails,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
    notInSidebar: true,
  },
];
