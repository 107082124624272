import { useGetDateWithTimeZone } from 'hooks/useGetDateWithTimeZone';
import moment from 'moment';

export type TUseCardExpiration = {
  isExpired?: boolean;
  nearToExpire?: boolean;
};
type TUseCardExpirationProps = {
  expirationDate?: string;
};

export const useCardExpiration = ({
  expirationDate,
}: TUseCardExpirationProps): TUseCardExpiration => {
  const dateNow = useGetDateWithTimeZone();
  const diffDays = Number(moment(expirationDate).diff(dateNow, 'day'));

  return {
    isExpired: diffDays <= 0,
    nearToExpire: diffDays <= 7,
  };
};
