const mockedQueues = {
  content: [
    {
      id: '20f7296a-1326-4bc1-9b18-f508baa368c4',
      name: 'Administration',
      description: '',
      order: 4,
      systemCreated: true,
      hidden: false,
      issueTypes: [
        {
          id: '1d2bc5da-41af-4faa-bff2-ad536051b3b4',
          name: 'Cloud Services',
        },
      ],
      userGroups: [
        {
          id: '426babde-4380-4cd8-b41d-172ff90f73d0',
          name: 'Accounting',
        },
      ],
      slaTargets: {
        'First Response SLA': {
          type: 'First Response SLA',
          targets: {
            Urgent: 0.1,
            Low: 2,
            Medium: 0.1,
            High: 0.2,
          },
        },
        'Resolution SLA': {
          type: 'Resolution SLA',
          targets: {
            Urgent: 0.1,
            Low: 5,
            Medium: 0.1,
            High: 0.1,
          },
        },
      },
      special: false,
      cpDefault: false,
      rmmDefault: false,
      attachedEmails: null,
      emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
    },
    {
      id: 'e096c43a-9ea2-4af4-a05d-bfd22a43959e',
      name: 'Break/Fix1',
      description: null,
      order: 1,
      systemCreated: true,
      hidden: false,
      issueTypes: [
        {
          id: '1d2bc5da-41af-4faa-bff2-ad536051b3b4',
          name: 'Cloud Services',
        },
        {
          id: '263945cf-958b-482d-963e-49539f5ff1a3',
          name: 'Vendor Service',
        },
        {
          id: 'c4e34f33-b419-444e-92d2-2e6ad253a0fe',
          name: 'Nelly hi',
        },
        {
          id: '12812629-dded-478a-824b-f8e3d514c8b0',
          name: 'Computersfsegrh',
        },
        {
          id: '198226dd-9ac2-460b-9fd0-2e3c31a3baa1',
          name: 'Network Is dfsue',
        },
        {
          id: 'c5bbd585-f9cb-4c5f-a4a2-ea727c92fa31',
          name: 'Endpoint',
        },
      ],
      userGroups: [
        {
          id: 'b2462b94-b65a-47ad-a6d4-ef020f13aab7',
          name: 'IT Support Tech',
        },
      ],
      slaTargets: {
        'First Response SLA': {
          type: 'First Response SLA',
          targets: {
            Urgent: 0.5,
            Low: 0.5,
            Medium: 0.5,
            High: 0.5,
          },
        },
        'Resolution SLA': {
          type: 'Resolution SLA',
          targets: {
            Urgent: 9,
            Low: 45,
            Medium: 18,
            High: 9,
          },
        },
      },
      special: true,
      cpDefault: true,
      rmmDefault: false,
      attachedEmails: [
        {
          id: '8412f6f7-1939-4bbf-b957-545221bba47d',
          email: 'abcUltralabsMSP@stage.zestmsp.com',
        },
      ],
      emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
    },
    {
      id: 'e71eda3b-0208-474b-92ca-7ac4ca4a554b',
      name: 'IT Management',
      description: null,
      order: 5,
      systemCreated: true,
      hidden: false,
      issueTypes: [
        {
          id: 'c4e34f33-b419-444e-92d2-2e6ad253a0fe',
          name: 'Nelly hi',
        },
        {
          id: 'd90bb9bb-002e-4f0d-af04-f543b0cd8334',
          name: 'IT Management',
        },
      ],
      userGroups: [
        {
          id: '71b07f38-b511-44c8-beee-69e1f85ccbe6',
          name: 'Owner',
        },
        {
          id: '2dc14b57-0a51-4f5d-be9d-52399e655a90',
          name: 'Director of IT',
        },
      ],
      slaTargets: {
        'First Response SLA': {
          type: 'First Response SLA',
          targets: {
            Urgent: 0.1,
            Low: 0.1,
            Medium: 0.1,
            High: 0.1,
          },
        },
        'Resolution SLA': {
          type: 'Resolution SLA',
          targets: {
            Urgent: 0.1,
            Low: 0.1,
            Medium: 0.1,
            High: 0.1,
          },
        },
      },
      special: false,
      cpDefault: false,
      rmmDefault: false,
      attachedEmails: null,
      emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
    },
    {
      id: '13f13206-199c-4804-a63e-66800eb0b592',
      name: 'Monitoring Alerts',
      description: null,
      order: 2,
      systemCreated: true,
      hidden: false,
      issueTypes: [
        {
          id: 'c4e34f33-b419-444e-92d2-2e6ad253a0fe',
          name: 'Nelly hi',
        },
        {
          id: '7a964224-5a90-4bf7-9f73-e6900370fe92',
          name: 'sadasd asdasd sda Provisioningxcv sada asd sdsdfdsf sadsa d asd as da d sda as dassadas dsadasd sa das dsa das da',
        },
        {
          id: '23ace7b7-d758-4835-b3a6-88a744a0868e',
          name: 'Alert',
        },
      ],
      userGroups: [
        {
          id: 'c5dba235-08c7-4dbd-8861-7f7714729ee4',
          name: 'IT Administrator',
        },
      ],
      slaTargets: {
        'First Response SLA': {
          type: 'First Response SLA',
          targets: {
            Urgent: 0.1,
            Low: 0.1,
            Medium: 0.1,
            High: 0.1,
          },
        },
        'Resolution SLA': {
          type: 'Resolution SLA',
          targets: {
            Urgent: 0.1,
            Low: 0.1,
            Medium: 0.1,
            High: 0.1,
          },
        },
      },
      special: true,
      cpDefault: false,
      rmmDefault: true,
      attachedEmails: [
        {
          id: '170e6d4e-42b9-469a-96ed-190e5bcb2967',
          email: 'alertsShakestage1llc@stage.zestmsp.com',
        },
        {
          id: '1564e98b-f83a-4ef7-976f-9cd6ca479f2a',
          email: 'charmMartirosjune13@stage.zestmsp.com',
        },
        {
          id: 'dd023a96-4008-43c9-aa2e-539b13899c7e',
          email: 'shakestage1@stage.zestmsp.com',
        },
        {
          id: '88e3139f-747c-4ced-a0ea-160f183ed3ad',
          email: 'ssssshakestage1@stage.zestmsp.com',
        },
        {
          id: 'd3d15e17-64f0-4ace-877b-f06e635b65de',
          email: 'tarmUltralabsMSP@stage.zestmsp.com',
        },
      ],
      emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
    },
    {
      id: '0b0a89e8-b1ce-4b8a-9499-cb8c2310ad1e',
      name: 'Network Admin',
      description: null,
      order: 6,
      systemCreated: true,
      hidden: false,
      issueTypes: [
        {
          id: 'c4e34f33-b419-444e-92d2-2e6ad253a0fe',
          name: 'Nelly hi',
        },
      ],
      userGroups: [],
      slaTargets: {
        'First Response SLA': {
          type: 'First Response SLA',
          targets: {
            Urgent: 0.1,
            Low: 0.1,
            Medium: 0.1,
            High: 0.1,
          },
        },
        'Resolution SLA': {
          type: 'Resolution SLA',
          targets: {
            Urgent: 0.1,
            Low: 0.1,
            Medium: 0.1,
            High: 0.1,
          },
        },
      },
      special: false,
      cpDefault: false,
      rmmDefault: false,
      attachedEmails: null,
      emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
    },
    {
      id: 'ca91017b-ed7a-40ac-bf23-559984112e79',
      name: 'Procurement',
      description: null,
      order: 7,
      systemCreated: true,
      hidden: false,
      issueTypes: [
        {
          id: '03c8d576-c175-4196-9c67-41b5a1686448',
          name: 'Provisioningxcv sada asd Provisioningxcv sada asdP fg h fg h',
        },
      ],
      userGroups: [
        {
          id: '426babde-4380-4cd8-b41d-172ff90f73d0',
          name: 'Accounting',
        },
        {
          id: '8f51726e-82d3-4392-b2c0-7ddf0a308232',
          name: 'Procurement',
        },
      ],
      slaTargets: {
        'First Response SLA': {
          type: 'First Response SLA',
          targets: {
            Urgent: 0.5,
            Low: 8,
            Medium: 8,
            High: 4,
          },
        },
        'Resolution SLA': {
          type: 'Resolution SLA',
          targets: {
            Urgent: 18,
            Low: 180,
            Medium: 90,
            High: 45,
          },
        },
      },
      special: false,
      cpDefault: false,
      rmmDefault: false,
      attachedEmails: null,
      emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
    },
    {
      id: 'd43e4540-b9a0-40a2-b0be-49ffeea2cd54',
      name: 'Prof. Services',
      description: null,
      order: 8,
      systemCreated: true,
      hidden: false,
      issueTypes: [
        {
          id: '1d2bc5da-41af-4faa-bff2-ad536051b3b4',
          name: 'Cloud Services',
        },
      ],
      userGroups: [
        {
          id: '2d012d48-867b-4313-82cd-096f0ddf9fe7',
          name: 'IT Engineer',
        },
      ],
      slaTargets: {
        'First Response SLA': {
          type: 'First Response SLA',
          targets: {
            Urgent: 0.5,
            Low: 4,
            Medium: 4,
            High: 2,
          },
        },
        'Resolution SLA': {
          type: 'Resolution SLA',
          targets: {
            Urgent: 45,
            Low: 360,
            Medium: 180,
            High: 90,
          },
        },
      },
      special: false,
      cpDefault: false,
      rmmDefault: false,
      attachedEmails: null,
      emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
    },
    {
      id: '7dc8482f-2b65-4383-88ff-3f3c0f38c6e4',
      name: 'Provisioning',
      description: null,
      order: 12,
      systemCreated: true,
      hidden: false,
      issueTypes: [
        {
          id: '1d2bc5da-41af-4faa-bff2-ad536051b3b4',
          name: 'Cloud Services',
        },
        {
          id: '263945cf-958b-482d-963e-49539f5ff1a3',
          name: 'Vendor Service',
        },
        {
          id: 'c5bbd585-f9cb-4c5f-a4a2-ea727c92fa31',
          name: 'Endpoint',
        },
        {
          id: '7a4a196f-b289-46a8-bfd5-cf3a4653a9e3',
          name: 'Windows Application',
        },
      ],
      userGroups: [
        {
          id: 'b2462b94-b65a-47ad-a6d4-ef020f13aab7',
          name: 'IT Support Tech',
        },
      ],
      slaTargets: {
        'First Response SLA': {
          type: 'First Response SLA',
          targets: {
            Urgent: 0.5,
            Low: 0.5,
            Medium: 0.5,
            High: 0.5,
          },
        },
        'Resolution SLA': {
          type: 'Resolution SLA',
          targets: {
            Urgent: 9,
            Low: 45,
            Medium: 18,
            High: 9,
          },
        },
      },
      special: false,
      cpDefault: false,
      rmmDefault: false,
      attachedEmails: null,
      emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
    },
    {
      id: '5e029454-4bc0-446e-b344-9846168298ea',
      name: 'Sales',
      description: null,
      order: 9,
      systemCreated: true,
      hidden: false,
      issueTypes: [],
      userGroups: [
        {
          id: '426babde-4380-4cd8-b41d-172ff90f73d0',
          name: 'Accounting',
        },
        {
          id: '43434b48-a695-4d2f-b7c6-8de223f5b6b2',
          name: 'zasda sd as das d',
        },
        {
          id: '8f51726e-82d3-4392-b2c0-7ddf0a308232',
          name: 'Procurement',
        },
        {
          id: '71b07f38-b511-44c8-beee-69e1f85ccbe6',
          name: 'Owner',
        },
        {
          id: 'c5dba235-08c7-4dbd-8861-7f7714729ee4',
          name: 'IT Administrator',
        },
        {
          id: '8d79b2a6-553c-4aa8-b170-5bec5e1a5daa',
          name: 'Service Manager',
        },
        {
          id: 'b2462b94-b65a-47ad-a6d4-ef020f13aab7',
          name: 'IT Support Tech',
        },
        {
          id: '0c115529-f6f9-49c4-9381-6766311ef0cb',
          name: 'Sales',
        },
        {
          id: '1b5c448e-b7fe-4a3e-9051-82f06db03deb',
          name: 'New group auhduaNew group au New group auhduaNew group au dsj kdskjf  dsfkj sdf sd f',
        },
        {
          id: '2d012d48-867b-4313-82cd-096f0ddf9fe7',
          name: 'IT Engineer',
        },
        {
          id: '2dc14b57-0a51-4f5d-be9d-52399e655a90',
          name: 'Director of IT',
        },
      ],
      slaTargets: {
        'First Response SLA': {
          type: 'First Response SLA',
          targets: {
            Urgent: 0.5,
            Low: 8,
            Medium: 8,
            High: 4,
          },
        },
        'Resolution SLA': {
          type: 'Resolution SLA',
          targets: {
            Urgent: 45,
            Low: 0.1,
            Medium: 180,
            High: 90,
          },
        },
      },
      special: false,
      cpDefault: false,
      rmmDefault: false,
      attachedEmails: null,
      emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
    },
    {
      id: '25fbaba1-287a-457a-9db4-27a01721ae62',
      name: 'Security',
      description: null,
      order: 11,
      systemCreated: true,
      hidden: false,
      issueTypes: [
        {
          id: '1d2bc5da-41af-4faa-bff2-ad536051b3b4',
          name: 'Cloud Services',
        },
        {
          id: '263945cf-958b-482d-963e-49539f5ff1a3',
          name: 'Vendor Service',
        },
        {
          id: 'c5bbd585-f9cb-4c5f-a4a2-ea727c92fa31',
          name: 'Endpoint',
        },
        {
          id: '7a4a196f-b289-46a8-bfd5-cf3a4653a9e3',
          name: 'Windows Application',
        },
      ],
      userGroups: [
        {
          id: 'b2462b94-b65a-47ad-a6d4-ef020f13aab7',
          name: 'IT Support Tech',
        },
      ],
      slaTargets: {
        'First Response SLA': {
          type: 'First Response SLA',
          targets: {
            Urgent: 0.5,
            Low: 0.5,
            Medium: 0.5,
            High: 0.5,
          },
        },
        'Resolution SLA': {
          type: 'Resolution SLA',
          targets: {
            Urgent: 9,
            Low: 45,
            Medium: 18,
            High: 9,
          },
        },
      },
      special: false,
      cpDefault: false,
      rmmDefault: false,
      attachedEmails: null,
      emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
    },
  ],
  pageable: {
    sort: {
      empty: false,
      sorted: true,
      unsorted: false,
    },
    offset: 0,
    pageNumber: 0,
    pageSize: 10,
    unpaged: false,
    paged: true,
  },
  last: false,
  totalElements: 12,
  totalPages: 2,
  size: 10,
  number: 0,
  sort: {
    empty: false,
    sorted: true,
    unsorted: false,
  },
  first: true,
  numberOfElements: 10,
  empty: false,
};

const mockedQueue = {
  id: 'e096c43a-9ea2-4af4-a05d-bfd22a43959e',
  name: 'Break/Fix1',
  description: 'description for queue',
  order: 1,
  systemCreated: true,
  hidden: false,
  issueTypes: [
    {
      id: '1d2bc5da-41af-4faa-bff2-ad536051b3b4',
      name: 'Cloud Services',
    },
    {
      id: '263945cf-958b-482d-963e-49539f5ff1a3',
      name: 'Vendor Service',
    },
    {
      id: 'c4e34f33-b419-444e-92d2-2e6ad253a0fe',
      name: 'Nelly hi',
    },
    {
      id: '12812629-dded-478a-824b-f8e3d514c8b0',
      name: 'Computersfsegrh',
    },
    {
      id: '198226dd-9ac2-460b-9fd0-2e3c31a3baa1',
      name: 'Network Is dfsue',
    },
    {
      id: 'c5bbd585-f9cb-4c5f-a4a2-ea727c92fa31',
      name: 'Endpoint',
    },
  ],
  userGroups: [
    {
      id: 'b2462b94-b65a-47ad-a6d4-ef020f13aab7',
      name: 'IT Support Tech',
    },
  ],
  slaTargets: {
    'First Response SLA': {
      type: 'First Response SLA',
      targets: {
        Urgent: 0.5,
        Low: 0.5,
        Medium: 0.5,
        High: 0.5,
      },
    },
    'Resolution SLA': {
      type: 'Resolution SLA',
      targets: {
        Urgent: 9,
        Low: 45,
        Medium: 18,
        High: 9,
      },
    },
  },
  special: true,
  cpDefault: true,
  rmmDefault: false,
  attachedEmails: [
    {
      id: '8412f6f7-1939-4bbf-b957-545221bba47d',
      email: 'abcUltralabsMSP@stage.zestmsp.com',
    },
  ],
  emailSuffix: 'UltralabsMSP@stage.zestmsp.com',
};

export { mockedQueues, mockedQueue };
