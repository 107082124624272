import { Moment } from 'moment';
import { TContactShortData, TContactShortDataUi } from './shortData.type';
import {
  TMaterial,
  TMaterialTotals,
  TMaterialTotalsUi,
  TMaterialUi,
} from './material.type';
import { TGetAttachment, TGetAttachmentUi } from './generalDataTypes';

export type TPhase = {
  id: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  estimatedHours: number;
  status?: PhaseStatusEnum;
  order: number;
  tasks: TPhaseTask[];
};

export type TPhaseUi = {
  id: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  estimatedHours: number;
  status?: PhaseStatusEnumUi;
  order: number;
  startEndDates?: string;
  tasks: TPhaseTaskUi[];
};

export type TCreatePhase = {
  title: string;
  description: string;
};

export type TCreatePhaseUi = {
  id: string;
  title: string;
  description: string;
};

export type TCreatePhaseTask = {
  estimatedHours: number;
  title: string;
  description: string;
};

export type TCreatePhaseTaskUi = {
  id?: string;
  title: string;
  description: string;
  estimatedHours: number;
  phaseId?: string;
};

export type TPhaseTaskAction = {
  action: PhaseTaskActionEnum;
  nextPossibleStatuses: PhaseTaskStatusEnum[];
};
export type TPhaseTaskActionUi = {
  action: PhaseTaskActionEnumUi;
  nextPossibleStatuses: PhaseTaskStatusEnumUi[];
};
export type TPhaseTaskNextAction = {
  description: string;
  scheduleTime: string;
  endTime: string;
  hasEventMapping: null | boolean;
};
export type TPhaseTaskNextActionUi = {
  description: string;
  scheduleTime: string;
  endTime: string;
  hasEventMapping: null | boolean;
};
export type TPhaseTask = {
  id: string;
  title: string;
  phaseId: string;
  projectId: string;
  description: string;
  startDate?: string;
  endDate?: string;
  status?: PhaseTaskStatusEnum;
  assignee?: TPhaseTaskAssignee;
  reporter?: TPhaseTaskReporter;
  company?: TPhaseTaskCompany;
  order: string;
  actions: TPhaseTaskAction[];
  nextActionItem?: TPhaseTaskNextAction;
  number: string;
  creationDateTime: string;
  materials: TMaterial[];
  materialTotals?: TMaterialTotals;
  estimatedHours: number;
  overdueSince: string | null;
  friendlyNumber: string;
  attachments: TGetAttachment[];
};

export type TPhaseTaskUi = {
  id: string;
  title: string;
  phaseId: string;
  projectId: string;
  description: string;
  startDate?: string;
  endDate?: string;
  startEndDates?: string;
  status?: PhaseTaskStatusEnumUi;
  assignee?: TPhaseTaskAssigneeUi;
  reporter?: TPhaseTaskReporterUi;
  company?: TPhaseTaskCompanyUi;
  estimatedHours: number;
  order: string;
  actions: TPhaseTaskActionUi[];
  nextActionItem?: TPhaseTaskNextActionUi;
  number: string;
  materials: TMaterialUi[];
  materialTotals?: TMaterialTotalsUi;
  creationDateTime: string;
  overdueSince: string | null;
  friendlyNumber: string;
  attachments: TGetAttachmentUi[];
};
export enum PhaseStatusEnum {
  New = 'New',
  Pending = 'Pending',
  Completed = 'Completed',
  'In Progress' = 'In Progress',
}

export enum PhaseStatusEnumUi {
  New = 'New',
  Pending = 'Pending',
  Completed = 'Completed',
  'In Progress' = 'In Progress',
}

export enum PhaseTaskStatusEnum {
  New = 'New',
  Closed = 'Closed',
  Completed = 'Completed',
  Scheduled = 'Scheduled',
  'In Progress' = 'In Progress',
  Assigned = 'Assigned',
}

export enum PhaseTaskStatusEnumUi {
  New = 'New',
  Closed = 'Closed',
  Completed = 'Completed',
  Scheduled = 'Scheduled',
  'In Progress' = 'In Progress',
  Assigned = 'Assigned',
}

export type TPhaseTaskAssigneeUi = {
  id: string;
  name: string;
  photoUrl: string;
  firstLetters?: string;
};

export type TPhaseTaskAssignee = {
  id: string;
  name: string;
  photoUrl: string;
};

export type TPhaseTaskReporter = {
  id: string;
  name: string;
  photoUrl: string;
};

export type TPhaseTaskReporterUi = {
  id: string;
  name: string;
  photoUrl: string;
  firstLetters?: string;
};

export type TPhaseTaskCompanyUi = {
  id: string;
  name: string;
  shortName: string;
  logoUrl: string;
  mainContact: TContactShortDataUi;
  firstLetters?: string;
  phoneNumber: string;
  nameWithAbbreviation: string;
};
export type TPhaseTaskCompany = {
  id: string;
  name: string;
  shortName: string;
  logoUrl: string;
  mainContact: TContactShortData;
  phoneNumber: string;
};

export type TPhaseTaskCommenterUi = {
  userId: string;
  fullName: string;
  photoUrl: string;
  firstLetters: string;
};

export type TPhaseTaskCommenter = {
  userId: string;
  fullName: string;
  photoUrl: string;
  firstLetters: string;
};

export type TPhaseTaskComment = {
  id: string;
  text: string;
  creationTime: string;
  commenter: TPhaseTaskCommenter;
  taskId: string;
  edited: boolean;
  lastModifiedTime: string;
};

export type TPhaseTaskCommentUi = {
  id: string;
  text: string;
  creationTime: string;
  commenter: TPhaseTaskCommenterUi;
  taskId: string;
  edited: boolean;
  lastModifiedTime: string;
};
export enum PhaseTaskActionEnumUi {
  START_WORK = 'START_WORK',
  ASSIGN = 'ASSIGN',
  RESOLVE = 'RESOLVE',
  RE_ASSIGN = 'RE_ASSIGN',
  SCHEDULE = 'SCHEDULE',
  RE_SCHEDULE = 'RE_SCHEDULE',
}

export enum PhaseTaskActionEnum {
  START_WORK = 'START_WORK',
  ASSIGN = 'ASSIGN',
  RESOLVE = 'RESOLVE',
  RE_ASSIGN = 'RE_ASSIGN',
  SCHEDULE = 'SCHEDULE',
  RE_SCHEDULE = 'RE_SCHEDULE',
}

export type TPhaseTaskAssign = {
  assigneeId: string;
  nextStatus: PhaseTaskStatusEnum;
  startDate: string; // format "2022-07-08"
  endDate: string;
};

export type TPhaseTaskAssignUi = {
  assigneeId: string;
  nextStatus: PhaseTaskStatusEnumUi;
  startDate: Moment;
  endDate: Moment;
  taskId?: string;
};

export type TPhaseTaskReassign = {
  assigneeId: string;
  keepNSI?: boolean;
};

export type TPhaseTaskReassignUi = {
  assigneeId: string;
  taskId?: string;
  keepScheduleAction?: boolean;
};

export type TPhaseTaskStartWork = {
  nextStatus: PhaseTaskStatusEnum;
};

export type TPhaseTaskStartWorkUi = {
  nextStatus: PhaseTaskStatusEnumUi;
  taskId?: string;
};

export type TPhaseTaskSchedule = {
  nextStatus: PhaseTaskStatusEnum;
  scheduleTime: string;
  description: string;
  createCalendarItem: boolean;
  endTime: string;
};

export type TPhaseTaskScheduleUi = {
  nextStatus: PhaseTaskStatusEnumUi;
  scheduleTime: string;
  description: string;
  taskId?: string;
  createCalendarItem: boolean;
  endTime: string;
};

export type TPhaseTaskResolve = {
  value: PhaseTaskStatusEnum;
};

export type TPhaseTaskResolveUi = {
  nextStatus: PhaseTaskStatusEnumUi;
  taskId?: string;
};

export type TPhaseTextEdit = {
  value: string;
};
export type TPhaseTextEditUi = {
  phaseId?: string;
  projectId?: string;
  value: string;
};

export type TPhaseTaskTextEdit = {
  value: string;
};
export type TPhaseTaskTextEditUi = {
  taskId?: string;
  value: string;
};

export type TPhaseTaskDateEdit = {
  value: string;
};
export type TPhaseTaskDateEditUi = {
  taskId?: string;
  value: Moment;
};

export type TProjectMaterialCategoryEdit = {
  value: string;
};
export type TProjectMaterialCategoryEditUi = {
  materialId?: string;
  projectId?: string;
  value: string;
};
