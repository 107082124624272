import { TPasswordProps } from '../Input/types';

export type TPasswordProgress = TPasswordProps;

export type TValidationRules = {
  [key in TValidationTags]: RegExp;
};

export type TPassedValidations = {
  [key in TValidationTags]?: boolean;
};

export enum TValidationTags {
  LETTERS8 = 'Minimum 8 characters',
  LOWERCASE = 'Lower Case',
  UPPERCASE = 'Upper Case',
  SYMBOL = 'Symbol',
  NUMBER = 'Number',
}
