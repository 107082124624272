import React, { FC } from 'react';
import { TPrimaryTitleTypeUI, TTitleGradeUI } from 'data/types/ticket.types';
import { Icon, TIconNames } from 'components/Icon';
import { Colors } from 'core/CssVariables';
import { ticketGradeMapper } from 'data/converters/ticket.converters';

const isAiEnabled = process.env.REACT_APP_AI_IS_ENABLED === 'true';

type TTicketTitleProps = {
  title: string;
  aiTitle?: string;
  primaryTitleType?: TPrimaryTitleTypeUI;
  grade?: TTitleGradeUI;
};

const TicketTitle: FC<TTicketTitleProps> = ({
  title,
  aiTitle,
  primaryTitleType,
  grade,
}) => {
  return (
    <>
      {isAiEnabled && primaryTitleType === TPrimaryTitleTypeUI.AI ? (
        <>
          {aiTitle}
          <Icon
            icon={TIconNames.AI_BORDER}
            size={34}
            className="mb-1"
            color={Colors.SecondaryColor}
          />
        </>
      ) : (
        title
      )}
      {isAiEnabled && grade && (
        <Icon
          color={ticketGradeMapper[grade].color}
          icon={ticketGradeMapper[grade].icon}
          size={18}
          className="pointer ml-1 mb-1"
        />
      )}
    </>
  );
};
export default TicketTitle;
