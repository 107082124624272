import { TAuthConverter } from 'data/types';
import { TFetchConverter } from 'data/types/converter.types';
import {
  TEmailAttachableQueues,
  TEmailAttachableQueuesUi,
  TEmailConfigGet,
  TEmailConfigGetUi,
  TEmailGet,
  TEmailGetUi,
  TEmailPost,
  TEmailPostUi,
} from 'data/types/email.types';

export const emailConverter: TAuthConverter<
  TEmailPostUi,
  TEmailPost,
  TEmailGetUi[],
  TEmailGet[]
> = {
  toDb: data => {
    return {
      emailPrefix: data.emailPrefix,
      queueId: data.queueId,
    };
  },
  fromDb: data => {
    return data.map((item: TEmailGet) => ({
      id: item.id,
      email: item.email,
    }));
  },
};

const emailAttachableQueuesConverter: TFetchConverter<
  TEmailAttachableQueuesUi,
  TEmailAttachableQueues
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
    };
  },
};
export const emailConfigConverter: TFetchConverter<
  TEmailConfigGetUi,
  TEmailConfigGet
> = {
  fromDb: data => {
    return {
      id: data.id,
      emailSuffix: data.emailSuffix,
      emailAttachableQueues: data.emailAttachableQueues.map(
        (queue: TEmailAttachableQueues) =>
          emailAttachableQueuesConverter.fromDb(queue),
      ),
    };
  },
};
