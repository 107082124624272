import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { useAppSelector } from 'redux/store';
import GlobalStyles, { DropDownGlobalStyle } from 'styles/GlobalStyles';
import { DarkTheme, LightTheme } from './theme';
import 'styles/App.less';

export const ThemeWrapper: FC = ({ children }) => {
  const theme = useAppSelector(state => state.appSettings.theme);
  return (
    <ThemeProvider theme={theme === 'dark' ? DarkTheme : LightTheme}>
      <GlobalStyles />
      <DropDownGlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
