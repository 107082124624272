import React, { FC } from 'react';
import { Icon, TIconNames } from 'components/Icon';
import { changeSidebarState } from 'redux/slices';
import { useAppDispatch, useAppSelector } from 'redux/store';
import SSidebarSwitcher from './SidebarSwithcer.style';

const SidebarSwitcher: FC<{ className?: string }> = ({ className = '' }) => {
  const dispatch = useAppDispatch();
  const isSidebarHidden = useAppSelector(
    state => state.appSettings.sidebar.hidden,
  );
  return (
    <SSidebarSwitcher className={`sidebar-switcher ${className}`}>
      {!isSidebarHidden ? (
        <Icon
          size={18}
          icon={TIconNames.CLOSE}
          className="pointer"
          onClick={() =>
            dispatch(
              changeSidebarState({
                hidden: true,
              }),
            )
          }
        />
      ) : (
        <Icon
          size={18}
          icon={TIconNames.MENU}
          className="pointer"
          onClick={() =>
            dispatch(
              changeSidebarState({
                hidden: false,
              }),
            )
          }
        />
      )}
    </SSidebarSwitcher>
  );
};

export default SidebarSwitcher;
