import { MemberSelectLabel } from 'components/MembersSelect';
import React from 'react';
import { useGetTaskAssigneesQuery } from 'redux/apiSlices/task.slices';
import { TFilterProps } from '../../FilterComponents.type';
import { AssignedFilter } from './AssignedFilter';

const AssignedTaskFilter: React.FC<TFilterProps> = ({
  onSelected,
  selectedValues,
}) => {
  const { data: membersList } = useGetTaskAssigneesQuery();
  const options = membersList?.map(({ id, name, photoUrl }) => ({
    value: id,
    label: <MemberSelectLabel name={name} photoUrl={photoUrl} key={name} />,
  }));
  return (
    <AssignedFilter
      options={options}
      onSelected={onSelected}
      selectedValues={selectedValues}
    />
  );
};

export default AssignedTaskFilter;
