import React from 'react';
import { Row } from 'components/Grid/Grid';
import { Icon, TIconNames } from 'components/Icon';
import { Colors, FontWeights } from 'core/CssVariables';
import { Text } from 'components/Typography';

const OverdueLabel = () => {
  return (
    <Row align="middle">
      <Icon
        icon={TIconNames.OVERDUE}
        color={Colors.ErrorColor}
        size={16}
        className="mr-1"
      />
      <Text
        fontlevel={5}
        fontWeight={FontWeights.SemiBold}
        color={Colors.ErrorColor}
      >
        Overdue
      </Text>
    </Row>
  );
};

export default OverdueLabel;
