import { TFetchConverter } from '../types/converter.types';
import { TPatchResponse, TPatchResponseUi } from '../types/responses.type';

export const patchResponseToToastConverter: TFetchConverter<
  TPatchResponseUi,
  TPatchResponse
> = {
  fromDb: data => {
    if (data?.status === 207) {
      return {
        msg: data.msg ? `(${data.msg})` : '',
        toastType: 'warning',
        isAssignmentActionOk: data.isAssignmentActionOk,
        isCalendarItemOk: data.isCalendarItemOk,
      };
    }

    return {
      isAssignmentActionOk: data.isAssignmentActionOk,
      isCalendarItemOk: data.isCalendarItemOk,
      toastType: 'success',
      msg: data.msg ? `(${data.msg})` : '',
    };
  },
};
