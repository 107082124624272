import {
  contractConverter,
  contractPrimaryDataConverter,
  updateContractDateConverter,
  updateContractLicensesConverter,
  updateContractServicesConverter,
} from 'data/converters/companyContract.converter';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import {
  TContractGet,
  TContractGetUi,
  TContractPost,
  TContractPostUi,
  TContractPrimaryDataPostUi,
  TUpdateContractDateUi,
  TUpdateContractLicenseUi,
  TUpdateContractServiceUi,
} from 'data/types/companyContract.type';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { authSplitApi } from 'redux/helpers/slice.helpers';

const COMPANY_CONTRACT = 'api/company-contracts';

export const companyContractApi = authSplitApi('companyContractApi', [
  'contracts',
  'contract',
]).injectEndpoints({
  endpoints: build => ({
    getContracts: build.query<
      TPageableDataWithContentUi<TContractGetUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        search?: string;
        companyIds?: string[];
      }
    >({
      query({ page, pageSize, sort, search, companyIds }) {
        const urlWithParams = COMPANY_CONTRACT?.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            sort: sort || '',
            ...(search && { search }),
            ...(companyIds?.length && { companies: companyIds }),
          },
        };
      },
      providesTags: ['contracts'],
      transformResponse: (data: TPageableDataWithContent<TContractGet[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(contract => contractConverter.fromDb(contract))
            : [],
        };
      },
    }),
    createContract: build.mutation<TContractPost, TContractPostUi>({
      query(body) {
        return {
          url: COMPANY_CONTRACT,
          method: 'POST',
          body: contractConverter.toDb(body),
        };
      },
      invalidatesTags: ['contracts'],
    }),
    getContractById: build.query<
      TContractGetUi,
      {
        id: string;
      }
    >({
      query({ id }) {
        return {
          url: COMPANY_CONTRACT.concat(`/${id}`),
          method: 'GET',
        };
      },
      providesTags: ['contract'],
      transformResponse: (data: TContractGet) => {
        return contractConverter.fromDb(data);
      },
    }),
    editContractPrimaryData: build.mutation<
      TContractGetUi,
      TContractPrimaryDataPostUi & { id: string }
    >({
      query(body) {
        return {
          url: `${COMPANY_CONTRACT}/${body.id}/primary-contract`,
          method: 'PATCH',
          body: contractPrimaryDataConverter.toDb(body),
        };
      },
      invalidatesTags: ['contract', 'contracts'],
    }),
    editContractName: build.mutation<
      TContractGetUi,
      { id: string; value: string }
    >({
      query({ id, value }) {
        return {
          url: `${COMPANY_CONTRACT}/${id}/name`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['contract', 'contracts'],
    }),
    editContractBillingPeriod: build.mutation<
      TContractGetUi,
      { id: string; value: string }
    >({
      query({ id, value }) {
        return {
          url: `${COMPANY_CONTRACT}/${id}/billing-period`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['contract'],
    }),
    editContractStartDate: build.mutation<
      TContractGetUi,
      TUpdateContractDateUi
    >({
      query(body) {
        const { id, value } = updateContractDateConverter.toDb(body);
        return {
          url: `${COMPANY_CONTRACT}/${id}/start-date`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['contract', 'contracts'],
    }),
    editContractEndDate: build.mutation<TContractGetUi, TUpdateContractDateUi>({
      query(body) {
        const { id, value } = updateContractDateConverter.toDb(body);
        return {
          url: `${COMPANY_CONTRACT}/${id}/end-date`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['contract', 'contracts'],
    }),
    addContractService: build.mutation<
      TContractGetUi,
      TUpdateContractServiceUi & { contractId: string }
    >({
      query({ contractId, ...body }) {
        return {
          url: `${COMPANY_CONTRACT}/${contractId}/services/add`,
          method: 'PATCH',
          body: updateContractServicesConverter.toDb(body),
        };
      },
      invalidatesTags: ['contract'],
    }),
    editContractService: build.mutation<
      TContractGetUi,
      TUpdateContractServiceUi & { contractId: string; serviceId: string }
    >({
      query({ contractId, serviceId, ...body }) {
        return {
          url: `${COMPANY_CONTRACT}/${contractId}/services/${serviceId}`,
          method: 'PATCH',
          body: updateContractServicesConverter.toDb(body),
        };
      },
      invalidatesTags: ['contract'],
    }),
    deleteContractServiceById: build.mutation<
      void,
      { contractId: string; serviceId: string }
    >({
      query({ contractId, serviceId }) {
        return {
          url: `${COMPANY_CONTRACT}/${contractId}/services/${serviceId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['contract'],
    }),
    addContractLicense: build.mutation<
      TContractGetUi,
      TUpdateContractLicenseUi & { contractId: string }
    >({
      query({ contractId, ...body }) {
        return {
          url: `${COMPANY_CONTRACT}/${contractId}/licenses/add`,
          method: 'PATCH',
          body: updateContractLicensesConverter.toDb(body),
        };
      },
      invalidatesTags: ['contract'],
    }),
    editContractLicense: build.mutation<
      TContractGetUi,
      TUpdateContractLicenseUi & { contractId: string; licenseId: string }
    >({
      query({ contractId, licenseId, ...body }) {
        return {
          url: `${COMPANY_CONTRACT}/${contractId}/licenses/${licenseId}`,
          method: 'PATCH',
          body: updateContractLicensesConverter.toDb(body),
        };
      },
      invalidatesTags: ['contract'],
    }),
    editContractStatus: build.mutation<
      TContractGetUi,
      { contractId: string; value: string }
    >({
      query({ contractId, value }) {
        return {
          url: `${COMPANY_CONTRACT}/${contractId}/contract-status`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['contract', 'contracts'],
    }),
    deleteContractLicenseById: build.mutation<
      void,
      { contractId: string; licenseId: string }
    >({
      query({ contractId, licenseId }) {
        return {
          url: `${COMPANY_CONTRACT}/${contractId}/licenses/${licenseId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['contract'],
    }),
    updateContractServiceOrder: build.mutation<
      TContractGet,
      { contractId: string; serviceId: string; value: number }
    >({
      query({ contractId, serviceId, value }) {
        return {
          url: `${COMPANY_CONTRACT}/${contractId}/services/${serviceId}/order`,
          method: 'PATCH',
          body: { value },
        };
      },
      async onQueryStarted({ contractId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const convertedData = contractConverter.fromDb(data);
        dispatch(
          companyContractApi.util.updateQueryData(
            'getContractById',
            { id: contractId },
            draft => {
              Object.assign(draft, convertedData);
            },
          ),
        );
      },
    }),
    updateContractLicenseOrder: build.mutation<
      TContractGet,
      { contractId: string; serviceId: string; value: number }
    >({
      query({ contractId, serviceId, value }) {
        return {
          url: `${COMPANY_CONTRACT}/${contractId}/licenses/${serviceId}/order`,
          method: 'PATCH',
          body: { value },
        };
      },
      async onQueryStarted({ contractId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const convertedData = contractConverter.fromDb(data);
        dispatch(
          companyContractApi.util.updateQueryData(
            'getContractById',
            { id: contractId },
            draft => {
              Object.assign(draft, convertedData);
            },
          ),
        );
      },
    }),
    editCompanyContractType: build.mutation<
      TContractGetUi,
      { contractId?: string; value: string }
    >({
      query({ contractId, value }) {
        return {
          url: `${COMPANY_CONTRACT}/${contractId}/type`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['contract', 'contracts'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetContractsQuery,
  useCreateContractMutation,
  useGetContractByIdQuery,
  useEditContractPrimaryDataMutation,
  useEditContractNameMutation,
  useEditContractBillingPeriodMutation,
  useAddContractServiceMutation,
  useEditContractServiceMutation,
  useDeleteContractServiceByIdMutation,
  useEditContractEndDateMutation,
  useEditContractStartDateMutation,
  useAddContractLicenseMutation,
  useEditContractLicenseMutation,
  useDeleteContractLicenseByIdMutation,
  useEditContractStatusMutation,
  useUpdateContractServiceOrderMutation,
  useUpdateContractLicenseOrderMutation,
  useEditCompanyContractTypeMutation,
} = companyContractApi;
