import React from 'react';
import { Col } from 'components/Grid/Grid';
import { TUserRoleUi } from 'data/types/user.types';
import { useAppSelector } from 'redux/store';
import moment from 'moment';
import { getDateTimeFormats } from 'helpers/utils';
import HeaderAlertMessage from '../components/HeaderAlertMessage';
import { SHeaderAlert } from '../HeaderAlert.style';
import { useCardExpiration } from './hooks/useCardExpiration';

const CardExpirationAlert = () => {
  const paymentInfo = useAppSelector(state => state.paymentInfoSlice);
  const role = useAppSelector(state => state.authSlice.user?.role);
  const { isExpired, nearToExpire } = useCardExpiration({
    expirationDate: paymentInfo?.pmValidDate,
  });
  const dateInMessage = moment(paymentInfo?.pmValidDate)
    .add(1, 'day')
    .format(getDateTimeFormats().dateFormat);
  const alertMessage = isExpired
    ? 'Your credit card is expired. Please update.'
    : `Your credit card will expire on ${dateInMessage}`;
  if (
    !nearToExpire ||
    role === TUserRoleUi.Technician ||
    !paymentInfo?.pmValidDate
  ) {
    return null;
  }

  return (
    <Col xs={24}>
      <SHeaderAlert
        type="error"
        message={
          <HeaderAlertMessage hasGoPaymentLink boldMessage={alertMessage} />
        }
      />
    </Col>
  );
};

export default CardExpirationAlert;
