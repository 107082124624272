import React from 'react';
import useForm from 'hooks/useForm';
import Modal from 'components/Modal/Modal';
import { changeticketAddModal } from 'redux/slices';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { TCreateTicketUi } from 'data/types/ticket.types';
import { useCreateTicketMutation } from 'redux/apiSlices/ticket.slice';
import { ModalWidthEnum } from 'components/Modal/Modal.types';

import { workFeedApi } from 'redux/apiSlices/workFeed.slice';
import { toast } from 'components/Notification';
import { CreateTicketForm } from './CreateTicketForm';

const CreateTicketModal = () => {
  const { form } = useForm();
  const dispatch = useAppDispatch();
  const { addTicketModal } = useAppSelector(state => ({
    addTicketModal: state.appSettings.addTicketModal,
  }));
  const userId = useAppSelector(state => state.authSlice.user?.id);

  const [createTicket, { isLoading }] = useCreateTicketMutation();

  const handleSubmitNewTicket = async () => {
    const formValues = form.getFieldsValue() as TCreateTicketUi;
    try {
      await form.validateFields();
      try {
        const ticket = await createTicket(formValues).unwrap();
        toast({
          description: (<>Ticket has been created successfully.<br />
            {process.env.REACT_APP_BASE_BACKEND_URL && 
              <a href={`/app/tickets/${ticket.id}`} target="_blank" rel="noopener noreferrer">Open Ticket</a>}
            </>),
          title: 'Creating ticket',
          type: 'success',
        });
        dispatch(changeticketAddModal(false));
        dispatch(workFeedApi.util.invalidateTags(['gauges']));
        if (formValues.assigneeId === userId) {
          dispatch(workFeedApi.util.invalidateTags(['workFeedNextTickets']));
        }
      } catch (e) {
        toast({
          description: (e as any)?.data.message,
          title: 'Creating ticket',
          type: 'error',
        });
      }
    } catch (error) {
      if ((error as any)?.errorFields) {
        form.scrollToField((error as any)?.errorFields[0].name[0], {
          behavior: 'smooth',
        });
      }
    }
  };
  const handleCancelNewTicket = () => {
    dispatch(changeticketAddModal(false));
  };

  return (
    <Modal
      visible={addTicketModal}
      onOk={handleSubmitNewTicket}
      onCancel={handleCancelNewTicket}
      title="Create a new ticket"
      okText="Create"
      cancelText="Cancel"
      destroyOnClose
      confirmLoading={isLoading}
      size={ModalWidthEnum.Large}
    >
      <CreateTicketForm form={form} />
    </Modal>
  );
};

export { CreateTicketModal };
