import { TContractType } from 'data/types/contractTypes.type';

const mockedContractTypes: TContractType[] = [
  {
    id: 'id1',
    name: 'Contract type1',
  },
  {
    id: 'id2',
    name: 'Contract type2',
  },
  {
    id: 'id3',
    name: 'Contract type3',
  },
];

export { mockedContractTypes };
