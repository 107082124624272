import { authSplitApi } from 'redux/helpers/slice.helpers';
import {
  contractTypeEditConverter,
  contractTypesConverter,
} from 'data/converters/contractTypes.converter';
import {
  TContractType,
  TContractTypeEditUI,
  TContractTypePostUI,
  TContractTypeUI,
} from 'data/types/contractTypes.type';

export const CONTRACT_TYPES_URL = 'api/company-contract-type';

export const contractTypesApi = authSplitApi('contractTypeApi', [
  'contractTypes',
]).injectEndpoints({
  endpoints: build => ({
    getContractTypes: build.query<TContractTypeUI[], void>({
      query: () => ({
        url: `${CONTRACT_TYPES_URL}/all`,
        method: 'GET',
      }),
      transformResponse: (data: TContractType[]) => {
        return data.map(type => contractTypesConverter.fromDb(type));
      },
      providesTags: ['contractTypes'],
    }),
    createContractType: build.mutation<TContractType, TContractTypePostUI>({
      query: name => {
        const body = contractTypesConverter.toDb(name);
        return {
          url: CONTRACT_TYPES_URL,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['contractTypes'],
    }),
    deleteContractType: build.mutation<void, string>({
      query: id => {
        return {
          url: `${CONTRACT_TYPES_URL}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['contractTypes'],
    }),
    editContractType: build.mutation<TContractType, TContractTypeEditUI>({
      query: type => {
        const { id } = type;
        return {
          url: `${CONTRACT_TYPES_URL}/${id}/name`,
          method: 'PATCH',
          body: contractTypeEditConverter.toDb(type),
        };
      },
      invalidatesTags: ['contractTypes'],
    }),
  }),
});

export const {
  useGetContractTypesQuery,
  useCreateContractTypeMutation,
  useDeleteContractTypeMutation,
  useEditContractTypeMutation,
} = contractTypesApi;
