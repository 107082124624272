import React from 'react';
import {
  TLinkProps,
  TParagraphProps,
  TTextProps,
  TTitleProps,
} from 'components/Typography/types';
import {
  SLink,
  SParagraph,
  SSecondaryText,
  SText,
  STitle,
} from 'components/Typography/style';

export function Title(props: TTitleProps) {
  return <STitle {...props} />;
}

export function Text(props: TTextProps) {
  return <SText {...props} />;
}
export function Link(props: TLinkProps) {
  return <SLink {...props} />;
}

export function SecondaryText(props: TTextProps) {
  return <SSecondaryText {...props} />;
}

export function Paragraph(props: TParagraphProps) {
  return <SParagraph {...props} />;
}
