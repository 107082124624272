import React, { FC, useEffect } from 'react';
import Sidebar from 'components/Sidebar/Sidebar';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  changeSidebarState,
  setTheme,
  changeTimeEntryAddModal,
} from 'redux/slices';
import { Outlet } from 'react-router-dom';
import { CreateTicketModal } from 'pages/private/Tickets/CreateTicket/CreateTicketModal';
import { CreateTaskModal } from 'pages/private/Tasks/CreateTask/CreateTaskModal';
import { AddContactModal } from 'pages/private/Customers/Contacts/AddContact/AddContactModal';
import CreateTimeEntry from 'pages/private/Timesheet/components/CreateTimeEntry/CreateTimeEntry';
import { AddProjectModal } from 'pages/private/Projects/AllProjects/AddProject/AddProjectModal';

import { TUserRoleUi } from 'data/types/user.types';
import { TLayout } from './Layout.types';
import { SContent, SContentLayout, SLayout, SSlider } from './Layout.style';
import Header from './Header/Header';
import SidebarSwitcher from './SidebarSwitcher/SidebarSwitcher';
import AddDeviceModal from '../../pages/private/Customers/Devices/AddDeviceModal/AddDeviceModal';

const Layout: FC<TLayout> = () => {
  const dispatch = useAppDispatch();
  const timeEntryAddModal = useAppSelector(
    state => state.appSettings.timeEntryModal,
  );
  const isAccessLimited = useAppSelector(
    state => state.paymentInfoSlice?.isAccessLimited,
  );
  const role = useAppSelector(state => state.authSlice.user?.role);
  const { sidebar, user } = useAppSelector(state => ({
    user: state.authSlice.user,
    sidebar: state.appSettings.sidebar,
  }));
  const onBreakpointChange = (breakPointChanged: boolean) => {
    if (window.innerWidth <= 767 && sidebar.collapsed) {
      dispatch(
        changeSidebarState({
          collapsed: false,
        }),
      );
    }
    dispatch(
      changeSidebarState({
        hidden: breakPointChanged,
      }),
    );
  };
  const onSidebarOutsideClick = () => {
    if (window.innerWidth <= 767 && !sidebar.hidden) {
      dispatch(
        changeSidebarState({
          hidden: true,
        }),
      );
    }
  };
  useEffect(() => {
    if (user) {
      if (user.theme.toLocaleLowerCase() === 'dark') {
        dispatch(setTheme('dark'));
      } else {
        dispatch(setTheme('light'));
      }
    }
  }, [user?.theme]);
  const hideSidebar = isAccessLimited && role === TUserRoleUi.Technician;
  return (
    <SLayout>
      {!hideSidebar && (
        <SSlider
          className="customScroll"
          breakpoint="md"
          collapsedWidth={0}
          width={sidebar.collapsed ? 85 : 284}
          trigger={null}
          collapsed={sidebar.hidden}
          onBreakpoint={onBreakpointChange}
        >
          <SidebarSwitcher />
          <Sidebar />
        </SSlider>
      )}
      <SContentLayout onClick={onSidebarOutsideClick}>
        <Header />
        <SContent>
          <Outlet />
        </SContent>
      </SContentLayout>
      <CreateTicketModal />
      <CreateTaskModal />
      <AddProjectModal />
      <AddContactModal />
      <AddDeviceModal />
      <CreateTimeEntry
        visible={timeEntryAddModal}
        onClose={() => dispatch(changeTimeEntryAddModal(false))}
      />
    </SLayout>
  );
};

export default Layout;
