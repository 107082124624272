import styled from 'styled-components';
import { Spacing } from 'core/CssVariables';
import Progress from 'antd/es/progress';
import { TProgress } from './types';

const SProgress = styled<typeof Progress>(Progress)`
  margin-top: ${(props: TProgress) => props.marginTop || Spacing.SpacingXS}px;
`;

export default SProgress;
