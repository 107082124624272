import { Colors } from 'core/CssVariables';
import {
  TAuthConverter,
  TConverter,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import {
  EBulkInvoiceType,
  InvoiceFilters,
  TBulkInvoicePost,
  TBulkInvoicePostUi,
  TInvoiceGet,
  TInvoiceProductItemUi,
  TInvoiceProductItem,
  TInvoiceGetUi,
  TInvoicePostUi,
  TInvoicePost,
  TInvoiceProductItemPost,
  TInvoiceStatusMapperColor,
  EInvoiceStatusUi,
  EInvoiceStatus,
  TInvoiceEditUi,
  TInvoiceEdit,
  TMergedInvoicePostUi,
  TMergedInvoicePost,
  TExportInvoiceResponseUi,
  TExportInvoiceResponse,
  EInvoiceActions,
  TInvoiceActionsUi,
} from 'data/types/invoice.type';
import {
  currencyFormatter,
  generateId,
  getDateTimeFormats,
} from 'helpers/utils';
import moment, { Moment } from 'moment';
import { getCategoryConverter } from './category.converter';
import { companyShortDataConverter } from './shortDataConverter';
import { workTypesConverters } from './workTypes.converters';

export const invoiceStatusConverter: TConverter<
  EInvoiceStatusUi,
  EInvoiceStatus
> = {
  fromDb: data => {
    return EInvoiceStatusUi[
      EInvoiceStatus[data] as keyof typeof EInvoiceStatusUi
    ];
  },
  toDb: data => {
    return EInvoiceStatus[
      EInvoiceStatusUi[data] as keyof typeof EInvoiceStatus
    ];
  },
};
export const invoiceActionsConverter: TFetchConverter<
  TInvoiceActionsUi,
  EInvoiceActions[]
> = {
  fromDb: data =>
    data.reduce((actionsObj, key) => ({ ...actionsObj, [key]: true }), {}),
};

export const invoiceProductItemConverter: TAuthConverter<
  TInvoiceProductItemUi,
  TInvoiceProductItemPost,
  TInvoiceProductItemUi,
  TInvoiceProductItem
> = {
  toDb: data => ({
    sourceType: data.sourceType,
    sourceId: data.sourceId,
    categoryId: data.category?.id,
    name: data.name,
    unitPrice: data.unitPrice,
    count: data.count,
    price: data.price,
    ...(data.id && { id: data.id }),
    ...(data.description && { description: data.description }),
  }),
  fromDb: data => {
    return {
      rowId: generateId(),
      id: data.id,
      sourceType: data.sourceType,
      sourceId: data.sourceId,
      name: data.name,
      unitPrice: data.unitPrice,
      count: data.count,
      price: data.price,
      category: data.category && getCategoryConverter.fromDb(data.category),
      description: data.description,
    };
  },
};

export const invoiceConverter: TAuthConverter<
  TInvoicePostUi,
  TInvoicePost,
  TInvoiceGetUi,
  TInvoiceGet
> = {
  fromDb: data => {
    const { dateFormat } = getDateTimeFormats();
    const startDate =
      data.startDate && moment(data.startDate).format(dateFormat);
    const endDate = data.endDate && moment(data.endDate).format(dateFormat);

    const range = endDate ? `${startDate} - ${endDate}` : `${startDate}`;
    return {
      id: data.id,
      creationDateTime:
        data.creationDateTime &&
        moment(data.creationDateTime).format(dateFormat),
      dueDate: data.dueDate,
      company: data.company && companyShortDataConverter.fromDb(data.company),
      name: data.name,
      range,
      products: data.products.length
        ? data.products.map(product =>
            invoiceProductItemConverter.fromDb(product),
          )
        : [],
      address: data.address,
      emailAddress: data.emailAddress,
      phoneNumber: data.phoneNumber || '',
      status: data.status && invoiceStatusConverter.fromDb(data.status),
      asInvoiceNumber: data.asInvoiceNumber,
      invoiceDate: data.invoiceDate,
      note: data.note,
      actions: invoiceActionsConverter.fromDb(data.actions),
      discount: data.discount ?? 0,
      subtotalAmount: currencyFormatter(data.subtotalAmount || 0),
      totalAmount: currencyFormatter(data.totalAmount || 0),
    };
  },
  toDb: data => {
    const dateFormatToDb = 'YYYY-MM-DD';
    return {
      invoiceName: data.invoiceName,
      companyId: data.companyId,
      startDate: data.range[0].format(dateFormatToDb),
      endDate: data.range[1].format(dateFormatToDb),
      invoiceDate: data.invoiceDate.format(dateFormatToDb),
      dueDate: data.dueDate.format(dateFormatToDb),
      products: data.products.length
        ? data.products.map(product =>
            invoiceProductItemConverter.toDb(product),
          )
        : [],
      phoneNumber: data.phoneNumber || '',
      address: data.address,
      emailAddress: data.emailAddress,
      note: data.note?.trim(),
      discount: data.discount,
    };
  },
};

export const bulkInvoiceCreateConverter: TCreateConverter<
  TBulkInvoicePostUi,
  TBulkInvoicePost
> = {
  toDb: data => {
    return {
      invoiceName: data.invoiceName,
      companyIds: data.companyIds,
      startDate: data.range.length ? data.range[0].format('YYYY-MM-DD') : '',
      endDate: data.range.length ? data.range[1].format('YYYY-MM-DD') : '',
      type: data.type,
      ...(data.type === EBulkInvoiceType.BILLABLE_TIME && {
        workTypes: data?.workTypes?.length
          ? data.workTypes.map(worktype => workTypesConverters.toDb(worktype))
          : [],
      }),
      ...(data.type === EBulkInvoiceType.CONTRACT && {
        contractTypeIds: data.contractTypeIds,
        includeUntypedContracts: data.includeUntypedContracts,
        mergeByContracts: data.mergeByContracts,
      }),
    };
  },
};

export const invoiceFilterConverter = (filters: {
  [key in InvoiceFilters]?: string[];
}) => {
  const invoiceFilterToDB = {
    ...(filters?.range?.[0] && {
      from: filters?.range?.[0],
    }),
    ...(filters?.range?.[1] && {
      to: filters?.range?.[1],
    }),
    ...(filters?.companyIds?.length && {
      [InvoiceFilters.COMPANY]: filters?.companyIds,
    }),
    ...(filters?.statuses?.length && {
      [InvoiceFilters.STATUS]: filters?.statuses,
    }),
  };
  return invoiceFilterToDB;
};

export const invoiceTimeEntryFilterConverter = (range: Moment[]) => {
  const invoiceFilterToDB = {
    ...(range?.[0] && {
      from: range?.[0].format('YYYY-MM-DD'),
    }),
    ...(range?.[1] && {
      to: range?.[1].format('YYYY-MM-DD'),
    }),
  };
  return invoiceFilterToDB;
};

export const invoiceStatusMapperColor: TInvoiceStatusMapperColor = {
  [EInvoiceStatusUi.Draft]: Colors.LightGrey,
  [EInvoiceStatusUi.Exported]: Colors.SecondaryColor,
  [EInvoiceStatusUi['Partially Paid']]: Colors.Orange,
  [EInvoiceStatusUi.Paid]: Colors.SuccessColor,
  [EInvoiceStatusUi.Sent]: Colors.InfoColor,
};

export const invoiceEditConverter: TCreateConverter<
  TInvoiceEditUi,
  TInvoiceEdit
> = {
  toDb: data => {
    const dateFormatToDb = 'YYYY-MM-DD';
    return {
      invoiceName: data.invoiceName,
      invoiceDate: data.invoiceDate && data.invoiceDate.format(dateFormatToDb),
      dueDate: data.invoiceDate && data.dueDate.format(dateFormatToDb),
      phoneNumber: data.phoneNumber || '',
      address: data.address,
      emailAddress: data.emailAddress,
      products: data.products.length
        ? data.products.map(product =>
            invoiceProductItemConverter.toDb(product),
          )
        : [],
      note: data.note?.trim(),
      discount: data.discount,
    };
  },
};

export const mergedInvoiceConverter: TCreateConverter<
  TMergedInvoicePostUi,
  TMergedInvoicePost
> = {
  toDb: data => ({
    mergedInvoiceData: invoiceConverter.toDb(data.mergedInvoiceData),
    mergedInvoices: data.mergedInvoices,
  }),
};

export const exportInvoiceResponseConverter: TFetchConverter<
  TExportInvoiceResponseUi,
  TExportInvoiceResponse
> = {
  fromDb: data => {
    return {
      msg:
        data.exported && data.saved
          ? `Invoice has been exported ${
              data.sent ? 'and sent' : ''
            } successfully`
          : `Invoice changes are saved, but failed to export (${data.msg})`,
      toastType: data.exported ? 'success' : 'warning',
    };
  },
};
