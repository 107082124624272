import { authSplitApi } from 'redux/helpers/slice.helpers';

const GO_NEXT = 'api/organizations/{resourceId}/onboard/next';

export const goNextApi = authSplitApi('goNext').injectEndpoints({
  endpoints: build => ({
    goNext: build.mutation({
      query(requestId) {
        return {
          url: GO_NEXT.replace('{resourceId}', `${requestId}`),
          method: 'POST',
        };
      },
      invalidatesTags: ['User'],
    }),
  }),
  overrideExisting: false,
});

export const { useGoNextMutation } = goNextApi;
