import { pagableDataConverter } from 'data/converters/pagableData.converters';
import { pslConverter } from 'data/converters/psl.converter';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { TPSLGet, TPSLGetUi, TPSLPost, TPSLPostUi } from 'data/types/psl.types';
import { authSplitApi } from 'redux/helpers/slice.helpers';

const PSL_URL = 'api/psl';

export const pslApi = authSplitApi('pslApi', ['psl']).injectEndpoints({
  endpoints: build => ({
    createPSL: build.mutation<TPSLPostUi, TPSLPost>({
      query(body) {
        return {
          url: PSL_URL,
          method: 'POST',
          body: pslConverter.toDb(body),
        };
      },
      invalidatesTags: ['psl'],
    }),
    getPSL: build.query<
      TPageableDataWithContentUi<TPSLGetUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        pslTypes?: string[];
        categoryIds?: string[];
        search?: string;
      }
    >({
      query({ page, pageSize, sort, pslTypes, search, categoryIds }) {
        const urlWithParams = PSL_URL.concat(`?page=${page}&size=${pageSize}`);
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(pslTypes?.length && { pslTypes }),
            ...(categoryIds?.length && { categoryIds }),
            ...(search && { search }),
          },
        };
      },
      providesTags: ['psl'],
      transformResponse: (data: TPageableDataWithContent<TPSLGet[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(psl => pslConverter.fromDb(psl))
            : [],
        };
      },
    }),

    editPSLName: build.mutation<TPSLGetUi, { pslId: string; value: string }>({
      query({ pslId, value }) {
        return {
          url: `${PSL_URL}/${pslId}/name`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['psl'],
    }),
    editPSLPrice: build.mutation<TPSLGetUi, { pslId: string; value: number }>({
      query({ pslId, value }) {
        return {
          url: `${PSL_URL}/${pslId}/price`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['psl'],
    }),
    editPSLCost: build.mutation<TPSLGetUi, { pslId: string; value: number }>({
      query({ pslId, value }) {
        return {
          url: `${PSL_URL}/${pslId}/cost`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['psl'],
    }),
    editPSLPartnumber: build.mutation<
      TPSLGetUi,
      { pslId: string; value: number }
    >({
      query({ pslId, value }) {
        return {
          url: `${PSL_URL}/${pslId}/part-number`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['psl'],
    }),
    editPSLCategory: build.mutation<
      TPSLGetUi,
      { pslId: string; value: string }
    >({
      query({ pslId, value }) {
        return {
          url: `${PSL_URL}/${pslId}/category`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['psl'],
    }),
    deletePSLById: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `${PSL_URL}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['psl'],
    }),
    editPSLDescription: build.mutation<
      TPSLGetUi,
      { pslId: string; value: string }
    >({
      query({ pslId, value }) {
        return {
          url: `${PSL_URL}/${pslId}/description`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['psl'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreatePSLMutation,
  useGetPSLQuery,
  useEditPSLNameMutation,
  useEditPSLPartnumberMutation,
  useEditPSLPriceMutation,
  useEditPSLCostMutation,
  useEditPSLCategoryMutation,
  useDeletePSLByIdMutation,
  useEditPSLDescriptionMutation,
} = pslApi;
