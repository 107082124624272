import { EStatusUi, TCreatedType } from 'data/types/generalDataTypes';
import {
  TInactiveQueue,
  TInactiveQueueUi,
  TQueue,
  TQueueIssueType,
  TQueueIssueTypeUi,
  TQueueSlaTargetsUpdate,
  TQueueSlaTargetsUpdateUi,
  TQueueUi,
  TQueueUserGroup,
  TQueueUserGroupUi,
} from 'data/types/queues.types';
import { TCreateConverter, TFetchConverter } from '../types/converter.types';
import {
  slaTargetsConverter,
  slaTargetsTypesConverter,
  targetsTypesConverter,
} from './sla.converter';
import { emailConverter } from './email.converters';

const queueIssuesConverter: TFetchConverter<
  TQueueIssueTypeUi,
  TQueueIssueType
> = {
  fromDb: data => {
    return {
      name: data.name,
      id: data.id,
    };
  },
};
const queueUserGroupsConverter: TFetchConverter<
  TQueueUserGroupUi,
  TQueueUserGroup
> = {
  fromDb: data => {
    return {
      name: data.name,
      id: data.id,
    };
  },
};

export const queueConverter: TFetchConverter<TQueueUi, TQueue> = {
  fromDb: data => {
    let issuesString = '';
    let userGroupsString = '';
    if (data.issueTypes?.length) {
      data.issueTypes.forEach((item, index) => {
        if (index !== data.issueTypes.length - 1) {
          issuesString += `${item.name}, `;
        } else {
          issuesString += item.name;
        }
      });
    }
    if (data.userGroups?.length) {
      data.userGroups.forEach((item, index) => {
        if (index !== data.userGroups.length - 1) {
          userGroupsString += `${item.name}, `;
        } else {
          userGroupsString += item.name;
        }
      });
    }
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      slaTargets: slaTargetsConverter.fromDb(data.slaTargets),
      type: data.systemCreated ? TCreatedType.DEFAULT : TCreatedType.CREATED,
      status: data.hidden ? EStatusUi.INACTIVE : EStatusUi.ACTIVE,
      issuesString,
      userGroupsString,
      issueTypes: data.issueTypes?.length
        ? data.issueTypes.map(issue => queueIssuesConverter.fromDb(issue))
        : [],
      userGroups: data.userGroups?.length
        ? data.userGroups.map(userGroup =>
            queueUserGroupsConverter.fromDb(userGroup),
          )
        : [],
      cpDefault: data.cpDefault,
      order: data.order,
      rmmDefault: data.rmmDefault,
      special: data.special,
      emailSuffix: data.emailSuffix,
      integrationEmailAddress: data.integrationEmailAddress,
      attachedEmails: data.attachedEmails?.length
        ? emailConverter.fromDb(data.attachedEmails)
        : [],
    };
  },
};

export const inactiveQueueConverter: TCreateConverter<
  TInactiveQueueUi,
  TInactiveQueue
> = {
  toDb: data => {
    return {
      id: data.id,
      hidden: data.status !== EStatusUi.INACTIVE,
    };
  },
};

export const queueSlaPatchConverter: TCreateConverter<
  TQueueSlaTargetsUpdateUi,
  TQueueSlaTargetsUpdate
> = {
  toDb: data => {
    return {
      slaType: slaTargetsTypesConverter.toDb(data.type),
      priority: targetsTypesConverter.toDb(data.priority),
      value: +data.value,
    };
  },
};
