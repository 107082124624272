import * as React from 'react';
import {
  TFormFieldTypes,
  TFormFieldsMap,
  TFormFieldProps,
  TFormItemProps,
} from 'components/Form/FormItem/types';
import Checkbox from 'components/Checkbox';
import { Input, Password } from 'components/Input';

import PasswordProgress from 'components/PasswordProgress';
import Select from 'antd/lib/select';
import MultiInput from 'components/MultiInput/MultiInput';
import { ChangeEvent } from 'react';
import CustomMultiSelect from 'components/CustomMultiSelect/CustomMultiSelect';
import SFormItem from './style';

const formFieldsMap: TFormFieldsMap = {
  [TFormFieldTypes.INPUT]: { Component: Input },
  [TFormFieldTypes.PASSWORD]: { Component: PasswordProgress },
  [TFormFieldTypes.POSSWORD_CONFIRM]: { Component: Password },
  [TFormFieldTypes.CHECKBOX]: { Component: Checkbox },
  [TFormFieldTypes.SELECT]: { Component: Select },
  [TFormFieldTypes.MULTIINPUT]: { Component: MultiInput, custom: true },
  [TFormFieldTypes.CUSTOM_MULTI_SELECT]: {
    Component: CustomMultiSelect,
    custom: true,
  },
};

export const FormField = (props: TFormFieldProps) => {
  const {
    fieldType,
    formItemLabel,
    title = '',
    label,
    name,
    selectOptions,
    rules,
    placeholder,
    onChange,
    setCustomFieldValue,
    valuePropName,
  } = props;
  if (!fieldType || !props) return null;
  const { Component, custom } = formFieldsMap[fieldType];

  const onHandleChange = (e: ChangeEvent<HTMLInputElement> | any) => {
    if (custom && setCustomFieldValue) {
      setCustomFieldValue(name as string, e);
    } else if (onChange) {
      onChange(e as ChangeEvent<HTMLInputElement>);
    }
  };
  return (
    <SFormItem
      rules={rules}
      name={name}
      valuePropName={valuePropName}
      label={formItemLabel}
    >
      <Component
        selectOptions={selectOptions}
        title={title}
        label={label}
        onChange={onHandleChange}
        placeholder={placeholder}
      />
    </SFormItem>
  );
};

export const FormItem = (props: TFormItemProps) => {
  return <SFormItem {...props} />;
};
