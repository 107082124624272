export enum PriorityEnum {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Urgent = 'Urgent',
}

export enum PriorityEnumUi {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Urgent = 'Urgent',
}

export enum TSlaTargetsTypes {
  'Resolution SLA' = 'Resolution SLA',
  'First Response SLA' = 'First Response SLA',
}

export enum TSlaTargetsTypesUi {
  'Resolution SLA' = 'Resolution SLA',
  'First Response SLA' = 'First Response SLA',
}

export type TTargetsUi = {
  [key in PriorityEnumUi]: number;
};

export type TTargets = {
  [key in PriorityEnumUi]: number;
};

export type TSlaTargets = {
  [key in TSlaTargetsTypesUi]: {
    type: TSlaTargetsTypesUi;
    targets: TTargets;
  };
};
export type TSlaTargetsUi = {
  [key in TSlaTargetsTypesUi]: {
    type: TSlaTargetsTypesUi;
    priorities: TTargetsUi;
  };
};

export enum RemainingEnum {
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
}
export enum RemainingEnumUi {
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
}
export type TSlaRemaining = {
  [key in RemainingEnum]: number;
};
export type TSlaRemainingUi = {
  [key in RemainingEnumUi]: number;
};

export type TSla = {
  type: TSlaTargetsTypes;
  status: string;
  remaining: TSlaRemaining;
  target: number;
};
export type TSlaUi = {
  type: TSlaTargetsTypesUi;
  status: string;
  remaining: TSlaRemainingUi;
  target: number;
};
