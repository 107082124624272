import React, { FC } from 'react';
import { MenuItem } from 'components/Menu/Menu';
import { SSettingMenu } from 'components/Table/Table.style';
import { TTableSettings } from 'components/Table/Table.types';
import Checkbox from 'components/Checkbox';

type TTableSettingsMenuProps = {
  menuItemsData: TTableSettings[];
  onMenuItemClick: (item: TTableSettings) => void;
};

const TableSettingsMenu: FC<TTableSettingsMenuProps> = ({
  menuItemsData,
  onMenuItemClick,
}) => {
  return (
    <SSettingMenu>
      {menuItemsData.map(item => {
        return (
          <MenuItem key={item.title} className="p-0 m-0">
            {item.title && (
              <Checkbox
                onClick={() => onMenuItemClick(item)}
                label={item.title}
                checked={item.show}
              />
            )}
          </MenuItem>
        );
      })}
    </SSettingMenu>
  );
};

export default TableSettingsMenu;
