import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'components/Grid/Grid';
import { Colors } from 'core/CssVariables';
import { useUploadFileMutation } from 'redux/apiSlices';
import { SUpload } from './ImageUpload.styles';
import { Text } from '../Typography';
import { Icon, TIconNames } from '../Icon';
import ToolTip from '../ToolTip/ToolTip';
import { Spinner } from '../Spinner/Spinner';

export type TImageUpload = {
  onChange: (url: string) => void;
  value?: string;
  viewLabel?: boolean;
  width?: number;
  height?: number;
  alt?: string;
  isLoading?: boolean;
  error?: string;
  size?: number;
};

export const errorType = {
  sizeError: 'Please upload images up to 10MB.',
  formatError: 'Please upload JPEG or PNG files.',
};

const Upload: React.FC<TImageUpload> = ({
  onChange,
  value: defaultValue,
  width,
  height,
  viewLabel = true,
  alt = '',
  isLoading,
  size = 1,
  error,
}) => {
  const uploadedImage = useRef<HTMLImageElement>(null);
  const imageUploader = useRef(null);
  const [imageError, setImageError] = useState('');
  const [uploadFile, { data, isLoading: uploadLoading }] =
    useUploadFileMutation();
  const [value, setValue] = useState(defaultValue || '');
  const loading = isLoading || uploadLoading;
  const errorMessage = imageError || error;
  useEffect(() => {
    if (data) {
      onChange(data);
      setValue(data);
    }
  }, [data]);

  const validateFile = (file: File) => {
    if (file?.size > size * 10485760) {
      setImageError(errorType.sizeError);
      return false;
    }

    if (!file?.type.match('image/jpeg|image/png')) {
      setImageError(errorType.formatError);
      return false;
    }
    setImageError('');
    return true;
  };

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (!file || !validateFile(file)) {
        return;
      }
      const reader = new FileReader();
      const formData = new FormData();
      const { current } = uploadedImage;
      if (current) {
        reader.onload = event => {
          if (
            event?.target?.result &&
            typeof event?.target?.result === 'string'
          ) {
            current.src = event.target.result;
          }
        };
        reader.readAsDataURL(file);

        formData.append('file', file);
        uploadFile(formData);
      }
    }
  };
  return (
    <Row align="middle" gutter={[16, 16]}>
      <Col>
        <ToolTip title={errorMessage} placement="right">
          <SUpload
            width={width}
            height={height}
            error={!!errorMessage}
            data-testid="uploader"
          >
            {loading && (
              <Spinner
                size="default"
                className="upload-loading"
                data-testid="loading-icon"
              />
            )}
            <div className="upload-wrapper">
              <label htmlFor="image" className="upload-btn pointer">
                <input
                  data-testid="image-uploader"
                  type="file"
                  accept=".jpeg, .jpg, .png"
                  name="image"
                  id="image"
                  onChange={handleImageUpload}
                  ref={imageUploader}
                />
                {!value && !loading && (
                  <Icon
                    icon={TIconNames.UPLOAD}
                    color={Colors.LightGrey}
                    data-testid="upload-icon"
                  />
                )}
                <img
                  className="upload-image"
                  ref={uploadedImage}
                  {...(value && { src: value })}
                  alt={alt}
                />
              </label>
            </div>
          </SUpload>
        </ToolTip>
      </Col>
      {viewLabel && (
        <Col>
          <Text fontlevel={5} className="upload-text">
            Upload logo (optional)
          </Text>
        </Col>
      )}
    </Row>
  );
};

export default Upload;
