import React, { FC } from 'react';
import { TAvatar } from './Avatar.types';
import SAvatar from './Avatar.style';

const Avatar: FC<TAvatar> = props => {
  const { size = 32 } = props;
  return (
    <SAvatar
      shape="circle"
      size={size}
      gap={4}
      data-testid="avatar"
      {...props}
    />
  );
};

export default Avatar;
