import styled from 'styled-components';
import { Colors, FontSizes, Spacing } from 'core/CssVariables';

export const SInviteStep = styled.div`
  .title {
    margin-bottom: ${Spacing.SpacingXXXL}px;
  }
  .with-close {
    display: flex;
    align-items: center;

    .ant-form-item {
      width: 100%;
      margin-right: 14px;
    }
  }
  .link-btn {
    color: ${Colors.TextColorPrimary};
    font-size: ${FontSizes.FontXS}px;
  }
`;
