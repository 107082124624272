import { TIconNames } from 'components/Icon';
import { Colors } from 'core/CssVariables';
import { TFetchConverter } from 'data/types/converter.types';
import {
  TGaugeContentMapper,
  TGaugeMetaData,
  TGaugeMetaDataUi,
  TGauges,
  GaugesColorEnum,
  TGaugesMapperColor,
  GaugesEnum,
  TGaugesUi,
  TTooltipTitleMapper,
  TooltipTitlesEnum,
} from 'data/types/gauges.types';

export const gaugesMapperColor: TGaugesMapperColor = {
  [GaugesColorEnum.INFO]: Colors.SuccessColor,
  [GaugesColorEnum.WARNING]: Colors.WarningColor,
  [GaugesColorEnum.ALERT]: Colors.ErrorColor,
  [GaugesColorEnum.TEXT]: Colors.SecondaryColor,
};

export const tooltipTitleMapper: TTooltipTitleMapper = {
  [TooltipTitlesEnum.openTickets]: {
    collapsed: 'Open tickets',
    expanded: 'Total',
  },
  [TooltipTitlesEnum.totalNewTicket]: {
    collapsed: 'New tickets',
    expanded: 'Total',
  },
  [TooltipTitlesEnum.frSlaFailedTickets]: {
    collapsed: 'FR SLA Failed',
    expanded: 'FR SLA Failed',
  },
  [TooltipTitlesEnum.overdueTickets]: {
    collapsed: 'Overdue tickets',
    expanded: 'Total',
  },
  [TooltipTitlesEnum.overdueTicketsMoreThanHour]: {
    collapsed: 'More than an hour',
    expanded: 'More than an hr',
  },
  [TooltipTitlesEnum.overdueTasks]: {
    collapsed: 'Overdue tasks',
    expanded: 'Total',
  },
  [TooltipTitlesEnum.overdueTasksMoreThanHour]: {
    collapsed: 'More than an hour',
    expanded: 'More than an hr',
  },
  [TooltipTitlesEnum.closeToFailSlas]: {
    collapsed: 'Tickets near SLA failure',
    expanded: 'Close to fail SLA',
  },
  [TooltipTitlesEnum.avgScore]: {
    collapsed: 'Average urgency',
    expanded: 'Avg score',
  },
  [TooltipTitlesEnum.customersNeedingAttention]: {
    collapsed: 'Customers needing attention',
    expanded: 'Customers needing attention',
  },
  [TooltipTitlesEnum.failedSlas]: {
    collapsed: 'Tickets which failed SLA',
    expanded: 'SLA failed tickets',
  },
};

export const gaugeContentMapper: TGaugeContentMapper = {
  [GaugesEnum.OPEN_TICKETS]: {
    name: 'Open Tickets',
    description:
      'Open tickets gauge shows the total number of open and new tickets present in Zest  at the moment',
    icon: null,
  },
  [GaugesEnum.NEW_TICKETS]: {
    name: 'New Tickets',
    description:
      'New tickets gauge tracks the state of new tickets in Zest at the moment and will be green - 0 new tickets, yellow - at least 1 new ticket, red - at least 1 FR SLA failed ticket',
    icon: TIconNames.TICKET,
  },
  [GaugesEnum.OVERDUE_TICKETS]: {
    name: 'Overdue Tickets',
    description:
      'Overdue tickets gauge tracks how many overdue tickets there are in Zest at the moment and will be green - 0 overdue tickets, yellow and red when there is at least 1 overdue ticket  for less/more than an hour respectively',
    icon: TIconNames.OVERDUE_TICKET,
  },
  [GaugesEnum.OVERDUE_TASKS]: {
    name: 'Overdue Tasks',
    description:
      'Overdue tasks gauge tracks how many overdue tasks there are in Zest at the moment and will be green - 0 overdue tasks, yellow and red when there is at least 1 overdue task for less/more than an hour respectively',
    icon: TIconNames.OVERDUE_TASK,
  },
  [GaugesEnum.SLA_CLOSE_TO_FAIL]: {
    name: 'SLA Health',
    description:
      'SLA health gauge monitors the Rez SLA health of the tickets in Zest and will be green - no tickets near SLA failure, yellow - ticket has less than 8 hrs to meet SLA, red - ticket has failed Rez SLA',
    icon: TIconNames.SLA,
  },
  [GaugesEnum.AVG_URGENCY]: {
    name: 'Average Urgency',
    description:
      'Average urgency gauge tracks the average urgency score of open and new tickets and will be green, yellow, red when score is below 5, between 5 and 8, above 8 respectively',
    icon: TIconNames.URGENCY,
  },
  [GaugesEnum.CUSTOMERS_NEEDING_INTERACTION]: {
    name: 'Customer Health',
    description:
      "Customer health gauge helps to monitor relationships with customer and track block hours. Customer is considered healthy if it has non overdue scheduled interaction. Also, for the block hours enabled customers the remaining hours should be at least 10% and hours shouldn't be expired",
    icon: TIconNames.CUSTOMER_HEALTH,
  },
};

export const gaugeMetaDataConverter: TFetchConverter<
  TGaugeMetaDataUi,
  TGaugeMetaData
> = {
  fromDb: data => {
    return {
      title: tooltipTitleMapper[data.key].collapsed,
      color: gaugesMapperColor[data.severity],
      value: data.value,
      expanedTitle: tooltipTitleMapper[data.key].expanded,
    };
  },
};

export const gaugesConverter: TFetchConverter<TGaugesUi, TGauges> = {
  fromDb: data => {
    return {
      type: data.type,
      color: gaugesMapperColor[data.severity],
      gaugeContent: gaugeContentMapper[data.type],
      metaData: data.metaData
        ? data.metaData.map(item => gaugeMetaDataConverter.fromDb(item))
        : [],
    };
  },
};

export const allGaugesConverter: TFetchConverter<
  TGaugesUi[],
  { [key in GaugesEnum]: TGauges }
> = {
  fromDb: data => {
    const gaugeOrder = Object.values(GaugesEnum);
    return Object.keys(data).length
      ? gaugeOrder.map((item: GaugesEnum) => gaugesConverter.fromDb(data[item]))
      : [];
  },
};
