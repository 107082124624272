import bigDecimal from 'js-big-decimal';
import moment, { Moment } from 'moment';
import { ceil } from 'lodash';
import TagManager from '@sooro-io/react-gtm-module';
import { TAvailableHour } from 'pages/Onboarding/WeekDays/WeekDays.type';
import { ECountryUI } from 'data/types/organization.types';
import { zestLocalStorage } from 'helpers/storage';
import {
  australiaTimeZoneOptions,
  canadaTimeZoneOptions,
  ukTimeZoneOptions,
  usTimeZoneOptions,
  newZealandTimeZoneOptions,
} from '../pages/Onboarding/static/static';

export const generateSpaceClassNames = (array: number[]) => {
  let classNames = ``;
  array.forEach((n, i) => {
    classNames += `
            .mx-${i} {
                margin: 0 ${n}px !important;
            }
            .my-${i} {
                margin: ${n}px 0 !important;
            }
            .m-${i} {
                margin: ${n}px !important;
            }
            .mt-${i} {
                margin-top: ${n}px !important;
            }
            .mb-${i} {
                margin-bottom: ${n}px !important;
            }
            .ml-${i} {
                margin-left: ${n}px !important;
            }
            .mr-${i} {
                margin-right: ${n}px !important;
            }
            .pt-${i} {
                padding-top: ${n}px !important;
            }
            .pb-${i} {
                padding-bottom: ${n}px !important;
            }
            .pl-${i} {
                padding-left: ${n}px !important;
            }
            .pr-${i} {
                padding-right: ${n}px !important;
            }
            .px-${i} {
                padding: 0 ${n}px !important;
            }
            .py-${i} {
                padding: ${n}px 0 !important;
            }
            .p-${i} {
                padding: ${n}px !important;
            }
        `;
  });

  return classNames;
};

export const removeEmptyWeekDays = (arr: TAvailableHour[]) => {
  return arr.filter(hour => hour.start && hour.end);
};

export const getDateWithCorrectZone = (
  firstDate: string,
  secondDate: string,
) => {
  const zoneOffset =
    new Date(firstDate).getTime() - new Date(secondDate).getTime();
  const newDate = new Date();
  return new Date(newDate.getTime() - zoneOffset);
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const formatterUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // INFO: These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const handleDecimalValues = (
  value: string,
  decimalsCount: number,
): string => {
  const indexOfPoint = value.indexOf('.');
  if (indexOfPoint >= 1) {
    const sliceEnd = decimalsCount
      ? indexOfPoint + decimalsCount + 1
      : indexOfPoint;
    const decimalValue = value.slice(0, sliceEnd);
    return decimalValue;
  }
  return value;
};

export const calculateDecimals = {
  multiply: (num1: number | string, num2: number | string): number => {
    return +bigDecimal.multiply(num1, num2);
  },
  add: (num1: number | string, num2: number | string): number => {
    return +bigDecimal.add(num1, num2);
  },
  subtract: (num1: number | string, num2: number | string): number => {
    return +bigDecimal.subtract(num1, num2);
  },
  ceilAfterTwoDecimal: (num: number): number => {
    return ceil(num, 2);
  },
  sumByObjKey: (array: { [key: string]: unknown }[], key: string): number => {
    return array.reduce((acc, curr) => {
      acc = +bigDecimal.add(curr[key], acc);
      return acc;
    }, 0);
  },
};

export const generateId = () =>
  Math.random().toString(36).substring(2) + new Date().getTime().toString(36);

export const yearAndQuarterToMoment = (string: string): Moment =>
  moment()
    .year(Number(string?.split('-')[0]))
    .quarter(Number(string?.split('-')[1]));

export const formatMapper: Record<
  ECountryUI,
  {
    locale: string;
    currency: string;
    dateFormat: string;
    dateTimeFormat: string;
    timeFormat: string;
    currencySign: string;
  }
> = {
  [ECountryUI.USA]: {
    locale: 'en-US',
    currency: 'USD',
    currencySign: '$',
    dateFormat: 'MM/DD/YYYY',
    dateTimeFormat: 'MM/DD/YYYY hh:mm A',
    timeFormat: 'hh:mm A',
  },
  [ECountryUI.UK]: {
    locale: 'en-GB',
    currency: 'GBP',
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'DD/MM/YYYY HH:mm ',
    timeFormat: 'HH:mm',
    currencySign: '£',
  },
  [ECountryUI.AUSTRALIA]: {
    locale: 'en-AU',
    currency: 'AUD',
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'DD/MM/YYYY hh:mm A',
    timeFormat: 'hh:mm A',
    currencySign: '$',
  },
  [ECountryUI.CANADA]: {
    locale: 'en-CA',
    currency: 'CAD',
    dateFormat: 'YYYY/MM/DD',
    dateTimeFormat: 'YYYY/MM/DD hh:mm A',
    timeFormat: 'hh:mm A',
    currencySign: '$',
  },
  [ECountryUI.NEW_ZEALAND]: {
    locale: 'en-NZ',
    currency: 'NZD',
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'DD/MM/YYYY hh:mm A',
    timeFormat: 'hh:mm A',
    currencySign: '$',
  },
};

export const countryTimezoneMapper = (country: ECountryUI | undefined) => {
  if (!country) return [];

  let timezones: { label: string; value: string }[] = [];
  switch (country) {
    case ECountryUI.USA:
      timezones = [...usTimeZoneOptions];
      break;
    case ECountryUI.UK:
      timezones = [...ukTimeZoneOptions];
      break;
    case ECountryUI.CANADA:
      timezones = [...canadaTimeZoneOptions];
      break;
    case ECountryUI.AUSTRALIA:
      timezones = [...australiaTimeZoneOptions];
      break;
    case ECountryUI.NEW_ZEALAND:
      timezones = [...newZealandTimeZoneOptions];
      break;
    default:
      break;
  }
  if (process.env.REACT_APP_TIMEZONE) {
    timezones.push({
      label: 'Asia/Yerevan',
      value: 'Asia/Yerevan',
    });
  }
  return timezones;
};

export const getDateTimeFormats = () => {
  const country =
    (zestLocalStorage.getItem('user')?.organization?.country as ECountryUI) ||
    ECountryUI.USA;
  return {
    dateFormat: formatMapper[country].dateFormat,
    dateTimeFormat: formatMapper[country].dateTimeFormat,
    timeFormat: formatMapper[country].timeFormat,
  };
};
export const currencyFormatter = (currency: number) => {
  const country =
    (zestLocalStorage.getItem('user')?.organization?.country as ECountryUI) ||
    ECountryUI.USA;
  return new Intl.NumberFormat(formatMapper[country].locale, {
    style: 'currency',
    currency: formatMapper[country].currency,
  }).format(currency);
};

export const getCurrencySign = () => {
  const country =
    (zestLocalStorage.getItem('user')?.organization?.country as ECountryUI) ||
    ECountryUI.USA;
  return formatMapper[country].currencySign;
};

export const generateActionsToastDescription = (
  ticketAction: string,
  errorDescription: string,
  isAssignmentActionOk: boolean,
  isCalendarItemOk: boolean,
) => {
  if (isAssignmentActionOk && isCalendarItemOk) {
    return `${ticketAction} completed successfully!`;
  }
  if (isAssignmentActionOk && !isCalendarItemOk) {
    return `${ticketAction} completed successfully, but failed to update calendar ${errorDescription}'`;
  }
  if (!isAssignmentActionOk && isCalendarItemOk) {
    return `Calendar updated successfully, but ${ticketAction} failed ${errorDescription}`;
  }
  return `Failed to ${ticketAction} and update calendar ${errorDescription}`;
};


export const sendGTMDataLayer = (dataLayer: Record<string, unknown>) =>
{
  if (process?.env.REACT_APP_ENVIRONMENT === 'prod') {
    TagManager.dataLayer({
      dataLayer
    });
  }
}

