import appSettingsSlice from './appSettings.slice';
import authSlice from './auth.slice';
import filtersSlice from './filters.slice';
import paymentInfoSlice from './paymentInfo.slice';

export { appSettingsSlice } from './appSettings.slice';
export const {
  setTheme,
  changeSidebarState,
  changeHeaderCollapsedState,
  setHeaderHeight,
  changeticketAddModal,
  changeTaskAddModal,
  changeContactAddModal,
  changeTimeEntryAddModal,
  changeProjectAddModal,
  setCurrentEntity,
  changeTimeTrackerModal,
  setRedirectWorkfeed,
  changeDeviceAddModal,
} = appSettingsSlice.actions;
export const {
  setUser,
  removeUser,
  setLogedInfirebase,
  setTimeZone,
  setUserRole,
} = authSlice.actions;

export const { setStoredFilters, removeStoredFiltersByPath, removeAllFilters } =
  filtersSlice.actions;

export const { setPaymentInfo, removePaymentInfo } = paymentInfoSlice.actions;
