import React, { useMemo } from 'react';
import LabelWithTag from 'components/LabelSelectOptions/LabelWithTag';
import { TOptionType } from 'data/types/generalDataTypes';
import { useGetContactsForOptionsQuery } from 'redux/apiSlices/contact.slice';

type TUseCompanyContactOptionsProps = {
  companyIds?: string[];
  skip?: boolean;
  sort?: string;
  withoutTag?: boolean;
};

const useCompanyContactOptionsCompact = ({
  companyIds = [],
  skip,
  sort,
  withoutTag,
}: TUseCompanyContactOptionsProps) => {
  const {
    data: contactsList,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetContactsForOptionsQuery(
    {
      sort: sort || 'name,ASC',
      filters: {
        ...(companyIds?.length && { companies: companyIds }),
      },
    },

    {
      ...(skip && { skip }),
    },
  );

  const contactsOptions: TOptionType[] = useMemo(
    () =>
      (contactsList || []).map(
        ({ id, name, isMainContact, isVip, isActive, isManaged }) => ({
          value: id,
          label: (
            <LabelWithTag
              withoutTag={withoutTag}
              title={name}
              isVip={isVip}
              isMainContact={isMainContact}
              notActive={!isActive}
              notManaged={!isManaged}
              key={name}
            />
          ),
        }),
      ),
    [contactsList],
  );

  return { isLoading, isFetching, isSuccess, contactsOptions };
};

export default useCompanyContactOptionsCompact;
