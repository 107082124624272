import { FontSizes, Screens, Spacing } from 'core/CssVariables';
import styled from 'styled-components';

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  @media (max-width: ${Screens.ScreensMD}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FilterWrapperItem = styled.div<{ width?: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: ${({ width }) => width || '200px'};
  margin-right: ${Spacing.SpacingSM}px;

  label {
    color: ${({ theme }) => theme.secondaryColor};
    font-size: ${FontSizes.FontXS}px;
  }
  @media (max-width: ${Screens.ScreensMD}) {
    width: 100%;
  }
`;

export { FilterWrapper, FilterWrapperItem };
