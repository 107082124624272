import {
  ContactActiveStatuses,
  ContactFilters,
  ContactVipStatuses,
  ECpUserRoles,
  ECpUserRolesUi,
  TContactCsvPreview,
  TContactCsvPreviewUi,
  TContactPersonalAddressEdit,
  TContactPersonalAddressEditUi,
  TContactPhoneEdit,
  TContactPhoneEditUi,
  TContactPost,
  TContactPostUi,
  TContactsForOptionsGet,
  TContactsForOptionsGetUi,
  TContactsGet,
  TContactsGetUi,
  TCpUser,
  TCpUserUi,
  TCsvPreviewFailedContact,
  TCsvPreviewFailedContactUi,
  TGrantCpLoginPost,
  TGrantCpLoginPostUi,
  TUpdateContactActive,
  TUpdateContactActiveUi,
  TUpdateContactLocation,
  TUpdateContactLocationUi,
  TUpdateContactManage,
  TUpdateContactManageUi,
  TUpdateCpRole,
  TUpdateCpRoleUi,
} from 'data/types/contact.type';
import {
  TAuthConverter,
  TConverter,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import { companyShortDataConverter } from './shortDataConverter';
import { locationConverter } from './location.converter';
import { DeviceManagedStatuses } from '../types/devicesEnum.type';

export const cpRoleConverter: TConverter<ECpUserRolesUi, ECpUserRoles> = {
  toDb: data => {
    const rolesMapper: { [key in ECpUserRolesUi]: ECpUserRoles } = {
      [ECpUserRolesUi.CONTACT]: ECpUserRoles.CONTACT,
      [ECpUserRolesUi.SUPERVISOR]: ECpUserRoles.SUPERVISOR,
    };
    return rolesMapper[data];
  },
  fromDb: data => {
    const rolesMapper: { [key in ECpUserRoles]: ECpUserRolesUi } = {
      [ECpUserRoles.CONTACT]: ECpUserRolesUi.CONTACT,
      [ECpUserRoles.SUPERVISOR]: ECpUserRolesUi.SUPERVISOR,
    };
    return rolesMapper[data];
  },
};

export const contactCpUserConverter: TFetchConverter<TCpUserUi, TCpUser> = {
  fromDb: data => {
    return {
      role: cpRoleConverter.fromDb(data.role),
    };
  },
};
export const updateCpUserRoleConverter: TCreateConverter<
  TUpdateCpRoleUi,
  TUpdateCpRole
> = {
  toDb: data => {
    return {
      value: cpRoleConverter.toDb(data.role),
    };
  },
};
export const GrantCpLoginConverter: TCreateConverter<
  TGrantCpLoginPostUi,
  TGrantCpLoginPost
> = {
  toDb: data => {
    return {
      role: cpRoleConverter.toDb(data.role),
      notify: data.notify,
    };
  },
};
export const contactForOptionsConverter: TFetchConverter<
TContactsForOptionsGetUi,
TContactsForOptionsGet
> = {
  fromDb: (data: TContactsForOptionsGet) => {
    return {
      id: data.id,
      name: data.name,
      isMainContact: data.isMainContact,
      isVip: data.isVip,
      isActive: data.isActive,
      isManaged: data.isManaged,
    };
  }
};

export const contactConverter: TAuthConverter<
  TContactPostUi,
  TContactPost,
  TContactsGetUi,
  TContactsGet
> = {
  fromDb: (data: TContactsGet) => {
    return {
      id: data.id,
      name: data.name,
      company: companyShortDataConverter.fromDb(data.company),
      email: data.email,
      isMainContact: data.isMainContact,
      phoneNumber: data.phoneNumber || '',
      cellPhoneNumber: data.cellPhoneNumber || '',
      phoneExtension: data.phoneExtension,
      jobTitle: data.jobTitle,
      isVip: data.isVip,
      personalAddress: data.personalAddress,
      isActive: data.isActive,
      isManaged: data.isManaged,
      location: data.location && locationConverter.fromDb(data.location),
      ...(data.cpUser && {
        cpUser: contactCpUserConverter.fromDb(data.cpUser),
      }),
    };
  },
  toDb: (data: TContactPostUi) => ({
    name: data.name,
    companyId: data.companyId,
    email: data.email,
    isMainContact: !!data.isMainContact,
    ...(data.phoneNumber && {
      phoneNumber: data.phoneNumber,
    }),
    ...(data.cellPhoneNumber && {
      cellPhoneNumber: data.cellPhoneNumber,
    }),
    phoneExtension: data.phoneExtension,
    ...(data.personalAddress && {
      personalAddress: data.personalAddress,
    }),
    jobTitle: data.jobTitle,
    isVip: data.isVip,
    ...(data.locationId && {
      locationId: data.locationId,
    }),
  }),
};

export const contactPhoneEditConverter: TCreateConverter<
  TContactPhoneEditUi,
  TContactPhoneEdit
> = {
  toDb: data => ({
    id: data.id,
    value: data.value || null,
  }),
};

export const contactPersonalAddressEditConverter: TCreateConverter<
  TContactPersonalAddressEditUi,
  TContactPersonalAddressEdit
> = {
  toDb: data => ({
    id: data.id,
    value: data.address,
  }),
};

export const csvPreviewfailedContactConverter: TFetchConverter<
  TCsvPreviewFailedContactUi,
  TCsvPreviewFailedContact
> = {
  fromDb: data => {
    return {
      companyContactName: data.companyContactName,
      failReason: data.failReason,
      companyName: data.companyName,
    };
  },
};

export const contactCsvPreviewConverter: TFetchConverter<
  TContactCsvPreviewUi,
  TContactCsvPreview
> = {
  fromDb: data => {
    return {
      passedContacts: data.passedContacts.length
        ? data.passedContacts.map(contact => contactConverter.fromDb(contact))
        : [],
      failedContacts: data.failedContacts.length
        ? data.failedContacts.map(failedContact =>
            csvPreviewfailedContactConverter.fromDb(failedContact),
          )
        : [],
    };
  },
};

export const contactUpdateActiveConverter: TCreateConverter<
  TUpdateContactActiveUi,
  TUpdateContactActive
> = {
  toDb: data => {
    return {
      value: data.active,
    };
  },
};

export const contactManageConverter: TCreateConverter<
  TUpdateContactManageUi,
  TUpdateContactManage
> = {
  toDb: data => {
    return {
      value: data.managed,
    };
  },
};

export const contactUpdateLocationConverter: TCreateConverter<
  TUpdateContactLocationUi,
  TUpdateContactLocation
> = {
  toDb: data => {
    return {
      value: data.value,
    };
  },
};

export const contactsFilterConverter = (filters: {
  [key in ContactFilters]?: string[];
}) => {
  const contactsFilterToDB = {
    ...(filters?.companies?.length && {
      [ContactFilters.COMPANY]: filters?.companies,
    }),
    ...(filters?.locations?.length && {
      [ContactFilters.LOCATION]: filters?.locations,
    }),
    ...(filters?.active?.length && {
      [ContactFilters.ACTIVE]:
        filters?.active[0] === ContactActiveStatuses.ACTIVE,
    }),
    ...(filters?.managed?.length && {
      [ContactFilters.MANAGE]:
        filters?.managed[0] === DeviceManagedStatuses.MANAGED,
    }),
    ...(filters?.vip?.length && {
      [ContactFilters.VIP]: filters?.vip[0] === ContactVipStatuses.VIP,
    }),
  };
  return contactsFilterToDB;
};
