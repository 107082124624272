import React, { FC, memo } from 'react';
import { TImage } from './Image.type';
import SImage from './Image.style';

const Image: FC<TImage> = ({ width, height, src, alt, onClick, shape }) => {
  return (
    <SImage
      shape={shape}
      role="presentation"
      src={src}
      width={width}
      height={height}
      alt={alt}
      onClick={onClick}
    />
  );
};

export default memo(Image);
