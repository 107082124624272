import { TSlaRemaining, TSlaRemainingUi } from './sla.type';

export enum TScheduleItemType {
  Ticket = 'Ticket',
  Task = 'Task',
  ProjectTask = 'Project Task',
  CompanyInteraction = 'Company Interaction',
}

export type TScheduleItemRedirectMapper = {
  [key in TScheduleItemType]: string;
};

export type TScheduleItem = {
  description: string;
  scheduledTime: string;
  remainingTime: TSlaRemaining;
  parentResourceId: string;
  parentResourceType: TScheduleItemType;
  parentResourceNumber: string;
  overdue: boolean;
};

export type TScheduleItemUi = {
  description: string;
  scheduledTime: string;
  remainingTime: TSlaRemainingUi;
  parentResourceId: string;
  parentResourceType: TScheduleItemType;
  parentResourceNumber: string;
  overdue: boolean;
};
