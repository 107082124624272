import React, { FC, memo, useEffect } from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import { Icon, TIconNames } from 'components/Icon';
import ToolTip from 'components/ToolTip/ToolTip';
import { useGAEvent } from 'hooks/GoogleAnalytics';
import {
  TGaugeMetaDataUi,
  GaugesEnum,
  TooltipTitlesEnum,
} from 'data/types/gauges.types';
import { tooltipTitleMapper } from 'data/converters/gauges.converter';
import { useAppSelector } from 'redux/store';
import { SecondaryText } from 'components/Typography';
import { SCircleGauge, SExpandedGaugeWrapper } from '../Gauges.style';
import GaugeTooltipContent from './GaugeTooltipContent';

export type TGaugeItemProps = {
  tooltipData: TGaugeMetaDataUi[];
  iconName?: TIconNames | null;
  backgroundColor?: Colors;
  onClick?: () => void;
  type: GaugesEnum;
  name: string;
  description: string;
};

const GaugeItem: FC<TGaugeItemProps> = ({
  tooltipData,
  iconName,
  backgroundColor,
  onClick,
  type,
  name,
  description,
}) => {
  const isHeaderCollapsed = useAppSelector(
    state => state.appSettings.header.collapsed,
  );
  const { sendGAEvent } = useGAEvent();

  useEffect(() => {
    if (
      (type === GaugesEnum.OVERDUE_TICKETS ||
        type === GaugesEnum.OVERDUE_TASKS) &&
      backgroundColor === Colors.ErrorColor
    ) {
      const valueOfGauge =
        tooltipData.find(
          s =>
            s.title ===
            (tooltipTitleMapper[TooltipTitlesEnum.overdueTicketsMoreThanHour]
              .collapsed ||
              tooltipTitleMapper[TooltipTitlesEnum.overdueTasksMoreThanHour]
                .collapsed),
        )?.value || 0;
      sendGAEvent('Gauges', type, valueOfGauge, true);
    }
  }, [backgroundColor]);
  return (
    <>
      {isHeaderCollapsed ? (
        <ToolTip title={<GaugeTooltipContent tooltipData={tooltipData} />}>
          <SCircleGauge
            onClick={onClick}
            backgroundColor={backgroundColor}
            className={onClick && 'pointer'}
          >
            {iconName ? (
              <Icon size={20} color={Colors.White} icon={iconName} />
            ) : (
              tooltipData[0].value
            )}
          </SCircleGauge>
        </ToolTip>
      ) : (
        <SExpandedGaugeWrapper>
          <SecondaryText fontlevel={5} fontWeight={FontWeights.Medium}>
            {name}
          </SecondaryText>
          <SCircleGauge
            onClick={onClick}
            backgroundColor={backgroundColor}
            className={onClick && 'pointer'}
          >
            <ToolTip title={description}>
              {iconName ? (
                <Icon size={24} color={Colors.White} icon={iconName} />
              ) : (
                tooltipData[0].value
              )}
            </ToolTip>
          </SCircleGauge>
          {tooltipData.map(el => (
            <SecondaryText
              fontlevel={6}
              fontWeight={FontWeights.Regular}
              className="m-0 p-0"
            >
              {el.expanedTitle}: {el.value}
            </SecondaryText>
          ))}
          <br />
        </SExpandedGaugeWrapper>
      )}
    </>
  );
};

export default memo(GaugeItem);
