import {
  TPageableData,
  TPageableDataWithContent,
} from 'data/types/pagableData.type';

const pageableData: TPageableData = {
  pageable: {
    sort: { sorted: true, unsorted: false, empty: false },
    pageNumber: 0,
    pageSize: 10,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  last: true,
  totalElements: 0,
  totalPages: 0,
  first: true,
  number: 0,
  sort: { sorted: true, unsorted: false, empty: false },
  numberOfElements: 0,
  size: 10,
  empty: true,
};

const pageableDataWithEmptyContent: TPageableDataWithContent<any> = {
  ...pageableData,
  empty: true,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  content: [],
};

export { pageableData, pageableDataWithEmptyContent };
