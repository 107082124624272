import React from 'react';
import notification from 'antd/es/notification';
import { Icon, TIconNames } from 'components/Icon';
import { Colors } from 'core/CssVariables';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

const NotificationColorMapper: { [key in NotificationType]: Colors } = {
  error: Colors.ErrorColor,
  info: Colors.InfoColor,
  success: Colors.SuccessColor,
  warning: Colors.WarningColor,
};

type TToastProps = {
  type: NotificationType;
  title: string;
  description?: string | React.ReactNode;
};
const toast = ({ type, title, description }: TToastProps) => {
  notification.open({
    message: title,
    description:
      type === 'error' && !description ? 'Something went wrong!' : description,
    style: {
      backgroundColor: NotificationColorMapper[type],
    },
    closeIcon: <Icon icon={TIconNames.HIGHLIGHT_OFF} />,
  });
};

export { toast };
