import React, { FC, useEffect, useState } from 'react';
import { UploadProps } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { useUploadFileMutation } from 'redux/apiSlices';
import { useDownloadByUrl } from 'hooks/useDownloadFile';
import { TGetAttachmentUi } from 'data/types/generalDataTypes';
import { SFileUploader } from './FileUploader.style';
import FileIcon from './FIleIcon/FIleIcon';
import UploadButton from './UploadButton/UploadButton';
import { TUploadFile } from './FileUploader.type';
import { Icon, TIconNames } from '../Icon';
import { ModalDeleteConfirmation } from '../Modal/Modal';
import { ModalWidthEnum } from '../Modal/Modal.types';

type TFileUploaderProps = {
  defaultFileList?: TUploadFile[];
  onChange?: (fileList: TUploadFile[]) => void;
  onAdd?: (fileList: TUploadFile[]) => void;
  onDelete?: (id: string) => void;
  onDeleteLoading?: boolean;
};
const fileSize = 5242880;
export const FileUploader: FC<TFileUploaderProps> = ({
  onChange = () => {
    /* do nothing */
  },
  onAdd,
  onDelete,
  defaultFileList,
  onDeleteLoading,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<TUploadFile | null>(null);
  const [newFileList, setNewFileList] = useState<TUploadFile[]>([]);
  const download = useDownloadByUrl();
  const [uploadFile] = useUploadFileMutation();
  const [fileList, setFileList] = useState<UploadFile[]>(defaultFileList || []);
  const onUpload = async (filObj: UploadFile) => {
    if (filObj?.size && filObj.size > fileSize) {
      setFileList(p => [
        ...p,
        {
          ...filObj,
          status: 'error',
          response: 'File size must be less than 5MB',
        },
      ]);
    } else {
      try {
        const file = filObj.originFileObj as Blob;
        const formData = new FormData();
        formData.append('file', file);
        const response = (await uploadFile(formData)) as { data: string };
        let newFileObj: TUploadFile = {
          ...filObj,
          status: 'uploading',
          url: response.data,
          percent: 10,
        };
        if (!onAdd) {
          newFileObj = { ...filObj, status: 'done', url: response.data };
        }
        if (onAdd) {
          await onAdd([newFileObj]);
        }
        setFileList(p => {
          const arr: TUploadFile[] = [...p, newFileObj];
          onChange(arr);
          return arr;
        });
      } catch (err) {
        setFileList(p => {
          const arr: TUploadFile[] = [
            ...p,
            { ...filObj, status: 'error', response: 'Upload error' },
          ];
          return arr;
        });
      }
    }
  };
  const onDeleteClick = (file: TUploadFile, newList: TUploadFile[]) => {
    setSelectedFile(file);
    setNewFileList(newList);
    setDeleteModal(true);
  };

  const onDeleteCancel = () => {
    setSelectedFile(null);
    setNewFileList([]);
    setDeleteModal(false);
  };
  const onDeleteHandle = async () => {
    const deletedFile = selectedFile as TGetAttachmentUi;
    if (onDelete) {
      await onDelete(deletedFile.id);
      setDeleteModal(false);
    }
    setFileList(newFileList);
  };
  const handleChange: UploadProps['onChange'] = async ({
    fileList: newList,
    file,
  }) => {
    if (fileList.length < newList.length) {
      await onUpload(file);
      return;
    }
    if (onDelete && file.url) {
      onDeleteClick(file, newList);
      return;
    }
    setFileList(newList);
    onChange(newList);
  };
  const onDownload = (file: TUploadFile) => {
    if (file.url) {
      if (file.type != null) {
        download(file.url, file.name);
      }
    }
  };
  useEffect(() => {
    if (defaultFileList?.length) {
      setFileList(defaultFileList);
    }
  }, [defaultFileList]);

  return (
    <>
      <ModalDeleteConfirmation
        confirmLoading={onDeleteLoading}
        onCancel={onDeleteCancel}
        visible={deleteModal}
        title={`Delete "${selectedFile?.name}"`}
        okText="Delete"
        onOk={onDeleteHandle}
        size={ModalWidthEnum.Small}
      />
      <SFileUploader
        defaultFileList={fileList}
        multiple
        showUploadList={{
          showPreviewIcon: true,
          showDownloadIcon: true,
          showRemoveIcon: true,
          removeIcon: <Icon icon={TIconNames.DELETE} size={24} />,
          downloadIcon: <Icon icon={TIconNames.DOWNLOAD} size={24} />,
          previewIcon: file =>
            file.url && <Icon icon={TIconNames.EYE_SHOW} size={24} />,
        }}
        onDownload={file => onDownload(file)}
        iconRender={file => <FileIcon file={file} />}
        listType="picture-card"
        fileList={[...fileList]}
        onChange={handleChange}
        customRequest={() => {
          /* do nothing */
        }}
      >
        <UploadButton />
      </SFileUploader>
    </>
  );
};
