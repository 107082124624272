import { BorderRadiuses, Colors, FontWeights } from 'core/CssVariables';
import styled, { ThemeProps } from 'styled-components';
import { TThemeProps } from 'theme/theme';

type TCircleGaugeProps = {
  backgroundColor?: Colors;
  size?: number;
  fontSize?: number;
};

const SLine = styled.div`
  width: 2px;
  height: 30px;
  background-color: ${Colors.DisabledGrey};
`;

const SCircleGauge = styled.div<TCircleGaugeProps>`
  display: flex;
  border-radius: 50%;
  width: ${props => props.size || 32}px;
  height: ${props => props.size || 32}px;
  min-width: ${props => props.size || 32}px;
  min-height: ${props => props.size || 32}px;
  font-size: ${props => props.fontSize || 12}px;
  font-weight: ${FontWeights.SemiBold};
  background-color: ${props => props.backgroundColor};
  align-items: center;
  justify-content: center;
  color: ${Colors.White};
  user-select: none;
`;

const SGaugesMenuItemWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  background-color: ${props => props.theme.secondaryBackgroundColor};
  border-radius: ${BorderRadiuses.RadiusLG}px;
  margin-top: 13px;
  padding: 12px 16px;
  width: fit-content;
  box-shadow: 0px 4px 12px
    ${({ theme }: ThemeProps<TThemeProps>) => theme.dropdown.boxShadowColor};
`;

const SMenuDotWrapper = styled.div`
  svg {
    path {
      fill: ${props => props.theme.color};
    }
  }
`;

const SExpandedGaugeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 128px;
  user-select: none;
`;

export {
  SLine,
  SCircleGauge,
  SGaugesMenuItemWrapper,
  SMenuDotWrapper,
  SExpandedGaugeWrapper,
};
