import { rest } from 'msw';
import { INVOICE_URL } from 'redux/apiSlices/invoice.slice';
import { TPageableDataWithContent } from 'data/types/pagableData.type';
import { TInvoiceGet } from 'data/types/invoice.type';
import { mockedPageableInvoices } from '../data/invoice.data';
import { pageableDataWithEmptyContent } from '../data/general.data';

export const mockedGetAllInvoices = (
  mockedData: TPageableDataWithContent<TInvoiceGet[]>,
) => {
  return rest.get(`${INVOICE_URL}`, (req, res, ctx) => {
    const search = req.url.searchParams.get('search');
    if (search === 'no search matches') {
      return res(ctx.status(200), ctx.json(pageableDataWithEmptyContent));
    }
    return res(ctx.status(200), ctx.json(mockedData));
  });
};

export const mockedDeleteInvoiceById = () => {
  return rest.delete(`${INVOICE_URL}/:id`, (req, res, ctx) => {
    if (req.params.id === 'fakeId') {
      return res(ctx.status(400), ctx.json({ message: 'Bad request' }));
    }
    return res(ctx.status(200));
  });
};

export const invoiceHandlers = [
  mockedGetAllInvoices(mockedPageableInvoices),
  mockedDeleteInvoiceById(),
];
