import React, { FC } from 'react';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { Icon, TIconNames } from 'components/Icon';
import { Colors } from 'core/CssVariables';
import { TTableSettings } from 'components/Table/Table.types';
import TableSettingsMenu from './TableSettingsMenu';

type TTableSettingsProps = {
  tableSettings: TTableSettings[];
  setTableSettings: React.Dispatch<React.SetStateAction<TTableSettings[]>>;
  unHidableColumnTitles?: string[];
};

const TableSettings: FC<TTableSettingsProps> = ({
  tableSettings,
  setTableSettings,
  unHidableColumnTitles,
}) => {
  const onMenuItemClick = (item: TTableSettings) => {
    setTableSettings(
      tableSettings.map(el => {
        if (el.title === item.title) {
          return {
            ...el,
            show: !el.show,
          };
        }
        return el;
      }),
    );
  };
  const filterMenuItems = (el: TTableSettings, index: number) => {
    const hideableColumns = unHidableColumnTitles
      ? unHidableColumnTitles.indexOf(el.title) < 0
      : index > 0;
    return !!el.title && hideableColumns;
  };
  return (
    <Dropdown
      trigger={['click']}
      placement="bottomLeft"
      overlay={
        <TableSettingsMenu
          menuItemsData={tableSettings?.filter(filterMenuItems)}
          onMenuItemClick={onMenuItemClick}
        />
      }
    >
      <Icon
        size={16}
        color={Colors.Grey}
        icon={TIconNames.SETTINGS}
        className="pointer"
      />
    </Dropdown>
  );
};

export default TableSettings;
