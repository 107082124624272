import {
  TCompanyShortData,
  TContactShortData,
} from 'data/types/shortData.type';
import {
  CompanyActionEnum,
  ECompanyEndpointType,
  InteractionTypeEnum,
  TComapnyGetData,
  TCompanyStatus,
} from 'data/types/company.types';
import { TPageableDataWithContent } from 'data/types/pagableData.type';
import { pageableData } from './general.data';

const mainContact1: TContactShortData = {
  id: 'mainContactId1',
  isManaged: true,
  isActive: true,
  isVip: true,
  name: 'Main Contact',
  phoneNumber: '4655878787',
  email: 'contact@mail.com',
};

const mockedCompanyData: TComapnyGetData = {
  id: 'mockedId',
  name: 'Mocked Company',
  blockHours: {
    blockHoursEnabled: false,
  },
  logoUrl:
    'https://cdn.dribbble.com/userupload/3158903/file/original-3f5abe8b99ff4ba4626ddf6660115182.jpg?compress=1&resize=1600x1200',
  phoneNumber: '+1785111222',
  shortName: 'MC',
  isMsp: true,
  website: 'www.googl.com',
  contactCount: 10,
  deviceCount: 6,
  address: 'USA, CA, Los Angeles',
  relationshipStatus: TCompanyStatus.Customer,
  specialNotes: ['note 1', 'note 2'],
  actions: [
    {
      action: CompanyActionEnum.MARK_AS_CONTACTED,
      nextPossibleStatuses: [TCompanyStatus.Customer, TCompanyStatus.Marketing],
    },
  ],
  mainContact: mainContact1,
  numberOfEmployees: 20,
  endpointType: ECompanyEndpointType.EMPLOYEES,
  domainNames: [],
  marketSegment: 'marketSegment',
  accountManager: {
    id: 'acid1',
    email: 'ac@email.com',
    name: 'manager name',
    phoneNumber: '56449849846',
    photoUrl: '',
  },
  scheduledInteraction: {
    id: 'id5',
    description: 'description',
    type: InteractionTypeEnum.Marketing,
    scheduleTime: '54646546',
    assignee: {
      id: 'id9',
      name: 'name000',
      photoUrl: '',
    },
    reporter: {
      id: 'reporterid',
      name: 'reporter name',
      photoUrl: '',
    },
    overdueSince: '465465465464',
    overdueSeverity: '5555',
  },
};

const mainContact: TContactShortData = {
  id: '976da261-4503-4f44-915e-b22045c9f39d',
  name: 'Toyota Main contact',
  email: 'toyoyta.sales@gmail.com',
  phoneNumber: '+17778889996',
  isVip: true,
  isActive: true,
  isManaged: true,
};

const companyShortData: TCompanyShortData = {
  id: '61cff846-bb97-4b0d-9d4e-4d9e6f8ad153',
  name: 'Toyota LLC',
  shortName: 'AT',
  logoUrl:
    'https://dev-69ut0zw1-public-s3-bucket.s3.us-west-2.amazonaws.com/47b370f0-0bbf-47f3-9b88-33097ca3f590-logo.png',
  phoneNumber: '+13216981041',
  mainContact,
};

const mockedContact1ShortData: TContactShortData = {
  id: 'mockedContact1ForDeviceMapping',
  name: 'Contact 1',
  email: 'contact1@gmail.com',
  phoneNumber: '+17794100000',
  isVip: false,
  isActive: true,
  isManaged: true,
};
const companyAMockedShortData: TCompanyShortData = {
  id: 'companyId123',
  name: 'Company A',
  logoUrl: '',
  phoneNumber: '',
  isMSP: false,
  mainContact: mockedContact1ShortData,
};

const companyAData: TComapnyGetData = {
  ...mockedCompanyData,
  id: 'companyId123',
  name: 'Company A',
};

const companyBData: TComapnyGetData = {
  ...mockedCompanyData,
  id: 'companyId1234',
  name: 'Company B',
};

const companyCData: TComapnyGetData = {
  ...mockedCompanyData,
  id: 'companyId12345',
  name: 'Company C',
};

const mockedPagableCompanyCustomersData: TPageableDataWithContent<
  TComapnyGetData[]
> = {
  ...pageableData,
  totalElements: 3,
  totalPages: 1,
  numberOfElements: 3,
  empty: false,
  content: [companyAData, companyBData, companyCData],
};
export {
  companyShortData,
  companyAMockedShortData,
  mockedPagableCompanyCustomersData,
};
