import { TCompanyShortData, TCompanyShortDataUi } from './shortData.type';
import { TLocationGet, TLocationGetUi } from './location.types';

export type TContactPost = {
  name: string;
  companyId: string;
  email: string;
  isMainContact: boolean;
  phoneNumber?: string;
  cellPhoneNumber?: string;
  phoneExtension?: string;
  jobTitle?: string;
  personalAddress?: string;
  isVip?: boolean;
  locationId?: string;
};

export type TContactPostUi = {
  name: string;
  companyId: string;
  email: string;
  isMainContact: boolean;
  phoneNumber?: string;
  cellPhoneNumber?: string;
  phoneExtension?: string;
  jobTitle?: string;
  personalAddress?: string;
  isVip?: boolean;
  locationId?: string;
};

export type TContactsForOptionsGetUi = {
  id: string;
  name: string;
  isMainContact: boolean;
  isVip?: boolean;
  isActive?: boolean;
  isManaged?: boolean;
};

export type TContactsForOptionsGet = {
  id: string;
  name: string;
  isMainContact: boolean;
  isVip?: boolean;
  isActive?: boolean;
  isManaged?: boolean;
};

export type TContactsGetUi = {
  id: string;
  name: string;
  company: TCompanyShortDataUi;
  email: string;
  isMainContact: boolean;
  phoneNumber: string;
  cellPhoneNumber?: string;
  phoneExtension?: string;
  jobTitle?: string;
  isVip?: boolean;
  personalAddress?: string;
  isActive?: boolean;
  isManaged?: boolean;
  location?: TLocationGetUi;
  cpUser?: TCpUserUi;
};
export type TContactsGet = {
  id: string;
  name: string;
  company: TCompanyShortData;
  email: string;
  isMainContact: boolean;
  phoneNumber: string;
  cellPhoneNumber?: string;
  phoneExtension?: string;
  jobTitle?: string;
  isVip?: boolean;
  personalAddress?: string;
  isActive?: boolean;
  isManaged?: boolean;
  location?: TLocationGet;
  cpUser?: TCpUser;
};
export enum ECpUserRoles {
  CONTACT = 'Contact',
  SUPERVISOR = 'Supervisor',
}
export enum ECpUserRolesUi {
  CONTACT = 'Contact',
  SUPERVISOR = 'Supervisor',
}
export type TCpUser = {
  role: ECpUserRoles;
};
export type TCpUserUi = {
  role: ECpUserRolesUi;
};

export type TUpdateCpRoleUi = {
  role: ECpUserRolesUi;
  id: string;
};
export type TUpdateCpRole = {
  value: ECpUserRoles;
};
export type TGrantCpLoginPostUi = {
  id: string;
  role: ECpUserRolesUi;
  notify: boolean;
};
export type TGrantCpLoginPost = { role: ECpUserRoles; notify: boolean };
export type TContactPhoneEditUi = {
  id: string;
  value: string;
};
export type TContactPhoneEdit = {
  id: string;
  value: string | null;
};

export type TContactPersonalAddressEditUi = {
  id: string;
  address: string;
};
export type TContactPersonalAddressEdit = {
  id: string;
  value: string;
};

export type TCsvPreviewFailedContactUi = {
  companyName: string;
  companyContactName: string;
  failReason: string;
};
export type TCsvPreviewFailedContact = {
  companyName: string;
  companyContactName: string;
  failReason: string;
};

export type TContactCsvPreviewUi = {
  passedContacts: TContactsGetUi[];
  failedContacts: TCsvPreviewFailedContactUi[];
};
export type TContactCsvPreview = {
  passedContacts: TContactsGet[];
  failedContacts: TCsvPreviewFailedContact[];
};
export type TUpdateContactActiveUi = {
  active: boolean;
  id: string;
};
export type TUpdateContactActive = {
  value: boolean;
};
export type TUpdateContactManageUi = {
  managed: boolean;
  id: string;
};
export type TUpdateContactManage = {
  value: boolean;
};

export type TUpdateContactLocationUi = {
  id: string;
  value: string | null;
};
export type TUpdateContactLocation = {
  value: string | null;
};

export enum ContactFilters {
  COMPANY = 'companies',
  ACTIVE = 'active',
  MANAGE = 'managed',
  VIP = 'vip',
  LOCATION = 'locations',
}
export enum ContactFilterNames {
  Company = 'Company',
  Active = 'Active',
  Manage = 'Managed',
  VIP = 'VIP',
  Location = 'Location',
}

export enum ContactActiveStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export enum ContactManagedStatuses {
  MANAGED = 'MANAGED',
  UNMANAGED = 'UNMANAGED',
}
export enum ContactVipStatuses {
  VIP = 'VIP',
  ISNOTVIP = 'ISNOTVIP',
}

export type TContactsBulkUpdateLocationUi = {
  locationId: string;
  contactIds: string[];
};
export type TContactsBulkUpdateLocation = {
  locationId: string;
  contactIds: string[];
};

export type TContactsBulkChangeActiveStatusUi = {
  active: boolean | null;
  contactIds: string[];
};
export type TContactsBulkChangeActiveStatus = {
  active: boolean | null;
  contactIds: string[];
};
export type TContactsBulkUpdateResponse = {
  updatedContacts?: TContactsGetUi[];
  failedIds?: string[];
};
export type TContactsBulkUpdateResponseUi = {
  updatedContacts: TContactsGetUi[];
  failedIds: string[];
};

export type TContactsBulkChangeManagedStatusUi = {
  managed: boolean | null;
  contactIds: string[];
};
export type TContactsBulkChangeManagedStatus = {
  managed: boolean | null;
  contactIds: string[];
};
export enum BulkActionNames {
  LOCATION = 'Change location',
  ACTIVE = 'Change active status',
  MANAGED = 'Change managed status',
}
