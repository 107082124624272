import { TUserRoleUi } from 'data/types/user.types';

export enum PermissionNames {
  EMAIL_ADD = 'email:add',
  EMAIL_DELETE = 'email:delete',
  TIMESHEET_ALL_MEMBERS = 'timesheet:all:members',
  ORGANIZATION_ADD = 'organization:add',
  ORGANIZATION_CSV = 'organization:csv',
  ORGANIZATION_DELETE = 'organization:delete',
  ORGANIZATION_EDIT = 'organization:edit',
  ORGANIZATION_ACCOUNT_MANAGER_REDIRECT = 'organization:account:manager:redirect',
  PROFILE_ROLE_USER_GROUP = 'profile:show:role/userGroup',
  CLIENT_PORTAL_SETTINGS_EDIT = 'clientPortalSettings:edit',
  SETTINGS_ORGANIZATION_EDIT = 'Settings:organization:edit',
  PSL_CHANGE = 'psl:change',
  CONTRACT_EDIT = 'Contract:edit',
  INVOICE_EDIT = 'Invoice:edit',
  SETUP_NON_WORKING_DAYS = 'organization:setup:non-working-days',
  MEMBER_HOURLY_RATE = 'member:hourly-rate',
  RESET_GAUGE_DATA = 'gauges:reset',
}

export type Permissions = {
  [key in PermissionNames]: TUserRoleUi[];
};
