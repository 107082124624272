import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import { TMaterial } from 'data/types/material.type';
import {
  TQuote,
  TQuotedItems,
  TQuotedItemsUi,
  TQuoteUi,
} from 'data/types/quote.type';
import { calculateDecimals } from 'helpers/utils';

export const materialForQuoteConverter: TFetchConverter<
  TQuotedItemsUi,
  TMaterial
> = {
  fromDb: data => {
    const extendedPrice = calculateDecimals.multiply(data.quantity, data.price);
    return {
      rowId: data.id,
      itemId: data.id,
      itemName: data.name,
      unitPrice: data.price,
      quantity: data.quantity,
      extendedPrice: calculateDecimals.ceilAfterTwoDecimal(extendedPrice),
      tax: 0,
      description: data.description,
    };
  },
};

export const quotedItemConverter: TCreateConverter<
  TQuotedItemsUi,
  TQuotedItems
> = {
  toDb: data => {
    return {
      itemId: data.itemId,
      itemName: data.itemName,
      unitPrice: +data.unitPrice,
      quantity: +data.quantity,
      extendedPrice: +data.extendedPrice,
      tax: +data.tax,
      ...(data.description && { description: data.description }),
    };
  },
};

export const quoteConverter: TCreateConverter<TQuoteUi, TQuote> = {
  toDb: data => {
    return {
      assignmentTitle: data.assignmentTitle,
      workPhoneNumber: data.workPhoneNumber,
      companyAddress: data.companyAddress,
      companyEmailAddress: data.companyEmailAddress,
      quotedItems: data.quotedItems.length
        ? data.quotedItems.map(item => quotedItemConverter.toDb(item))
        : [],
      extendedPrice: +data.extendedPrice,
      tax: +data.tax,
      amount: +data.amount,
    };
  },
};
