import React, { FC } from 'react';
import beacon from '../../assets/images/beacon.svg';
import Image from '../Image/Image';
import { Row } from '../Grid/Grid';

const HelpScoutBeacon: FC = () => {
  const handleClickBeacon = () => {
    // @ts-ignore
    window.Beacon('toggle');
  };

  return (
    <Row
      className="pointer"
      style={{
        backgroundColor: '#ac5cab',
        borderRadius: '50px',
        height: '32px',
        width: '32px',
      }}
      justify="center"
      align="middle"
    >
      <Image
        onClick={handleClickBeacon}
        src={beacon}
        alt="helpscout"
        width={20}
      />
    </Row>
  );
};

export default HelpScoutBeacon;
