export enum EReportPaths {
  MSP = 'msp',
  CUSTOMER = 'customer',
  TECHNICIAN = 'tech',
  SERVICE_QUEUE = 'service-queue',
}
export enum EReportNames {
  TechClosedTickets = 'TechClosedTickets',
  TechAvgTime = 'TechAvgTime',
  TechValueTime = 'TechValueTime',
  MspTimePerCategory = 'MspTimePerCategory',
  MspPofitByCustomer = 'MspPofitByCustomer',
  MspProfitByTech = 'MspProfitByTech',
  CustomerTicketPerUser = 'CustomerTicketPerUser',
  CustomerHoursPerUser = 'CustomerHoursPerUser',
  ServiceQueueRez = 'ServiceQueueRez',
  ServiceQueueFr = 'ServiceQueueFr',
}

export type TServiceQueueItemGet = {
  queueName: string;
  queueId: string;
  isHiddenQueue: boolean;
  openedTickets: string;
  closedTickets: string;
  frSlaMet: string;
  resSlaMet: string;
};
export type TServiceQueueItemGetUi = {
  queueName: string;
  queueId: string;
  isHiddenQueue: boolean;
  openedTickets: string;
  closedTickets: string;
  frSlaMet: string;
  resSlaMet: string;
};

export type TServiceQueueGet = {
  allOpenedTickets: string;
  allClosedTickets: string;
  avgFrSlaMet: string;
  avgResSlaMet: string;
  sqpReportChartItems: TServiceQueueItemGet[];
};
export type TServiceQueueGetUi = {
  allOpenedTickets: string;
  allClosedTickets: string;
  avgFrSlaMet: string;
  avgResSlaMet: string;
  sqpReportChartItems: TServiceQueueItemGetUi[];
};

export enum TReportPeriodUi {
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  SEMI_ANNUALLY = 'Semi annually',
  ANNUALLY = 'Annually',
}
export enum TReportPeriod {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  ANNUALLY = 'ANNUALLY',
}

export type TServiceQueueCommonFilterUi = {
  range: string[];
};
export type TServiceQueueCommonFilter = {
  from: string;
  to: string;
};
export type TTechPerfCommonFilterUi = {
  range: string[];
  period: TReportPeriodUi;
  technicianIds: string[];
};
export type TTechPerfCommonFilter = {
  from: string;
  to: string;
  period: string;
  technicianIds: string[];
};

export type TWorktypeReportFilterUi = {
  workTypes: string[];
};
export type TWorktypeReportFilter = {
  workTypes: string[];
};

export type TLineChartItems = {
  period: string;
  data: { [key: string]: string };
};
export type TLineChartItemsUi = {
  period: string;
  [key: string]: string;
};

export type TLineChartDetails = {
  id: string;
  name: string;
  color: string;
};
export type TLineChartDetailsUi = {
  id: string;
  name: string;
  color: string;
};

export type TLineChartData = {
  periods: TLineChartItems[];
  metadata: TLineChartDetails[];
};
export type TLineChartDataUi = {
  lineChartItems: TLineChartItemsUi[];
  metadata: TLineChartDetailsUi[];
};

export type TReportPeriodToLabelMapper = {
  [key in TReportPeriodUi]: string;
};

export type TCustomerPerfCommonFilterUi = {
  range: string[];
  period: TReportPeriodUi;
  companyIds: string[];
};
export type TCustomerPerfCommonFilter = {
  from: string;
  to: string;
  period: string;
  companyIds: string[];
};

export type TMspPerfCommonFilterUi = {
  range: string[];
  period: TReportPeriodUi;
};
export type TMspPerfCommonFilter = {
  from: string;
  to: string;
  period: string;
};

export type TCustomerReportFilterUi = {
  companyIds: string[];
};
export type TCustomerReportFilter = {
  companyIds: string[];
};
