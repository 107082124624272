import React, { FC, useMemo } from 'react';
import { TSlaRemainingUi } from 'data/types/sla.type';
import { FontSizes } from 'core/CssVariables';
import { SlaWrapper } from './Sla.style';

type TSlaProps = {
  sla?: TSlaRemainingUi;
  text?: string;
  fontSize?: FontSizes;
};

const Sla: FC<TSlaProps> = ({ sla, text, fontSize = FontSizes.FontSM }) => {
  const claculateRez = useMemo(() => {
    if (sla?.HOURS && sla?.MINUTES) {
      return `${sla.HOURS}h ${sla.MINUTES}m`;
    }
    if (sla?.HOURS && !sla?.MINUTES) {
      return `${sla.HOURS}h`;
    }
    if (!sla?.HOURS && sla?.MINUTES) {
      return `${sla.MINUTES}m`;
    }
    return '0';
  }, [sla]);
  return (
    <SlaWrapper
      data-testid="SlaWrapper"
      fontSize={fontSize}
      score={claculateRez[0] === '-' ? -1 : 1}
    >
      {text} {claculateRez}
    </SlaWrapper>
  );
};

export default Sla;
