import { gaugesConverter } from 'data/converters/gauges.converter';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import { phaseTaskConverter } from 'data/converters/phase.converters';
import { taskConverter } from 'data/converters/task.converters';
import { ticketDetailsConverter } from 'data/converters/ticket.converters';
import { scheduleItemConverter } from 'data/converters/workFeed.converters';
import { TGauges, TGaugesUi } from 'data/types/gauges.types';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { TPhaseTask, TPhaseTaskUi } from 'data/types/phase.type';
import { TGetTask, TGetTaskUi } from 'data/types/task.type';

import { TTicketDetails, TTicketDetailsUi } from 'data/types/ticket.types';
import { TScheduleItem, TScheduleItemUi } from 'data/types/workFeed.types';

import { authSplitApi } from 'redux/helpers/slice.helpers';

const GET_WORKFEED = 'api/workfeed';

export const workFeedApi = authSplitApi('workFeedApi', [
  'workFeed',
  'workFeedNextTickets',
  'workFeedTasks',
  'workFeedReminders',
  'WorkFeedScheduleItems',
  'gauges',
  'workFeedProjectTasks',
]).injectEndpoints({
  endpoints: build => ({
    getNextTickets: build.query<
      TPageableDataWithContentUi<TTicketDetailsUi[]>,
      void
    >({
      query() {
        return {
          url: `${GET_WORKFEED}/tickets`,
          method: 'GET',
        };
      },
      transformResponse: (data: TPageableDataWithContent<TTicketDetails[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(ticket => ticketDetailsConverter.fromDb(ticket))
            : [],
        };
      },
      providesTags: ['workFeedNextTickets'],
    }),
    getTasks: build.query<TPageableDataWithContentUi<TGetTaskUi[]>, void>({
      query() {
        return {
          url: `${GET_WORKFEED}/tasks`,
          method: 'GET',
        };
      },
      providesTags: ['workFeedTasks'],
      transformResponse: (data: TPageableDataWithContent<TGetTask[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(task => taskConverter.fromDb(task))
            : [],
        };
      },
    }),
    getReminders: build.query<
      TPageableDataWithContentUi<TScheduleItemUi[]>,
      void
    >({
      query() {
        return {
          url: `${GET_WORKFEED}/reminders`,
          method: 'GET',
          params: {
            page: 0,
            size: 2000,
          },
        };
      },
      providesTags: ['workFeedReminders'],
      transformResponse: (data: TPageableDataWithContent<TScheduleItem[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(item => scheduleItemConverter.fromDb(item))
            : [],
        };
      },
    }),
    getScheduleItems: build.query<
      TPageableDataWithContentUi<TScheduleItemUi[]>,
      { date: string; page: number; pageSize: number; sort?: string }
    >({
      query({ date, page, pageSize, sort }) {
        const urlWithParams = `${GET_WORKFEED}/scheduled-items`.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            date,
            ...(sort && { sort }),
          },
        };
      },
      providesTags: ['WorkFeedScheduleItems'],
      transformResponse: (data: TPageableDataWithContent<TScheduleItem[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(item => scheduleItemConverter.fromDb(item))
            : [],
        };
      },
    }),
    getGauges: build.mutation<TGaugesUi[], void>({
      query() {
        return {
          url: `${GET_WORKFEED}/dash-bar`,
          method: 'GET',
        };
      },
      transformResponse: (data: TGauges[]) => {
        return data.map(item => gaugesConverter.fromDb(item));
      },
    }),

    getGaugeFirebase: build.mutation<any, void>({
      query() {
        return {
          url: `${GET_WORKFEED}/dash-bar/firebase`,
          method: 'GET',
        };
      },
      transformResponse: (data: any) => {
        return data;
      },
    }),

    getProjectTasks: build.query<
      TPageableDataWithContentUi<TPhaseTaskUi[]>,
      void
    >({
      query() {
        return {
          url: `${GET_WORKFEED}/project-tasks`,
          method: 'GET',
        };
      },
      providesTags: ['workFeedProjectTasks'],
      transformResponse: (data: TPageableDataWithContent<TPhaseTask[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(task => phaseTaskConverter.fromDb(task))
            : [],
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNextTicketsQuery,
  useGetTasksQuery,
  useGetRemindersQuery,
  useGetScheduleItemsQuery,
  useGetGaugesMutation,
  useGetGaugeFirebaseMutation,
  useGetProjectTasksQuery,
} = workFeedApi;
