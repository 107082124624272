import React, { FC } from 'react';
import { STable } from 'components/Table/Table.style';
import { Text } from 'components/Typography';
import { Colors, FontWeights } from 'core/CssVariables';
import { ColumnsType } from 'antd/es/table';
import { TTableProps } from '../Table.types';

type TTotalItems = {
  value?: string;
  index: number;
};

type TTableTotalsProps = {
  totals?: TTableProps['totals'];
  columns?: ColumnsType<any>;
};

const TableTotals: FC<TTableTotalsProps> = ({ totals, columns }) => {
  const totalItems: TTotalItems[] = (totals || [])?.map(item => ({
    value: item.value,
    index: (columns || []).findIndex(col => col.title === item.title),
  }));
  const indexList = totalItems.map(el => el.index);
  const positiveIndexList = indexList.filter(el => el >= 0);
  const mergeColumnsCount = Math.min(...positiveIndexList);

  return (
    <STable.Summary.Row>
      {!!positiveIndexList.length && (
        <STable.Summary.Cell index={0} colSpan={mergeColumnsCount}>
          <Text
            fontlevel={5}
            color={Colors.SecondaryColor}
            fontWeight={FontWeights.SemiBold}
          >
            Total
          </Text>
        </STable.Summary.Cell>
      )}
      {totalItems?.map(({ index, value }) => {
        if (index >= 0) {
          return (
            <STable.Summary.Cell index={index}>
              <Text
                fontlevel={5}
                color={Colors.SecondaryColor}
                fontWeight={FontWeights.SemiBold}
              >
                {value}
              </Text>
            </STable.Summary.Cell>
          );
        }
        return null;
      })}
    </STable.Summary.Row>
  );
};

export default TableTotals;
