import React, { FC } from 'react';
import { TMenuItem, TSubMenu } from './MenuItem.types';
import { SMenuItem, SSubMenu } from './MenuItem.style';

export const MenuItem: FC<TMenuItem> = props => {
  return <SMenuItem {...props} />;
};

export const SubMenu: FC<TSubMenu> = props => {
  return <SSubMenu {...props} />;
};
