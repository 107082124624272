import moment from 'moment';
import { getDateTimeFormats } from 'helpers/utils';
import {
  TConverter,
  TCreateConverter,
  TFetchConverter,
} from '../types/converter.types';
import {
  PhaseStatusEnum,
  PhaseStatusEnumUi,
  PhaseTaskActionEnum,
  PhaseTaskActionEnumUi,
  PhaseTaskStatusEnum,
  PhaseTaskStatusEnumUi,
  TCreatePhase,
  TCreatePhaseTask,
  TCreatePhaseTaskUi,
  TCreatePhaseUi,
  TPhase,
  TPhaseTask,
  TPhaseTaskAction,
  TPhaseTaskActionUi,
  TPhaseTaskAssign,
  TPhaseTaskAssignee,
  TPhaseTaskAssigneeUi,
  TPhaseTaskComment,
  TPhaseTaskCommenter,
  TPhaseTaskCommenterUi,
  TPhaseTaskCommentUi,
  TPhaseTaskAssignUi,
  TPhaseTaskCompany,
  TPhaseTaskCompanyUi,
  TPhaseTaskNextAction,
  TPhaseTaskNextActionUi,
  TPhaseTaskReassign,
  TPhaseTaskReassignUi,
  TPhaseTaskReporter,
  TPhaseTaskReporterUi,
  TPhaseTaskResolve,
  TPhaseTaskResolveUi,
  TPhaseTaskSchedule,
  TPhaseTaskScheduleUi,
  TPhaseTaskStartWork,
  TPhaseTaskStartWorkUi,
  TPhaseTaskUi,
  TPhaseUi,
  TPhaseTaskTextEditUi,
  TPhaseTaskTextEdit,
  TPhaseTaskDateEditUi,
  TPhaseTaskDateEdit,
  TPhaseTextEditUi,
  TPhaseTextEdit,
  TProjectMaterialCategoryEditUi,
  TProjectMaterialCategoryEdit,
} from '../types/phase.type';
import { attachmentsGetConverter, GetFirstLetters } from './general.converters';
import { contactShortDataConverter } from './shortDataConverter';
import {
  materialConverter,
  materialTotalsConverter,
} from './material.converter';

export const estimatedHoursConverter: TFetchConverter<number, number> = {
  fromDb: data => data && parseFloat(data.toFixed(1)),
};

export const phaseTaskAssigneeConverter: TFetchConverter<
  TPhaseTaskAssigneeUi,
  TPhaseTaskAssignee
> = {
  fromDb: data => ({
    id: data.id,
    name: data.name,
    photoUrl: data.photoUrl,
    firstLetters: GetFirstLetters.fromDb(data.name),
  }),
};

export const phaseTaskReporterConverter: TFetchConverter<
  TPhaseTaskReporterUi,
  TPhaseTaskReporter
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
      firstLetters: GetFirstLetters.fromDb(data.name),
    };
  },
};
export const phaseTaskStatusConverter: TConverter<
  PhaseTaskStatusEnumUi,
  PhaseTaskStatusEnum
> = {
  fromDb: data => {
    return PhaseTaskStatusEnumUi[
      PhaseTaskStatusEnum[data] as keyof typeof PhaseTaskStatusEnumUi
    ];
  },
  toDb: data => {
    return PhaseTaskStatusEnum[
      PhaseTaskStatusEnumUi[data] as keyof typeof PhaseTaskStatusEnum
    ];
  },
};
const phaseTaskCompanyConverter: TFetchConverter<
  TPhaseTaskCompanyUi,
  TPhaseTaskCompany
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      shortName: data.shortName,
      logoUrl: data.logoUrl,
      mainContact:
        data.mainContact && contactShortDataConverter.fromDb(data.mainContact),
      firstLetters: GetFirstLetters.fromDb(data.name),
      phoneNumber: data.phoneNumber || '',
      nameWithAbbreviation: data.shortName
        ? `${data.shortName} - ${data.name}`
        : data.name,
    };
  },
};
export const phaseStatusConverter: TConverter<
  PhaseStatusEnumUi,
  PhaseStatusEnum
> = {
  fromDb: data => {
    return PhaseStatusEnumUi[
      PhaseStatusEnum[data] as keyof typeof PhaseStatusEnumUi
    ];
  },
  toDb: data => {
    return PhaseStatusEnum[
      PhaseStatusEnumUi[data] as keyof typeof PhaseStatusEnum
    ];
  },
};
const phaseTaskActionConverter: TFetchConverter<
  TPhaseTaskActionUi,
  TPhaseTaskAction
> = {
  fromDb: data => {
    return {
      action:
        PhaseTaskActionEnumUi[
          PhaseTaskActionEnum[data.action] as keyof typeof PhaseTaskActionEnumUi
        ],
      nextPossibleStatuses: data.nextPossibleStatuses.map(
        item =>
          PhaseTaskStatusEnumUi[
            PhaseTaskStatusEnum[item] as keyof typeof PhaseTaskStatusEnumUi
          ],
      ),
    };
  },
};
const phaseTaskNextActionConverter: TFetchConverter<
  TPhaseTaskNextActionUi,
  TPhaseTaskNextAction
> = {
  fromDb: data => {
    return {
      description: data.description,
      scheduleTime: data.scheduleTime,
      endTime: data.endTime,
      hasEventMapping: data.hasEventMapping,
    };
  },
};
export const phaseTaskConverter: TFetchConverter<TPhaseTaskUi, TPhaseTask> = {
  fromDb: data => {
    const { dateFormat, dateTimeFormat } = getDateTimeFormats();
    const arrActions = Object.keys(PhaseTaskActionEnumUi);
    let startEndDates = '-';
    if (data.startDate) {
      startEndDates = `${moment(data.startDate).format(dateFormat)} - `;
    }
    if (data.endDate) {
      startEndDates += moment(data.endDate).format(dateFormat);
    }
    return {
      id: data.id,
      title: data.title,
      description: data.description,
      phaseId: data.phaseId,
      startDate: data.startDate,
      endDate: data.endDate,
      assignee: data.assignee
        ? phaseTaskAssigneeConverter.fromDb(data.assignee)
        : undefined,
      status: data.status
        ? phaseTaskStatusConverter.fromDb(data.status)
        : undefined,
      company: data.company
        ? phaseTaskCompanyConverter.fromDb(data.company)
        : undefined,
      reporter: data.reporter
        ? phaseTaskReporterConverter.fromDb(data.reporter)
        : undefined,
      estimatedHours: estimatedHoursConverter.fromDb(data.estimatedHours),
      order: data.order,
      nextActionItem: data.nextActionItem
        ? phaseTaskNextActionConverter.fromDb(data.nextActionItem)
        : undefined,
      actions:
        data.actions
          ?.sort((a, b) => {
            return arrActions.indexOf(a.action) - arrActions.indexOf(b.action);
          })
          .map(action => phaseTaskActionConverter.fromDb(action)) || [],
      number: data.number,
      creationDateTime: data.creationDateTime
        ? moment(data.creationDateTime).format(dateTimeFormat)
        : '',
      projectId: data?.projectId || '',
      materials:
        data.materials?.map(item => materialConverter.fromDb(item)) || [],
      materialTotals: data?.materialTotals
        ? materialTotalsConverter.fromDb(data.materialTotals)
        : undefined,
      startEndDates,
      overdueSince: data.overdueSince
        ? moment(data.overdueSince).format(dateTimeFormat)
        : null,
      friendlyNumber: data.friendlyNumber,
      attachments: attachmentsGetConverter.fromDb(data.attachments),
    };
  },
};

export const phasesConverter: TFetchConverter<TPhaseUi, TPhase> = {
  fromDb: data => {
    const { dateFormat } = getDateTimeFormats();
    let startEndDates = '-';
    if (data.startDate) {
      startEndDates = `${moment(data.startDate).format(dateFormat)} - `;
    }
    if (data.endDate) {
      startEndDates += moment(data.endDate).format(dateFormat);
    }
    return {
      id: data.id,
      order: data.order,
      title: data.title,
      description: data.description,
      startDate: data.startDate || '',
      endDate: data.endDate || '',
      estimatedHours: estimatedHoursConverter.fromDb(data.estimatedHours),
      status: data.status
        ? phaseStatusConverter.fromDb(data.status)
        : undefined,
      startEndDates,
      tasks: data.tasks.length
        ? data.tasks.map(item => phaseTaskConverter.fromDb(item))
        : [],
    };
  },
};
export const createPhaseConverter: TCreateConverter<
  TCreatePhaseUi,
  TCreatePhase
> = {
  toDb: data => {
    return {
      title: data.title,
      description: data.description,
    };
  },
};
export const createPhaseTaskConverter: TCreateConverter<
  TCreatePhaseTask,
  TCreatePhaseTaskUi
> = {
  toDb: data => {
    return {
      title: data.title,
      description: data.description,
      estimatedHours: data.estimatedHours
    };
  },
};

export const phaseTaskCommenterConverter: TFetchConverter<
  TPhaseTaskCommenterUi,
  TPhaseTaskCommenter
> = {
  fromDb: data => {
    return {
      fullName: data.fullName,
      photoUrl: data.photoUrl,
      userId: data.userId,
      firstLetters: GetFirstLetters.fromDb(data.fullName),
    };
  },
};

export const phaseTaskCommentConverter: TFetchConverter<
  TPhaseTaskCommentUi,
  TPhaseTaskComment
> = {
  fromDb: data => {
    return {
      id: data.id,
      text: data.text,
      creationTime: data.creationTime,
      commenter: phaseTaskCommenterConverter.fromDb(data.commenter),
      taskId: data.taskId,
      edited: data.edited,
      lastModifiedTime: data.lastModifiedTime,
    };
  },
};

export const phaseTaskAssignConverter: TCreateConverter<
  TPhaseTaskAssignUi,
  TPhaseTaskAssign
> = {
  toDb: data => {
    return {
      assigneeId: data.assigneeId,
      nextStatus: phaseTaskStatusConverter.toDb(data.nextStatus),
      startDate: data.startDate.format('yyyy-MM-DD'),
      endDate: data.endDate.format('yyyy-MM-DD'),
    };
  },
};

export const phaseTaskReassignConverter: TCreateConverter<
  TPhaseTaskReassignUi,
  TPhaseTaskReassign
> = {
  toDb: data => {
    return {
      assigneeId: data.assigneeId,
      keepNSI: data.keepScheduleAction,
    };
  },
};

export const phaseTaskStartWorkConverter: TCreateConverter<
  TPhaseTaskStartWorkUi,
  TPhaseTaskStartWork
> = {
  toDb: data => {
    return { nextStatus: phaseTaskStatusConverter.toDb(data.nextStatus) };
  },
};

export const phaseTaskScheduleConverter: TCreateConverter<
  TPhaseTaskScheduleUi,
  TPhaseTaskSchedule
> = {
  toDb: data => {
    return {
      nextStatus: phaseTaskStatusConverter.toDb(data.nextStatus),
      description: data.description,
      scheduleTime: data.scheduleTime,
      endTime: data.endTime,
      createCalendarItem: data.createCalendarItem,
    };
  },
};

export const phaseTaskResolveConverter: TCreateConverter<
  TPhaseTaskResolveUi,
  TPhaseTaskResolve
> = {
  toDb: data => {
    return {
      value: phaseTaskStatusConverter.toDb(data.nextStatus),
    };
  },
};
export const phaseSingleTextEditConverter: TCreateConverter<
  TPhaseTextEditUi,
  TPhaseTextEdit
> = {
  toDb: data => {
    return {
      value: data.value,
    };
  },
};

export const phaseTaskSingleTextEditConverter: TCreateConverter<
  TPhaseTaskTextEditUi,
  TPhaseTaskTextEdit
> = {
  toDb: data => {
    return {
      value: data.value,
    };
  },
};
export const phaseTaskSingleDateEditConverter: TCreateConverter<
  TPhaseTaskDateEditUi,
  TPhaseTaskDateEdit
> = {
  toDb: data => {
    return {
      value: data.value.format('yyyy-MM-DD'),
    };
  },
};
export const projectMaterialCategoryEditConverter: TCreateConverter<
  TProjectMaterialCategoryEditUi,
  TProjectMaterialCategoryEdit
> = {
  toDb: data => {
    return {
      value: data.value,
    };
  },
};
