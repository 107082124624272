import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TPaymentInfoUi } from 'data/types/payment.type';
import { zestLocalStorage } from 'helpers/storage';

type TPaymentInfoState = TPaymentInfoUi | null;

const initialState: TPaymentInfoState =
  zestLocalStorage.getItem('PaymentInfo') || null;

export const paymentInfoSlice = createSlice({
  name: 'paymentInfoSlice',
  initialState,
  reducers: {
    setPaymentInfo(state, action: PayloadAction<TPaymentInfoUi>) {
      zestLocalStorage.setItem('PaymentInfo', action.payload);
      return action.payload;
    },
    removePaymentInfo() {
      zestLocalStorage.removeItem('PaymentInfo');
      return null;
    },
  },
});

export default paymentInfoSlice;
