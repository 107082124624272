import { Colors, Screens, Spacing } from 'core/CssVariables';
import styled from 'styled-components';
import { TTimeWrapper } from './AllTickets.type';

const TimeWrapper = styled.div<TTimeWrapper>`
  color: ${({ time }) =>
    parseFloat(time) > 0 ? Colors.SuccessColor : Colors.ErrorColor};
`;
const ScoreWrapper = styled.div<{ score: number }>`
  color: ${({ score }) => (score > 10 ? Colors.ErrorColor : Colors.InfoColor)};
`;

const SNoTicketWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  .no-result {
    color: ${({ theme }) => theme.color};
  }
`;

const FilterTicketWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: ${Spacing.SpacingSM}px;
  @media (max-width: ${Screens.ScreensMD}) {
    max-width: 350px;
  }
  @media (max-width: ${Screens.ScreensLG}) {
    flex-direction: column-reverse;
  }
  .button-wrapper {
    display: flex;
    @media (max-width: ${Screens.ScreensXXL}) {
      flex-wrap: wrap;
      gap: 16px;
    }
  }
  .search-ticket {
    width: 370px;
    display: flex;
    min-width: 370px;
    max-width: 370px;
    @media (max-width: ${Screens.ScreensLG}) {
      margin: ${Spacing.SpacingLG}px ${Spacing.SpacingXS}px;
      width: 330px;
      min-width: 330px;
      max-width: 330px;
    }
    .ant-input-group {
      width: 370px;
    }
  }
`;

const SStatusWrapper = styled.div`
  display: flex;
  width: max-content;
`;

export {
  TimeWrapper,
  SNoTicketWrapper,
  FilterTicketWrapper,
  ScoreWrapper,
  SStatusWrapper,
};
