import { lazy } from 'react';
import { TIconNames } from 'components/Icon';
import { TUserRoleUi } from 'data/types/user.types';
import { PrivateRouteType, RoutesPaths } from '../Routes.types';

const AllCompanies = lazy(
  () =>
    import('pages/private/Customers/Organizations/AllCompanies/AllCompanies'),
);
const OrganizationDetails = lazy(
  () =>
    import(
      'pages/private/Customers/Organizations/organizationDetails/OrganizationDetails'
    ),
);
const Contacts = lazy(
  () => import('pages/private/Customers/Contacts/Contacts'),
);
const ItGoals = lazy(
  () => import('pages/private/Customers/ItGoals/AllItGoals'),
);
const ContactDetails = lazy(
  () =>
    import('pages/private/Customers/Contacts/ContactDetails/ContactDetails'),
);
const CreateContract = lazy(
  () =>
    import(
      'pages/private/Customers/Organizations/organizationDetails/Contracts/CreateContract/CreateContract'
    ),
);
const ContractDetails = lazy(
  () =>
    import(
      'pages/private/Customers/Organizations/organizationDetails/Contracts/ContractDetails/ContractDetails'
    ),
);
const AllDevices = lazy(
  () => import('pages/private/Customers/Devices/AllDevices'),
);
const DeviceDetails = lazy(
  () => import('pages/private/Customers/Devices/DeviceDetails/DeviceDetails'),
);

export const CustomerSubRoutes: PrivateRouteType[] = [
  {
    title: 'Companies',
    component: AllCompanies,
    path: RoutesPaths.Organizations,
    index: true,
    icon: TIconNames.ORGANIZATION,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
  {
    title: 'Contacts',
    component: Contacts,
    path: RoutesPaths.Contacts,
    icon: TIconNames.CONTACTS,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
  {
    title: 'Devices',
    component: AllDevices,
    path: RoutesPaths.Devices,
    icon: TIconNames.DEVICE,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
  {
    title: 'All IT Goals',
    component: ItGoals,
    path: RoutesPaths.ItGoals,
    icon: TIconNames.IT_GOALS,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
  },
  {
    component: DeviceDetails,
    path: RoutesPaths.DeviceDetails,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
  {
    component: OrganizationDetails,
    path: RoutesPaths.OrganizationDetails,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
  {
    component: ContactDetails,
    path: RoutesPaths.ContactDetails,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
  {
    component: ContractDetails,
    path: RoutesPaths.ContractDetails,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
  {
    component: CreateContract,
    path: RoutesPaths.ContractCreate,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
    notInSidebar: true,
  },
];
