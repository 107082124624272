import { Auth } from 'aws-amplify';
import {
  TAmplifyLoginData,
  TForgotPasswordSubmit,
  TForgotPasswordUI,
  TLoginDataUi,
} from 'data/types';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import {
  forgotPasswordConverter,
  forgotPasswordSubmitConverter,
  loginAmplifyConverter,
} from 'data/converters';
import { TAmplifySetupMfaData, TSetupMfaDataUi } from 'data/types/auth.types';


export const verifyCodeAmplifyService = async (
  dependancy: TSetupMfaDataUi
): Promise<TAmplifySetupMfaData> => {

  await Auth.confirmSignIn(dependancy.user, dependancy.mfaCode, "SOFTWARE_TOKEN_MFA");

  return new Promise<TAmplifySetupMfaData>((resolve) => {
    resolve({

    });
  });
};

export const setupMfaAmplifyService = async (
  dependancy: TSetupMfaDataUi
): Promise<TAmplifySetupMfaData> => {

  await Auth.verifyTotpToken(dependancy.user, dependancy.mfaCode);
  await Auth.setPreferredMFA(dependancy.user, "SOFTWARE_TOKEN_MFA");
  return new Promise<TAmplifySetupMfaData>((resolve) => {
    resolve({

    });
  });
};

export const loginAmplifyService = async (
  dependancy: TLoginDataUi
): Promise<TAmplifyLoginData> => {
  const { email, password } = loginAmplifyConverter.toDb(dependancy);
  const user = await Auth.signIn(email, password);
  if (user.challengeName === "MFA_SETUP") {
    const code = await Auth.setupTOTP(user);
    return new Promise<TAmplifyLoginData>((resolve) => {
      resolve({
        user,
        setupMFACode: code
      });
    })
  }
  
  if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
    return new Promise<TAmplifyLoginData>((resolve) => {
      resolve({
        user,
        needMFACode: true
      });
    });
  }

  // If we get here, we know mfa isn't enabled for this user.
  // But it should be.  We force this here.
  // await Auth.setPreferredMFA(user, "SOFTWARE_TOKEN_MFA");
  const code = await Auth.setupTOTP(user);
  return new Promise<TAmplifyLoginData>((resolve) => {
    resolve({
      user,
      setupMFACode: code
    });
  })
};

export const signAmplifySignOut = async (): Promise<null> => {
  return Auth.signOut();
};

export const getCurrentSession = async (): Promise<CognitoUserSession> => {
  return Auth.currentSession();
};

export const forgotPasswordAmplifyService = async (
  dependancy: TForgotPasswordUI,
): Promise<any> => {
  const { username } = forgotPasswordConverter.toDb(dependancy);
  return Auth.forgotPassword(username);
};

export const forgotPasswordSubmitAmplifyService = async (
  dependancy: TForgotPasswordSubmit,
): Promise<string> => {
  const { username, code, newPassword } =
    forgotPasswordSubmitConverter.toDb(dependancy);
  return Auth.forgotPasswordSubmit(username, code, newPassword);
};
