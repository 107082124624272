import React, { FC, ReactNode } from 'react';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { PermissionNames } from './Permissions/Permissions.type';

type TCanProps = {
  permission: PermissionNames;
  children: ReactNode;
};

const Can: FC<TCanProps> = ({ permission, children }) => {
  const check = useCheckPermission(permission);

  if (check) {
    return <>{children}</>;
  }
  return null;
};

export default Can;
