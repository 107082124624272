import React, { FC } from 'react';
import Image from 'components/Image/Image';
import noResultImg from 'assets/images/noResult.svg';
import { Text } from 'components/Typography';
import SNoResaltWrapper from './NoResult.style';

export type TNoResult = {
  noResult?: boolean;
  text?: string;
  img?: string;
};
export const NoResult: FC<TNoResult> = ({
  noResult,
  text = 'No Results found',
  img = noResultImg,
}) => {
  if (!noResult) return null;
  return (
    <SNoResaltWrapper>
      <Image src={img} alt="no result" width={260} />
      <Text fontlevel={4} fontWeight={500} className="mt-4 no-result">
        {text}
      </Text>
    </SNoResaltWrapper>
  );
};
