import {
  TLoginData,
  TLoginDataUi,
  TAuthConverter,
  TAmplifyLoginDataUi,
  TAmplifyLoginData,
} from 'data/types';
import { TAmplifySetupMfaData, TSetupMfaData, TSetupMfaDataUi } from 'data/types/auth.types';

export const loginAmplifyConverter: TAuthConverter<
  TLoginDataUi,
  TLoginData,
  TAmplifyLoginDataUi,
  TAmplifyLoginData
> = {
  fromDb: (data: TAmplifyLoginData) => {
    if (data) {
      return {
        isMFASetupRequired: data.setupMFACode !== undefined,
        user: data.user,
        setupMFACode: data.setupMFACode,
        isMFACodeRequired: data.user.challengeName === "SOFTWARE_TOKEN_MFA"
      }
    }
    return undefined;
  },
  toDb: (data: TLoginDataUi) => {
    return {
      email: data.email,
      password: data.password,
    };
  },
};

export const setupMfaAmplifyConverter: TAuthConverter<
  TSetupMfaDataUi,
  TSetupMfaData,
  boolean,
  TAmplifySetupMfaData
> = {
  fromDb: () => {
    return true;
  },
  toDb: (data: TSetupMfaDataUi) => {
    return {
      mfaCode: data.mfaCode
    };
  },
};
