import { rest } from 'msw';
import {
  AS_INTEGRATION_URL,
  RMM_INTEGRATION_URL,
} from 'redux/apiSlices/integrations.slice';
import {
  EASIntegrationNames,
  ERMMIntegrationsTypes,
  TASIntegration,
  TASIntegrationSettings,
  TCredentialsPostUI,
  TRMMIntegration,
} from 'data/types/integrations.types';
import { mockedMappingData } from '../data/integration.data';

export const integrationsHandlers = [
  rest.post(`${RMM_INTEGRATION_URL}/connect`, (req, res, ctx) => {
    const requestBody: TCredentialsPostUI = req.body as TCredentialsPostUI;
    if (requestBody.clientId === 'fakeClientId') {
      return res(ctx.status(400), ctx.json({ message: 'fake id ,try again' }));
    }
    return res(ctx.status(200));
  }),
  rest.delete(`${RMM_INTEGRATION_URL}/:id1/disconnect`, (req, res, ctx) => {
    if (req.params.id1 === 'wrongId') {
      return res(ctx.status(400), ctx.json({ message: 'Id is wrong' }));
    }
    return res(ctx.status(200));
  }),
  rest.get(`${RMM_INTEGRATION_URL}/credentials`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ clientId: 'testClientId', region: 'USA' }),
    );
  }),
  rest.get(`${RMM_INTEGRATION_URL}/integration-statuses`, (req, res, ctx) => {
    const data: TRMMIntegration[] = [
      {
        id: 'id1',
        type: ERMMIntegrationsTypes.NINJA,
        connected: false,
        settings: {
          webhookUri: 'somurls.com',
          ticketProcessorEnabled: true,
          id: 'someid',
          syncInterval: 30,
        },
      },
    ];
    return res(ctx.status(200), ctx.json(data));
  }),
  rest.get(`${AS_INTEGRATION_URL}/integration-statuses`, (req, res, ctx) => {
    const data: TASIntegration[] = [
      {
        connected: true,
        type: EASIntegrationNames.QUICKBOOKS,
        id: 'id2',
        settings: {} as TASIntegrationSettings,
      },
      {
        connected: false,
        type: EASIntegrationNames.XERO,
        id: 'id3',
        settings: {} as TASIntegrationSettings,
      },
    ];
    return res(ctx.status(200), ctx.json(data));
  }),
  rest.get(`${RMM_INTEGRATION_URL}/company-mappings`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedMappingData));
  }),
  rest.post(`${RMM_INTEGRATION_URL}/synchronize-companies`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.put(`${RMM_INTEGRATION_URL}/settings/:id`, (req, res, ctx) => {
    if (req.params.id === 'fakeId') {
      return res(ctx.status(400), ctx.json({ message: 'ERROR, fake id' }));
    }
    return res(ctx.status(200));
  }),
];
