import { rest } from 'msw';
import { LOCATIONS_URL } from 'redux/apiSlices/location.slices';
import {
  contact1,
  location1,
  location2,
  mockedPagableLocationData,
} from '../data/locatonsMockedData';
import { TLocationPostUi } from '../../../data/types/location.types';

export const locationHandler = [
  rest.get(`${LOCATIONS_URL}`, (req, res, ctx) => {
    const companiesParam = req.url.searchParams.get('companies');
    if (companiesParam === contact1.company.id) {
      return res(ctx.status(200), ctx.json(mockedPagableLocationData));
    }
    return res(ctx.status(400), ctx.json({ message: 'unhandled case' }));
  }),
  rest.delete(`${LOCATIONS_URL}/:id`, (req, res, ctx) => {
    if (req.url.pathname.includes(location1.id)) {
      return res(ctx.status(200));
    }
    if (req.url.pathname.includes(location2.id)) {
      return res(
        ctx.status(400),
        ctx.json({ message: 'Error for location2 only for handle error case' }),
      );
    }
    return res(ctx.status(400), ctx.json({ message: 'unhandled case' }));
  }),
  rest.post(`${LOCATIONS_URL}`, (req, res, ctx) => {
    const reqBody: TLocationPostUi = req.body as TLocationPostUi;

    if (
      reqBody.companyId === contact1.company.id &&
      reqBody.name === 'Main office'
    ) {
      return res(ctx.status(200));
    }
    if (
      reqBody.companyId === contact1.company.id &&
      reqBody.name === 'Duplicated name'
    ) {
      return res(
        ctx.status(400),
        ctx.json({ message: 'Error: Duplicated name' }),
      );
    }
    return res(ctx.status(400), ctx.json({ message: 'unhandled case' }));
  }),
];
