import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PhaseTaskStatusEnumUi } from 'data/types/phase.type';
import { TTaskStatusUi } from 'data/types/task.type';
import { TTicketStatusUi } from 'data/types/ticket.types';
import { EUserSettingsStorageNames, zestLocalStorage } from 'helpers/storage';
import { AssignmentEnumUi } from '../../data/types/timeEntry.types';

type TContactModal = { companyDefaultId?: string; visibleModal: boolean };
type TDeviceModal = { companyDefaultId?: string; visibleModal: boolean };
type TTheme = 'dark' | 'light';

type TCurrentEntityType = {
  assignmentId: string;
  assignmentType: AssignmentEnumUi;
  company: string;
  name: string;
  status: false | TTicketStatusUi | TTaskStatusUi | PhaseTaskStatusEnumUi;
} | null;
export type TAppSettingsState = {
  theme: TTheme;
  sidebar: {
    hidden: boolean;
    collapsed: boolean;
  };
  header: {
    collapsed: boolean;
    height?: number;
  };
  addTicketModal: boolean;
  addTaskModal: boolean;
  addContactModal: TContactModal;
  addProjectModal: boolean;
  timeEntryModal: boolean;
  timeTrackerModal: boolean;
  currentAssignment: TCurrentEntityType;
  redirectWorkfeed: boolean;
  addDeviceModal: TDeviceModal;
};

const initialState: TAppSettingsState = {
  theme: 'light',
  sidebar: {
    hidden: true,
    collapsed:
      !!zestLocalStorage.getItem('userSettings')?.[
        EUserSettingsStorageNames.sidebarCollapsed
      ],
  },
  header: {
    collapsed:
      !!zestLocalStorage.getItem('userSettings')?.[
        EUserSettingsStorageNames.headerCollapsed
      ],
    height: 0,
  },
  addTicketModal: false,
  addTaskModal: false,
  addContactModal: { visibleModal: false },
  addProjectModal: false,
  timeEntryModal: false,
  timeTrackerModal: false,
  currentAssignment: null,
  redirectWorkfeed: false,
  addDeviceModal: { visibleModal: false },
};

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<TTheme>) {
      state.theme = action.payload;
    },
    changeSidebarState(
      state,
      action: PayloadAction<{
        hidden?: boolean;
        collapsed?: boolean;
      }>,
    ) {
      if (action.payload.collapsed !== undefined) {
        const newStorageObj = {
          ...(zestLocalStorage.getItem('userSettings') || {}),
          [EUserSettingsStorageNames.sidebarCollapsed]:
            action.payload.collapsed,
        };
        zestLocalStorage.setItem('userSettings', newStorageObj);
      }
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          ...action.payload,
        },
      };
    },
    changeHeaderCollapsedState(state, action: PayloadAction<boolean>) {
      const newStorageObj = {
        ...(zestLocalStorage.getItem('userSettings') || {}),
        [EUserSettingsStorageNames.headerCollapsed]: action.payload,
      };
      zestLocalStorage.setItem('userSettings', newStorageObj);
      return {
        ...state,
        header: {
          ...state.header,
          collapsed: action.payload,
        },
      };
    },
    setHeaderHeight(state, action: PayloadAction<number>) {
      return {
        ...state,
        header: {
          ...state.header,
          height: action.payload,
        },
      };
    },
    changeticketAddModal(state, action: PayloadAction<boolean>) {
      state.addTicketModal = action.payload;
    },
    changeTaskAddModal(state, action: PayloadAction<boolean>) {
      state.addTaskModal = action.payload;
    },
    changeContactAddModal(state, action: PayloadAction<TContactModal>) {
      state.addContactModal = {
        visibleModal: action.payload.visibleModal,
        companyDefaultId: action.payload.companyDefaultId || '',
      };
    },
    changeDeviceAddModal(state, action: PayloadAction<TDeviceModal>) {
      state.addDeviceModal = {
        visibleModal: action.payload.visibleModal,
        companyDefaultId: action.payload.companyDefaultId || '',
      };
    },
    changeProjectAddModal(state, action: PayloadAction<boolean>) {
      state.addProjectModal = action.payload;
    },
    changeTimeEntryAddModal(state, action: PayloadAction<boolean>) {
      state.timeEntryModal = action.payload;
    },
    changeTimeTrackerModal(state, action: PayloadAction<boolean>) {
      state.timeTrackerModal = action.payload;
    },
    setCurrentEntity(state, action: PayloadAction<TCurrentEntityType>) {
      state.currentAssignment = action.payload;
    },
    setRedirectWorkfeed(state, action: PayloadAction<boolean>) {
      state.redirectWorkfeed = action.payload;
    },
  },
});

export default appSettingsSlice;
