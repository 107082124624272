import styled, { css } from 'styled-components';
import { Colors, FontFamilies, Screens, Spacing } from 'core/CssVariables';
import { TAuthWrapper } from './types';

export const SAuthWrapper = styled.div<Pick<TAuthWrapper, 'withPadding'>>`
  min-height: 100vh;
  position: relative;
  .auth-title-block {
    padding-bottom: ${Spacing.SpacingXXL}px;
  }
  .auth-link-wrapper {
    font-family: ${FontFamilies.FontPrimary};
    color: ${Colors.LightGrey};
    margin-bottom: 0;
  }
  .auth-link-wrapper-bottom {
    font-family: ${FontFamilies.FontPrimary};
    color: ${Colors.Grey};
  }
  a {
    color: ${Colors.DarkPurple};
    font-weight: 500;
  }
  .ant-row,
  .auth-left,
  .auth-right {
    /* min-height: inherit; */
    justify-content: center;
  }
  .auth-left {
    background-color: ${Colors.DarkPurple};

    .content {
      padding-top: 112px;
      text-align: center;
      position: relative;
      z-index: 1;

      .title {
        color: ${Colors.White};
      }
    }
    .bg-img {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .auth-right {
    display: flex;
    flex-direction: column;
    ${({ withPadding }) =>
      withPadding &&
      css`
        padding: 80px 135px 40px 124px;

        @media (max-width: ${Screens.ScreensXL}) {
          padding: 40px 20px 40px;
        }
      `}
  }

  .auth-align {
    text-align: start;
  }
  .auth-logo-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  @media (max-width: ${Screens.ScreensMD}) {
    .auth-logo-wrapper {
      display: flex;
      justify-content: center;
    }
    .auth-align {
      text-align: center;
    }
    button {
      width: 100%;
      justify-content: center;
    }
    .title {
      color: ${Colors.PrimaryColor};
    }
    .auth-right {
      justify-content: flex-start;
    }
  }
`;

export const SLogoMobile = styled.div`
  display: none;
  @media (max-width: ${Screens.ScreensMD}) {
    display: block;
    text-align: center;
    color: ${Colors.DarkPurple};
    padding-bottom: ${Spacing.SpacingXL};
  }
`;
