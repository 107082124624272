import React from 'react';
import { Col } from 'components/Grid/Grid';
import { EPaymentInfoStatusUi } from 'data/types/payment.type';
import { useAppSelector } from 'redux/store';
import TrialAlert from './TrialAlert/TrialAlert';
import GraceAlert from './GraceAlert/GraceAlert';

const PaymentAlert = () => {
  const paymentInfo = useAppSelector(state => state.paymentInfoSlice);
  switch (paymentInfo?.paymentStatus) {
    case EPaymentInfoStatusUi.TRIALING:
      return (
        <Col xs={24}>
          <TrialAlert />
        </Col>
      );
    case EPaymentInfoStatusUi.PAST_DUE:
      return (
        <Col xs={24}>
          <GraceAlert />
        </Col>
      );
    default:
      return null;
  }
};

export default PaymentAlert;
