import { TDevicesGet } from 'data/types/devices.type';
import { EDevicesSources } from 'data/types/devicesEnum.type';
import { TContactShortData } from 'data/types/shortData.type';
import { TPageableDataWithContent } from 'data/types/pagableData.type';
import { companyAMockedShortData } from './company.data';
import { pageableData } from './general.data';

export const mockedContact1ShortData: TContactShortData = {
  id: 'mockedContact1ForDeviceMapping',
  name: 'Contact 1',
  email: 'contact1@gmail.com',
  phoneNumber: '+17794100000',
  cellPhoneNumber: '+10789898000',
  phoneExtension: '005',
  jobTitle: 'Sales Manager',
  personalAddress: 'USA, CA, Vashington',
  isVip: false,
  isActive: true,
  isManaged: true,
};
const mockedContact2ShortData: TContactShortData = {
  id: 'mockedContact2ForDeviceMapping',
  name: 'Contact 2',
  email: 'contact2@gmail.com',
  phoneNumber: '+17794100000',
  cellPhoneNumber: '+10789898000',
  phoneExtension: '005',
  jobTitle: 'Sales Manager',
  personalAddress: 'USA, CA, Vashington',
  isVip: false,
  isActive: true,
  isManaged: true,
};
const device1: TDevicesGet = {
  id: 'device1Id',
  company: companyAMockedShortData,
  contact: undefined,
  source: EDevicesSources.Manual,
  name: 'Tarons-MacBook-Pro.local',
  model: 'MacBook Pro, Intel(R) Core(TM) i7-9750H CPU @ 2.60GHz, 2019',
  deviceType: 'Laptop',
  isDeleted: false,
  isActive: true,
  isManaged: true,
  creationDateTime: '2023-06-15T16:35:57.167503',
};
const device2: TDevicesGet = {
  id: 'device2Id',
  company: companyAMockedShortData,
  contact: mockedContact1ShortData,
  source: EDevicesSources.Manual,
  name: 'Dell',
  model: 'latitude',
  deviceType: 'Laptop',
  isDeleted: false,
  isActive: true,
  isManaged: true,
  creationDateTime: '2023-06-20T14:43:36.449851',
};
const device3: TDevicesGet = {
  id: 'device3Id',
  company: companyAMockedShortData,
  contact: undefined,
  source: EDevicesSources.Manual,
  name: 'Acer',
  model: 'Nitro',
  deviceType: 'Laptop',
  isDeleted: false,
  isActive: true,
  isManaged: true,
  creationDateTime: '2023-06-20T14:43:36.449851',
};
const device4: TDevicesGet = {
  id: 'device4Id',
  company: companyAMockedShortData,
  contact: mockedContact2ShortData,
  source: EDevicesSources.Manual,
  name: 'Ipad',
  model: 'Apple iPad',
  deviceType: '',
  isDeleted: false,
  isActive: true,
  isManaged: true,
  creationDateTime: '2023-06-20T14:43:36.449851',
};

const mockedPagableDevicesData: TPageableDataWithContent<TDevicesGet[]> = {
  ...pageableData,
  totalElements: 4,
  totalPages: 1,
  numberOfElements: 4,
  empty: false,
  content: [device1, device2, device3, device4],
};

export { device1, mockedPagableDevicesData };
