import React, { FC } from 'react';

import { Colors } from 'core/CssVariables';
import { SModal, SDeleteWrapperMessage } from './Modal.style';
import { TModalDeleteProps, TModalProps } from './Modal.types';
import { Icon, TIconNames } from '../Icon';
import { Text } from '../Typography';
import { modalText } from './Modal.data';

const Modal: FC<Omit<TModalProps, 'width'>> = props => {
  const { okText, size } = props;
  return (
    <SModal
      closeIcon={
        <Icon icon={TIconNames.HIGHLIGHT_OFF} data-testid="modal-close-icon" />
      }
      okText={okText || 'Save'}
      width={size}
      maskClosable={false}
      {...props}
    />
  );
};

const ModalDeleteConfirmation: FC<Omit<TModalDeleteProps, 'width'>> = props => {
  const {
    title,
    onCancel,
    onOk,
    warningText,
    subtitleText,
    size,
    informationText,
    mode,
  } = props;
  const onClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    if (onCancel) {
      onCancel(e);
    }
  };
  const onSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    if (onOk) {
      onOk(e);
    }
  };
  return (
    <SModal
      closeIcon={
        <Icon icon={TIconNames.HIGHLIGHT_OFF} data-testid="modal-close-icon" />
      }
      width={size}
      maskClosable={false}
      {...props}
      onCancel={onClose}
      onOk={onSubmit}
      mode={mode}
    >
      <SDeleteWrapperMessage>
        {subtitleText && subtitleText}
        {!subtitleText &&
          typeof title === 'string' &&
          modalText(title).deleteConfirmBody}
        {!subtitleText && !title && modalText().deleteConfirmBodyDefault}
      </SDeleteWrapperMessage>
      {warningText && (
        <SDeleteWrapperMessage className="pt-2">
          <Text fontlevel={5} fontWeight={600} color={Colors.WarningColor}>
            Warning:&nbsp;
          </Text>
          {warningText}
        </SDeleteWrapperMessage>
      )}
      {informationText && (
        <SDeleteWrapperMessage className="pt-2">
          <Text fontlevel={5} fontWeight={600} color={Colors.InfoColor}>
            Information:&nbsp;
          </Text>
          {informationText}
        </SDeleteWrapperMessage>
      )}
    </SModal>
  );
};

export default Modal;
export { ModalDeleteConfirmation };
