import styled from 'styled-components';
import { Text } from 'components/Typography';
import { Screens } from 'core/CssVariables';

const SSeconds = styled(Text)`
  display: inline-block;
  @media (max-width: ${Screens.ScreensXS}) {
    display: none;
  }
`;

export default SSeconds;
