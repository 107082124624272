import { MemberSelectLabel } from 'components/MembersSelect';
import React, { useMemo } from 'react';
import { useGetTaskAssigneesQuery } from 'redux/apiSlices/task.slices';
import { TFilterProps } from '../../FilterComponents.type';
import { ReporterFilter } from './ReporterFilter';

const ReporterTaskFilter: React.FC<TFilterProps> = ({
  onSelected,
  selectedValues,
}) => {
  const { data: membersList } = useGetTaskAssigneesQuery();
  const options = useMemo(() => {
    return (membersList || []).map(({ id, name, photoUrl }) => ({
      value: id,
      label: <MemberSelectLabel name={name} photoUrl={photoUrl} key={name} />,
    }));
  }, [membersList]);

  return (
    <ReporterFilter
      options={options}
      onSelected={onSelected}
      selectedValues={selectedValues}
    />
  );
};

export default ReporterTaskFilter;
