import { TAuthUserUi, TConverter, TAuthUser } from 'data/types';
import { TFetchConverter } from 'data/types/converter.types';
import {
  TGroup,
  TGroupUi,
  TMember,
  TMemberMspEmployee,
  TMemberMspEmployeeUi,
  TMemberUi,
  TUserGroup,
  TUserGroupUi,
  TUserRole,
  TUserRoleUi,
} from 'data/types/user.types';
import { GetFirstLetters } from './general.converters';

export const userRolesConverter: TConverter<TUserRoleUi, TUserRole> = {
  fromDb: data => {
    return TUserRoleUi[TUserRole[data] as keyof typeof TUserRoleUi];
  },
  toDb: data => {
    return TUserRole[TUserRoleUi[data] as keyof typeof TUserRole];
  },
};

export const userConverter: TConverter<TAuthUserUi, TAuthUser> = {
  fromDb: data => {
    return {
      id: data.id,
      organization: data.organization,
      email: data.email,
      fullName: data.fullName,
      role: userRolesConverter.fromDb(data.role),
      theme: data.theme,
      groups: data.groups,
      featureFlags: JSON.parse(data.featureFlags)
    };
  },
  toDb: data => {
    return {
      id: data.id,
      organization: data.organization,
      email: data.email,
      fullName: data.fullName,
      role: userRolesConverter.toDb(data.role),
      theme: data.theme,
      groups: data.groups,
      featureFlags: JSON.stringify(data.featureFlags)
    };
  },
};

export const groupConverter: TConverter<TGroupUi, TGroup> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      membersCount: data.membersCount,
      members: data.members,
    };
  },
  toDb: data => {
    return {
      id: data.id,
      name: data.name,
      membersCount: data.membersCount,
      members: data.members,
    };
  },
};

export const memberConverter: TConverter<TMemberUi, TMember> = {
  fromDb: data => {
    let userGroupsString = '';
    if (data.groups?.length) {
      data.groups.forEach((item, index) => {
        if (index !== data.groups.length - 1) {
          userGroupsString += `${item.name}, `;
        } else {
          userGroupsString += item.name;
        }
      });
    }
    return {
      id: data.id,
      role: userRolesConverter.fromDb(data.role),
      email: data.email,
      fullName: data.fullName,
      photoUrl: data.photoUrl,
      isDeleted: data.isDeleted,
      groups: data.groups.map(group => groupConverter.fromDb(group)),
      userGroupsString,
      hourlyRate: data.hourlyRate,
    };
  },
  toDb: data => {
    return {
      id: data.id,
      role: userRolesConverter.toDb(data.role),
      email: data.email,
      fullName: data.fullName,
      photoUrl: data.photoUrl,
      isDeleted: data.isDeleted,
      groups: data.groups.map(group => groupConverter.toDb(group)),
    };
  },
};

export const userGroupConverter: TConverter<TUserGroupUi, TUserGroup> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      membersCount: data.membersCount,
      members: data.members,
    };
  },
  toDb: data => {
    return {
      id: data.id,
      name: data.name,
      membersCount: data.membersCount,
      members: data.members,
    };
  },
};

export const memberMspEmployeeConverter: TFetchConverter<
  TMemberMspEmployeeUi,
  TMemberMspEmployee
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
      firstLetters: GetFirstLetters.fromDb(data.name),
    };
  },
};
