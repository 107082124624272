import React from 'react';

import Select from 'components/Select/Select';

import { TTicketAssignedUi } from 'data/types/ticket.types';
import Avatar from 'components/Avatar/Avatar';
import { TSelectProps } from 'components/Select/Select.type';

import { TPhaseTaskAssigneeUi } from 'data/types/phase.type';
import { MembersListWrapper } from './MembersSelect.style';

type TMembersSelectProps = {
  membersData?: TTicketAssignedUi[] | TPhaseTaskAssigneeUi[];
} & TSelectProps;

type TMembersSelectLabelProps = {
  name: string;
  photoUrl: string;
  avatarSize?: number;
};
export const MemberSelectLabel = ({
  name,
  photoUrl,
  avatarSize = 16,
}: TMembersSelectLabelProps) => {
  return (
    <MembersListWrapper>
      <Avatar src={photoUrl} className="mr-3" size={avatarSize}>
        {!photoUrl && name[0].toUpperCase()}
      </Avatar>
      <span className="bold-td">{name}</span>
    </MembersListWrapper>
  );
};
const MembersSelect = (props: TMembersSelectProps) => {
  const { membersData, ...rest } = props;
  return (
    <Select
      className="mt-1"
      placeholder="Select"
      showSearch
      options={membersData?.map(({ id, name, photoUrl }) => ({
        value: id,
        label: <MemberSelectLabel name={name} photoUrl={photoUrl} key={name} />,
      }))}
      {...rest}
    />
  );
};
export { MembersSelect };
