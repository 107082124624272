import {
  EInvoiceActions,
  EInvoiceStatus,
  InvoiceProductSourceType,
  TInvoiceGet,
  TInvoiceProductItem,
} from 'data/types/invoice.type';
import { TPageableDataWithContent } from 'data/types/pagableData.type';
import { companyShortData } from './company.data';
import { category } from './category.data';
import { pageableData } from './general.data';

const invoiceProductItem: TInvoiceProductItem = {
  id: '3f573b06-aeb2-4c33-a604-10a55ee8723f',
  sourceType: InvoiceProductSourceType.FREE,
  category,
  name: 'Setup server',
  unitPrice: 150,
  count: 1,
  price: 150,
  description: 'Accounting server\nConnect with quikbook',
};

const invoice: TInvoiceGet = {
  id: '725b6d7a-fd8f-4414-8eb0-d7e8c7a51521',
  creationDateTime: '2023-04-25T08:21:20.760049',
  company: companyShortData,
  status: EInvoiceStatus.Draft,
  name: 'test',
  startDate: '2023-04-20',
  endDate: '2023-04-30',
  discount: 0,
  subtotalAmount: 150,
  totalAmount: 150,
  invoiceDate: '2023-04-25',
  dueDate: '2023-05-10',
  phoneNumber: '+13216981041',
  address: 'Armenia WI USA',
  emailAddress: 'toyota.info@gmail.com',
  products: [invoiceProductItem],
  note: 'test note',
  actions: [EInvoiceActions.SAVE, EInvoiceActions.DELETE],
};

const invoiceExported = {
  ...invoice,
  id: '725b6d7a-fd8f-4414-8eb0-d7e8c7a51522',
  status: EInvoiceStatus.Exported,
  actions: [],
};
const invoiceWithAllActions = {
  ...invoice,
  id: '725b6d7a-fd8f-4414-8eb0-d7e8c7a51523',
  actions: [
    EInvoiceActions.SAVE,
    EInvoiceActions.DELETE,
    EInvoiceActions.EXPORT,
    EInvoiceActions.EXPORT_AND_SEND,
  ],
};

const mockedPageableInvoices: TPageableDataWithContent<TInvoiceGet[]> = {
  ...pageableData,
  totalElements: 3,
  totalPages: 1,
  numberOfElements: 3,
  empty: false,
  content: [invoice, invoiceExported, invoiceWithAllActions],
};
export { mockedPageableInvoices };
