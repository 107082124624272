import React, { FC } from 'react';

import { TTooltipProps } from './ToolTip.types';

import SToolTip from './ToolTip.style';

const ToolTip: FC<TTooltipProps> = props => {
  return <SToolTip {...props} />;
};

export default ToolTip;
