import React, { ChangeEventHandler, FC, useState } from 'react';
import { SSearch } from './Search.style';
import { Icon, TIconNames } from '../Icon';
import Button from '../Button/Button';
import { TSearchProps } from './Search.type';

export const Search: FC<TSearchProps> = props => {
  const { hasFilter, onClear, ...other } = props;
  const antdProps = other as TSearchProps;
  const [value, setValue] = useState('');
  const onClearValue = () => {
    setValue('');
    onClear();
  };
  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const searchValue = e.target.value;
    setValue(searchValue);
    if (!searchValue) onClear();
  };
  return (
    <SSearch
      value={value}
      onChange={onChange}
      addonAfter={
        hasFilter && (
          <Button
            data-testid="clear-btn"
            type="primary"
            shape="circle"
            onClick={onClearValue}
            icon={<Icon icon={TIconNames.HIGHLIGHT_OFF} />}
          />
        )
      }
      {...antdProps}
    />
  );
};
