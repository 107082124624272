import { TCreateConverter, TFetchConverter } from '../types/converter.types';
import {
  EDevicesSources,
  EDevicesSourcesAsParam,
  EDevicesSourcesUi,
} from '../types/devicesEnum.type';

export const deviceSourceConverter: TFetchConverter<
  EDevicesSourcesUi,
  EDevicesSources
> = {
  fromDb: data => {
    const convertedDataMapper: {
      [key in EDevicesSources]: EDevicesSourcesUi;
    } = {
      [EDevicesSources.Manual]: EDevicesSourcesUi.Manual,
      [EDevicesSources.NinjaOne]: EDevicesSourcesUi.NinjaOne,
    };
    return convertedDataMapper[data];
  },
};
export const deviceSourceAsParamConverter: TCreateConverter<
  EDevicesSourcesUi,
  EDevicesSourcesAsParam
> = {
  toDb: data => {
    const convertedDataMapper: {
      [key in EDevicesSourcesUi]: EDevicesSourcesAsParam;
    } = {
      [EDevicesSourcesUi.NinjaOne]: EDevicesSourcesAsParam.NinjaOne,
      [EDevicesSourcesUi.Manual]: EDevicesSourcesAsParam.Manual,
    };
    return convertedDataMapper[data];
  },
};
