import { rest } from 'msw';
import { FILE_UPLOAD } from 'redux/apiSlices/upload.slice';
import { contractTypeHandlers } from './apiSlices/contractType.handler';
import { invoiceHandlers } from './apiSlices/invoice.handler';
import { organizationHandlers } from './apiSlices/organization.handler';
import { contactHandlers } from './apiSlices/contacts.handler';
import { integrationsHandlers } from './apiSlices/integrations.handler';
import { companyHandlers } from './apiSlices/company.handler';
import { devicesHandler } from './apiSlices/devices.handler';
import { locationHandler } from './apiSlices/location.handler';
import { queueHandler } from './apiSlices/queue.handler';

export const handlers = [
  rest.post(`${FILE_UPLOAD}`, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json('url'));
  }),
  ...contractTypeHandlers,
  ...invoiceHandlers,
  ...organizationHandlers,
  ...contactHandlers,
  ...integrationsHandlers,
  ...companyHandlers,
  ...devicesHandler,
  ...locationHandler,
  ...queueHandler,
];
