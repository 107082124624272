import React from 'react';
import { DefaultOptionType } from 'antd/lib/select';

import Select from 'components/Select/Select';
import { FilterWrapperItem } from '../FilterComponents.style';
import { TFilterProps } from '../FilterComponents.type';

const SelectFilterItem: React.FC<
  TFilterProps & {
    options?: DefaultOptionType[];
    label: string;
    placeholder?: string;
    disabled?: boolean;
  }
> = ({ onSelected, selectedValues, options, label, placeholder, disabled }) => {
  return (
    <FilterWrapperItem>
      <label htmlFor={label.replaceAll(' ', '_')}>{label}</label>
      <Select
        id={label.replaceAll(' ', '_')}
        placeholder={placeholder || 'Select'}
        onChange={(ids: string[]) => {
          onSelected(ids);
        }}
        value={selectedValues}
        options={options}
        mode="multiple"
        maxTagCount="responsive"
        disabled={disabled}
      />
    </FilterWrapperItem>
  );
};

export { SelectFilterItem };
