import React, { useMemo, useState } from 'react';
import Form, { FormInstance } from 'antd/es/form';
import { Col, Row } from 'components/Grid/Grid';
import { FormItem } from 'components/Form/FormItem';
import { Input } from 'components/Input';
import Select from 'components/Select/Select';
import { useGetCompaniesQuery } from 'redux/apiSlices/company.slice';
import { TOptionType } from 'data/types/generalDataTypes';
import { TContactPostUi } from 'data/types/contact.type';
import { validationRules } from 'components/Form/validationRules';
import Checkbox from 'components/Checkbox';
import { useGetLocationsQuery } from 'redux/apiSlices/location.slices';

type TAddContactFormProps = {
  form: FormInstance<TContactPostUi>;
  defaultCompanyId?: string;
};
const AddContactForm: React.FC<TAddContactFormProps> = ({
  form,
  defaultCompanyId,
}) => {
  const [selectedCompany, setSelectedCompany] = useState(
    defaultCompanyId || '',
  );

  const { data: companyList, isLoading: companyListLoading } =
    useGetCompaniesQuery({
      sort: 'name,ASC',
      page: 0,
      pageSize: 2000,
    });
  const { data: locations, isLoading: locationsLoading } = useGetLocationsQuery(
    {
      sort: ['name,ASC'],
      page: 0,
      size: 2000,
      filters: {
        companies: [selectedCompany],
      },
    },
  );
  const companiesOptions: TOptionType[] = useMemo(() => {
    if (companyList?.content.length) {
      return companyList?.content.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }
    return [];
  }, [companyList]);

  const locationsOptions: TOptionType[] = useMemo(() => {
    if (locations?.content.length) {
      return locations?.content.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }
    return [];
  }, [locations]);

  return (
    <Form form={form} name="contact" autoComplete="off" preserve={false}>
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12} className="mb-6">
          <FormItem
            name="name"
            marginBottom="0"
            label="Full name"
            rules={[validationRules.required()]}
          >
            <Input placeholder="Full name" />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12} className="mb-6">
          <FormItem
            name="email"
            marginBottom="0"
            label="Email address"
            rules={[validationRules.required(), validationRules.email()]}
          >
            <Input placeholder="Email address" />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12} className="mb-6">
          <FormItem
            name="phoneNumber"
            marginBottom="0"
            label="Direct phone number (optional)"
          >
            <Input placeholder="Set phone number" />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12} className="mb-6">
          <FormItem
            name="cellPhoneNumber"
            marginBottom="0"
            label="Cell phone number (optional)"
          >
            <Input placeholder="Set phone number" />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12} className="mb-6">
          <FormItem
            name="jobTitle"
            marginBottom="0"
            label="Job title (optional)"
          >
            <Input placeholder="Set job title" />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12} className="mb-6">
          <FormItem
            name="personalAddress"
            marginBottom="0"
            label="Personal address (optional)"
          >
            <Input placeholder="Personal address" />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12} className="mb-6">
          <FormItem
            name="phoneExtension"
            marginBottom="0"
            label="Extension (optional)"
          >
            <Input placeholder="Set extension" />
          </FormItem>
        </Col>

        {!defaultCompanyId && (
          <Col xs={24} sm={24} md={12} xl={12} xxl={12} className="mb-6">
            <FormItem
              name="companyId"
              marginBottom="0"
              label="Company"
              rules={[validationRules.required()]}
            >
              <Select
                placeholder="Select company"
                options={companiesOptions}
                disabled={companyListLoading}
                onChange={companyId => {
                  setSelectedCompany(companyId);
                  form.resetFields(['locationId']);
                }}
                showSearch
              />
            </FormItem>
          </Col>
        )}
        <Col xs={24} sm={24} md={12} xl={12} xxl={12} className="mb-6">
          <FormItem
            name="locationId"
            marginBottom="0"
            label="Location (optional)"
          >
            <Select
              placeholder="Select location"
              options={locationsOptions}
              disabled={
                companyListLoading || locationsLoading || !selectedCompany
              }
              showSearch
            />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <FormItem name="isVip" marginBottom="0" valuePropName="checked">
          <Checkbox label="Set as a vip contact for company" />
        </FormItem>
      </Row>
    </Form>
  );
};

export { AddContactForm };
