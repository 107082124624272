import { authSplitApi } from 'redux/helpers/slice.helpers';
import {
  memberConverter,
  memberMspEmployeeConverter,
  userRolesConverter,
} from 'data/converters/user.converters';
import {
  TMember,
  TMemberMspEmployee,
  TMemberMspEmployeeUi,
  TMemberSignature,
  TMemberUi,
  TUserRole,
  TUserRoleUi,
} from 'data/types/user.types';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { pagableDataConverter } from 'data/converters/pagableData.converters';

const GET_MEMBERS = 'api/members';
const DELETE_MEMBERS = 'api/users';

export const membersApi = authSplitApi('members', [
  'Members',
  'msp-employees',
  'member-roles',
  'email-signature'
]).injectEndpoints({
  endpoints: build => ({
    deleteMemberById: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: DELETE_MEMBERS.concat(`/${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Members' },
        { type: 'Members', id: arg.id },
      ],
    }),
    deleteMembersByIds: build.mutation<void, React.Key[]>({
      query(ids) {
        return {
          url: DELETE_MEMBERS,
          method: 'DELETE',
          body: ids,
        };
      },
      invalidatesTags: [{ type: 'Members' }],
    }),
    updateMemberRole: build.mutation<
      TMemberUi,
      { id: string; value: TUserRoleUi }
    >({
      query({ id, value }) {
        return {
          url: GET_MEMBERS.concat(`/${id}/role`),
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Members' },
        { type: 'Members', id: arg.id },
      ],
      transformResponse: (data: TMember) => memberConverter.fromDb(data),
    }),
    updateMemberUserGroup: build.mutation<
      TMemberUi,
      { id: string; value: string[] }
    >({
      query({ id, value }) {
        return {
          url: GET_MEMBERS.concat(`/${id}/groups`),
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Members' },
        { type: 'Members', id: arg.id },
      ],
      transformResponse: (data: TMember) => memberConverter.fromDb(data),
    }),
    getMembers: build.query<
      TPageableDataWithContentUi<TMemberUi[]>,
      {
        page: number;
        pageSize: number;
        emails?: string[];
        fullNames?: string[];
        groupIds?: string[];
        groupNames?: string[];
        sort?: string;
        includeDeleted?: boolean;
      }
    >({
      query({
        emails,
        fullNames,
        page,
        pageSize,
        sort,
        groupIds,
        groupNames,
        includeDeleted,
      }) {
        // TODO: create a converter for this that make a url from the query params
        const urlWithParams = GET_MEMBERS.concat('/all').concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(emails?.length && { emails }),
            ...(includeDeleted && { includeDeleted }),
            ...(fullNames?.length && { fullNames }),
            ...(groupIds?.length && { groupIds }),
            ...(groupNames?.length && { groupNames }),
            ...(sort && { sort }),
          },
        };
      },
      providesTags: [{ type: 'Members' }],
      keepUnusedDataFor: 60 * 60 * 24,
      transformResponse: (data: TPageableDataWithContent<TMember[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(member => memberConverter.fromDb(member))
            : [],
        };
      },
    }),
    getMemberById: build.query<
      TMemberUi,
      {
        id: string;
        role?: TUserRoleUi;
      }
    >({
      query({ id, role }) {
        const baseUrl = `${GET_MEMBERS}/${id}`;
        const url =
          role === TUserRoleUi.Owner ? baseUrl.concat('/for-owner') : baseUrl;
        return {
          url,
          method: 'GET',
        };
      },
      providesTags: result => [{ type: 'Members', id: result?.id }],
      keepUnusedDataFor: 60 * 60 * 24,
      transformResponse: (data: TMember) => memberConverter.fromDb(data),
    }),
    getMemberEmailSignatureById: build.query<
      TMemberSignature,
      void
    >({
      query() {
        return {
          url: `${GET_MEMBERS}/email-signature`,
          method: 'GET',
        };
      },
      providesTags: ['email-signature'],
    }),
    saveMemberEmailSignature: build.mutation<void, { value?: string }>({
      query({ value }) {
        return {
          url: `${GET_MEMBERS}/email-signature`,
          method: 'POST',
          body: { value },
        };
      },
      invalidatesTags: ['Members', 'email-signature'],
    }),
    deleteMemberEmailSignature: build.mutation<void, void>({
      query() {
        return {
          url: `${GET_MEMBERS}/email-signature`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Members', 'email-signature'],
    }),
    editMemberName: build.mutation<TMemberUi, { value: string }>({
      query({ value }) {
        return {
          url: `${GET_MEMBERS}/name`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Members'],
    }),
    editMemberPhoto: build.mutation<TMemberUi, { value: string }>({
      query({ value }) {
        return {
          url: `${GET_MEMBERS}/photo`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Members'],
    }),
    getMspEmployees: build.query<TMemberMspEmployeeUi[], void>({
      query() {
        return {
          url: `${GET_MEMBERS}/msp-employees`,
          method: 'GET',
        };
      },
      providesTags: ['msp-employees'],
      transformResponse: (data: TMemberMspEmployee[]) =>
        data && data.map(el => memberMspEmployeeConverter.fromDb(el)),
    }),
    getMemberRoles: build.query<TUserRoleUi[], void>({
      query() {
        return {
          url: `${GET_MEMBERS}/permitted-roles`,
          method: 'GET',
        };
      },
      providesTags: ['member-roles'],
      transformResponse: (data: TUserRole[]) =>
        data && data.map(el => userRolesConverter.fromDb(el)),
    }),
    editMemberHourlyRate: build.mutation<
      TMemberUi,
      { value: string; id: string }
    >({
      query({ value, id }) {
        return {
          url: `${GET_MEMBERS}/${id}/hourly-rate`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Members'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMembersQuery,
  useDeleteMemberByIdMutation,
  useDeleteMembersByIdsMutation,
  useGetMemberByIdQuery,
  useGetMemberEmailSignatureByIdQuery,
  useUpdateMemberRoleMutation,
  useUpdateMemberUserGroupMutation,
  useEditMemberNameMutation,
  useEditMemberPhotoMutation,
  useGetMspEmployeesQuery,
  useGetMemberRolesQuery,
  useEditMemberHourlyRateMutation,
  useSaveMemberEmailSignatureMutation,
  useDeleteMemberEmailSignatureMutation
} = membersApi;
