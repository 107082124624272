import React from 'react';
import { Text } from 'components/Typography';
import { Colors } from 'core/CssVariables';
import { Row } from 'components/Grid/Grid';
import Tag from 'components/Tag/Tag';

type TLabelWithTagProps = {
  title: string;
  isVip?: boolean;
  isMainContact?: boolean;
  withoutTag?: boolean;
  notManaged?: boolean;
  notActive?: boolean;
};

const LabelWithTag = ({
  title,
  isVip,
  isMainContact,
  withoutTag,
  notActive,
  notManaged,
}: TLabelWithTagProps) => {
  return (
    <Row wrap={false} justify="space-between">
      <Text
        ellipsis={{ tooltip: true }}
        fontlevel={5}
        style={{ color: 'inherit' }}
      >
        {title}
      </Text>
      {!withoutTag && (
        <Row wrap={false} className="pr-2 pl-1" gutter={[4, 4]} align="middle">
          {isVip && (
            <Tag size="sm" $lineHeight={8} color={Colors.Pink} fontlevel={7}>
              Vip
            </Tag>
          )}
          {isMainContact && (
            <Tag
              size="sm"
              $lineHeight={8}
              color={Colors.SecondaryColor}
              fontlevel={7}
            >
              Main
            </Tag>
          )}
          {/* if not actvie, does not make sense to show not managed */}
          {notManaged && !notActive && (
            <Tag
              size="sm"
              $lineHeight={8}
              color={Colors.LightGrey}
              fontlevel={7}
            >
              Not Managed
            </Tag>
          )}
          {notActive && (
            <Tag
              size="sm"
              $lineHeight={8}
              color={Colors.LightGrey}
              fontlevel={7}
            >
              Not Active
            </Tag>
          )}
        </Row>
      )}
    </Row>
  );
};

export default LabelWithTag;
