import { Colors } from 'core/CssVariables';
import styled, { css, ThemeProps } from 'styled-components';
import { TThemeProps } from 'theme/theme';

export const SUpload = styled.div<{
  width?: number;
  height?: number;
  error?: boolean;
}>`
  display: flex;
  align-items: center;
  .upload-loading {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  .upload-wrapper {
    border: 1px solid
      ${({ theme }: ThemeProps<TThemeProps>) => theme.uploadButton.borderColor};
    border-radius: 50%;
    ${({ error }) =>
      error &&
      css`
        border-color: ${Colors.ErrorColor};
      `}
    display: inline-flex;
    align-items: center;
  }

  .upload-btn {
    height: ${props => props.width || 71}px;
    width: ${props => props.height || 71}px;
    border-radius: 50%;
    background-color: ${({ theme }: ThemeProps<TThemeProps>) =>
      theme.uploadButton.backgroundColor};
    position: relative;
    overflow: hidden;

    .icon {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      path {
        fill: ${({ theme }: ThemeProps<TThemeProps>) =>
          theme.uploadButton.iconColor} !important;
      }
    }
    input {
      display: none;
    }
    .upload-image {
      border-radius: 50%;
      height: ${props => props.width || 71}px;
      width: ${props => props.height || 71}px;
      position: absolute;
      inset: 0 0 0 0;
      object-fit: cover;
      pointer-events: none;
      &:not([src]) {
        display: none;
      }
    }
  }

  .upload-text {
    white-space: nowrap;
    margin-left: 12px;
  }
`;
