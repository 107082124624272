import React, {
  ChangeEvent,
  FC,
  memo,
  RefObject,
  useRef,
  useState,
} from 'react';
import { Password } from 'components/Input';
import { Colors } from 'core/CssVariables';
import useResize from 'hooks/onResize';
import Progress from 'components/Progress';
import { PasswordValidationTags } from 'components/PasswordProgress/PasswordValidationTags';
import { regexPatterns } from 'components/Form/validationRules';
import {
  TPassedValidations,
  TPasswordProgress,
  TValidationRules,
  TValidationTags,
} from './types';

const validationRules: TValidationRules = {
  [TValidationTags.LOWERCASE]: regexPatterns.oneLowerCase,
  [TValidationTags.UPPERCASE]: regexPatterns.oneUpperCase,
  [TValidationTags.NUMBER]: regexPatterns.oneNumber,
  [TValidationTags.SYMBOL]: regexPatterns.oneSymbol,
  [TValidationTags.LETTERS8]: regexPatterns.letters8,
};

const PasswordProgress: FC<TPasswordProgress> = ({
  value,
  onChange,
  ...props
}) => {
  const [divWidth, setDivWidth] = useState(0);
  const [passedValidations, setPassedValidations] =
    useState<TPassedValidations>();

  const count = Object.keys(passedValidations || {}).length;
  const progressPercent = (100 / Object.keys(validationRules).length) * count;
  const passwordRef = useRef<HTMLDivElement>(null);

  const onResize = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      setDivWidth(ref.current.clientWidth);
    }
  };
  useResize<HTMLDivElement>(passwordRef, onResize);

  const validateRules = (inputValue: string) => {
    setPassedValidations({});
    const passedValidationsArr: TPassedValidations = {};
    Object.keys(validationRules).forEach(item => {
      if (validationRules[item as TValidationTags].test(inputValue)) {
        passedValidationsArr[item as TValidationTags] = true;
      }
    });
    setPassedValidations(passedValidationsArr);
  };

  const onHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    validateRules(e.target.value);
    if (onChange) onChange(e);
  };
  const getProgressStatus = () => {
    if (count < 4) {
      return Colors.ErrorColor;
    }
    if (count < 5) {
      return Colors.WarningColor;
    }
    return Colors.SuccessColor;
  };
  return (
    <div ref={passwordRef}>
      <Password
        data-testid="password-progress"
        placeholder={props.placeholder}
        value={value}
        onChange={onHandleChange}
        {...props}
      />
      <Progress
        percent={progressPercent}
        strokeColor={getProgressStatus()}
        showInfo={false}
        steps={Math.ceil(divWidth / 16)}
        strokeWidth={2}
      />
      <PasswordValidationTags passedValidations={passedValidations} />
    </div>
  );
};

export default memo(PasswordProgress);
