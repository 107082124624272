import React, { FC } from 'react';

import { Title } from 'components/Typography';
import Button from 'components/Button/Button';
import { TButtonTypes } from 'components/Button/Button.types';
import { Colors } from 'core/CssVariables';
import { TTableProps } from './Table.types';
import { SSelectedRowWrapper, STable } from './Table.style';
import TableExpandIcons from './components/TableExpandIcons';
import TableSettings from './components/TableSettings';
import useTableSettings from './useTableSettings';
import TableTotals from './components/TableTotals';

const Table: FC<TTableProps> = props => {
  const {
    pageSize,
    setPageSize,
    totalDataCount,
    selectHasOptions,
    rowSelection,
    isSelectAll,
    setIsSelectAll,
    currentPage,
    expandable,
    columns,
    tableName,
    hideSettings,
    unHidableColumnTitles,
    totals,
    ...rest
  } = props;

  const settingsInitialData = (columns || []).map(el => {
    return {
      title: el.title as string,
      show: true,
    };
  });
  const [tableSettings, setTableSettings] = useTableSettings({
    initialData: settingsInitialData,
    tableName,
  });

  const settingColumn = {
    title: (
      <TableSettings
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        unHidableColumnTitles={unHidableColumnTitles}
      />
    ),
    width: 10,
  };

  const filteredColumns = (columns || []).filter(
    el => tableSettings?.find(item => item.title === el.title)?.show,
  );
  return (
    <>
      {totalDataCount > 0 &&
        !!pageSize &&
        pageSize === rowSelection?.selectedRowKeys?.length && (
          <SSelectedRowWrapper>
            <Title fontlevel={5} color={Colors.SecondaryColor} fontWeight={400}>
              All {isSelectAll ? totalDataCount : pageSize} items on this page
              are selected.
            </Title>
            <Button
              type="link"
              color={TButtonTypes.Orange}
              className="ml-1"
              {...(setIsSelectAll && {
                onClick: () => {
                  setIsSelectAll(p => !p);
                  if (rowSelection.onChange && isSelectAll) {
                    rowSelection.onChange([], []);
                  }
                },
              })}
            >
              {isSelectAll
                ? 'Clear all'
                : `Select all ${totalDataCount} items.`}
            </Button>
          </SSelectedRowWrapper>
        )}
      <STable
        locale={{
          filterConfirm: 'Filter',
        }}
        selectHasOptions={selectHasOptions}
        rowKey="id"
        tableLayout="auto"
        showSorterTooltip={false}
        {...(rowSelection && { rowSelection })}
        pagination={
          pageSize && setPageSize
            ? {
                position: ['bottomRight'],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Results: ${range[0]}-${range[1]} of ${total}`,
                onShowSizeChange: (currentSize, size) => setPageSize(size),
                pageSize,
                total: totalDataCount,
                ...(currentPage && { current: currentPage + 1 }),
              }
            : false
        }
        expandable={{
          ...expandable,
          ...(expandable && {
            expandIcon: ({ expanded, onExpand, record }) => {
              return (
                <TableExpandIcons
                  expanded={expanded}
                  onExpand={onExpand}
                  record={record}
                  rowExpandable={expandable.rowExpandable}
                />
              );
            },
          }),
        }}
        columns={hideSettings ? columns : [...filteredColumns, settingColumn]}
        {...(totals && {
          summary: () => (
            <TableTotals totals={totals} columns={filteredColumns} />
          ),
        })}
        {...rest}
      />
    </>
  );
};

export default Table;
