import { TUserRoleUi } from 'data/types/user.types';
import { PermissionNames, Permissions } from './Permissions.type';

export const permissions: Permissions = {
  [PermissionNames.EMAIL_ADD]: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  [PermissionNames.EMAIL_DELETE]: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  [PermissionNames.TIMESHEET_ALL_MEMBERS]: [
    TUserRoleUi.Admin,
    TUserRoleUi.Owner,
  ],
  [PermissionNames.ORGANIZATION_ADD]: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  [PermissionNames.ORGANIZATION_EDIT]: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  [PermissionNames.ORGANIZATION_CSV]: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  [PermissionNames.ORGANIZATION_DELETE]: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  [PermissionNames.ORGANIZATION_ACCOUNT_MANAGER_REDIRECT]: [
    TUserRoleUi.Admin,
    TUserRoleUi.Owner,
  ],
  [PermissionNames.PROFILE_ROLE_USER_GROUP]: [
    TUserRoleUi.Admin,
    TUserRoleUi.Owner,
  ],
  [PermissionNames.CLIENT_PORTAL_SETTINGS_EDIT]: [
    TUserRoleUi.Admin,
    TUserRoleUi.Owner,
  ],
  [PermissionNames.SETTINGS_ORGANIZATION_EDIT]: [
    TUserRoleUi.Admin,
    TUserRoleUi.Owner,
  ],
  [PermissionNames.PSL_CHANGE]: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  [PermissionNames.CONTRACT_EDIT]: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  [PermissionNames.INVOICE_EDIT]: [TUserRoleUi.Admin, TUserRoleUi.Owner],
  [PermissionNames.SETUP_NON_WORKING_DAYS]: [
    TUserRoleUi.Admin,
    TUserRoleUi.Owner,
  ],
  [PermissionNames.MEMBER_HOURLY_RATE]: [TUserRoleUi.Owner],
  [PermissionNames.RESET_GAUGE_DATA]: [TUserRoleUi.Admin, TUserRoleUi.Owner],
};
