import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  signUpApi,
  getUserApi,
  uploadFileApi,
  organizationsApi,
  invitationsApi,
  goNextApi,
} from 'redux/apiSlices';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { appSettingsSlice } from './slices';
import authSlice from './slices/auth.slice';
import filtersSlice from './slices/filters.slice';
import paymentInfoSlice from './slices/paymentInfo.slice';
import { issuesApi } from './apiSlices/issue.slices';
import { membersApi } from './apiSlices/members.slices';
import { userGroupApi } from './apiSlices/userGroups.slice';
import { queuesApi } from './apiSlices/queues.slice';
import { ticketsApi } from './apiSlices/ticket.slice';
import { companiesApi } from './apiSlices/company.slice';
import { tasksApi } from './apiSlices/task.slices';
import { timeEntryApi } from './apiSlices/timeEntry.slice';
import { workFeedApi } from './apiSlices/workFeed.slice';
import { settingsApi } from './apiSlices/settings.slice';
import { contactsApi } from './apiSlices/contact.slice';
import { projectApi } from './apiSlices/project.slice';
import { firebaseApi } from './apiSlices/firebase.slice';
import { pslApi } from './apiSlices/psl.slice';
import { companyContractApi } from './apiSlices/companyContract.slice';
import { invoiceApi } from './apiSlices/invoice.slice';
import { integrationsApi } from './apiSlices/integrations.slice';
import { categoriesApi } from './apiSlices/category.slice';
import { demoControllerApi } from './apiSlices/demoControllerApi.slice';
import { reportApi } from './apiSlices/report.slice';
import { itGoalApi } from './apiSlices/itGoal.slice';
import { paymentApi } from './apiSlices/payment.slice';
import { contractTypesApi } from './apiSlices/contractTypes.slices';
import { devicesApi } from './apiSlices/devices.slice';
import { aiApi } from './apiSlices/ai.slices';
import { locationApi } from './apiSlices/location.slices';

export const rootReducer = combineReducers({
  [contractTypesApi.reducerPath]: contractTypesApi.reducer,
  [signUpApi.reducerPath]: signUpApi.reducer,
  [issuesApi.reducerPath]: issuesApi.reducer,
  [uploadFileApi.reducerPath]: uploadFileApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [invitationsApi.reducerPath]: invitationsApi.reducer,
  [membersApi.reducerPath]: membersApi.reducer,
  [userGroupApi.reducerPath]: userGroupApi.reducer,
  [queuesApi.reducerPath]: queuesApi.reducer,
  [goNextApi.reducerPath]: goNextApi.reducer,
  [getUserApi.reducerPath]: getUserApi.reducer,
  [ticketsApi.reducerPath]: ticketsApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [timeEntryApi.reducerPath]: timeEntryApi.reducer,
  [workFeedApi.reducerPath]: workFeedApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [firebaseApi.reducerPath]: firebaseApi.reducer,
  [demoControllerApi.reducerPath]: demoControllerApi.reducer,
  [pslApi.reducerPath]: pslApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
  [companyContractApi.reducerPath]: companyContractApi.reducer,
  [integrationsApi.reducerPath]: integrationsApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [itGoalApi.reducerPath]: itGoalApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  appSettings: appSettingsSlice.reducer,
  authSlice: authSlice.reducer,
  filtersSlice: filtersSlice.reducer,
  paymentInfoSlice: paymentInfoSlice.reducer,
  [devicesApi.reducerPath]: devicesApi.reducer,
  [aiApi.reducerPath]: aiApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
});

// export const rtkQueryErrorLogger: Middleware =
//   (api: MiddlewareAPI) => next => action => {
//     // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
//     if (isRejectedWithValue(action)) {
//       console.error('We got a rejected action!');
//     }
//
//     return next(action);
//   };

export const setupStore = (initialState: Record<string, any> = {}) =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(
        signUpApi.middleware,
        uploadFileApi.middleware,
        getUserApi.middleware,
        organizationsApi.middleware,
        invitationsApi.middleware,
        goNextApi.middleware,
        issuesApi.middleware,
        membersApi.middleware,
        queuesApi.middleware,
        ticketsApi.middleware,
        tasksApi.middleware,
        userGroupApi.middleware,
        companiesApi.middleware,
        timeEntryApi.middleware,
        workFeedApi.middleware,
        settingsApi.middleware,
        contactsApi.middleware,
        projectApi.middleware,
        firebaseApi.middleware,
        pslApi.middleware,
        invoiceApi.middleware,
        companyContractApi.middleware,
        integrationsApi.middleware,
        categoriesApi.middleware,
        demoControllerApi.middleware,
        reportApi.middleware,
        itGoalApi.middleware,
        paymentApi.middleware,
        contractTypesApi.middleware,
        devicesApi.middleware,
        aiApi.middleware,
        locationApi.middleware,
      ),
    preloadedState: initialState,
  });

export const store = setupStore();

export const clearAllCachedData = () => {
  store.dispatch(signUpApi.util.resetApiState());
  store.dispatch(uploadFileApi.util.resetApiState());
  store.dispatch(getUserApi.util.resetApiState());
  store.dispatch(organizationsApi.util.resetApiState());
  store.dispatch(invitationsApi.util.resetApiState());
  store.dispatch(goNextApi.util.resetApiState());
  store.dispatch(issuesApi.util.resetApiState());
  store.dispatch(membersApi.util.resetApiState());
  store.dispatch(queuesApi.util.resetApiState());
  store.dispatch(ticketsApi.util.resetApiState());
  store.dispatch(tasksApi.util.resetApiState());
  store.dispatch(userGroupApi.util.resetApiState());
  store.dispatch(companiesApi.util.resetApiState());
  store.dispatch(timeEntryApi.util.resetApiState());
  store.dispatch(workFeedApi.util.resetApiState());
  store.dispatch(settingsApi.util.resetApiState());
  store.dispatch(contactsApi.util.resetApiState());
  store.dispatch(projectApi.util.resetApiState());
  store.dispatch(pslApi.util.resetApiState());
  store.dispatch(companyContractApi.util.resetApiState());
  store.dispatch(invoiceApi.util.resetApiState());
  store.dispatch(integrationsApi.util.resetApiState());
  store.dispatch(categoriesApi.util.resetApiState());
  store.dispatch(demoControllerApi.util.resetApiState());
  store.dispatch(reportApi.util.resetApiState());
  store.dispatch(itGoalApi.util.resetApiState());
  store.dispatch(paymentApi.util.resetApiState());
  store.dispatch(contractTypesApi.util.resetApiState());
  store.dispatch(devicesApi.util.resetApiState());
  store.dispatch(aiApi.util.resetApiState());
  store.dispatch(locationApi.util.resetApiState());
};

export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
setupListeners(store.dispatch);
