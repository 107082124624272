import { TThemeProps } from 'theme/theme';
import styled, { css } from 'styled-components';
import { Row } from 'components/Grid/Grid';
import { Colors, FontWeights, Screens, Spacing } from 'core/CssVariables';
import { TQuickFilterButtonProps } from './QuickFilterButton.type';

export const SQuickFilterButton = styled.div<TQuickFilterButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.SpacingXXS}px ${Spacing.SpacingXS}px;
  font-weight: ${FontWeights.Regular};
  cursor: pointer;
  width: fit-content;
  height: 32px;

  background: ${({ theme }: { theme: TThemeProps }) =>
    theme.normalWrapper.backgroundColor};
  border-radius: 4px;
  ${({ selected }) =>
    !!selected &&
    css`
      background-color: ${Colors.SecondaryColor};
      .text {
        color: ${Colors.White};
      }
      position: relative;
      &::after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        // border-radius: 50%;
        border: solid black;
        border-width: 0px 5px 5px 0;
        border-color: ${Colors.SecondaryColor};
        position: absolute;
        right: 57px;
        bottom: -4px;
        transform: rotate(45deg);
        @media (max-width: ${Screens.ScreensMD}) {
          right: 57px;
        }
      }
    `}
`;

export const SQuickFilterButtonWrapper = styled(Row)`
  min-width: 295px;
  margin-bottom: 8px;
`;
