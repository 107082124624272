import React, { FC, useEffect, useMemo, useState } from 'react';
import Form from 'components/Form/Form';
import { Col, Row } from 'components/Grid/Grid';
import Select from 'components/Select/Select';
import { FormItem } from 'components/Form/FormItem';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { Group, TextArea } from 'components/Input';
import { useSearchCompaniesInFilterQuery } from 'redux/apiSlices/company.slice';
import {
  useGetTimeEntryAssignmentsQuery,
  useGetWorkTypesQuery,
} from 'redux/apiSlices/timeEntry.slice';
import { validationRules } from 'components/Form/validationRules';
import { AssignmentEnumUi } from 'data/types/timeEntry.types';
import { WorkTypeEnumUi } from 'data/types/workTypes.types';
import LabelWithStatus from 'components/LabelSelectOptions/LabelWithStatus';
import debounce from 'lodash/debounce';
import { useAppSelector } from 'redux/store';
import { Colors } from 'core/CssVariables';
import { Text } from 'components/Typography';
import moment, { Moment } from 'moment';
import { convertMinsToHrsMins } from 'data/converters/general.converters';
import { CompanyStatusMapperColor } from 'data/converters/company.converter';
import { getDateTimeFormats } from 'helpers/utils';
import { TCreateTimeEntryForm } from './CreateTimeEntryForm.type';
import { getStatusColor } from './CreateTimeEntry.data';

const rules = {
  workType: [validationRules.required()],
  note: [validationRules.required()],
  companyId: [validationRules.required()],
  startTime: validationRules.startTime,
  endTime: validationRules.endTime,
};

const CreateTimeEntryForm: FC<TCreateTimeEntryForm> = ({
  formObj,
  initialValues,
}) => {
  const { dateTimeFormat } = getDateTimeFormats();
  const [start, setStart] = useState<Moment | null>(
    initialValues?.startTime || null,
  );
  const [end, setEnd] = useState<Moment | null>(initialValues?.endTime || null);
  const { form } = formObj;
  const { setFieldsValue } = form;
  const currentAssignment = useAppSelector(
    state => state.appSettings.currentAssignment,
  );
  const [assignmentType, setAssignmentType] = useState<
    AssignmentEnumUi | undefined
  >(initialValues?.assignmentType);
  const [work, setWork] = useState<WorkTypeEnumUi | string | undefined>(
    initialValues?.workType,
  );
  const [companyId, setCompanyId] = useState<string | undefined>(
    initialValues?.companyId || '',
  );
  const [searchAssignment, setSearchAssignment] = useState<string>('');
  const [searchCompany, setSearchCompany] = useState<string>('');

  const { data: assignments } = useGetTimeEntryAssignmentsQuery(
    {
      assignmentType,
      companyIds: [companyId || ''],
      search: searchAssignment,
    },
    {
      skip: !assignmentType,
    },
  );
  const { data: companyList, isLoading: companyLoading } =
    useSearchCompaniesInFilterQuery({
      sort: 'name,ASC',
      page: 0,
      pageSize: 2000,
      search: searchCompany || ' ',
    });
  const { data: workTypes, isLoading: workTypesLoading } =
    useGetWorkTypesQuery();
  const companyOptions = useMemo(() => {
    if (companyList?.content.length) {
      return companyList?.content?.map(
        ({ id, nameWithAbbreviation, relationshipStatus }) => ({
          value: id,
          label: (
            <LabelWithStatus
              title={nameWithAbbreviation}
              status={relationshipStatus}
              statusColor={CompanyStatusMapperColor[relationshipStatus]}
            />
          ),
        }),
      );
    }
    return [];
  }, [companyList]);

  const currentAssignmentOptions = useMemo(() => {
    if (
      currentAssignment &&
      !assignments?.find(
        el => el.assignmentId === currentAssignment?.assignmentId,
      )
    ) {
      return [
        {
          value: currentAssignment.assignmentId,
          label: (
            <LabelWithStatus
              statusColor={getStatusColor(
                currentAssignment.assignmentType,
                currentAssignment.status,
              )}
              title={currentAssignment.name}
              status={currentAssignment.status}
            />
          ),
        },
      ];
    }
    return [];
  }, [currentAssignment]);

  const assignmentOptions = useMemo(() => {
    if (assignments?.length) {
      return assignments?.map(
        ({ assignmentId, name, status, assignmentType: type }) => ({
          value: assignmentId,
          label: (
            <LabelWithStatus
              statusColor={getStatusColor(type, status)}
              title={name}
              status={status}
            />
          ),
        }),
      );
    }
    return [];
  }, [assignments]);

  const workTypeOptions = useMemo(() => {
    if (workTypes?.length) {
      return workTypes?.map(workType => ({
        value: workType,
        label: workType,
      }));
    }
    return [];
  }, [workTypes]);

  useEffect(() => {
    if (
      work === WorkTypeEnumUi.BREAK_LUNCH ||
      work === WorkTypeEnumUi.PAID_TIME_OFF
    ) {
      setFieldsValue({ assignmentType: undefined, assignmentId: undefined });
      setAssignmentType(undefined);
    }
  }, [work]);

  const onAssignmentTypeChange = async (value: AssignmentEnumUi) => {
    setAssignmentType(value);
    setFieldsValue({ assignmentId: undefined });
  };

  useEffect(() => {
    setAssignmentType(initialValues?.assignmentType);
  }, [initialValues?.assignmentType, initialValues?.assignmentId]);

  const startDate = moment(start).seconds(0).milliseconds(0).toISOString();
  const endDate = moment(end).seconds(0).milliseconds(0).toISOString();
  const diff = moment.utc(endDate).diff(startDate, 'seconds');
  const diffInMin = convertMinsToHrsMins.fromDb(diff);
  return (
    <Form
      form={form}
      name="basic"
      initialValues={initialValues}
      autoComplete="off"
    >
      <Row gutter={[10, 0]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <FormItem name="workType" label="Work type" rules={rules.workType}>
            <Select
              showSearch
              defaultValue="Select work type"
              placeholder="Select"
              options={workTypeOptions}
              onChange={value => setWork(value)}
              loading={workTypesLoading}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <FormItem name="companyId" label="Company" rules={rules.companyId}>
            <Select
              showSearch
              defaultValue="Select company"
              options={companyOptions}
              loading={companyLoading}
              onChange={value => {
                setCompanyId(value);
                setFieldsValue({ assignmentId: undefined });
                setFieldsValue({ assignmentType: undefined });
                setAssignmentType(undefined);
              }}
              onSearch={debounce(input => {
                setSearchCompany(input);
              }, 400)}
              filterOption={() => true}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <FormItem name="startTime" label="Start date" rules={rules.startTime}>
            <DatePicker
              format={dateTimeFormat}
              showTime
              onChange={date => setStart(date)}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <FormItem name="endTime" label="End date" rules={rules.endTime}>
            <DatePicker
              format={dateTimeFormat}
              showTime
              onChange={date => setEnd(date)}
            />
          </FormItem>
        </Col>
        {start && end && (
          <Col>
            <Text fontlevel={5} color={Colors.SuccessColor}>
              Total duration: {diffInMin || 0}
            </Text>
          </Col>
        )}
        <Col span={24}>
          <FormItem label="Ticket/Task/Project Task (optional)">
            <Group compact>
              <FormItem name="assignmentType" noStyle>
                <Select
                  style={{ width: '30%' }}
                  disabled={
                    work === WorkTypeEnumUi.BREAK_LUNCH ||
                    work === WorkTypeEnumUi.PAID_TIME_OFF ||
                    !companyId ||
                    !work
                  }
                  onChange={onAssignmentTypeChange}
                  defaultValue="Select type"
                  placeholder="Select type"
                  options={[
                    { label: 'Ticket', value: AssignmentEnumUi.Ticket },
                    { label: 'Task', value: AssignmentEnumUi.Task },
                    {
                      label: 'Project Task',
                      value: AssignmentEnumUi['Project Task'],
                    },
                  ]}
                />
              </FormItem>
              <FormItem noStyle name="assignmentId">
                <Select
                  style={{ width: '70%' }}
                  showSearch
                  disabled={!assignmentType}
                  options={[...currentAssignmentOptions, ...assignmentOptions]}
                  placeholder="Select ticket/task/project task"
                  onSearch={debounce(input => {
                    setSearchAssignment(input);
                  }, 400)}
                  filterOption={() => true}
                />
              </FormItem>
            </Group>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="note" label="Description" rules={rules.note}>
            <TextArea rows={4} />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateTimeEntryForm;
