import { TAlert } from 'components/Alert/Alert.types';
import { TUserRoleUi } from 'data/types/user.types';
import { useAppSelector } from 'redux/store';
import { usePaymentAlertDays } from '../../hooks/usePaymentAlertDays';

export type TTrailAlertMessage = {
  type: TAlert['type'];
  message?: string;
  hasGoPaymentLink?: boolean;
  boldMessage?: string;
};
export type TUseTrailAlertMessageProps = {
  expirationTime?: number;
  hasPayment?: boolean;
};

export const useTrailAlertMessage = ({
  expirationTime,
  hasPayment,
}: TUseTrailAlertMessageProps): TTrailAlertMessage => {
  const { dayInMessage, expirationDays } = usePaymentAlertDays({
    expirationTime,
  });
  const role = useAppSelector(state => state.authSlice.user?.role);

  if (hasPayment) {
    return {
      type: 'info',
      boldMessage: `Your trial ends ${dayInMessage}.`,
    };
  }
  if (expirationDays >= 1 && expirationDays <= 2) {
    return {
      type: 'error',
      message:
        role === TUserRoleUi.Technician
          ? 'Please contact your MSP administrators'
          : '',
      boldMessage: `Your trial ends ${dayInMessage}.`,
      hasGoPaymentLink: role !== TUserRoleUi.Technician,
    };
  }
  return {
    type: 'info',
    boldMessage: `Your trial ends ${dayInMessage}.`,
    hasGoPaymentLink: role !== TUserRoleUi.Technician,
  };
};
