import styled from 'styled-components';
import TimePicker from 'antd/es/time-picker';
import { Colors } from 'core/CssVariables';

const { RangePicker } = TimePicker;
export const STimePicker = styled(TimePicker)``;
export const SRangePicker = styled(RangePicker)`
  &.ant-picker {
    background-color: ${({ theme }) => theme.datePicker.backgroundColor};
    border-color: ${({ theme }) => theme.datePicker.borderColor};
    .ant-picker-clear {
      background: transparent;
    }
    .ant-picker-input {
      > input {
        color: ${Colors.LightGrey};
      }
    }
    .anticon {
      color: ${Colors.LightGrey};
    }
  }
  &.ant-picker-range {
    width: 100%;

    .ant-picker-active-bar {
      display: none;
    }
  }

  .ant-picker-input {
    > input {
      padding: 0 4px;
      color: ${Colors.LightGrey};
    }
  }
`;
