import Menu from 'antd/es/menu';
import { Icon } from 'components/Icon';
import { Screens } from 'core/CssVariables';
import styled, { ThemeProps } from 'styled-components';
import { TThemeProps } from 'theme/theme';

export const SMenu = styled(Menu)`
  && {
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    border: 0;

    svg {
      fill: ${props => props.theme.sidebar.color};
    }

    li {
      height: auto;
      margin: 0 !important;
      padding: 12px 0 12px 12px;
      background-color: ${props => props.theme.sidebar.menuBackgroundColor};
      transition: border-color 0.3s,
        padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);

      &:after {
        content: none;
      }

      .ant-menu-submenu-arrow {
        color: ${props => props.theme.sidebar.color};
      }

      &:hover {
        .ant-menu-submenu-arrow {
          color: ${props => props.theme.sidebar.color};
        }
      }

      .ant-menu-submenu-title:active {
        background: transparent;
      }

      .ant-menu-title-content {
        padding-left: 12px;
        margin-left: 0;
        user-select: none;
        .icon {
          margin-right: 12px;
        }
      }
    }

    a {
      text-decoration: none;
      color: ${props => props.theme.sidebar.color};

      &:hover {
        color: ${props => props.theme.sidebar.color};
      }
    }
  }

  &.ant-menu-root {
    background-color: ${props => props.theme.sidebar.backgroundColor};

    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        font-weight: 600;
      }
    }

    .ant-menu-item-selected {
      background-color: ${props => props.theme.sidebar.backgroundColor};
      color: white;
      font-weight: 600;
      a {
        color: white;
      }

      .ant-menu-title-content {
        background: linear-gradient(6.63deg, #602dd3 3.52%, #9a49ff 93.14%);
        border-radius: 0px 20px 20px 0px;
        color: white;
        svg {
          fill: white;
        }
      }
    }

    &.ant-menu-inline {
      & .ant-menu-sub {
        transition: height 0.3s,
          background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
          padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        background-color: ${props => props.theme.sidebar.backgroundColor};
        li {
          padding-left: 24px !important;
          margin-bottom: 0;
          background-color: transparent;

          a {
            color: white;
          }

          && .ant-menu-submenu-title {
            &:active {
              background: red;
            }
          }
        }
      }

      .ant-menu-submenu-open,
      .ant-menu-submenu-selected {
        background-color: ${props => props.theme.sidebar.backgroundColor};

        svg {
          fill: white;
        }

        .ant-menu-submenu-arrow {
          color: white;
        }

        .ant-menu-item-selected {
          background-color: #4e277e;
          border-radius: 0px 20px 20px 0px;
        }

        .ant-menu-title-content {
          color: white;
          background: transparent;
        }

        .ant-menu-submenu-title {
          border-radius: 0px 20px 0 0px;
          background: linear-gradient(10.63deg, #602dd3 -156%, #9a49ff 44%);
        }

        ul {
          border-radius: 0px 0 20px 0px;
          background: linear-gradient(6.63deg, #602dd3 0%, #9a49ff 93%);
        }
      }

      .ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
        .ant-menu-submenu-title {
          border-radius: 0px 20px 20px 0px;
        }
      }

      .ant-menu-submenu-title,
      .ant-menu-item {
        padding-left: 12px !important;
        color: ${props => props.theme.sidebar.color};
        margin: 0;
        width: auto;
        transition: border-color 0.3s,
          padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }

  .ant-menu-submenu-open,
  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    & + li {
      border-radius: 0px 20px 0 0px;
    }
  }
`;

export const SSidebarLogo = styled.div<{
  previous: string;
  collapsed?: boolean;
}>`
  border-radius: ${props => props.previous === 'true' && '0px 0 20px 0px'};
  background: ${props => props.theme.sidebar.menuBackgroundColor};
  padding: ${props => (props.collapsed ? '24px 0' : '24px 0 24px 24px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon-logo {
    width: 70px;
    height: 24px;
    cursor: pointer;
  }
  @media (max-width: ${Screens.ScreensMD}) {
    justify-content: start;
  }
`;

export const SSidebarExpandIcon = styled(Icon)`
  background: ${({ theme }: ThemeProps<TThemeProps>) => theme.backgroundColor};
  fill: ${({ theme }: ThemeProps<TThemeProps>) => theme.color};
  cursor: pointer;
  border-radius: 18px;
  margin-right: 4px;
  @media (max-width: ${Screens.ScreensMD}) {
    visibility: hidden;
  }
`;
