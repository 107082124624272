import React, { memo } from 'react';
import { TUserRoleUi } from 'data/types/user.types';
import { useAppSelector } from 'redux/store';
import { SHeaderAlert } from 'components/Layout/HeaderAlert/HeaderAlert.style';
import HeaderAlertMessage from 'components/Layout/HeaderAlert/components/HeaderAlertMessage';
import { usePaymentAlertDays } from '../hooks/usePaymentAlertDays';
import { THeaderAlertMessageProps } from '../../HeaderAlert.type';

const GraceAlert = () => {
  const paymentInfo = useAppSelector(state => state.paymentInfoSlice);
  const role = useAppSelector(state => state.authSlice.user?.role);
  const { expirationDays } = usePaymentAlertDays({
    expirationTime: paymentInfo?.expirationDate,
  });
  const dayInMessage = expirationDays <= 1 ? 'today' : `${expirationDays} days`;
  const alertMessage: THeaderAlertMessageProps =
    role === TUserRoleUi.Technician
      ? {
          boldMessage: 'There are some difficulties with payment.',
          message: 'Please contact your MSP administrators.',
        }
      : {
          boldMessage: 'Payment failed.',
          message: `Please enter valid payment information within ${dayInMessage} to avoid loss of access.`,
          hasGoPaymentLink: true,
        };

  return (
    <SHeaderAlert
      type="error"
      message={<HeaderAlertMessage {...alertMessage} />}
    />
  );
};

export default memo(GraceAlert);
