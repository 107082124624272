import styled from 'styled-components';
import { TImage } from './Image.type';

const SImage = styled.img<TImage>`
  width: ${props => props.width}px;
  height: ${({ width, shape, height }) =>
    shape === 'circle' ? width : height}px;
  border-radius: ${({ shape }) => shape === 'circle' && 50}%;
  svg {
    fill: ${props => props.theme.color};
  }
`;

export default SImage;
