import React, { memo } from 'react';
import { useAppSelector } from 'redux/store';
import { SHeaderAlert } from 'components/Layout/HeaderAlert/HeaderAlert.style';
import HeaderAlertMessage from 'components/Layout/HeaderAlert/components/HeaderAlertMessage';
import { useTrailAlertMessage } from './hooks/useTrailAlertMessage';

const TrailAlert = () => {
  const paymentInfo = useAppSelector(state => state.paymentInfoSlice);
  const trailAlertMessage = useTrailAlertMessage({
    expirationTime: paymentInfo?.expirationDate,
    hasPayment: paymentInfo?.paymentMethodExists,
  });
  return (
    <SHeaderAlert
      type={trailAlertMessage.type}
      message={<HeaderAlertMessage {...trailAlertMessage} />}
    />
  );
};

export default memo(TrailAlert);
