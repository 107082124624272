import React, { useMemo } from 'react';
import Form, { FormInstance } from 'antd/es/form';
import { Col, Row } from 'components/Grid/Grid';
import Button from 'components/Button/Button';
import { Icon, TIconNames } from 'components/Icon';
import { Colors } from 'core/CssVariables';
import { FormItem } from 'components/Form/FormItem';
import { Input } from 'components/Input';
import Select from 'components/Select/Select';
import { useGetUserGroupsQuery } from 'redux/apiSlices/userGroups.slice';
import { TInvitationsPostDataUi } from 'data/types';
import { TOptionType } from 'data/types/generalDataTypes';
import { useGetMemberRolesQuery } from 'redux/apiSlices/members.slices';
import { SWrapperWithCloseButton } from '../Invitations.style';

type TProps = {
  form: FormInstance<TInvitationsPostDataUi>;
};
const InvitationMemberForm: React.FC<TProps> = ({ form }) => {
  const { data: UserGroupsData, isLoading: UserGroupsLoading } =
    useGetUserGroupsQuery({
      page: 0,
      pageSize: 2000,
      search: '',
      sort: 'name,ASC',
    });
  const { data: roles } = useGetMemberRolesQuery();
  const rolesOptions: TOptionType[] = useMemo(
    () =>
      (roles || [])?.map(role => ({
        value: role,
        label: role,
      })),
    [roles],
  );
  return (
    <Form form={form} name="basic" autoComplete="off" preserve={false}>
      <Form.List
        name="invitation"
        initialValue={[
          {
            email: undefined,
            role: undefined,
            // userGroups: [],
          },
        ]}
      >
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} gutter={[10, 0]}>
                  <Col xs={24} md={9} className="mb-6">
                    <FormItem
                      {...restField}
                      name={[name, 'email']}
                      marginBottom="0"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        {
                          type: 'email',
                          message: 'invalid email',
                        },
                      ]}
                    >
                      <Input placeholder="Email address" />
                    </FormItem>
                  </Col>
                  <Col xs={24} md={9} className="mb-6">
                    <FormItem
                      {...restField}
                      name={[name, 'groupIds']}
                      marginBottom="0"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                      ]}
                    >
                      <Select
                        placeholder="User group"
                        maxTagCount="responsive"
                        options={UserGroupsData?.content?.map(
                          ({ id: groupId, name: groupName }) => ({
                            value: groupId,
                            label: `${groupName}`,
                          }),
                        )}
                        filterOption={(input, option) => {
                          if (typeof option?.label === 'string') {
                            return (
                              option?.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }
                          return false;
                        }}
                        mode="multiple"
                        loading={UserGroupsLoading}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} md={6} className="mb-6">
                    <SWrapperWithCloseButton>
                      <FormItem
                        {...restField}
                        name={[name, 'role']}
                        marginBottom="0"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <Select placeholder="Role" options={rolesOptions} />
                      </FormItem>
                      {fields.length > 1 && (
                        <Icon
                          className="pointer remove-icon"
                          icon={TIconNames.HIGHLIGHT_OFF}
                          onClick={() => remove(name)}
                          size={24}
                          color={Colors.ErrorColor}
                        />
                      )}
                    </SWrapperWithCloseButton>
                  </Col>
                </Row>
              ))}
              <FormItem>
                <Button
                  type="link"
                  htmlType="button"
                  onClick={() => add()}
                  icon={<Icon icon={TIconNames.PLUS} />}
                >
                  Add new field
                </Button>
              </FormItem>
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};

export { InvitationMemberForm };
