import { TAuthConverter } from 'data/types';
import {
  TContractType,
  TContractTypeEdit,
  TContractTypeEditUI,
  TContractTypePost,
  TContractTypePostUI,
  TContractTypeUI,
} from 'data/types/contractTypes.type';
import { TCreateConverter } from 'data/types/converter.types';

export const contractTypesConverter: TAuthConverter<
  TContractTypePostUI,
  TContractTypePost,
  TContractTypeUI,
  TContractType
> = {
  toDb: data => {
    return {
      name: data,
    };
  },
  fromDb: data => {
    return {
      name: data.name,
      id: data.id,
    };
  },
};

export const contractTypeEditConverter: TCreateConverter<
  TContractTypeEditUI,
  TContractTypeEdit
> = {
  toDb: data => {
    return {
      value: data.name,
    };
  },
};
