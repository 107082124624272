import { authSplitApi } from 'redux/helpers/slice.helpers';
import {
  TInactiveQueueUi,
  TQueue,
  TQueueSlaTargetsUpdateUi,
  TQueueUi,
} from 'data/types/queues.types';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import {
  inactiveQueueConverter,
  queueConverter,
  queueSlaPatchConverter,
} from 'data/converters/queues.converters';

export const QUEUES_URL = 'api/queues';
export const queuesApi = authSplitApi('queuesApi', [
  'Queues',
  'Queue',
]).injectEndpoints({
  endpoints: build => ({
    getQueues: build.query<
      TPageableDataWithContentUi<TQueueUi[]>,
      {
        page?: number;
        pageSize?: number;
        sort?: string;
        showHidden?: boolean;
      }
    >({
      query({ sort, pageSize, page, showHidden }) {
        return {
          url: QUEUES_URL,
          method: 'GET',
          params: {
            page: page || 0,
            size: pageSize || 2000,
            ...(sort && { sort }),
            showHidden: showHidden !== undefined ? showHidden : false,
          },
        };
      },
      providesTags: result =>
        result
          ? [
              { type: 'Queues', id: 'LIST' },
              ...result.content.map(({ id }) => ({
                type: 'Queues' as const,
                id,
              })),
            ]
          : [{ type: 'Queues', id: 'LIST' }],
      transformResponse: (data: TPageableDataWithContent<TQueue[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(issue => queueConverter.fromDb(issue))
            : [],
        };
      },
    }),
    getQueue: build.query<TQueueUi, string>({
      query(resourceId) {
        return {
          url: `${QUEUES_URL}/${resourceId}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TQueue) => queueConverter.fromDb(data),
      providesTags: ['Queue'],
    }),
    updateQueueIssues: build.mutation<
      TQueue,
      { id: string; issueTypes: string[] }
    >({
      query({ id, issueTypes }) {
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: `${QUEUES_URL}/${id}/issuetypes`,
          method: 'PATCH',
          body: issueTypes,
          transformResponse: (data: TQueue) => queueConverter.fromDb(data),
        };
      },
      invalidatesTags: ['Queues', 'Queue'],
    }),
    updateUserGroups: build.mutation<
      TQueueUi,
      { id: string; userGroups: string[] }
    >({
      query({ id, userGroups }) {
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: `${QUEUES_URL}/${id}/usergroups`,
          method: 'PATCH',
          body: userGroups,
        };
      },
      transformResponse: (data: TQueue) => queueConverter.fromDb(data),
      invalidatesTags: ['Queues', 'Queue'],
    }),
    updateSlaTargets: build.mutation<
      TQueueUi,
      { id: string; slaTargets: TQueueSlaTargetsUpdateUi }
    >({
      query({ id, slaTargets }) {
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: `${QUEUES_URL}/${id}/slatargets`,
          method: 'PATCH',
          body: [queueSlaPatchConverter.toDb(slaTargets)],
        };
      },
      transformResponse: (data: TQueue) => queueConverter.fromDb(data),
      invalidatesTags: ['Queues', 'Queue'],
    }),
    deleteUserGroup: build.mutation<
      TQueueUi,
      { id: string; userGroupId: string }
    >({
      query({ id, userGroupId }) {
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: `${QUEUES_URL}/${id}/usergroups/${userGroupId}`,
          method: 'DELETE',
        };
      },
      transformResponse: (data: TQueue) => queueConverter.fromDb(data),
      invalidatesTags: ['Queues', 'Queue'],
    }),
    deleteIssueType: build.mutation<
      TQueueUi,
      { id: string; issueTypeId: string }
    >({
      query({ id, issueTypeId }) {
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: `${QUEUES_URL}/${id}/issuetypes/${issueTypeId}`,
          method: 'DELETE',
        };
      },
      transformResponse: (data: TQueue) => queueConverter.fromDb(data),
      invalidatesTags: ['Queues', 'Queue'],
    }),
    inactiveQueue: build.mutation<TQueue, TInactiveQueueUi>({
      query(body) {
        const { id, hidden } = inactiveQueueConverter.toDb(body);
        return {
          headers: {
            'content-type': 'application/json',
          },
          url: `${QUEUES_URL}/${id}/hidden`,
          method: 'PATCH',
          body: hidden,
        };
      },
      invalidatesTags: [{ type: 'Queues', id: 'LIST' }, 'Queue'],
    }),
    renameQueue: build.mutation<void, { id: string; value: string }>({
      query(body) {
        const { id, value } = body;
        return {
          url: `${QUEUES_URL}/${id}/rename`,
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Queues', 'Queue'],
    }),
    updateQueueDescription: build.mutation<void, { id: string; value: string }>(
      {
        query(body) {
          const { id, value } = body;
          return {
            url: `${QUEUES_URL}/${id}/description`,
            method: 'PATCH',
            body: {
              value,
            },
          };
        },
        invalidatesTags: ['Queues', 'Queue'],
      },
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetQueuesQuery,
  useGetQueueQuery,
  useUpdateSlaTargetsMutation,
  useInactiveQueueMutation,
  useUpdateQueueIssuesMutation,
  useUpdateUserGroupsMutation,
  useDeleteIssueTypeMutation,
  useDeleteUserGroupMutation,
  useRenameQueueMutation,
  useUpdateQueueDescriptionMutation,
} = queuesApi;
