import React, { FC } from 'react';
import { TButtonTypes } from 'components/Button/Button.types';
import { Icon, TIconNames } from 'components/Icon';
import Button from 'components/Button/Button';

type TTTimeTrackerStopProps = {
  onClick: () => void;
  iconName: TIconNames;
  outline?: boolean;
  loading?: boolean;
  disabled?: boolean;
};
const TimeTrackerAction: FC<TTTimeTrackerStopProps> = ({
  onClick,
  outline,
  iconName,
  loading = false,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      width={32}
      type="primary"
      color={TButtonTypes.Info}
      shape="circle"
      outline={outline}
      icon={<Icon icon={iconName} size={15} />}
    />
  );
};

export default TimeTrackerAction;
