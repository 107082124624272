import { invitationConverter } from 'data/converters/invitation.converters';
import {
  TInvitationGetData,
  TInvitationGetDataUi,
  TInvitationsPostData,
  TInvitationsPostDataUi,
} from 'data/types/invitation.types';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import { pagableDataConverter } from 'data/converters/pagableData.converters';

const INVITATIONS_URL = 'api/invitations';

export const invitationsApi = authSplitApi('invitations', [
  'Invitations',
]).injectEndpoints({
  endpoints: build => ({
    // TODO: this name(invitations) is not correct, it should be createInvitation or something like that
    invitations: build.mutation<TInvitationsPostDataUi, TInvitationsPostData>({
      query(body) {
        return {
          url: INVITATIONS_URL,
          method: 'POST',
          body: invitationConverter.toDb(body),
        };
      },
      invalidatesTags: ['Invitations'],
    }),
    deleteInvitationById: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: INVITATIONS_URL.concat(`/${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Invitations'],
    }),
    deleteInvitationsByIds: build.mutation<void, React.Key[]>({
      query(ids) {
        return {
          url: INVITATIONS_URL,
          method: 'DELETE',
          body: ids,
        };
      },
      invalidatesTags: ['Invitations'],
    }),
    getInvitations: build.query<
      TPageableDataWithContentUi<TInvitationGetDataUi[]>,
      {
        page: number;
        pageSize: number;
        emails?: string[];
        status?: string;
        sort?: string;
      }
    >({
      query({ emails, status, page, pageSize, sort }) {
        // TODO: create a converter for this that make a url from the query params
        const urlWithParams = INVITATIONS_URL?.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(emails?.length && { emails }),
            ...(status && { status }),
            ...(sort && { sort }),
          },
        };
      },
      providesTags: (result: any) =>
        result?.content instanceof Array
          ? [
              { type: 'Invitations', id: 'LIST' },
              ...result.content.map((item: TInvitationGetDataUi) => ({
                type: 'Invitations' as const,
                id: item.id,
              })),
            ]
          : [{ type: 'Invitations', id: 'LIST' }],
      transformResponse: (
        data: TPageableDataWithContent<TInvitationGetData[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(invitation =>
                invitationConverter.fromDb(invitation),
              )
            : [],
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useInvitationsMutation,
  useGetInvitationsQuery,
  useDeleteInvitationByIdMutation,
  useDeleteInvitationsByIdsMutation,
} = invitationsApi;
