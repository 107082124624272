import {
  serviceQueueConverter,
  lineChartConverter,
  mspPerfCommonFilterConverter,
  worktypeReportFilterConverter,
  customerReportFilterConverter,
  techPerfCommonFilterConverter,
  customerPerfCommonFilterConverter,
  serviceQueueCommonFilterConverter,
} from 'data/converters/report.converter';
import {
  TServiceQueueGet,
  TServiceQueueGetUi,
  TLineChartData,
  TLineChartDataUi,
  TMspPerfCommonFilterUi,
  TWorktypeReportFilterUi,
  TCustomerReportFilterUi,
  TTechPerfCommonFilterUi,
  TCustomerPerfCommonFilterUi,
  TServiceQueueCommonFilterUi,
} from 'data/types/report.type';
import { authSplitApi } from 'redux/helpers/slice.helpers';

const REPORT_SERVICE_QUEUE = 'api/service-queue-performance';
const REPORT_TECH_URL = 'api/technician-performance';
const REPORT_MSP_URL = 'api/reports/msp-performance';
const REPORT_CUSTOMER_URL = 'api/customer-performance';

export const reportApi = authSplitApi('reportApi', [
  'service-queue-performance',
  'tickets-closed-per-tech',
  'tech-avg-resolution',
  'value-time-per-tech',
  'mrr-by-customer',
  'time-per-worktype',
  'tickets-per-endpoint',
  'hours-per-endpoint',
]).injectEndpoints({
  endpoints: build => ({
    getServiceQueuePerformance: build.query<
      TServiceQueueGetUi,
      {
        filters?: TServiceQueueCommonFilterUi;
      }
    >({
      query({ filters }) {
        return {
          url: `${REPORT_SERVICE_QUEUE}`,
          method: 'GET',
          params: {
            ...(filters && {
              ...serviceQueueCommonFilterConverter.toDb(filters),
            }),
          },
        };
      },
      providesTags: ['service-queue-performance'],
      transformResponse: (data: TServiceQueueGet) => {
        return data && serviceQueueConverter.fromDb(data);
      },
    }),
    getTechClosedTicketsReport: build.query<
      TLineChartDataUi,
      {
        filters?: TTechPerfCommonFilterUi;
      }
    >({
      query({ filters }) {
        return {
          url: `${REPORT_TECH_URL}/closed-tickets`,
          method: 'GET',
          params: {
            ...(filters && { ...techPerfCommonFilterConverter.toDb(filters) }),
          },
        };
      },
      providesTags: ['tickets-closed-per-tech'],
      transformResponse: (data: TLineChartData) => {
        return data && lineChartConverter.fromDb(data);
      },
    }),
    getTechAvgResolutionReport: build.query<
      TLineChartDataUi,
      {
        filters?: TTechPerfCommonFilterUi;
      }
    >({
      query({ filters }) {
        return {
          url: `${REPORT_TECH_URL}/avg-time`,
          method: 'GET',
          params: {
            ...(filters && { ...techPerfCommonFilterConverter.toDb(filters) }),
          },
        };
      },
      providesTags: ['tech-avg-resolution'],
      transformResponse: (data: TLineChartData) => {
        return data && lineChartConverter.fromDb(data);
      },
    }),
    getTechValueTimeReport: build.query<
      TLineChartDataUi,
      {
        commonFilters?: TTechPerfCommonFilterUi;
        filters?: TWorktypeReportFilterUi;
      }
    >({
      query({ commonFilters, filters }) {
        return {
          url: `${REPORT_TECH_URL}/value-time`,
          method: 'GET',
          params: {
            ...(commonFilters && {
              ...techPerfCommonFilterConverter.toDb(commonFilters),
            }),
            ...(filters && { ...worktypeReportFilterConverter.toDb(filters) }),
          },
        };
      },
      providesTags: ['value-time-per-tech'],
      transformResponse: (data: TLineChartData) => {
        return data && lineChartConverter.fromDb(data);
      },
    }),
    getMrrByCustomerReport: build.query<
      TLineChartDataUi,
      {
        commonFilters?: TMspPerfCommonFilterUi;
        filters?: TCustomerReportFilterUi;
      }
    >({
      query({ commonFilters, filters }) {
        return {
          url: `${REPORT_MSP_URL}/mrr-by-customer`,
          method: 'GET',
          params: {
            ...(commonFilters && {
              ...mspPerfCommonFilterConverter.toDb(commonFilters),
            }),
            ...(filters && { ...customerReportFilterConverter.toDb(filters) }),
          },
        };
      },
      providesTags: ['mrr-by-customer'],
      transformResponse: (data: TLineChartData) => {
        return data && lineChartConverter.fromDb(data);
      },
    }),
    getTimePerWorktypeReport: build.query<
      TLineChartDataUi,
      {
        commonFilters?: TMspPerfCommonFilterUi;
        filters?: TWorktypeReportFilterUi;
      }
    >({
      query({ commonFilters, filters }) {
        return {
          url: `${REPORT_MSP_URL}/time-per-work-type`,
          method: 'GET',
          params: {
            ...(commonFilters && {
              ...mspPerfCommonFilterConverter.toDb(commonFilters),
            }),
            ...(filters && { ...worktypeReportFilterConverter.toDb(filters) }),
          },
        };
      },
      providesTags: ['time-per-worktype'],
      transformResponse: (data: TLineChartData) => {
        return data && lineChartConverter.fromDb(data);
      },
    }),
    getTicketsPerEndpointReport: build.query<
      TLineChartDataUi,
      {
        commonFilters?: TCustomerPerfCommonFilterUi;
      }
    >({
      query({ commonFilters }) {
        return {
          url: `${REPORT_CUSTOMER_URL}/tickets-per-endpoint`,
          method: 'GET',
          params: {
            ...(commonFilters && {
              ...customerPerfCommonFilterConverter.toDb(commonFilters),
            }),
          },
        };
      },
      providesTags: ['tickets-per-endpoint'],
      transformResponse: (data: TLineChartData) => {
        return data && lineChartConverter.fromDb(data);
      },
    }),
    getHoursPerEndpointReport: build.query<
      TLineChartDataUi,
      {
        commonFilters?: TCustomerPerfCommonFilterUi;
      }
    >({
      query({ commonFilters }) {
        return {
          url: `${REPORT_CUSTOMER_URL}/hours-per-endpoint`,
          method: 'GET',
          params: {
            ...(commonFilters && {
              ...customerPerfCommonFilterConverter.toDb(commonFilters),
            }),
          },
        };
      },
      providesTags: ['hours-per-endpoint'],
      transformResponse: (data: TLineChartData) => {
        return data && lineChartConverter.fromDb(data);
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetServiceQueuePerformanceQuery,
  useGetTechClosedTicketsReportQuery,
  useGetTechAvgResolutionReportQuery,
  useGetTechValueTimeReportQuery,
  useGetMrrByCustomerReportQuery,
  useGetTimePerWorktypeReportQuery,
  useGetHoursPerEndpointReportQuery,
  useGetTicketsPerEndpointReportQuery,
} = reportApi;
