import React from 'react';
import Button from 'components/Button/Button';
import SSteps from './Steps.style';
import { TStepsProps } from './Steps.type';
import Step from './Step/Step';

const Steps = (props: TStepsProps) => {
  const {
    steps,
    current,
    stepView,
    isLoadingNextBtn,
    getFunctionForNextBtn,
    getFunctionForBackBtn,
  } = props;

  return (
    <>
      <div className="onboarding-header">
        <SSteps {...props}>
          {steps.map((step: string, index: number) => {
            return (
              <Step key={step} step={step} index={index} current={current} />
            );
          })}
        </SSteps>
      </div>
      <div className="onboarding-main">{stepView}</div>

      {current !== 2 && (
        <div className="onboarding-footer">
          {!!current && (
            <Button
              type="primary"
              outline
              htmlType="submit"
              onClick={getFunctionForBackBtn}
              className="mr-2"
            >
              Back
            </Button>
          )}
          <Button
            type="primary"
            htmlType="submit"
            onClick={getFunctionForNextBtn}
            loading={isLoadingNextBtn}
          >
            Next
          </Button>
        </div>
      )}
    </>
  );
};

export default Steps;
