import moment from 'moment';
import { getDateTimeFormats } from 'helpers/utils';
import { TAuthConverter } from '../types';
import {
  TLocationGetUi,
  TLocationPostUi,
  TLocationPost,
  TLocationGet,
  TLocationUpdateFieldsUi,
  TLocationUpdateFields,
} from '../types/location.types';
import { TCreateConverter, TFetchConverter } from '../types/converter.types';
import { companyShortDataConverter } from './shortDataConverter';
import {
  TContactsBulkChangeActiveStatus,
  TContactsBulkChangeActiveStatusUi,
  TContactsBulkChangeManagedStatus,
  TContactsBulkChangeManagedStatusUi,
  TContactsBulkUpdateLocation,
  TContactsBulkUpdateLocationUi,
  TContactsBulkUpdateResponse,
  TContactsBulkUpdateResponseUi,
} from '../types/contact.type';

export const locationConverter: TAuthConverter<
  TLocationPostUi,
  TLocationPost,
  TLocationGetUi,
  TLocationGet
> = {
  fromDb: data => {
    return {
      name: data.name,
      id: data.id,
      address: data.address,
      creationDateTime: moment(data.creationTime).format(
        getDateTimeFormats().dateFormat,
      ),
      company: companyShortDataConverter.fromDb(data.company),
    };
  },
  toDb: data => {
    return {
      name: data.name,
      address: data.address,
      companyId: data.companyId,
    };
  },
};

export const locationUpdateFieldsConverter: TCreateConverter<
  TLocationUpdateFieldsUi,
  TLocationUpdateFields
> = {
  toDb: data => {
    return {
      value: data.value,
    };
  },
};

export const locationBulkUpdateLocationConverter: TCreateConverter<
  TContactsBulkUpdateLocationUi,
  TContactsBulkUpdateLocation
> = {
  toDb: data => {
    return {
      locationId: data.locationId,
      contactIds: data.contactIds,
    };
  },
};
export const contactsBulkUpdateActiveStatusConverter: TCreateConverter<
  TContactsBulkChangeActiveStatusUi,
  TContactsBulkChangeActiveStatus
> = {
  toDb: data => {
    return {
      active: data.active,
      contactIds: data.contactIds,
    };
  },
};

export const contactsBulkUpdateManagedStatusConverter: TCreateConverter<
  TContactsBulkChangeManagedStatusUi,
  TContactsBulkChangeManagedStatus
> = {
  toDb: data => {
    return {
      managed: data.managed,
      contactIds: data.contactIds,
    };
  },
};

export const contactsBulkUpdateResponseConverter: TFetchConverter<
  TContactsBulkUpdateResponseUi,
  TContactsBulkUpdateResponse
> = {
  fromDb: data => {
    return {
      updatedContacts: data?.updatedContacts || [],
      failedIds: data?.failedIds || [],
    };
  },
};
