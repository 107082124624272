import styled from 'styled-components';
import Tabs from 'antd/es/tabs';
import { Colors } from 'core/CssVariables';

const { TabPane } = Tabs;

export const STabs = styled(Tabs)`
  overflow: auto;

  &.ant-tabs-top {
    &.ant-tabs-card {
      .ant-tabs-nav {
        margin-bottom: 16px;
        &:before {
          border-bottom: 0px;
        }
        .ant-tabs-nav-wrap {
          overflow: auto;
        }
        .ant-tabs-nav-list {
          width: 100%;
        }
        .ant-tabs-nav-operations {
          display: none;
        }
        .ant-tabs-tab {
          border-radius: 4px;
          background-color: ${({ theme }) => theme.weekDays.backgroundColor};
          color: ${({ theme }) => theme.secondaryColor};
          font-weight: 500;
          flex: 1;
          justify-content: center;
          min-width: 40px;
          padding: 0;
          border: none;

          .ant-tabs-tab-btn {
            text-shadow: none;
          }

          .ant-tabs-tab-btn {
            width: 100%;
          }

          .tab-inner {
            padding: 0 2px;
            text-align: center;
            border-radius: 4px;
            height: 45px;
            line-height: 45px;

            &.filled {
              background-color: ${Colors.PrimaryColor};
              color: ${Colors.White};
            }
          }
          &.ant-tabs-tab-active {
            .tab-inner {
              background-color: ${Colors.SecondaryColor};
            }
            .ant-tabs-tab-btn {
              color: ${Colors.White};
            }
          }
        }
      }
    }
  }
`;

export const STabPane = styled(TabPane)`
  //background-color: green;
`;

export const SErrorMessage = styled.p`
  font-size: 12px;
  color: ${Colors.ErrorColor};
`;
