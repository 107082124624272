import Tag from 'components/Tag/Tag';
import React, { ChangeEvent, FC, useState } from 'react';
import { Icon, TIconNames } from 'components/Icon';
import { Input } from 'components/Input';
import { Colors } from 'core/CssVariables';
import { TMultiInput } from './MultiInput.type';

const MultiInput: FC<TMultiInput> = ({
  placeholder,
  onChange,
  value: values,
}) => {
  const [currentValue, setCurrentValue] = useState<string>('');
  const onHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCurrentValue(value);
  };
  const onEnter = () => {
    if (!currentValue.trim()) return;
    const foundValue = values.find(
      item => item.toLowerCase() === currentValue.toLowerCase(),
    );
    if (!foundValue) {
      onChange([currentValue, ...values]);
    } else {
      const index = values.indexOf(foundValue);
      const arr = values;
      arr.splice(index, 1);
      onChange([currentValue, ...arr]);
    }
    setCurrentValue('');
  };
  const onRemove = (value: string) => {
    const arr: string[] = values.filter(
      item => item.toLocaleLowerCase() !== value.toLocaleLowerCase(),
    );
    onChange(arr);
  };
  return (
    <>
      <Input
        suffix={
          <Icon icon={TIconNames.ENTER} color={Colors.LightGrey} size={15} />
        }
        placeholder={placeholder || 'Type value'}
        onChange={onHandleChange}
        value={currentValue}
        onPressEnter={onEnter}
      />
      {values.map(value => (
        <Tag
          key={value}
          className="mt-2"
          color={Colors.InfoColor}
          onClose={() => onRemove(value)}
          closable
          closeIcon={
            <Icon
              color={Colors.White}
              size={13}
              icon={TIconNames.HIGHLIGHT_OFF}
            />
          }
        >
          {value}
        </Tag>
      ))}
    </>
  );
};

export default MultiInput;
