import useDidUpdate from 'hooks/useDidUpdate';
import firebase from 'configs/firebase.config';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { TPaymentInfo, TPaymentInfoUi } from 'data/types/payment.type';
import { paymentInfoConverter } from 'data/converters/payment.converter';
import { setPaymentInfo } from 'redux/slices';
import { useCallback, useState } from 'react';
import { ApiHookType } from './hook.types';

const MSPS_COLLECTION = 'msps';
const PAYMENT_COLLECTION = 'paymentInfo';
const PAYMENT_DOC = 'info';
const db = firebase.firestore();

const useGetPaymentInfo = (
  onSuccess?: (result: TPaymentInfoUi) => void,
  onError?: (error: Error) => void,
): ApiHookType<string, TPaymentInfoUi> => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<TPaymentInfoUi | null | undefined>(
    undefined,
  );
  const [error, setError] = useState<Error | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const mspId = useAppSelector(state => state.authSlice.user?.organization?.id);
  const [dependency, setDependency] = useState<string>(mspId || '');
  const logedInfirebase = useAppSelector(
    state => state.authSlice.logedInfirebase,
  );
  const refetch = useCallback(dep => {
    setDependency(dep);
  }, []);
  useDidUpdate(() => {
    let unsubscribe: () => void;
    if (dependency && logedInfirebase) {
      (async (): Promise<void> => {
        setIsLoading(true);
        unsubscribe = await db
          .collection(MSPS_COLLECTION)
          .doc(dependency)
          .collection(PAYMENT_COLLECTION)
          .doc(PAYMENT_DOC)
          .onSnapshot(
            snapshot => {
              const dbData = snapshot.data() as TPaymentInfo;
              const convertedData = paymentInfoConverter.fromDb(dbData);
              dispatch(setPaymentInfo(convertedData));
              setData(convertedData);
              setError(null);
              setIsSuccess(true);
              setIsLoading(false);
              if (onSuccess) {
                onSuccess(convertedData);
              }
            },
            err => {
              const errorObj = err as Error;
              setError(errorObj);
              setData(null);
              setIsSuccess(false);
              setIsLoading(false);
              console.log({ errorObj });
              if (onError) {
                onError(errorObj);
              }
            },
          );
      })();
    }
    return () => {
      unsubscribe();
    };
  }, [dependency, logedInfirebase]);
  return { data, error, isLoading, isSuccess, refetch };
};

export default useGetPaymentInfo;
