import { emptySplitApi } from 'redux/helpers/slice.helpers';
import { signupConverter } from 'data/converters';
import { TAuthUser, TSignUpData, TSignUpDataUi } from 'data/types';
import { ConfirmInvitationPost } from 'data/types/invitation.types';
import { ConfirmVerification } from 'data/types/signUp.types';

// TODO: we must put the urls into enum
const USER_URL = 'api/users';
const INVITATIONS_URL = 'api/invitations';

export const signUpApi = emptySplitApi('signUpApi').injectEndpoints({
  endpoints: build => ({
    signUpUser: build.mutation<TSignUpData, TSignUpDataUi>({
      query(body) {
        return {
          url: USER_URL.concat('/signup'),
          method: 'POST',
          body: signupConverter.toDb(body),
        };
      },
    }),
    confirmInvitation: build.mutation<void, ConfirmInvitationPost>({
      query(body) {
        return {
          url: INVITATIONS_URL.concat(`/confirm`),
          method: 'POST',
          body,
        };
      },
    }),
    resend: build.mutation<TAuthUser, string>({
      query(body) {
        return {
          url: USER_URL.concat('/resend'),
          method: 'POST',
          body,
        };
      },
    }),
    confirmVerification: build.mutation<TAuthUser, ConfirmVerification>({
      query({ code, username }) {
        return {
          url: USER_URL.concat(`/confirmation`).concat(
            `?code=${code}&username=${username}`,
          ),
          method: 'GET',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignUpUserMutation,
  useConfirmInvitationMutation,
  useResendMutation,
  useConfirmVerificationMutation,
} = signUpApi;
