import styled from 'styled-components';
import { Screens, Spacing } from 'core/CssVariables';

type TFilterTaskWrapper = {
  hasBulkAction: boolean;
};
const FilterTaskWrapper = styled.div<TFilterTaskWrapper>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: ${Spacing.SpacingSM}px;
  @media (max-width: ${Screens.ScreensLG}) {
    flex-direction: column-reverse;
  }
  .button-wrapper {
    display: flex;
    @media (max-width: ${Screens.ScreensMD}) {
      flex-wrap: wrap;
    }
  }
  .search-task {
    width: 370px;
    display: flex;
    min-width: 370px;
    max-width: 370px;
    @media (max-width: ${Screens.ScreensLG}) {
      margin: ${Spacing.SpacingLG}px ${Spacing.SpacingXS}px;
      width: 330px;
      min-width: 330px;
      max-width: 330px;
    }
    .ant-input-group {
      width: 370px;
    }
  }
`;

const SStatusWrapper = styled.div`
  display: flex;
  width: max-content;
`;

export { FilterTaskWrapper, SStatusWrapper };
