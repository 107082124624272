import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { Menu, MenuItem } from 'components/Menu/Menu';
import { FontWeights } from 'core/CssVariables';
import { Icon } from 'components/Icon';
import { Title } from 'components/Typography';
import { TDashbarDropDownMenuProps } from './DashbarDropDownMenu.type';

const DashbarDropDownMenu: FC<TDashbarDropDownMenuProps> = ({
  menuItems,
  children,
  onClick,
}) => {
  const location = useLocation();
  const selectedKey = location.pathname.split('/').pop();
  return (
    <Dropdown
      trigger={['click']}
      overlayClassName="profile-menu"
      overlay={
        <Menu selectedKeys={selectedKey ? [selectedKey] : []}>
          {menuItems.map(item => {
            return (
              <MenuItem
                onClick={() => onClick(item.actionType)}
                key={item.actionType}
              >
                <Title fontlevel={5} fontWeight={FontWeights.Regular}>
                  <Icon
                    icon={item.icon}
                    size={18}
                    className="mr-3"
                    data-testid="dropdown-icon"
                  />
                  {item.title}
                </Title>
              </MenuItem>
            );
          })}
        </Menu>
      }
    >
      {children}
    </Dropdown>
  );
};

export default DashbarDropDownMenu;
