import { TContactShortData, TContactShortDataUi } from './shortData.type';
import {
  TAttachment,
  TAttachmentUi,
  TGetAttachment,
  TGetAttachmentUi,
} from './generalDataTypes';

export enum TTaskStatus {
  New = 'New',
  Closed = 'Closed',
  Completed = 'Completed',
  Scheduled = 'Scheduled',
  'In Progress' = 'In Progress',
}
export enum TTaskStatusUi {
  New = 'New',
  Closed = 'Closed',
  Completed = 'Completed',
  Scheduled = 'Scheduled',
  'In Progress' = 'In Progress',
}

export type TTaskReporterUI = {
  id: string;
  name: string;
  photoUrl: string;
  firstLetters?: string;
};
export type TTaskReporter = {
  id: string;
  name: string;
  photoUrl: string;
};

export type TTaskAssigneeUI = {
  id: string;
  name: string;
  photoUrl: string;
  firstLetters?: string;
};
export type TTaskAssignee = {
  id: string;
  name: string;
  photoUrl: string;
};

export type TCreateTaskUi = {
  title: string;
  description: string;
  assigneeId: string;
  companyId: string;
  attachments: TAttachmentUi[];
};
export type TCreateContractTypeUi = {
  title: string;
};
export type TCreateTask = {
  title: string;
  description: string;
  assigneeId: string;
  companyId: string;
  attachments: TAttachment[];
};

export type TGetTaskUi = {
  id: string;
  number: string;
  title: string;
  status: TTaskStatusUi;
  creationDateTime: string;
  assignee: TTaskAssigneeUI;
  reporter: TTaskReporterUI;
  nextActionItem: {
    description: string;
    scheduleTime: string;
    endTime: string;
    hasEventMapping: null | boolean;
  };
  overdueSince: string | null;
};
export type TGetTask = {
  id: string;
  number: string;
  title: string;
  status: TTaskStatus;
  creationDateTime: string;
  assignee: TTaskAssigneeUI;
  reporter: TTaskReporter;
  nextActionItem: {
    description: string;
    scheduleTime: string;
    endTime: string;
    hasEventMapping: null | boolean;
  };
  overdueSince: string | null;
};

export type TTaskCompanyUi = {
  id: string;
  name: string;
  shortName: string;
  logoUrl: string;
  mainContact: TContactShortDataUi;
  firstLetters?: string;
  phoneNumber: string;
  nameWithAbbreviation: string;
};
export type TTaskCompany = {
  id: string;
  name: string;
  shortName: string;
  logoUrl: string;
  mainContact: TContactShortData;
  phoneNumber: string;
};

export type TTaskAction = {
  action: TaskActionEnum;
  nextPossibleStatuses: TTaskStatus[];
};
export type TTaskActionUi = {
  action: TaskActionEnum;
  nextPossibleStatuses: TTaskStatus[];
};
export type TTaskDetailsUi = {
  task: TGetTaskUi;
  description: string;
  compilationDateTime: number;
  company: TTaskCompanyUi;
  actions: TTaskActionUi[];
  attachments: TGetAttachmentUi[];
};

export type TTaskDetails = {
  task: TGetTask;
  description: string;
  compilationDateTime: number;
  company: TTaskCompany;
  actions: TTaskAction[];
  attachments: TGetAttachment[];
};

export enum TaskFilters {
  STATUS = 'statuses',
  ASSIGNED = 'assigneeIds',
  CREATION_DATE = 'creationDate',
  REPORTER = 'reporterIds',
  COMPANY = 'companyIds',
  OVERDUE = 'overdue',
}
export enum TaskFilterNames {
  'Creation_date' = 'Creation date',
  'Company' = 'Company',
  'Reporter' = 'Reporter',
  'Status' = 'Status',
  'Assignee' = 'Primary resource',
  'Overdue' = 'Overdue',
}

export enum AllTasksPath {
  OPEN = 'open',
  CLOSE = 'closed',
}

// edit types  start
export type TEditTaskUi = {
  id: string;
  value: string;
};
export type TEditTaskTitle = {
  id: string;
  value: string;
};

export type TEditTaskDescription = {
  id: string;
  value: string;
};

export type TEditTaskAssignee = {
  id: string;
  assigneeId: string;
};

export type TEditTaskStatus = {
  id: string;
  status: string;
};

export type TEditTaskReporter = {
  id: string;
  value: string;
};

export type TEditTaskCompany = {
  id: string;
  value: string;
};
export type TEditNextActionItemDesc = {
  id: string;
  value: string;
};
// edit types  end

export enum TaskActionEnum {
  START_WORK = 'START_WORK',
  RESOLVE = 'RESOLVE',
  RE_ASSIGN = 'RE_ASSIGN',
  SCHEDULE = 'SCHEDULE',
  RE_SCHEDULE = 'RE_SCHEDULE',
}

export type TTaskComment = {
  id: string;
  text: string;
  creationTime: string;
  commenter: TTaskCommenter;
  taskId: string;
  edited: boolean;
  lastModifiedTime: string;
  isHtml: boolean;
};

export type TTaskCommenter = {
  userId: string;
  fullName: string;
  photoUrl: string;
  firstLetters: string;
};
export type TTaskCommentUi = {
  id: string;
  text: string;
  creationTime: string;
  commenter: TTaskCommenterUi;
  taskId: string;
  edited: boolean;
  lastModifiedTime: string;
  isHtml: boolean;
};

export type TTaskCommenterUi = {
  userId: string;
  fullName: string;
  photoUrl: string;
  firstLetters: string;
};
