import { rest } from 'msw';
import { TOrganizationData } from 'data/types';
import { ORGANIZATION_URL } from 'redux/apiSlices/organization.slice';
import { mockedOrganzation } from '../data/organization.data';

export const mockedGetOrganizationById = (mockedData: TOrganizationData) => {
  return rest.get(`${ORGANIZATION_URL}/:id`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedData));
  });
};

export const organizationHandlers = [
  mockedGetOrganizationById(mockedOrganzation),
];
