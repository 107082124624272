export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const steps = ['Organization details', 'Invite your members'];

export const companySize = [
  {
    label: '1-10',
    value: '1-10',
  },
  {
    label: '11-25',
    value: '11-25',
  },
  {
    label: '26-50',
    value: '26-50',
  },
  {
    label: '50+',
    value: '50+',
  },
];

export const usTimeZoneOptions = [
  {
    label: 'US/Alaska',
    value: 'US/Alaska',
  },
  {
    label: 'US/Aleutian',
    value: 'US/Aleutian',
  },
  {
    label: 'US/Arizona',
    value: 'US/Arizona',
  },
  {
    label: 'US/Central',
    value: 'US/Central',
  },
  {
    label: 'US/East-Indiana',
    value: 'US/East-Indiana',
  },
  {
    label: 'US/Eastern',
    value: 'US/Eastern',
  },
  {
    label: 'US/Hawaii',
    value: 'US/Hawaii',
  },
  {
    label: 'US/Indiana-Starke',
    value: 'US/Indiana-Starke',
  },
  {
    label: 'US/Michigan',
    value: 'US/Michigan',
  },
  {
    label: 'US/Mountain',
    value: 'US/Mountain',
  },
  {
    label: 'US/Pacific',
    value: 'US/Pacific',
  },
  {
    label: 'US/Samoa',
    value: 'US/Samoa',
  },
];
export const canadaTimeZoneOptions = [
  {
    label: 'Canada/Atlantic',
    value: 'Canada/Atlantic',
  },
  {
    label: 'Canada/Central',
    value: 'Canada/Central',
  },
  {
    label: 'Canada/Eastern',
    value: 'Canada/Eastern',
  },
  {
    label: 'Canada/Mountain',
    value: 'Canada/Mountain',
  },
  {
    label: 'Canada/Newfoundland',
    value: 'Canada/Newfoundland',
  },
  {
    label: 'Canada/Pacific',
    value: 'Canada/Pacific',
  },
  {
    label: 'Canada/Saskatchewan',
    value: 'Canada/Saskatchewan',
  },
  {
    label: 'Canada/Yukon',
    value: 'Canada/Yukon',
  },
];
export const australiaTimeZoneOptions = [
  {
    label: 'Australia/ACT',
    value: 'Australia/ACT',
  },
  {
    label: 'Australia/Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    label: 'Australia/Brisbane',
    value: 'Australia/Brisbane',
  },
  {
    label: 'Australia/Broken_Hill',
    value: 'Australia/Broken_Hill',
  },
  {
    label: 'Australia/Canberra',
    value: 'Australia/Canberra',
  },
  {
    label: 'Australia/Currie',
    value: 'Australia/Currie',
  },
  {
    label: 'Australia/Darwin',
    value: 'Australia/Darwin',
  },
  {
    label: 'Australia/Eucla',
    value: 'Australia/Eucla',
  },
  {
    label: 'Australia/Hobart',
    value: 'Australia/Hobart',
  },
  {
    label: 'Australia/LHI',
    value: 'Australia/LHI',
  },
  {
    label: 'Australia/Lindeman',
    value: 'Australia/Lindeman',
  },
  {
    label: 'Australia/Lord_Howe',
    value: 'Australia/Lord_Howe',
  },
  {
    label: 'Australia/Melbourne',
    value: 'Australia/Melbourne',
  },
  {
    label: 'Australia/NSW',
    value: 'Australia/NSW',
  },
  {
    label: 'Australia/North',
    value: 'Australia/North',
  },
  {
    label: 'Australia/Perth',
    value: 'Australia/Perth',
  },
  {
    label: 'Australia/Queensland',
    value: 'Australia/Queensland',
  },
  {
    label: 'Australia/South',
    value: 'Australia/South',
  },
  {
    label: 'Australia/Sydney',
    value: 'Australia/Sydney',
  },
  {
    label: 'Australia/Tasmania',
    value: 'Australia/Tasmania',
  },
  {
    label: 'Australia/Victoria',
    value: 'Australia/Victoria',
  },
  {
    label: 'Australia/West',
    value: 'Australia/West',
  },
  {
    label: 'Australia/Yancowinna',
    value: 'Australia/Yancowinna',
  },
];
export const newZealandTimeZoneOptions = [
  {
    label: 'Pacific/Auckland',
    value: 'Pacific/Auckland',
  },
  {
    label: 'Pacific/Chatham',
    value: 'Pacific/Chatham',
  },
];
export const ukTimeZoneOptions = [
  {
    label: 'Europe/London',
    value: 'Europe/London',
  },
];

export const roles = [
  {
    label: 'Admin',
    value: 'Admin',
  },
  {
    label: 'Technician',
    value: 'Technician',
  },
];
