import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'redux/store';
import Amplify from 'aws-amplify';
import './styles/App.less';
import ErrorBoundary from 'ErrorBoundary';
import amplifyConfig from './aws-exports';
import ThemeWrapper from './theme/ThemeWrapper';
import RoutesPage from './routes';
import { googleAnalyticsInitialization } from './configs/coogleAnalytics.config';

Amplify.configure({
  ...amplifyConfig,
  ssr: true,
});

googleAnalyticsInitialization();
export function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeWrapper>
            <RoutesPage />
          </ThemeWrapper>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
