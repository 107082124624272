import DatePicker from 'antd/es/date-picker';
import { Colors } from 'core/CssVariables';
import styled, { css } from 'styled-components';
import { TDatePickerProps, TDateRangePickerProps } from './DatePicker.type';

const { RangePicker } = DatePicker;

const cssPicker = css`
  &.ant-picker {
    width: 100%;
    background-color: ${({ theme }) => theme.datePicker.backgroundColor};
    border-color: ${({ theme }) => theme.datePicker.borderColor};
    .ant-picker-input {
      > input {
        &::placeholder {
          color: ${({ theme }) => theme.input.placeholder};
        }
        color: ${Colors.LightGrey};
      }
    }
    .anticon {
      color: ${Colors.LightGrey};
    }
  }
`;
const SDateRangePicker = styled(RangePicker)<TDateRangePickerProps>`
  ${cssPicker}
`;
const SDatePicker = styled(DatePicker)<TDatePickerProps>`
  ${cssPicker}
`;

export { SDateRangePicker, SDatePicker };
