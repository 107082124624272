import Alert from 'antd/es/alert';
import { BorderRadiuses, Colors, FontSizes } from 'core/CssVariables';
import styled from 'styled-components';
import { TAlert } from './Alert.types';

const textColors = {
  success: Colors.SuccessColor,
  error: Colors.ErrorColor,
  warning: Colors.WarningColor,
  info: Colors.InfoColor,
};
const SAlert = styled(Alert)`
  margin-bottom: ${(props: TAlert) => props.marginBottom || 24}px;
  border-radius: ${(props: TAlert) =>
    props.borderRadius || BorderRadiuses.RadiusXS}px;
  border: 0;
  padding: 8px 12px;
  font-size: ${FontSizes.FontSM}px;

  .ant-alert-message .ant-typography {
    color: ${(props: TAlert) => (props.type ? textColors[props.type] : '')};
  }
`;
export default SAlert;
