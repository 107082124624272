import React from 'react';
import useForm from 'hooks/useForm';
import Modal from 'components/Modal/Modal';
import { changeTaskAddModal } from 'redux/slices';
import { ModalWidthEnum } from 'components/Modal/Modal.types';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { TCreateTaskUi } from 'data/types/task.type';
import { useCreateTaskMutation } from 'redux/apiSlices/task.slices';
import { workFeedApi } from 'redux/apiSlices/workFeed.slice';
import { toast } from 'components/Notification';
import { CreateTaskForm } from './CreateTaskForm';

const CreateTaskModal = () => {
  const userId = useAppSelector(state => state.authSlice.user?.id);
  const { form } = useForm();
  const dispatch = useAppDispatch();
  const { addTaskModal } = useAppSelector(state => ({
    addTaskModal: state.appSettings.addTaskModal,
  }));

  const [createTask, { isLoading }] = useCreateTaskMutation();

  const handleSubmitNewTask = async () => {
    const formValues = form.getFieldsValue() as TCreateTaskUi;
    try {
      await form.validateFields();
      await createTask(formValues).unwrap();
      dispatch(changeTaskAddModal(false));
      if (formValues.assigneeId === userId) {
        dispatch(workFeedApi.util.invalidateTags(['workFeedTasks']));
      }
      toast({
        description: 'Task has been created successfully',
        title: 'Creating task',
        type: 'success',
      });
    } catch (e) {
      toast({
        description: (e as any)?.data.message,
        title: 'Creating task',
        type: 'error',
      });
    }
  };
  const handleCancelNewTask = () => {
    dispatch(changeTaskAddModal(false));
  };

  return (
    <Modal
      visible={addTaskModal}
      onOk={handleSubmitNewTask}
      onCancel={handleCancelNewTask}
      title="Create a new task"
      okText="Create"
      cancelText="Cancel"
      destroyOnClose
      confirmLoading={isLoading}
      size={ModalWidthEnum.Large}
    >
      <CreateTaskForm form={form} />
    </Modal>
  );
};

export { CreateTaskModal };
