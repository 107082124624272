import Modal from 'antd/es/modal';
import { Colors, FontSizes, FontWeights, Spacing } from 'core/CssVariables';
import styled from 'styled-components';

import { TModalProps } from './Modal.types';
import { DarkTheme, LightTheme } from '../../theme/theme';

const SModal = styled(Modal)<TModalProps>`
  &.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .ant-modal-content {
    background: ${({ theme, mode }) => {
      if (mode === 'dark') {
        return DarkTheme.modal.backgroundColor;
      }
      if (mode === 'light') {
        return LightTheme.modal.backgroundColor;
      }
      return theme.modal.backgroundColor;
    }};
    border-radius: 8px;
    .ant-modal-header {
      background: transparent;
      padding: 16px;
      margin-top: ${Spacing.SpacingLG}px;
      .ant-modal-title {
        color: ${({ theme }) => theme.color};
        font-weight: ${FontWeights.SemiBold};
        font-size: ${FontSizes.FontLG}px;
        line-height: 36px;
        margin-right: 16px;
      }
    }
    .ant-modal-body {
      padding: 16px;
    }
    .ant-modal-close-x {
      svg {
        fill: ${Colors.Grey};
      }
    }
    .ant-modal-footer {
      text-align: left;
      padding: 16px;
      .ant-btn-primary {
        &:disabled {
          background-color: ${Colors.PrimaryColor}4D;
          color: ${Colors.White}B2;
          border-color: transparent;
        }
      }
    }
  }
`;
const SDeleteWrapperMessage = styled.p`
  font-weight: 300;
  font-size: 14px;
  text-align: left;
  color: ${({ theme }) => theme.color};
`;
export { SModal, SDeleteWrapperMessage };
