import React, { FC, useMemo } from 'react';
import Select from 'components/Select/Select';
import { TSelectProps } from 'components/Select/Select.type';
import { TTimeZone } from './TimeZone.type';
import { countryTimezoneMapper } from '../../../helpers/utils';

const TimeZone: FC<TTimeZone> = ({ country, onChange, ...props }) => {
  const handleChange = (e: TSelectProps) => {
    onChange(e);
  };
  const options = useMemo(() => {
    return countryTimezoneMapper(country);
  }, [country]);
  return (
    <div>
      <Select
        placeholder="Select timezone"
        options={options}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
};

export default TimeZone;
