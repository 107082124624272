import React from 'react';
import { useGetTaskStatusQuery } from 'redux/apiSlices/task.slices';
import { TFilterProps, TFilterPath } from '../../FilterComponents.type';
import { StatusFilter } from './StatusFilter';

const StatusTaskFilter: React.FC<TFilterProps & { path: TFilterPath }> = ({
  onSelected,
  selectedValues,
  path,
}) => {
  const { data: statusData } = useGetTaskStatusQuery({
    exactState: path,
  });
  return (
    <StatusFilter
      data={statusData}
      onSelected={onSelected}
      selectedValues={selectedValues}
    />
  );
};

export default StatusTaskFilter;
