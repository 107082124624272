import React, { FC } from 'react';
import { Moment } from 'moment';
import { TTimeEntryPostUi } from 'data/types/timeEntry.types';
import { useCreateTimeEntryMutation } from 'redux/apiSlices/timeEntry.slice';
import { toast } from 'components/Notification';
import TimeEntryFormModal from './TimeEntryFormModal';

const CreateTimeEntry: FC<{
  selectedDates?: { startTime: Moment; endTime: Moment } | null;
  visible: boolean;
  onClose: () => void;
}> = ({ visible, onClose, selectedDates }) => {
  const [createTimeEntry, { isSuccess, isLoading, reset }] =
    useCreateTimeEntryMutation();

  const onSubmit = async (values: TTimeEntryPostUi) => {
    try {
      await createTimeEntry(values).unwrap();
      toast({
        type: 'success',
        title: 'Adding time entry',
        description: 'Time entry has benn added successfully',
      });
    } catch (err) {
      toast({
        description: (err as any)?.data?.message,
        title: 'Adding time entry',
        type: 'error',
      });
    }
  };
  return (
    <TimeEntryFormModal
      onSubmit={onSubmit}
      isSuccess={isSuccess}
      isLoading={isLoading}
      reset={reset}
      visible={visible}
      onClose={onClose}
      selectedDates={selectedDates}
    />
  );
};

export default CreateTimeEntry;
