import { TUserRoleUi } from 'data/types/user.types';
import { lazy } from 'react';
import { TIconNames } from 'components/Icon';
import { PrivateRouteType, RoutesPaths } from '../Routes.types';

const AllTickets = lazy(
  () => import('pages/private/Tickets/AllTickets/AllTickets'),
);
const TicketDetails = lazy(
  () => import('pages/private/Tickets/TicketsDetails/TicketDetails'),
);

export const TicketRoutes: PrivateRouteType[] = [
  {
    title: 'Tickets',
    component: AllTickets,
    path: RoutesPaths.Tickets,
    icon: TIconNames.TICKET,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
  {
    component: TicketDetails,
    path: RoutesPaths.TicketDetails,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Technician, TUserRoleUi.Owner],
    notInSidebar: true,
  },
];
