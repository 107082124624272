import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import Button from 'components/Button/Button';
import { SecondaryText } from 'components/Typography';
import { Icon, TIconNames } from 'components/Icon';
import { removeEmptyWeekDays } from 'helpers/utils';
import { weekDays } from 'data/types/organization.types';
import moment from 'moment';
import { STabs, STabPane, SErrorMessage } from './WeekDays.style';
import WorkingHours from './WorkingHours/WorkingHours';
import WeekDaysTab from './WeekDaysTab';
import { TAvailableHour, TWeekDaysProps } from './WeekDays.type';

const defaultAvailableHours = Object.values(weekDays).map(d => {
  return { dayOfWeek: d, start: '', end: '' };
});

const WeekDays = ({
  setCustomFieldValue,
  value,
  error,
  disabled,
}: TWeekDaysProps) => {
  const [selectedDay, setSelectedDay] = useState<weekDays>(() => {
    const firstWeekDay = Object.keys(weekDays).find(
      weekDay => !!value?.find(item => item.dayOfWeek === weekDay),
    );
    return (firstWeekDay as weekDays) || weekDays.MONDAY;
  });

  const [availableHours, setAvailableHours] = useState<TAvailableHour[]>(
    value?.length ? value : defaultAvailableHours,
  );

  const handleChange = (hours: string[], index: number) => {
    const [start, end] = hours;
    const indexes: number[] = [];
    availableHours.forEach((availableHour, idx: number) => {
      if (availableHour.dayOfWeek === selectedDay) {
        indexes.push(idx);
      }
    });
    const indexOfChange = indexes[index];
    const copiedAvailableHours = cloneDeep(availableHours);
    copiedAvailableHours[indexOfChange].start = start
      ? moment(start, ['h:mm A']).format('HH:mm:ss')
      : '';
    copiedAvailableHours[indexOfChange].end = end
      ? moment(end, ['h:mm A']).format('HH:mm:ss')
      : '';
    setAvailableHours(copiedAvailableHours);
    setCustomFieldValue(removeEmptyWeekDays(copiedAvailableHours));
  };

  const handleAddOption = () => {
    setAvailableHours([
      ...availableHours,
      { dayOfWeek: selectedDay, start: '', end: '' },
    ]);
    setCustomFieldValue(
      removeEmptyWeekDays([
        ...availableHours,
        { dayOfWeek: selectedDay, start: '', end: '' },
      ]),
    );
  };

  const handleDeleteOption = (index: number) => {
    const indexes: number[] = [];
    availableHours.forEach((availableHour, idx) => {
      if (availableHour.dayOfWeek === selectedDay) {
        indexes.push(idx);
      }
    });
    const indexOfChange = indexes[index];
    const filteredAvailableHours = availableHours.filter(
      (item, idx: number) => idx !== indexOfChange,
    );
    setAvailableHours(filteredAvailableHours);
    setCustomFieldValue(removeEmptyWeekDays(filteredAvailableHours));
  };

  const handleSelectedDayChange = (selected: weekDays) => {
    setSelectedDay(selected);
  };

  const hoursForSelectedDay = availableHours.filter(
    d => d.dayOfWeek === selectedDay,
  );

  const addFieldStatus = !!hoursForSelectedDay.find(
    item => !item.start || !item.end,
  );
  return (
    <STabs
      defaultActiveKey={selectedDay}
      type="card"
      onChange={e => handleSelectedDayChange(e as weekDays)}
    >
      {Object.values(weekDays).map(day => {
        return (
          <STabPane
            tab={<WeekDaysTab availableHours={availableHours} day={day} />}
            key={day}
          >
            {!!error && !value?.length && (
              <SErrorMessage>{error}</SErrorMessage>
            )}
            <SecondaryText fontlevel={6} fontWeight={500}>
              Working hours
            </SecondaryText>
            {hoursForSelectedDay.map((availableHour, index) => {
              return (
                <WorkingHours
                  disabled={disabled}
                  key={`${availableHour.dayOfWeek}${availableHour.start}${availableHour.end}`}
                  index={index}
                  onChange={handleChange}
                  onDeleteOption={handleDeleteOption}
                  availableHoursCount={hoursForSelectedDay.length}
                  availableHour={availableHour}
                />
              );
            })}
            {!disabled && (
              <Button
                disabled={addFieldStatus}
                type="link"
                htmlType="button"
                onClick={handleAddOption}
                icon={<Icon icon={TIconNames.PLUS} />}
                className="text-left"
              >
                Add new fields
              </Button>
            )}
          </STabPane>
        );
      })}
    </STabs>
  );
};

export default WeekDays;
