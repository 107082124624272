import React from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import OverdueLabel from 'components/OverdueLabel/OverdueLabel';
import { ETicketSpecialFlagsUi, TTicketUi } from 'data/types/ticket.types';
import { Link } from 'react-router-dom';
import { Row } from 'components/Grid/Grid';
import Tag from 'components/Tag/Tag';
import { ColumnType } from 'antd/es/table';
import Sla from 'components/Sla/Sla';
import { MemberSelectLabel } from 'components/MembersSelect';
import Avatar from 'components/Avatar/Avatar';
import { Link as LinkTypography, Text } from 'components/Typography';
import { SColumnWrapper } from 'components/Table/Table.style';
import {
  Priority,
  TicketStatus,
  TPriorityColorsMap,
  TTicketStatusMapperColor,
} from '../AllTickets.type';
import { ScoreWrapper, SStatusWrapper } from '../AllTickets.style';
import TicketTitle from './TicketTitle';

export const TicketStatusMapperColor: TTicketStatusMapperColor = {
  [TicketStatus.New]: Colors.SecondaryColor,
  [TicketStatus.Closed]: Colors.LightGrey,
  [TicketStatus.Completed]: Colors.SuccessColor,
  [TicketStatus.Assigned]: Colors.InfoColor,
  [TicketStatus.Scheduled]: Colors.Orange,
  [TicketStatus.CustomerNoteAdded]: Colors.PrimaryColor,
  [TicketStatus.InProgress]: Colors.WarningColor,
  [TicketStatus.WaitingParts]: Colors.Orange,
  [TicketStatus.WaitingCustomer]: Colors.Orange,
  [TicketStatus.WaitingVendor]: Colors.Orange,
  [TicketStatus.Escalate]: Colors.DarkPurple,
};
export const priorityColorsMap: TPriorityColorsMap = {
  [Priority.Low]: Colors.LightGrey,
  [Priority.Medium]: Colors.InfoColor,
  [Priority.High]: Colors.WarningColor,
  [Priority.Urgent]: Colors.ErrorColor,
};

const unassignedColumns: ColumnType<TTicketUi>[] = [
  {
    title: 'Ticket Title',
    dataIndex: 'title',
    key: 'title',
    sorter: true,
    render: (text: string, record: TTicketUi) => (
      <SColumnWrapper minWidth={170}>
        <Row>
          {record.specialFlags.invoicePaid && (
            <Text
              fontlevel={6}
              color={Colors.SuccessColor}
              fontWeight={FontWeights.SemiBold}
              className="mr-2"
            >
              {ETicketSpecialFlagsUi.INVOICE_PAID}
            </Text>
          )}
          {record.specialFlags.invoiceSent && (
            <Text
              fontlevel={6}
              color={Colors.Pink}
              fontWeight={FontWeights.SemiBold}
            >
              {ETicketSpecialFlagsUi.INVOICE_SENT}
            </Text>
          )}
          {record.specialFlags.rmmAlertResolved && (
            <Text
              fontlevel={6}
              color={Colors.InfoColor}
              fontWeight={FontWeights.SemiBold}
            >
              {ETicketSpecialFlagsUi.RMM_ALERT_RESOLVED}
            </Text>
          )}
        </Row>
        <Link to={`${record.id}`} className="bold-td">
          {record.number} &nbsp;
          <TicketTitle
            title={text}
            aiTitle={record.aiTitle}
            primaryTitleType={record.primaryTitleType}
            grade={record.titleGrade}
          />
        </Link>
      </SColumnWrapper>
    ),
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    width: 50,
    ellipsis: true,
    render: (text: string, record: TTicketUi) => (
      <Link to={`/app/customers/companies/${record.company.id}`}>
        <LinkTypography color={Colors.InfoColor} fontlevel={5}>
          <Avatar size={24} src={record?.company.logoUrl} className="mr-3">
            {!record?.company.logoUrl && record.company.firstLetters}
          </Avatar>
          <span>{record?.company.shortName || record?.company.name}</span>
        </LinkTypography>
      </Link>
    ),
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    sorter: true,
  },
  {
    title: 'FR SLA',
    dataIndex: 'frSLA',
    key: 'frSLA',
    render: (text: string, record: TTicketUi) => {
      return <Sla sla={record.firstResponseSla.remaining} />;
    },
    // sorter: true,
  },
  {
    title: 'Res SLA',
    dataIndex: 'resSLA',
    key: 'resSLA',
    render: (text: string, record: TTicketUi) => {
      return <Sla sla={record.resolutionSla.remaining} />;
    },
    // sorter: true,
  },
  {
    title: 'Urgency',
    dataIndex: 'score',
    key: 'score',
    render: (score: number) => (
      <ScoreWrapper score={score}>{score}</ScoreWrapper>
    ),
    sorter: true,
  },
  {
    title: 'Created date',
    dataIndex: 'creationDateTime',
    key: 'creationDate',
    sorter: true,
    render: (creationDateTime: string, record: TTicketUi) =>
      record?.creationDate,
  },
  {
    title: 'Queue',
    dataIndex: 'queue',
    key: 'queue',
    sorter: true,
    render: (queue: string, record: TTicketUi) => record?.queue?.name,
  },
];

const assigneColumn: ColumnType<TTicketUi> = {
  title: 'Primary resource',
  dataIndex: 'assignee',
  key: 'assignee',
  sorter: true,
  render: (assignee: string, record: TTicketUi) => {
    if (record.assignee) {
      return (
        <MemberSelectLabel
          name={record.assignee?.name}
          photoUrl={record.assignee.url}
          avatarSize={24}
        />
      );
    }
    return null;
  },
};
const statusColumn: ColumnType<TTicketUi> = {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  sorter: true,
  render: (status: TicketStatus, record: TTicketUi) => (
    <SStatusWrapper>
      <Tag size="md" fontlevel={5} color={TicketStatusMapperColor[status]}>
        {status}
      </Tag>
      {record.specialFlags.overdueSince && <OverdueLabel />}
    </SStatusWrapper>
  ),
};

const openCloseColumns = [...unassignedColumns];
openCloseColumns.splice(2, 0, assigneColumn);
openCloseColumns.splice(8, 0, statusColumn);

export { openCloseColumns, unassignedColumns };
