import { rest } from 'msw';
import { COMPANY_URL } from 'redux/apiSlices/company.slice';
import { mockedPagableCompanyCustomersData } from '../data/company.data';

export const companyHandlers = [
  rest.patch(`${COMPANY_URL}/:companyId/endpoint-type`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get(`${COMPANY_URL}/customers`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedPagableCompanyCustomersData));
  }),
];
