import AntdLayout from 'antd/es/layout';
import styled from 'styled-components';
import { LineHeights, Screens } from 'core/CssVariables';

const { Header: AntdHeader } = AntdLayout;

const SHeader = styled(AntdHeader)`
  & {
    margin-left: 16px;
    height: fit-content;
    border-radius: 0px 0px 0px 20px;
    background-color: ${props => props.theme.secondaryBackgroundColor};
    color: ${props => props.theme.color};
    padding: 8px 12px;
    line-height: ${LineHeights.ExtraShort};
    user-select: none;
    .header-expand-icon {
      transform: rotate(90deg);
    }
  }

  @media (max-width: ${Screens.ScreensMD}) {
    border-radius: 0;
    margin-left: 0;
  }
`;

export default SHeader;
