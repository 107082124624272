import React from 'react';
import { TFilterProps } from '../../FilterComponents.type';

import { SelectFilterItem } from '../SelectFilterItem';

const StatusFilter: React.FC<TFilterProps & { data?: string[] }> = ({
  onSelected,
  selectedValues,
  data,
}) => {
  return (
    <SelectFilterItem
      label="Status"
      onSelected={onSelected}
      selectedValues={selectedValues.map(item =>
        item.toUpperCase().replaceAll(' ', '_'),
      )}
      options={data?.map(item => ({
        value: item.toUpperCase().replaceAll(' ', '_'),
        label: item,
      }))}
    />
  );
};

export { StatusFilter };
