import React, { FC } from 'react';
import { STimePicker, SRangePicker } from './TimePicker.style';
import { TRangePicker, TTimePicker } from './TimePicker.type';

export const TimePicker: FC<TTimePicker> = props => {
  return <STimePicker {...props} />;
};
export const RangePicker: FC<TRangePicker> = props => {
  return <SRangePicker {...props} />;
};
