import { Colors } from 'core/CssVariables';
import { TCompanyStatusUi } from 'data/types/company.types';
import {
  TAuthConverter,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import {
  EItGoalStatus,
  EItGoalStatusAsParam,
  ItGoalFilters,
  TItGoal,
  TItGoalMapperColor,
  TItGoalPost,
  TItGoalPostUi,
  TItGoalReporter,
  TItGoalReporterUi,
  TItGoalUi,
  TShowItGoalTab,
} from 'data/types/ItGoal.type';
import moment from 'moment';
import { companyShortDataConverter } from './shortDataConverter';
import { GetFirstLetters } from './general.converters';

export const itGoalStatusConverter: TCreateConverter<
  EItGoalStatus,
  EItGoalStatusAsParam
> = {
  toDb: data => {
    const convertedDataMapper: {
      [key in EItGoalStatus]: EItGoalStatusAsParam;
    } = {
      [EItGoalStatus.COMPLETED]: EItGoalStatusAsParam.COMPLETED,
      [EItGoalStatus.GOAL]: EItGoalStatusAsParam.GOAL,
      [EItGoalStatus.IN_PROGRESS]: EItGoalStatusAsParam.IN_PROGRESS,
      [EItGoalStatus.POTENTIAL]: EItGoalStatusAsParam.POTENTIAL,
      [EItGoalStatus.SCHEDULED]: EItGoalStatusAsParam.SCHEDULED,
      [EItGoalStatus.REJECTED]: EItGoalStatusAsParam.REJECTED,
    };
    return convertedDataMapper[data];
  },
};

const itGoalReporterConverter: TFetchConverter<
  TItGoalReporterUi,
  TItGoalReporter
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
      firstLetters: GetFirstLetters.fromDb(data.name),
    };
  },
};

export const itGoalsConverter: TAuthConverter<
  TItGoalPostUi,
  TItGoalPost,
  TItGoalUi,
  TItGoal
> = {
  toDb: data => {
    return {
      companyId: data.companyId,
      yearAndQuarter: moment(data.yearAndQuarter).format('YYYY-Q'),
      status: data.status,
      description: data.description,
    };
  },
  fromDb: data => {
    return {
      id: data.id,
      company: data.company && companyShortDataConverter.fromDb(data.company),
      reporter: itGoalReporterConverter.fromDb(data.reporter),
      description: data.description,
      yearAndQuarter: data.yearAndQuarter,
      status: data.status,
      formattedYearAndQuarter:
        data.yearAndQuarter && data.yearAndQuarter?.replace('Q', ''),
    };
  },
};

export const itGoalStatusMapperColor: TItGoalMapperColor = {
  [EItGoalStatus.GOAL]: Colors.SecondaryColor,
  [EItGoalStatus.SCHEDULED]: Colors.PrimaryColor,
  [EItGoalStatus.IN_PROGRESS]: Colors.WarningColor,
  [EItGoalStatus.COMPLETED]: Colors.SuccessColor,
  [EItGoalStatus.POTENTIAL]: Colors.Pink,
  [EItGoalStatus.REJECTED]: Colors.ErrorColor,
};

export const showItGoalTabMapper: TShowItGoalTab = {
  [TCompanyStatusUi['Former Customer']]: true,
  [TCompanyStatusUi.Customer]: true,
};

export const itGoalFilterConverter = (filters: {
  [key in ItGoalFilters]?: string[];
}) => {
  const invoiceFilterToDB = {
    ...(filters?.range?.[0] && {
      yearAndQuarterFrom: filters?.range?.[0],
    }),
    ...(filters?.range?.[1] && {
      yearAndQuarterTo: filters?.range?.[1],
    }),
    ...(filters?.reporterIds?.length && {
      [ItGoalFilters.REPORTER]: filters?.reporterIds,
    }),
    ...(filters?.statuses?.length && {
      [ItGoalFilters.STATUS]: filters?.statuses.map(status =>
        itGoalStatusConverter.toDb(status as EItGoalStatus),
      ),
    }),
    ...(filters?.companyIds?.length && {
      [ItGoalFilters.COMPANY]: filters?.companyIds,
    }),
  };
  return invoiceFilterToDB;
};
