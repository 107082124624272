import { pagableDataConverter } from 'data/converters/pagableData.converters';
import { userGroupConverter } from 'data/converters/user.converters';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { TUserGroup } from 'data/types/user.types';
import { authSplitApi } from 'redux/helpers/slice.helpers';

const GET_USERGROUPS = 'api/usergroups';

export const userGroupApi = authSplitApi('userGroups', [
  'UserGroups',
]).injectEndpoints({
  endpoints: build => ({
    getUserGroups: build.query<
      TPageableDataWithContentUi<TUserGroup[]>,
      {
        page: number;
        pageSize: number;
        search?: string;
        sort?: string;
      }
    >({
      query({ page, pageSize, search, sort }) {
        // TODO: create a converter for this that make a url from the query params
        const urlWithParams = GET_USERGROUPS.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            sort: sort || '',
            ...(search && { search }),
          },
        };
      },
      providesTags: [{ type: 'UserGroups' }],
      keepUnusedDataFor: 60 * 60 * 24,
      transformResponse: (data: TPageableDataWithContent<TUserGroup[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(userGroup =>
                userGroupConverter.fromDb(userGroup),
              )
            : [],
        };
      },
    }),
    createUserGroup: build.mutation<
      void,
      {
        name: string;
        memberIds?: string[];
      }
    >({
      query(body) {
        return {
          url: GET_USERGROUPS,
          method: 'POST',
          body: {
            name: body.name.trim(),
            memberIds: body.memberIds || [],
          },
        };
      },
      invalidatesTags: [{ type: 'UserGroups' }],
    }),
    editUserGroupName: build.mutation<
      void,
      {
        groupId: string;
        newName: string;
      }
    >({
      query({ groupId, newName }) {
        return {
          url: GET_USERGROUPS.concat(
            `/${groupId}/name?newName=${newName.trim()}`,
          ),
          method: 'PATCH',
        };
      },
      invalidatesTags: [{ type: 'UserGroups' }],
    }),
    deleteUserGroup: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: GET_USERGROUPS.concat(`/${id}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'UserGroups' }],
    }),
    assignMembers: build.mutation<
      void,
      {
        groupId: string;
        memberIds?: string[];
      }
    >({
      query({ memberIds, groupId }) {
        return {
          url: GET_USERGROUPS.concat(`/${groupId}/members`),
          method: 'PATCH',
          body: memberIds,
        };
      },
      invalidatesTags: [{ type: 'UserGroups' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserGroupsQuery,
  useCreateUserGroupMutation,
  useAssignMembersMutation,
  useEditUserGroupNameMutation,
  useDeleteUserGroupMutation,
} = userGroupApi;
