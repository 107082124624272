import styled from 'styled-components';
import { Screens } from 'core/CssVariables';
import Alert from 'components/Alert/Alert';

const SHeaderAlert = styled(Alert)`
  margin-bottom: 0px;
  margin-left: 16px;
  border-radius: 0px;
  @media (max-width: ${Screens.ScreensMD}) {
    margin-left: 0;
  }
`;

export { SHeaderAlert };
