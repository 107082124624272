import React, { memo } from 'react';
import { Colors } from 'core/CssVariables';
import { Icon, TIconNames } from 'components/Icon';

type TTableExpandIconsProps = {
  onExpand: (
    record: any,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  expanded: boolean;
  record: any;
  rowExpandable?: (record: any) => boolean;
};
const TableExpandIcons = ({
  expanded,
  onExpand,
  record,
  rowExpandable,
}: TTableExpandIconsProps) => {
  if (!rowExpandable) return null;
  if (!rowExpandable(record)) return null;
  if (expanded) {
    return (
      <Icon
        className="table-collapsible-icon"
        color={Colors.InfoColor}
        icon={TIconNames.COLLAPSE_MINUS}
        onClick={e => {
          const event = e as any as React.MouseEvent<HTMLElement, MouseEvent>;
          onExpand(record, event);
        }}
      />
    );
  }
  return (
    <Icon
      className="table-collapsible-icon"
      icon={TIconNames.COLLAPSE_PLUS}
      onClick={e => {
        const event = e as any as React.MouseEvent<HTMLElement, MouseEvent>;
        onExpand(record, event);
      }}
    />
  );
};

export default memo(TableExpandIcons);
