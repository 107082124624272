import React, { useMemo, useState } from 'react';
import { Paragraph, Title } from 'components/Typography';
import Form from 'antd/es/form';
import { FormItem } from 'components/Form/FormItem';
import { Input } from 'components/Input';
import Upload from 'components/ImageUpload/ImageUpload';
import Select from 'components/Select/Select';
import Alert from 'components/Alert/Alert';
import { weekDays, ECountryUI } from 'data/types/organization.types';
import { companySize } from '../static/static';
import WeekDays from '../WeekDays/WeekDays';
import TimeZone from '../TimeZone/TimeZone';
import {
  SOrganizationStep,
  SUploadLogoWrapper,
} from './OrganizationStep.style';
import { TOrganizationStepProps } from './OrganizationStep.type';
import { TAvailableHour } from '../WeekDays/WeekDays.type';

const initialWorkingDays = [
  weekDays.MONDAY,
  weekDays.TUESDAY,
  weekDays.WEDNESDAY,
  weekDays.THURSDAY,
  weekDays.FRIDAY,
];

const initialWorkingHours: TAvailableHour[] = [];
initialWorkingDays.forEach(day => {
  initialWorkingHours.push({
    start: '08:00',
    end: '17:00',
    dayOfWeek: day,
  });
});

const OrganizationStep = ({
  error,
  form,
  setCustomFieldValue,
  initialValues,
  isLoading,
  weekDaysError
}: TOrganizationStepProps) => {
  const countries = useMemo(() => {
    return Object.values(ECountryUI).map(country => {
      return {
        label: country,
        value: country,
      };
    });
  }, []);
  const [country, setCountry] = useState<ECountryUI>();

  if (isLoading) {
    return null;
  }

  return (
    <SOrganizationStep>
      <div className="title auth-align">
        <Title fontlevel={3}>Organization details</Title>
        <Paragraph fontlevel={5} subtitle className="mt-2">
          Provide the details of your company here.
        </Paragraph>
      </div>
      <Form
        form={form}
        name="basic"
        initialValues={initialValues}
        autoComplete="off"
      >
        <FormItem name="logoUrl">
          <SUploadLogoWrapper>
            <Upload
              value={initialValues?.logoUrl}
              onChange={(url: string) => setCustomFieldValue('logoUrl', url)}
            />
          </SUploadLogoWrapper>
        </FormItem>
        <Alert type="error" message={error?.data?.message} />
        <FormItem
          name="name"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input placeholder="Company name" />
        </FormItem>
        <FormItem name="website">
          <Input placeholder="Company website" />
        </FormItem>

        <FormItem
          name="address"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input placeholder="Company address" />
        </FormItem>

        <FormItem
          name="country"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Select
            placeholder="Company country"
            options={countries}
            onChange={value => {
              setCountry(value);
              form.setFieldsValue({
                timeZone: undefined,
              });
            }}
          />
        </FormItem>

        <FormItem
          name="timeZone"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <TimeZone
            country={country}
            onChange={e => setCustomFieldValue('timeZone', e)}
          />
        </FormItem>

        <FormItem
          name="size"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Select placeholder="Company size" options={companySize} />
        </FormItem>

        <Title fontlevel={4}>Set working hours</Title>
        <Paragraph fontlevel={5} subtitle>
          Configure the working hours for your company
        </Paragraph>

        <FormItem
          name="workingHours"
          rules={[
            {
              required: true,
              message: 'Please set working hours',
            },
          ]}
          noStyle
          initialValue={initialWorkingHours}
        >
          <WeekDays
            error={weekDaysError}
            setCustomFieldValue={(workingHours: TAvailableHour[]) =>
              setCustomFieldValue('workingHours', workingHours)
            }
          />
        </FormItem>
      </Form>
    </SOrganizationStep>
  );
};

export default OrganizationStep;
