import { TUserRoleUi } from 'data/types/user.types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'redux/store';

import { PrivateRouteType, RoutesPaths } from '../routes/Routes.types';
import { privateRoutes } from '../routes/RoutesData';

const useGetPrivateRoutesByRole = (): PrivateRouteType[] => {
  const navigate = useNavigate();
  const [routes, setRoutes] = useState(privateRoutes);
  const user = useAppSelector(state => state.authSlice.user);
  const paymentStatus = useAppSelector(
    state => state.paymentInfoSlice?.paymentStatus,
  );
  const isAccessLimited = useAppSelector(
    state => state.paymentInfoSlice?.isAccessLimited,
  );
  useEffect(() => {
    if (isAccessLimited) {
      if (user?.role === TUserRoleUi.Technician) {
        setRoutes(prev =>
          prev.filter(
            route => route.path === RoutesPaths.AccessLimitedTechnician,
          ),
        );
        navigate(`/app/${RoutesPaths.AccessLimitedTechnician}`);
      } else {
        setRoutes(prev =>
          prev.filter(route => route.path === RoutesPaths.Payment),
        );
        navigate(`/app/${RoutesPaths.Payment}`);
      }
    } else {
      setRoutes(privateRoutes);
    }
  }, [paymentStatus]);
  if (!user) return [];
  return routes
    .filter(item => item.roles?.includes(user.role))
    .map(el => {
      if (el.subRoutes) {
        return {
          ...el,
          subRoutes: el.subRoutes.filter(i => i?.roles?.includes(user.role)),
        };
      }
      return el;
    });
};
export default useGetPrivateRoutesByRole;
