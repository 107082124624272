import React, { useEffect, useState } from 'react';
import AuthWrapper from 'components/Auth';
import Steps from 'components/Steps/Steps';
import {
  useGetUserQuery,
  useGoNextMutation,
  useInvitationsMutation,
  usePostOrganizationsMutation,
  usePutOrganizationsMutation,
  useGetOrganizationByIdQuery,
} from 'redux/apiSlices';
import useForm from 'hooks/useForm';
import { sendGTMDataLayer } from 'helpers/utils';
import { steps } from './static/static';
import InviteStep from './InviteStep/InviteStep';
import OrganizationStep from './OrganizationStep/OrganizationStep';
import InviteSentStep from './InviteSentStep/InviteSentStep';
import { OnboardState, TStepView } from './Onboarding.type';
import OnboardingWrapper from './Onboarding.style';

const stepMapping = {
  [OnboardState.USER_INVITES]: 1,
  INVITES_SENT: 2,
  [OnboardState.ONBOARDED]: 3,
};

const Onboarding = () => {
  const { data, isLoading, refetch } = useGetUserQuery(true);
  const [weekDaysError, setWeekDaysError] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<number>(0);
  const step = data?.organization?.onboardState;
  const id = data?.organization?.id;

  const [
    postOrganizations,
    {
      isSuccess: isSuccessOrganization,
      error: postError,
      isLoading: isLoadingPostOrg,
    },
  ] = usePostOrganizationsMutation();
  const [putOrganizations, { error: putError, isLoading: isLoadingPutOrg }] =
    usePutOrganizationsMutation();
  const { data: getOrgData, isLoading: isLoadingGetOrg } =
    useGetOrganizationByIdQuery(id, { skip: !id });
  const [
    invitations,
    { isLoading: isLoadingInvitation, isSuccess: isSuccessInvitation },
  ] = useInvitationsMutation();
  const [goNext] = useGoNextMutation();
  const {
    form: organizationForm,
    setCustomFieldValue: setOrganizationFormCustomFieldValue,
  } = useForm();
  const { form: invitationForm } = useForm();

  const handleFinishOrgStep = async () => {
    try {
      const orgData = await organizationForm.validateFields();
      setWeekDaysError('');
      if (step === OnboardState.USER_INVITES) {
        await putOrganizations({ ...orgData, id })
          .unwrap()
          .then(() => {
            setCurrentStep(stepMapping[OnboardState.USER_INVITES]);
          });
      } else {
        try {
          await postOrganizations(orgData);
          sendGTMDataLayer({
              event: 'org_created',
              emailAddress: data?.email,
              fullName: data?.fullName,
            });
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      setWeekDaysError(organizationForm.getFieldError('workingHours')[0]);
    }
  };

  const handleGoBack = (currentStepNumber: number) => {
    setCurrentStep(currentStepNumber - 1);
  };

  const handleFinishInviteStep = async () => {
    try {
      const iviteData = await invitationForm.validateFields();
      await invitations(iviteData.invitation);
      setCurrentStep(stepMapping.INVITES_SENT);
      sendGTMDataLayer({
          event: 'invite_users_done',
          emailAddress: data?.email,
          fullName: data?.fullName,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    refetch();
  }, [isSuccessOrganization]);

  useEffect(() => {
    if (step) {
      setCurrentStep(stepMapping[step] || 0);
    }
  }, [step]);

  if (isLoading) {
    return null;
  }
  const getFunctionForNextBtn = (current: number) => {
    switch (current) {
      case 0:
        return handleFinishOrgStep;
      case 1:
        return handleFinishInviteStep;
      default:
        return () => {
          return null;
        };
    }
  };
  const stepViews: TStepView[] = [
    <OrganizationStep
      form={organizationForm}
      setCustomFieldValue={setOrganizationFormCustomFieldValue}
      error={postError || putError}
      initialValues={getOrgData}
      isLoading={isLoadingGetOrg}
      weekDaysError={weekDaysError}
    />,
    <InviteStep
      form={invitationForm}
      goNext={() => goNext(id)}
      isSuccess={isSuccessInvitation}
      authData={data}
    />,
    <InviteSentStep refetch={refetch} isLoading={isLoading} />,
  ];

  return (
    <AuthWrapper>
      <OnboardingWrapper>
        <Steps
          current={currentStep}
          steps={steps}
          stepView={stepViews[currentStep]}
          isLoadingNextBtn={
            isLoadingPostOrg || isLoadingPutOrg || isLoadingInvitation
          }
          getFunctionForNextBtn={getFunctionForNextBtn(currentStep)}
          getFunctionForBackBtn={() => handleGoBack(currentStep)}
        />
      </OnboardingWrapper>
    </AuthWrapper>
  );
};

export default Onboarding;
