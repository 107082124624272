import {
  TLocationGet,
  TLocationGetUi,
  TLocationPostUi,
  TLocationUpdateFieldsUi,
} from 'data/types/location.types';
import {
  locationConverter,
  locationUpdateFieldsConverter,
} from 'data/converters/location.converter';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { authSplitApi } from '../helpers/slice.helpers';

export const LOCATIONS_URL = 'api/company-locations';

export const locationApi = authSplitApi('location', [
  'location',
  'locations',
]).injectEndpoints({
  endpoints: build => ({
    getLocation: build.query<TLocationGetUi, string>({
      query(id) {
        return {
          url: `${LOCATIONS_URL}/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TLocationGet) => locationConverter.fromDb(data),
      providesTags: ['location'],
    }),
    deleteLocation: build.mutation<void, string>({
      query(id) {
        return {
          url: `${LOCATIONS_URL}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['locations', 'location'],
    }),
    getLocations: build.query<
      TPageableDataWithContentUi<TLocationGetUi[]>,
      {
        page: number;
        size: number;
        sort?: string[];
        search?: string;
        filters?: {
          companies?: string[];
        };
      }
    >({
      query({ page, size, sort, search, filters }) {
        const urlWithParams = LOCATIONS_URL.concat(
          `?page=${page}&size=${size}`,
        );

        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(search && { search }),
            ...(filters && { ...filters }),
          },
        };
      },
      transformResponse: (data: TPageableDataWithContent<TLocationGet[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(location => locationConverter.fromDb(location))
            : [],
        };
      },
      providesTags: ['locations'],
    }),
    updateLocationAddress: build.mutation<void, TLocationUpdateFieldsUi>({
      query(data) {
        const { id } = data;
        return {
          url: `${LOCATIONS_URL}/${id}/address`,
          method: 'PATCH',
          body: locationUpdateFieldsConverter.toDb(data),
        };
      },
      invalidatesTags: ['locations'],
    }),
    updateLocationName: build.mutation<void, TLocationUpdateFieldsUi>({
      query(data) {
        const { id } = data;
        return {
          url: `${LOCATIONS_URL}/${id}/rename`,
          method: 'PATCH',
          body: locationUpdateFieldsConverter.toDb(data),
        };
      },
      invalidatesTags: ['locations'],
    }),
    createNewLocation: build.mutation<void, TLocationPostUi>({
      query(data) {
        return {
          url: `${LOCATIONS_URL}`,
          method: 'POST',
          body: locationConverter.toDb(data),
        };
      },
      invalidatesTags: ['locations'],
    }),
  }),
});

export const {
  useGetLocationQuery,
  useDeleteLocationMutation,
  useGetLocationsQuery,
  useUpdateLocationAddressMutation,
  useUpdateLocationNameMutation,
  useCreateNewLocationMutation,
} = locationApi;
