import { authSplitApi } from 'redux/helpers/slice.helpers';

const DEMO = 'api/demo';

export const demoControllerApi = authSplitApi(
  'demoControllerApi',
).injectEndpoints({
  endpoints: build => ({
    isResetAllowed: build.query<{ value: boolean }, void>({
      query() {
        return {
          url: `${DEMO}/is-reset-allowed`,
          method: 'GET',
        };
      },
    }),
    resetData: build.mutation<void, void>({
      query() {
        return {
          url: `${DEMO}/reset`,
          method: 'POST',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useIsResetAllowedQuery, useResetDataMutation } =
  demoControllerApi;
