import React, { useState } from 'react';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { Icon, TIconNames } from 'components/Icon';
import { Colors } from 'core/CssVariables';
import { SGaugesMenuItemWrapper, SMenuDotWrapper } from '../Gauges.style';
import Gauges from '../Gauges';

const GaugeMobileMenu = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <SGaugesMenuItemWrapper>
          <Gauges />
        </SGaugesMenuItemWrapper>
      }
      onVisibleChange={() => setIsActive(!isActive)}
    >
      <SMenuDotWrapper>
        <Icon
          size={24}
          icon={TIconNames.MENU_DOT}
          className="pointer"
          {...(isActive && {
            color: Colors.InfoColor,
          })}
        />
      </SMenuDotWrapper>
    </Dropdown>
  );
};

export default GaugeMobileMenu;
