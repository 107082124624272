import { ColumnType } from 'antd/es/table';
import {
  TAvailableFilter,
  TFilterPath,
} from 'components/FilterComponents/FilterComponents.type';
import { TIconNames } from 'components/Icon';
import { ETableNames } from 'components/Table/Table.types';
import { Colors } from 'core/CssVariables';
import {
  AllTicketsPath,
  TicketFilterNames,
  TicketFilters,
  TTicketUi,
} from 'data/types/ticket.types';

export enum Priority {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Urgent = 'Urgent',
}

export enum TicketStatus {
  New = 'New',
  Closed = 'Closed',
  Completed = 'Completed',
  Assigned = 'Assigned',
  Scheduled = 'Scheduled',
  CustomerNoteAdded = 'Customer Note Added',
  InProgress = 'In Progress',
  WaitingParts = 'Waiting Parts',
  WaitingCustomer = 'Waiting Customer',
  WaitingVendor = 'Waiting Vendor',
  Escalate = 'Escalate',
}

export type TTicketPriorityMapperColor = {
  [key in Priority]: Colors;
};

export type TTicketStatusMapperColor = {
  [key in TicketStatus]: Colors;
};

export type TPriorityColorsMap = {
  [key in Priority]: Colors;
};

export type TTicketTabs = {
  label: string;
  path: AllTicketsPath;
  availabelFilters: TAvailableFilter<TicketFilters, TicketFilterNames>[];
  availabelBulkActions: TAvailableBulkAction[];
  columns: ColumnType<TTicketUi>[];
  tableName: ETableNames;
};

export type TTimeWrapper = {
  time: string;
};

export type TAvailableBulkAction = {
  icon: TIconNames;
  title: BulkActionNames;
};

export enum BulkActionNames {
  QUEUE = 'Change queue',
  ASSIGNE = 'Assign',
  REASSIGN = 'Reassign',
}

export type TBulkUpdateProps = {
  modalVisibility: boolean;
  setModalVisibility: () => void;
  filters?: {
    [key in TicketFilters]?: string[];
  };
  selectedBulk?: boolean;
  ticketIds?: string[];
  path: TFilterPath;
  selectedRecords?: TTicketUi[];
  removeSelectedRows: () => void;
};
