import { TIconNames } from 'components/Icon';
import { Colors } from 'core/CssVariables';

export type TGaugeMetaDataUi = {
  title: string;
  color: Colors;
  value: number;
  expanedTitle: string;
};
export type TGaugeMetaData = {
  key: TooltipTitlesEnum;
  severity: GaugesColorEnum;
  value: number;
};

export type TGaugeContent = {
  name: string;
  icon: TIconNames | null;
  description: string;
};

export type TGauges = {
  type: GaugesEnum;
  severity: GaugesColorEnum;
  metaData: TGaugeMetaData[];
};
export type TGaugesUi = {
  type: GaugesEnum;
  color: Colors;
  metaData: TGaugeMetaDataUi[];
  gaugeContent: TGaugeContent;
};

// add new type in that order which should shown in dashbar
export enum GaugesEnum {
  OPEN_TICKETS = 'OPEN_TICKETS',
  NEW_TICKETS = 'NEW_TICKETS',
  OVERDUE_TICKETS = 'OVERDUE_TICKETS',
  OVERDUE_TASKS = 'OVERDUE_TASKS',
  SLA_CLOSE_TO_FAIL = 'SLA_CLOSE_TO_FAIL',
  AVG_URGENCY = 'AVG_URGENCY',
  CUSTOMERS_NEEDING_INTERACTION = 'CUSTOMERS_NEEDING_INTERACTION',
}
export enum TooltipTitlesEnum {
  totalNewTicket = 'totalNewTicket',
  frSlaFailedTickets = 'frSlaFailedTickets',
  openTickets = 'openTickets',
  overdueTickets = 'overdueTickets',
  overdueTicketsMoreThanHour = 'overdueTicketsMoreThanHour',
  overdueTasks = 'overdueTasks',
  overdueTasksMoreThanHour = 'overdueTasksMoreThanHour',
  closeToFailSlas = 'closeToFailSlas',
  avgScore = 'avgScore',
  customersNeedingAttention = 'customersNeedingAttention',
  failedSlas = 'failedSlas',
}

export enum GaugesColorEnum {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ALERT = 'ALERT',
  TEXT = 'TEXT',
}

export type TGaugesMapperColor = {
  [key in GaugesColorEnum]: Colors;
};

export type TGaugeTooltipTitle = {
  collapsed: string;
  expanded: string;
};

export type TTooltipTitleMapper = {
  [key in TooltipTitlesEnum]: TGaugeTooltipTitle;
};

export type TGaugeContentMapper = {
  [key in GaugesEnum]: TGaugeContent;
};
