import React, { FC } from 'react';
import Avatar from 'components/Avatar/Avatar';

type TNameWithAvatar = {
  name?: string;
  logoUrl?: string;
  firstLetters?: string;
  size?: number;
};

const NameWithAvatar: FC<TNameWithAvatar> = ({
  name,
  logoUrl,
  firstLetters,
  size = 32,
}) => {
  return (
    <>
      {(logoUrl || firstLetters) && (
        <Avatar size={size} src={logoUrl} className="mr-1">
          {!logoUrl && firstLetters}
        </Avatar>
      )}
      <span className="ml-2">{name}</span>
    </>
  );
};

export default NameWithAvatar;
