import { useGetDateWithTimeZone } from 'hooks/useGetDateWithTimeZone';
import moment from 'moment';

export type TPaymentAlertDays = {
  expirationDays: number;
  dayInMessage?: string;
};
type TPaymentAlertDaysProps = {
  expirationTime?: number;
};

export const usePaymentAlertDays = ({
  expirationTime,
}: TPaymentAlertDaysProps): TPaymentAlertDays => {
  const dateNow = useGetDateWithTimeZone();
  const paymentEndDate = useGetDateWithTimeZone(moment(expirationTime));
  const paymentEndDays = +paymentEndDate.diff(dateNow, 'day') + 1;
  const dayInMessage =
    paymentEndDays <= 1 ? 'today' : `in ${paymentEndDays} days`;

  return {
    expirationDays: paymentEndDays,
    dayInMessage,
  };
};
