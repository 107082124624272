import { OnboardState } from 'pages/Onboarding/Onboarding.type';
import { ECountryUI } from './organization.types';
import { TFeatureFlags } from './featureFlags.type';

export enum TUserRoleUi {
  Admin = 'Admin',
  Technician = 'Technician',
  Owner = 'Owner',
}

export enum TUserRole {
  Admin = 'Admin',
  Technician = 'Technician',
  Owner = 'Owner',
}

export type TAuthUserUi = {
  id: string;
  role: TUserRoleUi;
  email: string;
  fullName: string;
  theme: string;
  groups: { id: string; name: string }[];
  organization: null | {
    id: string;
    name: string;
    onboardState: OnboardState;
    timeZone: string;
    country: ECountryUI;
  };
  featureFlags: TFeatureFlags;
};

export type TAuthUser = {
  id: string;
  role: TUserRole;
  email: string;
  fullName: string;
  theme: string;
  groups: { id: string; name: string }[];
  organization: null | {
    onboardState: OnboardState;
    id: string;
    name: string;
    timeZone: string;
    country: ECountryUI;
  };
  featureFlags: string;
};

export type TMember = {
  id: string;
  role: TUserRole;
  email: string;
  fullName: string;
  photoUrl: string;
  groups: TGroup[];
  isDeleted: boolean;
  hourlyRate?: string;
};

export type TMemberSignature = {
  signature: string;
}

export type TGroup = {
  id: string;
  name: string;
  membersCount: number;
  members: string[];
};
export type TMemberUi = {
  id: string;
  role: TUserRoleUi;
  email: string;
  fullName: string;
  photoUrl: string;
  groups: TGroupUi[];
  isDeleted: boolean;
  userGroupsString?: string;
  hourlyRate?: string;
};

export type TGroupUi = {
  id: string;
  name: string;
  membersCount: number;
  members: string[];
};

export type TUserGroup = {
  id: string;
  name: string;
  membersCount: number;
  members: TUserGroupMember[];
};

type TUserGroupMember = {
  id: string;
  role: string;
  email: string;
  fullName: string;
  photoUrl: string;
  isDeleted: boolean;
  groups: string[];
};
export type TUserGroupUi = {
  id: string;
  name: string;
  membersCount: number;
  members: TUserGroupMemberUi[];
};

export type TUserGroupMemberUi = {
  id: string;
  role: string;
  email: string;
  fullName: string;
  photoUrl: string;
  isDeleted: boolean;
  groups: string[];
};

export type TMemberMspEmployeeUi = {
  id: string;
  name: string;
  photoUrl?: string;
  firstLetters: string;
};
export type TMemberMspEmployee = {
  id: string;
  name: string;
  photoUrl?: string;
};
