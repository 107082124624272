import { PrivateRouteType, RoutesPaths } from 'routes/Routes.types';
import { TUserRoleUi } from 'data/types/user.types';
import { lazy } from 'react';
import { TIconNames } from 'components/Icon';

const Integrations = lazy(() => import('pages/private/Integrations'));

const IntegrationDetails = lazy(
  () =>
    import(
      'pages/private/Integrations/AllIntegrations/RMMIntegrations/components/IntegrationDetails/IntegrationDetails'
    ),
);
const OutlookIntegrationConnectionPage = lazy(
  () =>
    import(
      'pages/private/Integrations/AllIntegrations/CalendarIntegrations/MicrosoftOutlookIntegration/OutlookIntegrationConnectionPage'
    ),
);
export const IntegrationsRoutes: PrivateRouteType[] = [
  {
    title: 'Integrations',
    component: Integrations,
    path: RoutesPaths.Integrations,
    icon: TIconNames.INTEGRATION,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
    notInSidebar: true,
  },
  {
    title: 'Integration Details',
    component: IntegrationDetails,
    path: RoutesPaths.IntegrationDetails,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
    notInSidebar: true,
  },
  {
    title: 'Outlook connecton',
    component: OutlookIntegrationConnectionPage,
    path: RoutesPaths.OutlookConnection,
    roles: [TUserRoleUi.Admin, TUserRoleUi.Owner],
    notInSidebar: true,
  },
];
