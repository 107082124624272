export enum TCreatedType {
  DEFAULT = 'Default',
  CREATED = 'Created',
}
export enum EStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum EStatusUi {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export type TOptionType = {
  value: string;
  label: string | JSX.Element;
};

export type TAttachment = {
  name: string;
  fileUrl?: string;
};
export type TAttachmentUi = {
  name: string;
  url?: string;
};

export type TGetAttachment = {
  id: string;
  name: string;
  extension: string;
  fileUrl?: string;
};

export type TGetAttachmentUi = {
  uid: string;
  id: string;
  name: string;
  url?: string;
  status?: string;
  thumbUrl?: string;
  type?: string;
};

export type TUpdateAttachmentUi = {
  id?: string;
  name: string;
  url: string;
};

export type TUpdateAttachment = {
  id?: string;
  name: string;
  fileUrl: string;
};

export type TRtkErrorType = {
  code?: number;
  data: {
    message: string;
  };
  status?: number;
} | null;

type TErrorField = {
  name: string[];
  errors: string[];
  warnings: string[];
};
export type TValidateFieldsError = {
  errorFields: TErrorField[];
  outOfDate: boolean;
};
