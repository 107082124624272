import { weekDays } from 'data/types/organization.types';
import React, { FC, useMemo } from 'react';
import { TWeekDaysTab, weekDaysMapper } from './WeekDays.type';

const WeekDaysTab: FC<TWeekDaysTab> = ({ availableHours, day }) => {
  const filledDay = useMemo(() => {
    return availableHours.find(
      item => item.start && item.end && item.dayOfWeek === day,
    )
      ? 'filled'
      : '';
  }, [availableHours, day]);
  return (
    <div className={` tab-inner ${filledDay}`}>
      {weekDaysMapper[day as weekDays]}
    </div>
  );
};

export default WeekDaysTab;
