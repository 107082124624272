import { Colors, FontSizes } from 'core/CssVariables';
import styled from 'styled-components';

const SlaWrapper = styled.div<{ score: number; fontSize: FontSizes }>`
  color: ${({ score }) =>
    score > 0 ? Colors.SuccessColor : Colors.ErrorColor};
  font-size: ${({ fontSize }) => `${fontSize}px`};
`;

export { SlaWrapper };
