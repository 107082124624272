import { TCompanyStatusUi } from 'data/types/company.types';
import {
  TContractGet,
  TContractGetUi,
  TContractLicensesGet,
  TContractLicensesGetUi,
  TContractLicensesItem,
  TContractLicensesItemPost,
  TContractLicensesItemUi,
  TContractPost,
  TContractPostUi,
  TContractPrimaryDataGet,
  TContractPrimaryDataGetUi,
  TContractPrimaryDataPost,
  TContractPrimaryDataPostUi,
  TContractServicesGet,
  TContractServicesGetUi,
  TContractServicesItem,
  TContractServicesItemPost,
  TContractServicesItemUi,
  TShowContractsTab,
  TUpdateContractDate,
  TUpdateContractDateUi,
  TUpdateContractLicense,
  TUpdateContractLicenseUi,
  TUpdateContractService,
  TUpdateContractServiceUi,
} from 'data/types/companyContract.type';
import {
  TAuthConverter,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import { calculateDecimals, getDateTimeFormats } from 'helpers/utils';
import moment from 'moment';
import { getCategoryConverter } from './category.converter';
import { companyShortDataConverter } from './shortDataConverter';
import { workTypesConverters } from './workTypes.converters';
import { contractTypesConverter } from './contractTypes.converter';

export const contractServicesItemConverter: TAuthConverter<
  TContractServicesItemUi,
  TContractServicesItemPost,
  TContractServicesItemUi,
  TContractServicesItem
> = {
  toDb: data => {
    return {
      name: data.name,
      effectiveDate: moment(data.effectiveDate, getDateTimeFormats().dateFormat).format('YYYY-MM-DD'),
      count: data.count,
      unitCost: data.unitCost,
      unitPrice: data.unitPrice,
      discount: data.discount,
      price: data.price,
      cost: data.cost,
      finalPrice: data.finalPrice,
      categoryId: data.category?.id,
      ...(data.description && { description: data.description }),
    };
  },
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      effectiveDate: moment(data.effectiveDate, 'YYYY-MM-DD').format(
        getDateTimeFormats().dateFormat,
      ),
      count: data.count,
      unitCost: data.unitCost,
      unitPrice: data.unitPrice,
      discount: data.discount,
      price: data.price,
      cost: data.cost,
      finalPrice: data.finalPrice,
      category: data.category && getCategoryConverter.fromDb(data.category),
      description: data.description,
    };
  },
};

export const contractServicesConverter: TFetchConverter<
  TContractServicesGetUi,
  TContractServicesGet
> = {
  fromDb: data => {
    return {
      items: data.items.length
        ? data.items.map(service =>
            contractServicesItemConverter.fromDb(service),
          )
        : [],
      totalDiscount: data.totalDiscount,
      totalCost: data.totalCost,
      totalPrice: data.totalPrice,
      totalFinalPrice: data.totalFinalPrice,
    };
  },
};

export const contractLicensesItemConverter: TAuthConverter<
  TContractLicensesItemUi,
  TContractLicensesItemPost,
  TContractLicensesItemUi,
  TContractLicensesItem
> = {
  toDb: data => {
    return {
      id: data.id,
      name: data.name,
      effectiveDate: moment(data.effectiveDate, getDateTimeFormats().dateFormat).format('YYYY-MM-DD'),
      count: data.count,
      unitCost: data.unitCost,
      unitPrice: data.unitPrice,
      discount: data.discount,
      price: data.price,
      cost: data.cost,
      finalPrice: data.finalPrice,
      partNumber: data.partNumber,
      categoryId: data.category?.id,
      ...(data.description && { description: data.description }),
    };
  },
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      effectiveDate: moment(data.effectiveDate, 'YYYY-MM-DD').format(
        getDateTimeFormats().dateFormat,
      ),
      count: data.count,
      unitCost: data.unitCost,
      unitPrice: data.unitPrice,
      discount: data.discount,
      price: data.price,
      cost: data.cost,
      finalPrice: data.finalPrice,
      partNumber: data.partNumber,
      category: data.category && getCategoryConverter.fromDb(data.category),
      description: data.description,
    };
  },
};

export const contractLicensesConverter: TFetchConverter<
  TContractLicensesGetUi,
  TContractLicensesGet
> = {
  fromDb: data => {
    return {
      items: data.items.length
        ? data.items.map(license =>
            contractLicensesItemConverter.fromDb(license),
          )
        : [],
      totalDiscount: data.totalDiscount,
      totalCost: data.totalCost,
      totalPrice: data.totalPrice,
      totalFinalPrice: data.totalFinalPrice,
    };
  },
};

export const contractPrimaryDataConverter: TAuthConverter<
  TContractPrimaryDataPostUi,
  TContractPrimaryDataPost,
  TContractPrimaryDataGetUi,
  TContractPrimaryDataGet
> = {
  toDb: data => ({
    isPrimaryContract: data.isPrimaryContract,
    ...(data.billableRate && { billableRate: Number(data.billableRate) }),
    billableWorkTypes: data?.billableWorkTypes?.length
      ? data.billableWorkTypes.map(worktype =>
          workTypesConverters.toDb(worktype),
        )
      : [],
  }),
  fromDb: data => {
    return {
      isPrimaryContract: data.isPrimaryContract,
      ...(data.billableRate && { billableRate: data.billableRate }),
      billableWorkTypes: data.billableWorkTypes.length
        ? data.billableWorkTypes.map(worktype =>
            workTypesConverters.fromDb(worktype),
          )
        : [],
    };
  },
};

export const contractConverter: TAuthConverter<
  TContractPostUi,
  TContractPost,
  TContractGetUi,
  TContractGet
> = {
  toDb: data => ({
    name: data.name,
    companyId: data.companyId,
    startDate: data.startDate && data.startDate.format('YYYY-MM-DD'),
    endDate: data.endDate && data.endDate.format('YYYY-MM-DD'),
    billingPeriod: data.billingPeriod,
    primaryData: contractPrimaryDataConverter.toDb(data.primaryData),
    services: data.services.length
      ? data.services.map(service =>
          contractServicesItemConverter.toDb(service),
        )
      : [],
    licenses: data.licenses.length
      ? data.licenses.map(license =>
          contractLicensesItemConverter.toDb(license),
        )
      : [],
    typeId: data.contractType,
  }),
  fromDb: data => {
    const { dateFormat } = getDateTimeFormats();
    return {
      id: data.id,
      creationDateTime: moment(data.creationDateTime, 'YYYY-MM-DD').format(dateFormat),
      name: data.name,
      company: companyShortDataConverter.fromDb(data.company),
      startDate: moment(data.startDate).format(dateFormat),
      endDate: data.endDate && moment(data.endDate).format(dateFormat),
      billingPeriod: data.billingPeriod,
      primaryData: contractPrimaryDataConverter.fromDb(data.primaryData),
      services: contractServicesConverter.fromDb(data.services),
      licenses: contractLicensesConverter.fromDb(data.licenses),
      contractStatus: data.contractStatus,
      contractType:
        data.contractType && contractTypesConverter.fromDb(data.contractType),
    };
  },
};

export const updateContractServicesConverter: TCreateConverter<
  TUpdateContractServiceUi,
  TUpdateContractService
> = {
  toDb: data => {
    const cost = calculateDecimals.multiply(data.count, data.unitCost);
    const price = calculateDecimals.multiply(data.count, data.unitPrice);
    const finalPrice = calculateDecimals.subtract(price, data.discount);
    return {
      name: data.name,
      effectiveDate: moment(data.effectiveDate).format('YYYY-MM-DD'),
      count: +data.count,
      unitCost: +data.unitCost,
      unitPrice: +data.unitPrice,
      discount: +data.discount,
      cost: calculateDecimals.ceilAfterTwoDecimal(cost),
      price: calculateDecimals.ceilAfterTwoDecimal(price),
      finalPrice: calculateDecimals.ceilAfterTwoDecimal(finalPrice),
      categoryId: data.categoryId,
      ...(data.description && { description: data.description }),
    };
  },
};

export const updateContractLicensesConverter: TCreateConverter<
  TUpdateContractLicenseUi,
  TUpdateContractLicense
> = {
  toDb: data => {
    const cost = calculateDecimals.multiply(data.count, data.unitCost);
    const price = calculateDecimals.multiply(data.count, data.unitPrice);
    const finalPrice = calculateDecimals.subtract(price, data.discount);
    return {
      name: data.name,
      effectiveDate: moment(data.effectiveDate).format('YYYY-MM-DD'),
      count: +data.count,
      unitCost: +data.unitCost,
      unitPrice: +data.unitPrice,
      discount: +data.discount,
      partNumber: data.partNumber,
      cost: calculateDecimals.ceilAfterTwoDecimal(cost),
      price: calculateDecimals.ceilAfterTwoDecimal(price),
      finalPrice: calculateDecimals.ceilAfterTwoDecimal(finalPrice),
      categoryId: data.categoryId,
      ...(data.description && { description: data.description }),
    };
  },
};

export const updateContractDateConverter: TCreateConverter<
  TUpdateContractDateUi,
  TUpdateContractDate
> = {
  toDb: data => {
    return {
      id: data.id,
      value: data.value && data.value.format('YYYY-MM-DD'),
    };
  },
};

export const showContractTabMapper: TShowContractsTab = {
  [TCompanyStatusUi['Former Customer']]: true,
  [TCompanyStatusUi.Customer]: true,
};
