import { TConverter, TFetchConverter } from '../types/converter.types';
import {
  PriorityEnum,
  PriorityEnumUi,
  RemainingEnum,
  RemainingEnumUi,
  TSlaRemaining,
  TSlaRemainingUi,
  TSla,
  TSlaUi,
  TSlaTargets,
  TSlaTargetsTypes,
  TSlaTargetsTypesUi,
  TSlaTargetsUi,
  TTargets,
  TTargetsUi,
} from '../types/sla.type';

export const slaTargetsTypesConverter: TConverter<
  TSlaTargetsTypesUi,
  TSlaTargetsTypes
> = {
  fromDb: data => {
    return TSlaTargetsTypesUi[
      TSlaTargetsTypes[data] as keyof typeof TSlaTargetsTypesUi
    ];
  },
  toDb: data => {
    return TSlaTargetsTypes[
      TSlaTargetsTypesUi[data] as keyof typeof TSlaTargetsTypes
    ];
  },
};

export const targetsTypesConverter: TConverter<PriorityEnumUi, PriorityEnum> = {
  fromDb: data => {
    return PriorityEnumUi[PriorityEnum[data] as keyof typeof PriorityEnumUi];
  },
  toDb: data => {
    return PriorityEnum[PriorityEnumUi[data] as keyof typeof PriorityEnum];
  },
};

export const targetsConverter: TConverter<TTargetsUi, TTargets> = {
  fromDb: data => {
    const convertedTargets = {} as TTargetsUi;
    Object.keys(data).forEach(item => {
      const idx = item as PriorityEnum;
      convertedTargets[targetsTypesConverter.fromDb(idx)] = data[idx];
    });
    return convertedTargets;
  },
  toDb: data => {
    const convertedTargets = {} as TTargets;
    Object.keys(data).forEach(item => {
      const idx = item as PriorityEnumUi;
      convertedTargets[targetsTypesConverter.toDb(idx)] = data[idx];
    });
    return convertedTargets;
  },
};

export const slaTargetsConverter: TFetchConverter<TSlaTargetsUi, TSlaTargets> =
  {
    fromDb: data => {
      const convertedSlaTargets = {} as TSlaTargetsUi;
      Object.keys(data).forEach(item => {
        const idx = item as TSlaTargetsTypes;
        convertedSlaTargets[slaTargetsTypesConverter.fromDb(idx)] = {
          type: slaTargetsTypesConverter.fromDb(idx),
          priorities: targetsConverter.fromDb(data[idx].targets),
        };
      });
      return convertedSlaTargets;
    },
  };

export const RemainingConverter: TFetchConverter<
  RemainingEnumUi,
  RemainingEnum
> = {
  fromDb: data => {
    return RemainingEnumUi[RemainingEnum[data] as keyof typeof RemainingEnumUi];
  },
};

export const slaRemainingConverter: TFetchConverter<
  TSlaRemainingUi,
  TSlaRemaining
> = {
  fromDb: data => {
    const convertedSlaRemaining = {} as TSlaRemainingUi;
    Object.keys(data).forEach(item => {
      const idx = item as RemainingEnum;
      convertedSlaRemaining[RemainingConverter.fromDb(idx)] = data[idx];
    });
    return convertedSlaRemaining;
  },
};

export const slaConverter: TFetchConverter<TSlaUi, TSla> = {
  fromDb: data => {
    return {
      type: slaTargetsTypesConverter.fromDb(data.type),
      status: data.status,
      remaining: slaRemainingConverter.fromDb(data.remaining),
      target: data.target,
    };
  },
};
