import { Colors } from 'core/CssVariables';
import { TContactShortData, TContactShortDataUi } from './shortData.type';

export type TAccountManagersUI = {
  id: string;
  name: string;
  photoUrl: string;
  phoneNumber: string;
  email: string;
};
export type TAccountManagers = {
  id: string;
  name: string;
  photoUrl: string;
  phoneNumber: string;
  email: string;
};

export enum TCompanyStatusUi {
  Marketing = 'Marketing',
  Lead = 'Lead',
  Customer = 'Customer',
  'Former Customer' = 'Former Customer',
  'Never Contacted' = 'Never Contacted',
  'Not A Fit' = 'Not A Fit',
}
export enum TCompanyStatus {
  Marketing = 'Marketing',
  Lead = 'Lead',
  Customer = 'Customer',
  'Former Customer' = 'Former Customer',
  'Never Contacted' = 'Never Contacted',
  'Not A Fit' = 'Not A Fit',
}
export enum TCompanyStatusAsParam {
  MARKETING = 'MARKETING',
  LEAD = 'LEAD',
  CUSTOMER = 'CUSTOMER',
  FORMER_CUSTOMER = 'FORMER_CUSTOMER',
  NEVER_CONTACTED = 'NEVER_CONTACTED',
  NOT_A_FIT = 'NOT_A_FIT',
}
export enum ECompanyEndpointType {
  CONTACTS = 'CONTACTS',
  DEVICES = 'DEVICES',
  EMPLOYEES = 'EMPLOYEES',
}
export enum ECompanyEndpointTypeUi {
  CONTACTS = 'Users',
  DEVICES = 'Devices',
  EMPLOYEES = 'Employees',
}
export type TCompanyDomainGetUi = {
  id: string;
  domainName: string;
};
export type TCompanyDomainGet = {
  id: string;
  domainName: string;
};
export type TComapnyPostDataUi = {
  name: string;
  logoUrl?: string;
  address?: string;
  accountManagerId?: string;
  website?: string;
  marketSegment?: string[];
  phoneNumber?: string;
  domainNames: string[];
  shortName?: string;
  numberOfEmployees?: number;
  relationshipStatus: TCompanyStatusUi;
  invoiceTerms?: string;
};

export type TComapnyPostData = {
  name: string;
  logoUrl?: string;
  address?: string;
  accountManagerId?: string;
  website?: string;
  marketSegment?: string[];
  phoneNumber?: string;
  domainNames: string[];
  shortName?: string;
  numberOfEmployees?: number;
  relationshipStatus: TCompanyStatus;
  invoiceTerms?: string;
};

export type TComapnyGetData = {
  id: string;
  name: string;
  logoUrl: string;
  address: string;
  accountManager: TAccountManagers;
  website: string;
  marketSegment: string;
  phoneNumber: string;
  domainNames: TCompanyDomainGet[];
  mainContact: TContactShortData;
  shortName?: string;
  numberOfEmployees?: number;
  relationshipStatus: TCompanyStatus;
  actions: TCompanyAction[];
  scheduledInteraction: TScheduleInteractionGet;
  specialNotes: string[];
  invoiceTerms?: string;
  invoiceRecipientEmails?: string;
  isMsp: boolean;
  endpointType: ECompanyEndpointType;
  contactCount: number;
  deviceCount: number;
  blockHours?: TBlockHoursGet;
  notes?: string;
};

export type TComapnyGetDataUi = {
  id: string;
  name: string;
  logoUrl: string;
  address: string;
  accountManager: TAccountManagers;
  website: string;
  marketSegment: string;
  phoneNumber: string;
  domainNames: TCompanyDomainGetUi[];
  mainContact: TContactShortDataUi;
  firstLetters: string;
  shortName?: string;
  nameWithAbbreviation: string;
  numberOfEmployees?: number;
  relationshipStatus: TCompanyStatusUi;
  actions: TCompanyActionUi[];
  scheduledInteraction: TScheduleInteractionGetUi;
  specialNotes: string[];
  invoiceTerms?: string;
  invoiceRecipientEmails?: string;
  isMsp: boolean;
  endpointType: ECompanyEndpointTypeUi;
  contactCount: string;
  deviceCount: string;
  blockHours?: TBlockHoursGetUi;
  notes?: string;
};

export enum TCsvTypeEnum {
  NATIVE = 'NATIVE',
  AUTO_TASK = 'AUTO_TASK',
}

export enum AllCompaniesPath {
  CUSTOMERS = 'customers',
  POTENTIAL_CUSTOMERS = 'potential-customers',
  FORMER_CUSTOMERS = 'former-customers',
}

export type TCompanyStatusMapperColor = {
  [key in TCompanyStatus]: Colors;
};

export enum CompanyActionEnumUi {
  MARK_AS_CONTACTED = 'MARK_AS_CONTACTED',
  MARK_AS_LEAD = 'MARK_AS_LEAD',
  MARK_AS_NOT_A_FIT = 'MARK_AS_NOT_A_FIT',
  MARK_AS_CUSTOMER = 'MARK_AS_CUSTOMER',
  MARK_AS_FORMER_CUSTOMER = 'MARK_AS_FORMER_CUSTOMER',
}
export enum CompanyActionEnum {
  MARK_AS_CONTACTED = 'MARK_AS_CONTACTED',
  MARK_AS_LEAD = 'MARK_AS_LEAD',
  MARK_AS_NOT_A_FIT = 'MARK_AS_NOT_A_FIT',
  MARK_AS_CUSTOMER = 'MARK_AS_CUSTOMER',
  MARK_AS_FORMER_CUSTOMER = 'MARK_AS_FORMER_CUSTOMER',
}

export type TCompanyActionUi = {
  action: CompanyActionEnumUi;
  nextPossibleStatuses: TCompanyStatusUi[];
};
export type TCompanyAction = {
  action: CompanyActionEnum;
  nextPossibleStatuses: TCompanyStatus[];
};

// edit types  start
export type TEditCompanyUi = {
  id: string;
  value: string;
};
export type TEditCompanyShortName = {
  id: string;
  value?: string;
};
export type TEditCompanyMainContact = {
  id: string;
  value: string;
};
export type TEditCompanyLogo = {
  id: string;
  value: string;
};
export type TEditCompanySegment = {
  id: string;
  value: string;
};
export type TEditCompanyPhone = {
  id: string;
  value: string;
};
export type TEditCompanyWebsite = {
  id: string;
  value: string;
};
export type TEditCompanyDomain = {
  id: string;
  value: string;
};
export type TEditCompanyAccountManager = {
  id: string;
  value: string;
};
export type TEditCompanyName = {
  id: string;
  value: string;
};
export type TEditCompanyAddressUi = {
  id: string;
  address: string;
};
export type TEditCompanyAddress = {
  id: string;
  value: string;
};
export type TEditCompanyEmployeeUi = {
  id: string;
  value: string;
};
export type TEditCompanyEmployee = {
  id: string;
  value: number;
};
export type TEditCompanyInvoiceTermsUi = {
  id: string;
  value: string;
};
export type TEditCompanyInvoiceTerms = {
  id: string;
  value: string;
};
export type TEditCompanyInvoiceRecipientEmailsUi = {
  id: string;
  value: string;
};
export type TEditCompanyInvoiceRecipientEmails = {
  id: string;
  value: string;
};
export type TEditInteractionDescriptionUi = {
  interactionId: string;
  value: string;
};
export type TEditInteraction = {
  interactionId: string;
  value: string;
};
export type TEditInteractionTypeUi = {
  interactionId: string;
  value: InteractionTypeEnumUi;
};
export type TEditInteractionType = {
  interactionId: string;
  value: InteractionTypeEnum;
};
export type TEditScheduleInteractionDescUi = {
  companyId: string;
  value: string;
};
export type TEditScheduleInteractionDesc = {
  companyId: string;
  value: string;
};
export type TEditCompanyEndpointTypeUi = {
  companyId: string;
  value: ECompanyEndpointTypeUi;
};
export type TEditCompanyEndpointType = {
  companyId: string;
  value: string;
};
// edit types  end

// action buttons types  start
export type TMarkAsContacted = {
  nextStatus: TCompanyStatus;
  description: string;
};
export type TMarkAsContactedUi = {
  companyId: string;
  nextStatus: TCompanyStatusUi;
  description: string;
};
export type TMarkAsLead = {
  nextStatus: TCompanyStatus;
  description: string;
};
export type TMarkAsLeadUi = {
  companyId: string;
  nextStatus: TCompanyStatusUi;
  description: string;
};
export type TMarkAsNotAFit = {
  nextStatus: TCompanyStatus;
  description: string;
};
export type TMarkAsNotAFitUi = {
  companyId: string;
  nextStatus: TCompanyStatusUi;
  description: string;
};
export type TMarkAsCustomer = {
  nextStatus: TCompanyStatus;
  description: string;
};
export type TMarkAsCustomerUi = {
  companyId: string;
  nextStatus: TCompanyStatusUi;
  description: string;
};
export type TMarkAsFormerCustomer = {
  nextStatus: TCompanyStatus;
  description: string;
};
export type TMarkAsFormerCustomerUi = {
  companyId: string;
  nextStatus: TCompanyStatusUi;
  description: string;
};
// action buttons types  end

export enum InteractionTypeEnumUi {
  'vCIO Meeting' = 'vCIO Meeting',
  'Training Meeting' = 'Training Meeting',
  'Pop-in' = 'Pop-in',
  Sales = 'Sales',
  Marketing = 'Marketing',
}
export enum InteractionTypeEnum {
  'vCIO Meeting' = 'vCIO Meeting',
  'Training Meeting' = 'Training Meeting',
  'Pop-in' = 'Pop-in',
  Sales = 'Sales',
  Marketing = 'Marketing',
}
export enum InteractionTypeAsParam {
  'vCIO Meeting' = 'V_CIO_MEETING',
  'Training Meeting' = 'TRAINING_MEETING',
  'Pop-in' = 'POP_IN',
  Sales = 'SALES',
  Marketing = 'MARKETING',
}
export enum CompanySpecialFlagsEnum {
  'Needs scheduled interaction' = 'Needs scheduled interaction',
  'Needs interaction' = 'Needs interaction',
  'Block hours low' = 'Block hours low',
  'Block hours depleted' = 'Block hours depleted',
  'Block hours expired' = 'Block hours expired',
}
export type TIteractionReporter = {
  id: string;
  name: string;
  photoUrl: string;
};

export type TIteractionReporterUi = {
  id: string;
  name: string;
  photoUrl: string;
  firstLetters?: string;
};
export type TIteractionAssignee = {
  id: string;
  name: string;
  photoUrl: string;
};

export type TIteractionAssigneeUi = {
  id: string;
  name: string;
  photoUrl: string;
  firstLetters?: string;
};

export type TScheduleInteractionPostUi = {
  companyId: string;
  type: InteractionTypeEnumUi;
  description: string;
  scheduleTime: string;
  assigneeId: string;
};
export type TScheduleInteractionPost = {
  type: InteractionTypeEnum;
  description: string;
  scheduleTime: string;
  assigneeId: string;
};
export type TScheduleInteractionGetUi = {
  id: string;
  type: InteractionTypeEnumUi;
  description: string;
  scheduleTime: string;
  assignee: TIteractionAssigneeUi;
  reporter: TIteractionReporterUi;
  overdueSeverity: string;
  overdueSince: string;
};
export type TScheduleInteractionGet = {
  id: string;
  type: InteractionTypeEnum;
  description: string;
  scheduleTime: string;
  assignee: TIteractionAssignee;
  reporter: TIteractionReporter;
  overdueSeverity: string;
  overdueSince: string;
};
export type TReScheduleInteractionPostUi = {
  companyId?: string;
  type: InteractionTypeEnumUi;
  description: string;
  scheduleTime: string;
  assigneeId: string;
};
export type TReScheduleInteractionPost = {
  type: InteractionTypeEnum;
  description: string;
  scheduleTime: string;
  assigneeId: string;
};
export type TInteractionPostUi = {
  companyId: string;
  type: InteractionTypeEnumUi;
  description: string;
  interactionDateTime: string;
};
export type TInteractionPost = {
  companyId: string;
  type: InteractionTypeEnum;
  description: string;
  interactionDateTime: string;
};
export type TInteractionGetUi = {
  id: string;
  interactionType: InteractionTypeEnumUi;
  description: string;
  interactionDateTime: string;
  reporter: TIteractionReporterUi;
  companyId: string;
};
export type TInteractionGet = {
  id: string;
  interactionType: InteractionTypeEnum;
  description: string;
  interactionDateTime: string;
  reporter: TIteractionReporter;
  companyId: string;
};
export type TResolveInteractionUi = {
  companyId: string;
  type: InteractionTypeEnumUi;
  description: string;
  interactionDateTime: string;
};
export type TResolveInteraction = {
  type: InteractionTypeEnum;
  description: string;
  interactionDateTime: string;
};

export enum InteractionsFiltersEnumUi {
  interactionTypes = 'interactionTypes',
}

export enum InteractionsFiltersEnum {
  interactionTypes = 'interactionTypes',
}

export type TInteractionTypeMapperColor = {
  [key in InteractionTypeEnumUi]: Colors;
};

export type TCsvPreviewFailedCompanyUi = {
  companyName: string;
  failReason: string;
};
export type TCsvPreviewFailedCompany = {
  companyName: string;
  failReason: string;
};

export type TCompanyCsvPreviewUi = {
  passedCompanies: TComapnyGetDataUi[];
  failedCompanies: TCsvPreviewFailedCompanyUi[];
};
export type TCompanyCsvPreview = {
  passedCompanies: TComapnyGetData[];
  failedCompanies: TCsvPreviewFailedCompany[];
};

export type TBulkLogInteractionPostUi = {
  companyIds: string[];
  type: InteractionTypeEnumUi;
  description: string;
  interactionDateTime: string;
};
export type TBulkLogInteractionPost = {
  companyIds: string[];
  type: InteractionTypeEnum;
  description: string;
  interactionDateTime: string;
};

export type TBulkLogInteractionGetUi = {
  companyInteractions: TInteractionGetUi[];
  failedCompanyIds: string[];
};
export type TBulkLogInteractionGet = {
  companyInteractions: TInteractionGet[];
  failedCompanyIds: string[];
};

export type TCompanyStatusesBasedOnTabs = {
  [key in AllCompaniesPath]: TCompanyStatusAsParam[];
};

export type TBlockHoursGet = {
  blockHoursEnabled: boolean;
  startingHours?: number;
  remainingHours?: number;
  blockHoursStart?: string;
  blockHoursEnd?: string;
};

export type TBlockHoursGetUi = {
  blockHoursEnabled: boolean;
  blockHoursData?: {
    startingHours: number;
    remainingHours: number;
    blockHoursStart: string;
    blockHoursEnd?: string;
  };
};

export type TBlockHoursPostUi = {
  companyId: string;
  blockHoursEnabled: boolean;
  blockHoursStart?: string;
  blockHoursEnd?: string;
  startingHours?: number;
};

export type TBlockHoursPost = {
  blockHoursEnabled: boolean;
  blockHoursStart?: string;
  blockHoursEnd?: string;
  startingHours?: number;
};

export type TBlockHoursGetRemainingHoursParamsUi = {
  companyId: string;
  startingHours: number;
  blockHoursStart: string;
  blockHoursEnd?: string;
};
export type TBlockHoursGetRemainingHoursParams = {
  startingHours: number;
  blockHoursStart: string;
  blockHoursEnd?: string;
};

export type TBlockHoursGetRemainingHoursResponse = {
  blockHoursEnabled?: boolean;
  startingHours?: number;
  blockHoursStart?: string;
  blockHoursEnd?: string;
  remainingHours: number;
};

export type TBlockHoursGetRemainingHoursResponseUi = {
  remainingHours: number;
};
