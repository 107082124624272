import React, { FC } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { Icon, TIconNames } from 'components/Icon';
import { Colors } from 'core/CssVariables';
import { SAuthWrapper } from './style';
import { TAuthWrapper } from './types';

const AuthWrapper: FC<TAuthWrapper> = ({
  logo,
  withPadding = false,
  children,
}) => {
  return (
    <SAuthWrapper withPadding={withPadding}>
      <Row>
        <Col md={12} xs={24} className="auth-right">
          {logo && (
            <Row className="auth-logo-wrapper">
              <Icon
                icon={TIconNames.LOGO}
                color={Colors.PrimaryColor}
                size={200}
              />
            </Row>
          )}

          {children}
        </Col>
      </Row>
    </SAuthWrapper>
  );
};

export default AuthWrapper;
