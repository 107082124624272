import firebase from 'configs/firebase.config';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import { getAuth } from 'firebase/auth';

const FIREBASE_TOKEN = 'api/users/firebase-token';

export const firebaseApi = authSplitApi('firebaseApi', [
  'firebaseToken',
]).injectEndpoints({
  endpoints: build => ({
    getFirebaseToken: build.query<string, null>({
      query() {
        return {
          url: FIREBASE_TOKEN,
          method: 'GET',
        };
      },
      transformResponse: (data: { value: string }) => {
        return data ? data.value : '';
      },
      providesTags: ['firebaseToken'],
    }),
    authToFirebase: build.query<any, string>({
      // @ts-ignore
      async queryFn(token) {
        try {
          const auth = await getAuth();
          const user = auth.currentUser;
          if (user) return { data: null };
          await firebase.auth().signInWithCustomToken(token);
          return { data: null };
        } catch (e) {
          return { error: e };
        }
      },
      keepUnusedDataFor: 1,
    }),
  }),
  overrideExisting: false,
});

export const { useGetFirebaseTokenQuery, useAuthToFirebaseQuery } = firebaseApi;
