import { TCommentAdditionalRecipients, TCommentAdditionalRecipientsUi } from 'data/types/commentAdditionalRecipients.types';
import { TFetchConverter } from 'data/types/converter.types';

export const commentAdditionalRecipientsConverter: TFetchConverter<
  TCommentAdditionalRecipientsUi[],
  TCommentAdditionalRecipients[]
  > = {
    fromDb: data => {
      return data.map(x => {
        return {
          name: x.name,
          emailAddress: x.emailAddress
        }
      })
    }
  }