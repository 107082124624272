import { Colors } from 'core/CssVariables';
import { ButtonProps } from 'antd/es/button';
import { TIconNames } from '../Icon';

export enum TButtonTypes {
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
  Danger = 'danger',
  Primary = 'primary',
  Link = 'link',
  Default = 'default',
  Grey = 'grey',
  Orange = 'orange',
  LightGrey = 'lightGrey',
  Pink = 'Pink',
}

export type TButtonColors = {
  color: Colors;
  hoverColor: Colors;
};

export type TButtonColorProp = {
  color?: TButtonTypes;
  outline?: boolean | 'true';
};

export type TButtonStyles = { [key in TButtonTypes]: TButtonColors };
export type TButtonProps = ButtonProps &
  TButtonColorProp & {
    width?: number;
    height?: number;
  } & {
    iconName?: TIconNames;
  };
