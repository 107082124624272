import React, { FC } from 'react';
import { Row } from 'components/Grid/Grid';
import { Text } from 'components/Typography';
import { FontWeights } from 'core/CssVariables';
import { useLocation, useNavigate } from 'react-router';
import { RoutesPaths } from 'routes/Routes.types';
import { THeaderAlertMessageProps } from '../HeaderAlert.type';

const HeaderAlertMessage: FC<THeaderAlertMessageProps> = ({
  message,
  boldMessage,
  hasGoPaymentLink,
}) => {
  const { pathname } = useLocation();
  const isInPaymentPage = pathname === `/app/${RoutesPaths.Payment}`;
  const navigate = useNavigate();
  const onNavigatePaymentPage = () => {
    return navigate(`/app/${RoutesPaths.Payment}`, { replace: true });
  };
  return (
    <Row role="row">
      {boldMessage && (
        <Text className="mr-1" fontlevel={5} fontWeight={FontWeights.SemiBold}>
          {boldMessage}
        </Text>
      )}
      {message && (
        <Text fontlevel={5} fontWeight={FontWeights.Regular}>
          {message}
        </Text>
      )}
      {hasGoPaymentLink && !isInPaymentPage && (
        <Text
          className="pointer ml-1"
          onClick={onNavigatePaymentPage}
          fontlevel={5}
          fontWeight={FontWeights.Regular}
          underline
        >
          Go to Payments
        </Text>
      )}
    </Row>
  );
};

export default HeaderAlertMessage;
