import styled from 'styled-components';

const SWrapperWithCloseButton = styled.div`
  display: flex;
  align-items: center;

  .ant-form-item {
    width: 100%;
    margin-right: 28px;
  }
  .remove-icon {
    position: absolute;
    top: 10px;
    right: 7px;
  }
`;

const SWrapperForDeleteActionButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;
const TagWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
`;

export { SWrapperWithCloseButton, SWrapperForDeleteActionButton, TagWrapper };
