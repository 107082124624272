import React, { useState, useMemo, useEffect } from 'react';
import { Icon, TIconNames } from 'components/Icon';
import { SelectInfo } from 'rc-menu/lib/interface';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { changeSidebarState } from 'redux/slices';
import { FontWeights } from 'core/CssVariables';
import { PrivateRouteType, RoutesPaths } from 'routes/Routes.types';
import ToolTip from 'components/ToolTip/ToolTip';
import { Text } from 'components/Typography';
import { Row, Col } from 'components/Grid/Grid';
import { SMenu, SSidebarLogo } from './Sidebar.style';
import { MenuItem, SubMenu } from './MenuItem/MenuItem';
import useGetPrivateRoutesByRole from '../../hooks/useGetPrivateRoutesByRole';
import CollapseIcons from './components/CollapseIcons';

const Sidebar = () => {
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedParent, setSelectedParent] = useState<string>('');
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sidebar = useAppSelector(state => state.appSettings.sidebar);
  const privateRoutes: PrivateRouteType[] = [];
  const isAccessLimited = useAppSelector(
    state => state.paymentInfoSlice?.isAccessLimited,
  );
  useGetPrivateRoutesByRole().forEach(item => {
    if (!item.notInSidebar) {
      if (item.subRoutes?.length) {
        const newRoute = { ...item };
        const newSubRoutes = item.subRoutes.filter(
          subRoute => !subRoute.notInSidebar,
        );
        newRoute.subRoutes = newSubRoutes;
        privateRoutes.push(newRoute);
      } else {
        privateRoutes.push(item);
      }
    }
  });

  const rootSubmenuKeys = useMemo(() => {
    const rootSubmenuKeysArr: string[] = [];
    privateRoutes.forEach(route => {
      if (route.subRoutes) rootSubmenuKeysArr.push(route.path);
    });
    return rootSubmenuKeysArr;
  }, []);

  const onOpenChange = (keys: string[]) => {
    const lastOpenedMenu = keys[keys.length - 1];
    const route = privateRoutes.find(item => item.path === lastOpenedMenu);
    if (route && route.subRoutes?.length) {
      setSelectedParent(route.path);
    }

    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onSelect = (selectInfo: SelectInfo) => {
    const { key, keyPath } = selectInfo;
    if (window.innerWidth <= 767) {
      dispatch(
        changeSidebarState({
          hidden: true,
        }),
      );
    }

    setSelectedParent(keyPath[keyPath.length - 1]);
    if (keyPath.length === 1) {
      setOpenKeys([]);
    }
    setSelected([key]);
  };
  const isPrevious = (idx: number) => {
    if (
      idx < privateRoutes.length &&
      (privateRoutes[idx].path === openKeys[0] ||
        privateRoutes[idx].path === selectedParent)
    ) {
      return 'true';
    }
    return 'false';
  };

  const onLogoClick = () => {
    if (window.innerWidth <= 767) {
      dispatch(
        changeSidebarState({
          hidden: true,
        }),
      );
    }
    if (!isAccessLimited) {
      navigate(RoutesPaths.Workfeed);
    }
  };

  useEffect(() => {
    const paths = location.pathname
      .split('/')
      .filter(item => item && item !== 'app');
    const route = privateRoutes.find(item => item.path === paths[0]);
    if (
      paths.length > 1 &&
      rootSubmenuKeys.includes(paths[0]) &&
      route?.subRoutes?.length
    ) {
      setSelected([`${paths[0]}/${paths[1]}`]);
    } else {
      setSelected([paths[0]]);
    }
    setOpenKeys([paths[0]]);
    setSelectedParent(paths[0]);
  }, [sidebar.hidden, location.pathname]);

  return (
    <>
    <SMenu
      onSelect={onSelect}
      openKeys={sidebar.hidden ? [] : openKeys}
      selectedKeys={selected}
      onOpenChange={onOpenChange}
      mode="inline"
    >
      <SSidebarLogo
        previous={selectedParent === privateRoutes[0]?.path ? 'true' : 'false'}
        collapsed={sidebar.collapsed}
      >
        <Icon
          icon={sidebar.collapsed ? TIconNames.LOGO_COLLAPSED : TIconNames.LOGO}
          onClick={onLogoClick}
          className="icon-logo"
        />
        <CollapseIcons />
      </SSidebarLogo>

      {privateRoutes.map(({ title, icon, path, subRoutes }, idx) => {
        if (!subRoutes?.length) {
          return (
            <MenuItem key={path} previous={isPrevious(idx + 1)} title="">
              <ToolTip placement="right" title={sidebar.collapsed ? title : ''}>
                {icon && <Icon size={20} icon={icon} />}
                <NavLink to={`${path}`}>
                  {sidebar.collapsed ? '' : title}
                </NavLink>
              </ToolTip>
            </MenuItem>
          );
        }
        return (
          <SubMenu
            previous={isPrevious(idx + 1)}
            title={sidebar.collapsed ? '' : title}
            key={path}
            icon={
              icon && (
                <ToolTip
                  placement="right"
                  title={sidebar.collapsed ? title : ''}
                >
                  <Icon size={20} icon={icon} />
                </ToolTip>
              )
            }
          >
            {subRoutes.map(subRoute => (
              <MenuItem
                key={subRoute.path}
                icon={subRoute.icon && <Icon size={20} icon={subRoute.icon} />}
                title=""
              >
                <ToolTip
                  placement="right"
                  title={sidebar.collapsed ? subRoute.title : ''}
                >
                  <NavLink to={`${subRoute.path}`}>
                    {sidebar.collapsed ? '' : subRoute.title}
                  </NavLink>
                </ToolTip>
              </MenuItem>
            ))}
          </SubMenu>
        );
      })}
      <MenuItem key="lastItem" unselectable="on" />
    </SMenu>
    <Col>
      <Row align="middle" justify="center">
        <Text
          fontlevel={6}
          fontWeight={FontWeights.Regular}
        >
            {sidebar.collapsed ? '' : 'v'}{sidebar.collapsed ? '' : process.env.REACT_APP_VERSION}
        </Text>
      </Row>
    </Col>
    </>
  );
};
export default Sidebar;
