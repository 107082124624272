import { TContactsGetUi } from 'data/types/contact.type';
import { TLocationGet } from 'data/types/location.types';
import { TPageableDataWithContent } from 'data/types/pagableData.type';
import { pageableData } from 'test/mocks/data/general.data';

const contact1: TContactsGetUi = {
  id: '6d8b2d22-5aea-4247-be54-bdfde80844bb',
  name: 'new',
  company: {
    id: 'aac8ee41-6356-46ed-b297-38c671ca31bc',
    name: 'AAA Company',
    logoUrl: '',
    phoneNumber: '+12345678900000000',
    mainContact: {
      id: '0b744501-b00d-4334-a93e-51cfce95259e',
      name: 'new contact',
      phoneNumber: '',
      email: 'n@m.com',
      isVip: false,
      cellPhoneNumber: '',
      isActive: false,
      isManaged: false,
      jobTitle: '',
      personalAddress: '',
      phoneExtension: '',
    },
    firstLetters: 'AC',
    shortName: '',
    nameWithAbbreviation: 'AAA Company',
    isMSP: false,
  },
  email: 's@m.com',
  isMainContact: false,
  phoneNumber: '',
  cellPhoneNumber: '',
  phoneExtension: '',
  jobTitle: '',
  isVip: false,
  personalAddress: '',
  isActive: false,
  isManaged: false,
  location: undefined,
};
const contactFromAnotherCompany: TContactsGetUi = {
  id: '2be221e7-e7b9-4829-86ac-37336b9ef92b',
  name: 'aa',
  company: {
    id: '939594e6-3aab-4821-a258-86d14a6f888a',
    name: '232',
    logoUrl: '',
    phoneNumber: '+12222222222',
    mainContact: {
      id: '0b744501-b0dasdsad0d-4334-a93e-51cfce95259e',
      name: 'new contactaaaa',
      phoneNumber: '',
      email: 'adn@m.com',
      isVip: false,
      cellPhoneNumber: '',
      isActive: false,
      isManaged: false,
      jobTitle: '',
      personalAddress: '',
      phoneExtension: '',
    },
    firstLetters: 'AC',
    shortName: '',
    nameWithAbbreviation: 'AAA Company',
    isMSP: false,
  },
  email: 'aa@mail.com',
  isMainContact: false,
  phoneNumber: '',
  cellPhoneNumber: '',
  phoneExtension: '',
  jobTitle: '',
  isVip: false,
  personalAddress: '',
  isActive: true,
  isManaged: true,
  location: undefined,
};
const contact2: TContactsGetUi = {
  id: 'e1d14f2c-6f98-44ee-bde2-bbfb90db58c4',
  name: 'Aram',
  company: {
    id: 'aac8ee41-6356-46ed-b297-38c671ca31bc',
    name: 'AAA Company',
    logoUrl: '',
    phoneNumber: '+12345678900000000',
    mainContact: {
      id: '0b744501-b00d-4334-a93e-51cfce95259e',
      name: 'new contact',
      phoneNumber: '',
      email: 'n@m.com',
      isVip: false,
      cellPhoneNumber: '',
      isActive: false,
      isManaged: false,
      jobTitle: '',
      personalAddress: '',
      phoneExtension: '',
    },
    firstLetters: 'AC',
    shortName: '',
    nameWithAbbreviation: 'AAA Company',
    isMSP: false,
  },
  email: 'aram@ultralabs.io',
  isMainContact: false,
  phoneNumber: '',
  cellPhoneNumber: '',
  phoneExtension: '',
  jobTitle: '',
  isVip: true,
  personalAddress: '',
  isActive: true,
  isManaged: true,
  location: undefined,
};
const contact3: TContactsGetUi = {
  id: '0b744501-b00d-4334-a93e-51cfce95259e',
  name: 'new contact',
  company: {
    id: 'aac8ee41-6356-46ed-b297-38c671ca31bc',
    name: 'AAA Company',
    logoUrl: '',
    phoneNumber: '+12345678900000000',
    mainContact: {
      id: '0b744501-b00d-4334-a93e-51cfce95259e',
      name: 'new contact',
      phoneNumber: '',
      email: 'n@m.com',
      isVip: false,
      cellPhoneNumber: '',
      isActive: false,
      isManaged: false,
      jobTitle: '',
      personalAddress: '',
      phoneExtension: '',
    },
    firstLetters: 'AC',
    shortName: '',
    nameWithAbbreviation: 'AAA Company',
    isMSP: false,
  },
  email: 'n@m.com',
  isMainContact: true,
  phoneNumber: '',
  cellPhoneNumber: '',
  phoneExtension: '',
  jobTitle: '',
  isVip: false,
  personalAddress: '',
  isActive: true,
  isManaged: false,
  location: {
    name: 'Main office2',
    id: '07b7e0f2-9cce-488d-ad51-fc32ec243b0a',
    address: 'Yerevan',
    creationDateTime: '09/05/2023',
    company: {
      id: 'aac8ee41-6356-46ed-b297-38c671ca31bc',
      name: 'AAA Company',
      logoUrl: '',
      phoneNumber: '+12345678900000000',
      mainContact: {
        id: '0b744501-b00d-4334-a93e-51cfce95259e',
        name: 'new contact',
        phoneNumber: '',
        email: 'n@m.com',
        isVip: false,
        cellPhoneNumber: '',
        isActive: false,
        isManaged: false,
        jobTitle: '',
        personalAddress: '',
        phoneExtension: '',
      },
      firstLetters: 'AC',
      shortName: '',
      nameWithAbbreviation: 'AAA Company',
      isMSP: false,
    },
  },
};

const location1: TLocationGet = {
  name: 'Main office',
  id: 'main-office-random-id1',
  address: 'Yerevan',
  creationTime: '2023-09-05T17:44:53.15711',
  company: {
    id: 'aac8ee41-6356-46ed-b297-38c671ca31bc',
    name: 'AAA Company',
    shortName: '',
    logoUrl: '',
    phoneNumber: '+12345678900000000',
    mainContact: {
      id: '0b744501-b00d-4334-a93e-51cfce95259e',
      name: 'new contact',
      email: 'n@m.com',
      phoneNumber: '',
      isVip: false,
      phoneExtension: '',
      cellPhoneNumber: '',
      personalAddress: '',
      jobTitle: '',
      isActive: false,
      isManaged: false,
    },
    isMSP: false,
  },
};
const location2: TLocationGet = {
  name: 'Gyumri office',
  id: 'office-random-id1',
  address: 'Gyumri',
  creationTime: '2023-09-05T17:44:53.15711',
  company: {
    id: 'aac8ee41-6356-46ed-b297-38c671ca31bc',
    name: 'AAA Company',
    shortName: '',
    logoUrl: '',
    phoneNumber: '+12345678900000000',
    mainContact: {
      id: '0b744501-b00d-4334-a93e-51cfce95259e',
      name: 'new contact',
      email: 'n@m.com',
      phoneNumber: '',
      isVip: false,
      phoneExtension: '',
      cellPhoneNumber: '',
      personalAddress: '',
      jobTitle: '',
      isActive: false,
      isManaged: false,
    },
    isMSP: false,
  },
};

const mockedPagableLocationData: TPageableDataWithContent<TLocationGet[]> = {
  ...pageableData,
  totalElements: 4,
  totalPages: 1,
  numberOfElements: 4,
  empty: false,
  content: [location1, location2],
};

export {
  contact1,
  contact3,
  contact2,
  contactFromAnotherCompany,
  location1,
  location2,
  mockedPagableLocationData,
};
