import React from 'react';
import { SDatePicker, SDateRangePicker } from './DatePicker.style';
import { TDatePickerProps, TDateRangePickerProps } from './DatePicker.type';

const DateRangePicker: React.FC<TDateRangePickerProps> = props => {
  return <SDateRangePicker {...props} />;
};

const DatePicker: React.FC<TDatePickerProps> = props => {
  return <SDatePicker data-testid="date-picker" {...props} />;
};

export { DateRangePicker, DatePicker };
