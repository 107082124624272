import { weekDays } from 'data/types/organization.types';

export type TAvailableHour = {
  dayOfWeek: weekDays;
  start: string;
  end: string;
};

export type TWeekDaysProps = {
  setCustomFieldValue: (workingHours: TAvailableHour[]) => void;
  value?: TAvailableHour[];
  error?: string;
  disabled?: boolean;
};

export type TWeekDaysTab = {
  availableHours: TAvailableHour[];
  day: string;
};

export const weekDaysMapper = {
  [weekDays.SUNDAY]: 'Sun',
  [weekDays.MONDAY]: 'Mon',
  [weekDays.TUESDAY]: 'Tue',
  [weekDays.WEDNESDAY]: 'Wed',
  [weekDays.THURSDAY]: 'Thu',
  [weekDays.FRIDAY]: 'Fri',
  [weekDays.SATURDAY]: 'Sat',
};
