import {
  accountManagerConverter,
  BlockHoursConverter,
  BlockHoursRemainingHoursConverter,
  BlockHoursRemainingHoursResponseConverter,
  bulkLogInteractionConverter,
  companyAccountManagerEditConverter,
  companyAddressEditConverter,
  companyConverter,
  companyCsvPreviewConverter,
  companyDomainEditConverter,
  companyEmployeeEditConverter,
  companyEndpointTypeEditConverter,
  companyInvoiceRecipientEmailsEditConverter,
  companyInvoiceTermsEditConverter,
  companyLogoEditConverter,
  companyMainContactEditConverter,
  companyNameEditConverter,
  companyPhoneEditConverter,
  companySegmentEditConverter,
  companyShortNameEditConverter,
  companyWebsiteEditConverter,
  interactionConverter,
  interactionDescriptionEditConverter,
  interactionTypeEditConverter,
  markAsContactedConverter,
  markAsCustomerConverter,
  markAsFormerCustomerConverter,
  markAsLeadConverter,
  markAsnotAFitConverter,
  reShceduleInteractionConverter,
  resolveInteractionConverter,
  scheduleInteractionDescEditConverter,
  shceduleInteractionConverter,
} from 'data/converters/company.converter';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import {
  AllCompaniesPath,
  TAccountManagers,
  TAccountManagersUI,
  TBlockHoursGet,
  TBlockHoursGetRemainingHoursParamsUi,
  TBlockHoursGetRemainingHoursResponse,
  TBlockHoursGetRemainingHoursResponseUi,
  TBlockHoursPostUi,
  TBulkLogInteractionGet,
  TBulkLogInteractionGetUi,
  TBulkLogInteractionPostUi,
  TComapnyGetData,
  TComapnyGetDataUi,
  TComapnyPostData,
  TComapnyPostDataUi,
  TCompanyCsvPreview,
  TCompanyCsvPreviewUi,
  TCsvTypeEnum,
  TEditCompanyAddressUi,
  TEditCompanyEmployeeUi,
  TEditCompanyEndpointTypeUi,
  TEditCompanyInvoiceTermsUi,
  TEditCompanyUi,
  TEditInteractionDescriptionUi,
  TEditInteractionTypeUi,
  TEditScheduleInteractionDescUi,
  TInteractionGet,
  TInteractionGetUi,
  TInteractionPostUi,
  TMarkAsContactedUi,
  TMarkAsCustomerUi,
  TMarkAsFormerCustomerUi,
  TMarkAsLeadUi,
  TMarkAsNotAFitUi,
  TReScheduleInteractionPostUi,
  TResolveInteractionUi,
  TScheduleInteractionPostUi,
} from 'data/types/company.types';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { authSplitApi } from 'redux/helpers/slice.helpers';

export const COMPANY_URL = 'api/companies';
const INTERACTION_URL = 'api/company-interactions';

export const companiesApi = authSplitApi('companiesApi', [
  'Companies',
  'Company',
  'Market-Segments',
  'Account-Managers',
  'interactions',
  'Search_Companies_In_Filter',
  'blockHours',
]).injectEndpoints({
  endpoints: build => ({
    createCompany: build.mutation<TComapnyPostData, TComapnyPostDataUi>({
      query(body) {
        return {
          url: COMPANY_URL,
          method: 'POST',
          body: companyConverter.toDb(body),
        };
      },
      invalidatesTags: ['Companies'],
    }),
    createCompanyCSVUpload: build.mutation<
      Blob,
      { file: File; csvType: TCsvTypeEnum }
    >({
      query({ file, csvType }) {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: COMPANY_URL.concat(`/csv-upload?csvType=${csvType}`),
          method: 'POST',
          responseHandler: response =>
            response.status === 200 ? response.text() : response.json(),
          body: formData,
        };
      },
      invalidatesTags: ['Companies'],
    }),
    companyCSVPreview: build.mutation<
      TCompanyCsvPreviewUi,
      { file: File; csvType: TCsvTypeEnum }
    >({
      query({ file, csvType }) {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: COMPANY_URL.concat('/csv-preview'),
          method: 'POST',
          params: {
            csvType,
          },
          responseHandler: response =>
            response.status === 200 ? response.text() : response.json(),
          body: formData,
        };
      },
      transformResponse: (data: string) => {
        const parsedData: TCompanyCsvPreview = JSON.parse(data);
        return companyCsvPreviewConverter.fromDb(parsedData);
      },
    }),
    getMarketSegments: build.query<string[], void>({
      query() {
        return {
          url: COMPANY_URL.concat('/market-segments'),
          method: 'GET',
        };
      },
      providesTags: ['Market-Segments'],
    }),
    getAccountManagers: build.query<
      TPageableDataWithContentUi<TAccountManagersUI[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
      }
    >({
      query({ sort, pageSize, page }) {
        return {
          url: COMPANY_URL.concat('/account-managers'),
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sort && { sort }),
          },
        };
      },
      transformResponse: (
        data: TPageableDataWithContent<TAccountManagers[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(manager => accountManagerConverter.toDb(manager))
            : [],
        };
      },
      providesTags: ['Account-Managers'],
    }),
    getCompanies: build.query<
      TPageableDataWithContentUi<TComapnyGetDataUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        search?: string;
      }
    >({
      query({ page, pageSize, sort, search }) {
        const urlWithParams = COMPANY_URL?.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            sort: sort || '',
            ...(search && { search }),
          },
        };
      },
      providesTags: ['Companies'],
      transformResponse: (
        data: TPageableDataWithContent<TComapnyGetData[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(company => companyConverter.fromDb(company))
            : [],
        };
      },
    }),
    getCompanyById: build.query<
      TComapnyGetDataUi,
      {
        id: string;
      }
    >({
      query({ id }) {
        return {
          url: COMPANY_URL.concat(`/${id}`),
          method: 'GET',
        };
      },
      providesTags: ['Company'],
      transformResponse: (data: TComapnyGetData) => {
        return companyConverter.fromDb(data);
      },
    }),
    editCompanyName: build.mutation<TComapnyGetDataUi, TEditCompanyUi>({
      query(body) {
        const { id, value } = companyNameEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/name`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyNotes: build.mutation<TComapnyGetDataUi, TEditCompanyUi>({
      query(body) {
        const { id, value } = companyNameEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/notes`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyAccountManager: build.mutation<
      TComapnyGetDataUi,
      TEditCompanyUi
    >({
      query(body) {
        const { id, value } = companyAccountManagerEditConverter.toDb(body);

        return {
          url: `${COMPANY_URL}/${id}/account-manager`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyDomain: build.mutation<TComapnyGetDataUi, TEditCompanyUi>({
      query(body) {
        const { id, value } = companyDomainEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/domain`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    deleteCompanyDomainById: build.mutation<
      void,
      { companyId: string; domainId: string }
    >({
      query({ companyId, domainId }) {
        return {
          url: `${COMPANY_URL}/${companyId}/domain/${domainId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    markCompanyAsDeleted: build.mutation<
    void,
    { customerId: string }
    >({
      query({ customerId }) {
        return {
          url: `${COMPANY_URL}/${customerId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyWebsite: build.mutation<TComapnyGetDataUi, TEditCompanyUi>({
      query(body) {
        const { id, value } = companyWebsiteEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/website`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyPhone: build.mutation<TComapnyGetDataUi, TEditCompanyUi>({
      query(body) {
        const { id, value } = companyPhoneEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/phone`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanySegment: build.mutation<TComapnyGetDataUi, TEditCompanyUi>({
      query(body) {
        const { id, value } = companySegmentEditConverter.toDb(body);

        return {
          url: `${COMPANY_URL}/${id}/market-segment`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyAddress: build.mutation<
      TComapnyGetDataUi,
      TEditCompanyAddressUi
    >({
      query(body) {
        const { id, value } = companyAddressEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/address`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyLogo: build.mutation<TComapnyGetDataUi, TEditCompanyUi>({
      query(body) {
        const { id, value } = companyLogoEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/logo`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyMainContact: build.mutation<TComapnyGetDataUi, TEditCompanyUi>({
      query(body) {
        const { id, value } = companyMainContactEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/maincontact`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyShortName: build.mutation<TComapnyGetDataUi, TEditCompanyUi>({
      query(body) {
        const { id, value } = companyShortNameEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/short-name`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyEmployees: build.mutation<
      TComapnyGetDataUi,
      TEditCompanyEmployeeUi
    >({
      query(body) {
        const { id, value } = companyEmployeeEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/number-of-employees`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyInvoiceTerms: build.mutation<
      TComapnyGetDataUi,
      TEditCompanyInvoiceTermsUi
    >({
      query(body) {
        const { id, value } = companyInvoiceTermsEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/invoice-terms`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    editCompanyInvoiceRecipientEmails: build.mutation<
      TComapnyGetDataUi,
      TEditCompanyInvoiceTermsUi
    >({
      query(body) {
        const { id, value } =
          companyInvoiceRecipientEmailsEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${id}/invoice-recipient-emails`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company', 'Companies'],
    }),
    searchCompaniesInFilter: build.query<
      TPageableDataWithContentUi<TComapnyGetDataUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        search?: string;
      }
    >({
      query({ page, pageSize, sort, search }) {
        const urlWithParams = COMPANY_URL?.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            sort: sort || '',
            ...(search && { search }),
          },
        };
      },
      providesTags: ['Search_Companies_In_Filter'],
      transformResponse: (
        data: TPageableDataWithContent<TComapnyGetData[]>,
        meta,
        { search },
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content:
            search && data?.content?.length
              ? data.content.map(company => companyConverter.fromDb(company))
              : [],
        };
      },
    }),
    getAllCompaniesByTab: build.query<
      TPageableDataWithContentUi<TComapnyGetDataUi[]>,
      {
        page: number;
        pageSize: number;
        path: AllCompaniesPath;
        sort?: string;
        relationshipStatuses?: string[];
        search?: string;
        filters?: any;
      }
    >({
      query({
        page,
        pageSize,
        sort,
        path,
        search,
        relationshipStatuses,
        filters,
      }) {
        const urlWithParams = COMPANY_URL.concat(`/${path}`).concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(search && { search }),
            ...(relationshipStatuses?.length && { relationshipStatuses }),
            ...(filters && { ...filters }),
          },
        };
      },
      providesTags: ['Companies'],
      transformResponse: (
        data: TPageableDataWithContent<TComapnyGetData[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(company => companyConverter.fromDb(company))
            : [],
        };
      },
    }),
    getCompaniesCustomers: build.query<
      TPageableDataWithContentUi<TComapnyGetDataUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        search?: string;
        includeFormer?: boolean;
        includePotential?: boolean;
      }
    >({
      query({ page, pageSize, sort, search, includeFormer, includePotential }) {
        const urlWithParams = COMPANY_URL.concat(`/customers`).concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(search && { search }),
            ...(includeFormer && { includeFormer }),
            ...(includePotential && { includePotential }),
          },
        };
      },
      providesTags: ['Companies'],
      transformResponse: (
        data: TPageableDataWithContent<TComapnyGetData[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(company => companyConverter.fromDb(company))
            : [],
        };
      },
    }),
    markAsContacted: build.mutation<TComapnyGetDataUi, TMarkAsContactedUi>({
      query(body) {
        const { companyId } = body;
        return {
          url: `${COMPANY_URL}/${companyId}/mark-as-contacted`,
          method: 'PATCH',
          body: markAsContactedConverter.toDb(body),
        };
      },
      invalidatesTags: ['Companies', 'Company'],
    }),
    markAsLead: build.mutation<TComapnyGetDataUi, TMarkAsLeadUi>({
      query(body) {
        const { companyId } = body;
        return {
          url: `${COMPANY_URL}/${companyId}/mark-as-lead`,
          method: 'PATCH',
          body: markAsLeadConverter.toDb(body),
        };
      },
      invalidatesTags: ['Companies', 'Company'],
    }),
    markAsNotAFit: build.mutation<TComapnyGetDataUi, TMarkAsNotAFitUi>({
      query(body) {
        const { companyId } = body;
        return {
          url: `${COMPANY_URL}/${companyId}/mark-as-not-a-fit`,
          method: 'PATCH',
          body: markAsnotAFitConverter.toDb(body),
        };
      },
      invalidatesTags: ['Companies', 'Company'],
    }),
    markAsCustomer: build.mutation<TComapnyGetDataUi, TMarkAsCustomerUi>({
      query(body) {
        const { companyId } = body;
        return {
          url: `${COMPANY_URL}/${companyId}/mark-as-customer`,
          method: 'PATCH',
          body: markAsCustomerConverter.toDb(body),
        };
      },
      invalidatesTags: ['Companies', 'Company'],
    }),
    markAsFormerCustomer: build.mutation<
      TComapnyGetDataUi,
      TMarkAsFormerCustomerUi
    >({
      query(body) {
        const { companyId } = body;
        return {
          url: `${COMPANY_URL}/${companyId}/mark-as-former-customer`,
          method: 'PATCH',
          body: markAsFormerCustomerConverter.toDb(body),
        };
      },
      invalidatesTags: ['Companies', 'Company'],
    }),
    scheduleInteraction: build.mutation<
      TComapnyGetDataUi,
      TScheduleInteractionPostUi
    >({
      query(body) {
        const { companyId } = body;
        return {
          url: `${COMPANY_URL}/${companyId}/schedule-interaction`,
          method: 'PATCH',
          body: shceduleInteractionConverter.toDb(body),
        };
      },
      invalidatesTags: ['Company'],
    }),
    reScheduleInteraction: build.mutation<
      TComapnyGetDataUi,
      TReScheduleInteractionPostUi
    >({
      query(body) {
        const { companyId } = body;
        return {
          url: `${COMPANY_URL}/${companyId}/reschedule-interaction`,
          method: 'PATCH',
          body: reShceduleInteractionConverter.toDb(body),
        };
      },
      invalidatesTags: ['Company'],
    }),
    resolveInteraction: build.mutation<
      TComapnyGetDataUi,
      TResolveInteractionUi
    >({
      query(body) {
        const { companyId } = body;
        return {
          url: `${COMPANY_URL}/${companyId}/resolve-nsi`,
          method: 'PATCH',
          body: resolveInteractionConverter.toDb(body),
        };
      },
      invalidatesTags: ['interactions', 'Company'],
    }),
    logInteraction: build.mutation<TInteractionGetUi, TInteractionPostUi>({
      query(body) {
        return {
          url: `${INTERACTION_URL}/log-interaction`,
          method: 'POST',
          body: interactionConverter.toDb(body),
        };
      },
      invalidatesTags: ['interactions'],
    }),
    bulkLogInteraction: build.mutation<
      TBulkLogInteractionGetUi,
      TBulkLogInteractionPostUi & {
        relationshipStatuses?: string[];
      }
    >({
      query(body) {
        const { relationshipStatuses, ...payload } = body;
        return {
          url: `${INTERACTION_URL}/bulk-log-interaction`,
          method: 'POST',
          body: bulkLogInteractionConverter.toDb(payload),
          params: {
            ...(relationshipStatuses?.length && { relationshipStatuses }),
          },
        };
      },
      transformResponse: (data: TBulkLogInteractionGet) => {
        return bulkLogInteractionConverter.fromDb(data);
      },
      invalidatesTags: ['interactions'],
    }),
    getInteractions: build.query<
      TPageableDataWithContentUi<TInteractionGetUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        filters?: any;
        companyId: string;
      }
    >({
      query({ page, pageSize, sort, filters, companyId }) {
        const filtersWithCompanyId = {
          companies: [companyId],
          ...filters,
        };
        const urlWithParams = INTERACTION_URL.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...filtersWithCompanyId,
          },
        };
      },
      providesTags: ['interactions'],
      transformResponse: (
        data: TPageableDataWithContent<TInteractionGet[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(interaction =>
                interactionConverter.fromDb(interaction),
              )
            : [],
        };
      },
    }),
    editInteractionDescription: build.mutation<
      TInteractionGetUi,
      TEditInteractionDescriptionUi
    >({
      query(body) {
        const { interactionId, value } =
          interactionDescriptionEditConverter.toDb(body);
        return {
          url: `${INTERACTION_URL}/${interactionId}/description`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['interactions'],
    }),
    editInteractionType: build.mutation<
      TInteractionGetUi,
      TEditInteractionTypeUi
    >({
      query(body) {
        const { interactionId, value } =
          interactionTypeEditConverter.toDb(body);
        return {
          url: `${INTERACTION_URL}/${interactionId}/type`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['interactions'],
    }),
    editScheduleInteractionDescription: build.mutation<
      TComapnyGetDataUi,
      TEditScheduleInteractionDescUi
    >({
      query(body) {
        const { companyId } = body;
        return {
          url: `${COMPANY_URL}/${companyId}/interaction-description`,
          method: 'PATCH',
          body: scheduleInteractionDescEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['Company'],
    }),
    deleteInteractionById: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `${INTERACTION_URL}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['interactions'],
    }),
    editCompanyEndpointType: build.mutation<
      TComapnyGetDataUi,
      TEditCompanyEndpointTypeUi
    >({
      query(body) {
        const { companyId, value } =
          companyEndpointTypeEditConverter.toDb(body);
        return {
          url: `${COMPANY_URL}/${companyId}/endpoint-type`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Company'],
    }),
    setBlockHours: build.mutation<TBlockHoursGet, TBlockHoursPostUi>({
      query(data) {
        const { companyId } = data;
        return {
          url: `${COMPANY_URL}/${companyId}/block-hours`,
          method: 'PUT',
          body: BlockHoursConverter.toDb(data),
        };
      },
      invalidatesTags: ['Company'],
    }),
    getRemainingBlockHours: build.query<
      TBlockHoursGetRemainingHoursResponseUi,
      TBlockHoursGetRemainingHoursParamsUi
    >({
      query(data) {
        const { companyId } = data;
        return {
          url: `${COMPANY_URL}/${companyId}/block-hours/preview`,
          method: 'GET',
          params: BlockHoursRemainingHoursConverter.toDb(data),
        };
      },
      transformResponse: (data: TBlockHoursGetRemainingHoursResponse) => {
        return BlockHoursRemainingHoursResponseConverter.fromDb(data);
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useEditCompanyAccountManagerMutation,
  useCreateCompanyCSVUploadMutation,
  useEditCompanyMainContactMutation,
  useSearchCompaniesInFilterQuery,
  useEditCompanySegmentMutation,
  useEditCompanyAddressMutation,
  useEditCompanyWebsiteMutation,
  useEditCompanyDomainMutation,
  useEditCompanyPhoneMutation,
  useEditCompanyNameMutation,
  useEditCompanyNotesMutation,
  useEditCompanyLogoMutation,
  useEditCompanyShortNameMutation,
  useEditCompanyEmployeesMutation,
  useEditCompanyInvoiceTermsMutation,
  useEditCompanyInvoiceRecipientEmailsMutation,
  useGetAccountManagersQuery,
  useGetMarketSegmentsQuery,
  useCreateCompanyMutation,
  useGetCompanyByIdQuery,
  useGetCompaniesQuery,
  useGetCompaniesCustomersQuery,
  useGetAllCompaniesByTabQuery,
  useMarkAsContactedMutation,
  useMarkAsLeadMutation,
  useMarkAsNotAFitMutation,
  useMarkAsCustomerMutation,
  useMarkAsFormerCustomerMutation,
  useScheduleInteractionMutation,
  useReScheduleInteractionMutation,
  useResolveInteractionMutation,
  useLogInteractionMutation,
  useGetInteractionsQuery,
  useEditInteractionDescriptionMutation,
  useEditInteractionTypeMutation,
  useEditScheduleInteractionDescriptionMutation,
  useCompanyCSVPreviewMutation,
  useBulkLogInteractionMutation,
  useDeleteInteractionByIdMutation,
  useDeleteCompanyDomainByIdMutation,
  useMarkCompanyAsDeletedMutation,
  useEditCompanyEndpointTypeMutation,
  useSetBlockHoursMutation,
  useGetRemainingBlockHoursQuery,
} = companiesApi;
