import moment, { Moment } from 'moment';
import { useAppSelector } from 'redux/store';

export const useGetDateWithTimeZone = (date?: Moment): Moment => {
  const timeZone = useAppSelector(
    state => state.authSlice.user?.organization?.timeZone,
  );

  const inputDate = date?.toDate() || new Date();

  const invdate = new Date(
    inputDate.toLocaleString('en-US', {
      timeZone,
    }),
  );
  const diff = inputDate.getTime() - invdate.getTime();
  const dateWithTimeZone = new Date(inputDate.getTime() - diff);
  return moment(dateWithTimeZone);
};
