import { rest } from 'msw';
import { QUEUES_URL } from 'redux/apiSlices/queues.slice';
import { mockedQueue, mockedQueues } from '../data/mockedQueueData';

export const queueHandler = [
  rest.get(`${QUEUES_URL}/:id`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedQueue));
  }),
  rest.get(QUEUES_URL, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedQueues));
  }),
  rest.patch(`${QUEUES_URL}/:id/rename`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedQueue));
  }),
  rest.patch(`${QUEUES_URL}/:id/description`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockedQueue));
  }),
];
