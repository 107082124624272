import {
  taskAssigneeConverter,
  taskAssigneeEditConverter,
  taskComapnyEditConverter,
  taskCommentConverter,
  taskConverter,
  taskDescriptionEditConverter,
  taskDetailsConverter,
  taskNextActionItemDescEditConverter,
  taskReporterConverter,
  taskReporterEditConverter,
  taskStatusEditConverter,
  taskTitleEditConverter,
} from 'data/converters/task.converters';
import {
  TCreateTask,
  TCreateTaskUi,
  TTaskDetails,
  TTaskDetailsUi,
  TGetTask,
  TGetTaskUi,
  TTaskAssigneeUI,
  TTaskAssignee,
  TTaskReporterUI,
  TTaskReporter,
  AllTasksPath,
  TEditTaskUi,
  TTaskComment,
  TTaskCommentUi,
} from 'data/types/task.type';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';

import { authSplitApi } from 'redux/helpers/slice.helpers';
import { TFilterPath } from 'components/FilterComponents/FilterComponents.type';
import { TUpdateAttachmentUi } from 'data/types/generalDataTypes';
import { attachmentsUpdateConverter } from 'data/converters/general.converters';
import { patchResponseToToastConverter } from 'data/converters/responses.converter';
import { TPatchResponse, TPatchResponseUi } from 'data/types/responses.type';

const TASK_URL = 'api/tasks';
const ALL_TASK_URL = 'api/tasks/all';

export const tasksApi = authSplitApi('tasksApi', [
  'Tasks',
  'Task',
  'TaskComments',
]).injectEndpoints({
  endpoints: build => ({
    createTask: build.mutation<TCreateTaskUi, TCreateTask>({
      query(body) {
        return {
          url: TASK_URL,
          method: 'POST',
          body: taskConverter.toDb(body),
        };
      },
      invalidatesTags: ['Tasks'],
    }),

    getTask: build.query<TTaskDetailsUi, string>({
      query(taskId) {
        return {
          url: `${TASK_URL}/${taskId}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TTaskDetails) =>
        taskDetailsConverter.fromDb(data),
      providesTags: ['Task'],
    }),
    getAllTasks: build.query<
      TPageableDataWithContentUi<TGetTaskUi[]>,
      {
        page: number;
        pageSize: number;
        sort?: string;
        filters?: any;
        search?: string;
      }
    >({
      query({ page, pageSize, sort, filters, search }) {
        const urlWithParams = ALL_TASK_URL.concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
        };
      },
      providesTags: ['Tasks'],
      transformResponse: (data: TPageableDataWithContent<TGetTask[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(task => taskConverter.fromDb(task))
            : [],
        };
      },
    }),
    getAllTasksByTab: build.query<
      TPageableDataWithContentUi<TGetTaskUi[]>,
      {
        page: number;
        pageSize: number;
        path: AllTasksPath;
        sort?: string;
        filters?: any;
        search?: string;
      }
    >({
      query({ page, pageSize, sort, filters, path, search }) {
        const urlWithParams = TASK_URL.concat(`/${path}`).concat(
          `?page=${page}&size=${pageSize}`,
        );
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(sort && { sort }),
            ...(filters && { ...filters }),
            ...(search && { search }),
          },
        };
      },
      providesTags: ['Tasks'],
      transformResponse: (data: TPageableDataWithContent<TGetTask[]>) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: data?.content?.length
            ? data.content.map(task => taskConverter.fromDb(task))
            : [],
        };
      },
    }),
    getTaskStatus: build.query<
      string[],
      {
        exactState?: TFilterPath;
        fromState?: TFilterPath;
        fromStatus?: string;
      }
    >({
      query({ exactState, fromState, fromStatus }) {
        const urlWithParams = TASK_URL.concat(`/statuses`);
        return {
          url: urlWithParams,
          method: 'GET',
          params: {
            ...(exactState && { exactState: exactState.toUpperCase() }),
            ...(fromState && { fromState: fromState.toUpperCase() }),
            ...(fromStatus && {
              fromStatus: fromStatus.toUpperCase().replace(' ', '_'),
            }),
          },
        };
      },
    }),

    editTitle: build.mutation<TTaskDetailsUi, TEditTaskUi>({
      query(body) {
        const { id, value } = taskTitleEditConverter.toDb(body);
        return {
          url: `${TASK_URL}/${id}/title`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Tasks'],
    }),
    editDescription: build.mutation<TTaskDetailsUi, TEditTaskUi>({
      query(body) {
        const { id, value } = taskDescriptionEditConverter.toDb(body);
        return {
          url: `${TASK_URL}/${id}/description`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Tasks'],
    }),
    editAssignee: build.mutation<TTaskDetailsUi, TEditTaskUi>({
      query(body) {
        const { id, assigneeId } = taskAssigneeEditConverter.toDb(body);
        return {
          url: `${TASK_URL}/${id}/assignee`,
          method: 'PATCH',
          body: { assigneeId },
        };
      },
      invalidatesTags: ['Tasks'],
    }),
    editStatus: build.mutation<TTaskDetailsUi, TEditTaskUi>({
      query(body) {
        const { id, status } = taskStatusEditConverter.toDb(body);
        return {
          url: `${TASK_URL}/${id}/status`,
          method: 'PATCH',
          body: { status },
        };
      },
      invalidatesTags: ['Tasks'],
    }),
    editReporter: build.mutation<TTaskDetailsUi, TEditTaskUi>({
      query(body) {
        const { id, value } = taskReporterEditConverter.toDb(body);
        return {
          url: `${TASK_URL}/${id}/reporter`,
          method: 'PATCH',
          body: { value },
        };
      },
      transformResponse: (data: TTaskDetails) =>
        taskDetailsConverter.fromDb(data),
      invalidatesTags: ['Tasks'],
    }),
    editCompany: build.mutation<TTaskDetailsUi, TEditTaskUi>({
      query(body) {
        const { id, value } = taskComapnyEditConverter.toDb(body);
        return {
          url: `${TASK_URL}/${id}/company`,
          method: 'PATCH',
          body: { value },
        };
      },
      transformResponse: (data: TTaskDetails) =>
        taskDetailsConverter.fromDb(data),
      invalidatesTags: ['Tasks', 'Task'],
    }),
    editNextActionItemDesc: build.mutation<TTaskDetailsUi, TEditTaskUi>({
      query(body) {
        const { id, value } = taskNextActionItemDescEditConverter.toDb(body);
        return {
          url: `${TASK_URL}/${id}/next-action-item-description`,
          method: 'PATCH',
          body: { value },
        };
      },
      invalidatesTags: ['Task'],
    }),

    getTaskAssignees: build.query<TTaskAssigneeUI[], void>({
      query() {
        return {
          url: `${TASK_URL}/assignee`,
          method: 'GET',
        };
      },
      transformResponse: (data: TTaskAssignee[]) => {
        return data.map(item => {
          return taskAssigneeConverter.fromDb(item);
        });
      },
    }),

    getTaskReporters: build.query<TTaskReporterUI[], void>({
      query() {
        return {
          url: `${TASK_URL}/assignee`,
          method: 'GET',
        };
      },
      transformResponse: (data: TTaskReporter[]) => {
        return data.map(item => {
          return taskReporterConverter.fromDb(item);
        });
      },
    }),
    updateTaskReAssigne: build.mutation<
      TPatchResponseUi,
      {
        taskId: string;
        assigneeId: string;
        noteForAssignee?: string;
        keepNSI?: boolean;
      }
    >({
      query({ taskId, assigneeId, keepNSI }) {
        return {
          url: TASK_URL.concat(`/${taskId}/reassign`),
          method: 'PATCH',
          body: {
            assigneeId,
            keepNSI,
          },
        };
      },
      transformResponse(data: TPatchResponse, meta) {
        return patchResponseToToastConverter.fromDb({
          ...data,
          status: meta?.response?.status,
        });
      },
      invalidatesTags: ['Tasks', 'Task', 'TaskComments'],
    }),
    startTaskWork: build.mutation<
      void,
      {
        taskId: string;
        value: string;
      }
    >({
      query({ taskId, value }) {
        return {
          url: TASK_URL.concat(`/${taskId}/start-work`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Tasks', 'Task'],
    }),
    resolveTask: build.mutation<
      void,
      {
        taskId: string;
        value: string;
      }
    >({
      query({ taskId, value }) {
        return {
          url: TASK_URL.concat(`/${taskId}/resolve`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['Tasks', 'Task', 'TaskComments'],
    }),
    scheduleTask: build.mutation<
      TPatchResponseUi,
      {
        taskId: string;
        scheduleTime: string;
        description: string;
        status: string;
        endTime: string;
        createCalendarItem: boolean;
      }
    >({
      query({
        taskId,
        status,
        description,
        scheduleTime,
        endTime,
        createCalendarItem,
      }) {
        return {
          url: TASK_URL.concat(`/${taskId}/schedule`),
          method: 'PATCH',
          body: {
            nextStatus: status,
            description,
            scheduleTime,
            endTime,
            createCalendarItem,
          },
        };
      },
      transformResponse(data: TPatchResponse, meta) {
        return patchResponseToToastConverter.fromDb({
          ...data,
          status: meta?.response?.status,
        });
      },
      invalidatesTags: ['Tasks', 'Task', 'TaskComments'],
    }),
    reScheduleTask: build.mutation<
      TPatchResponseUi,
      {
        taskId: string;
        scheduleTime: string;
        description: string;
        status: string;
        endTime: string;
        createCalendarItem: boolean;
      }
    >({
      query({
        taskId,
        status,
        description,
        scheduleTime,
        endTime,
        createCalendarItem,
      }) {
        return {
          url: TASK_URL.concat(`/${taskId}/reschedule`),
          method: 'PATCH',
          body: {
            nextStatus: status,
            description,
            scheduleTime,
            endTime,
            createCalendarItem,
          },
        };
      },
      transformResponse(data: TPatchResponse, meta) {
        return patchResponseToToastConverter.fromDb({
          ...data,
          status: meta?.response?.status,
        });
      },
      invalidatesTags: ['Tasks', 'Task', 'TaskComments'],
    }),
    deleteTaskById: build.mutation<void, string>({
      query(taskId) {
        return {
          url: `${TASK_URL}/${taskId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Tasks'],
    }),
    getTaskComments: build.query<
      TTaskCommentUi[],
      {
        taskId: string;
      }
    >({
      query({ taskId }) {
        const urlWithParams = TASK_URL.concat(`/${taskId}/comments`);
        return {
          url: urlWithParams,
          method: 'GET',
        };
      },
      providesTags: ['TaskComments'],
      transformResponse: (data: TTaskComment[]) => {
        return data.map(comment => taskCommentConverter.fromDb(comment));
      },
      keepUnusedDataFor: 0,
    }),
    saveTaskComment: build.mutation<
      void,
      {
        taskId: string;
        text: string;
        isHtml: boolean;
      }
    >({
      query({ taskId, text, isHtml }) {
        return {
          url: TASK_URL.concat(`/${taskId}/comments`),
          method: 'PATCH',
          body: {
            text,
            isHtml
          },
        };
      },
      invalidatesTags: ['TaskComments'],
    }),
    editTaskComment: build.mutation<
      void,
      {
        commentId: string;
        value: string;
      }
    >({
      query({ commentId, value }) {
        return {
          url: TASK_URL.concat(`/comments/${commentId}/text`),
          method: 'PATCH',
          body: {
            value,
          },
        };
      },
      invalidatesTags: ['TaskComments'],
    }),
    updateTaskAttachments: build.mutation<
      void,
      {
        taskId: string;
        attachments: TUpdateAttachmentUi[];
      }
    >({
      query({ taskId, attachments }) {
        return {
          url: `${TASK_URL}/${taskId}/attachments`,
          method: 'PATCH',
          body: attachmentsUpdateConverter.toDb(attachments),
        };
      },
      invalidatesTags: ['Task'],
    }),
    deleteTaskAttachment: build.mutation<
      void,
      {
        taskId: string;
        attachmentId: string;
      }
    >({
      query({ taskId, attachmentId }) {
        return {
          url: `${TASK_URL}/${taskId}/attachments/${attachmentId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Task'],
    }),
    deleteTaskCommentById: build.mutation<
      void,
      {
        commentId: string;
      }
    >({
      query({ commentId }) {
        return {
          url: TASK_URL.concat(`/comments/${commentId}`),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['TaskComments'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeleteTaskCommentByIdMutation,
  useEditTaskCommentMutation,
  useGetTaskCommentsQuery,
  useSaveTaskCommentMutation,
  useGetAllTasksQuery,
  useCreateTaskMutation,
  useGetTaskQuery,
  useGetAllTasksByTabQuery,
  useGetTaskStatusQuery,
  useEditTitleMutation,
  useEditDescriptionMutation,
  useEditAssigneeMutation,
  useEditStatusMutation,
  useEditReporterMutation,
  useEditCompanyMutation,
  useEditNextActionItemDescMutation,
  useGetTaskAssigneesQuery,
  useGetTaskReportersQuery,
  useUpdateTaskReAssigneMutation,
  useStartTaskWorkMutation,
  useResolveTaskMutation,
  useScheduleTaskMutation,
  useReScheduleTaskMutation,
  useDeleteTaskByIdMutation,
  useUpdateTaskAttachmentsMutation,
  useDeleteTaskAttachmentMutation,
} = tasksApi;
