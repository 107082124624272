import React from 'react';
import SAlert from './Alert.style';
import { TAlert } from './Alert.types';

export const Alert = (props: TAlert) => {
  const { message } = props;
  if (!message) return null;
  return <SAlert {...props} />;
};

export default Alert;
