import AntInput from 'antd/es/input';
import React, { forwardRef } from 'react';
import { SGroup, SInput, SPassword, STextArea } from 'components/Input/style';
import {
  TGroupProps,
  TInputProps,
  TPasswordProps,
  TTextAreaProps,
} from 'components/Input/types';
import { Colors } from 'core/CssVariables';
import { Icon, TIconNames } from '../Icon';

export const Input = forwardRef<AntInput, TInputProps>(
  (props: TInputProps, ref) => {
    return <SInput ref={ref} {...props} />;
  },
);

export const Password = (props: TPasswordProps) => {
  return (
    <SPassword
      iconRender={(visible: boolean) => (
        <Icon
          size={16}
          color={Colors.Grey}
          icon={visible ? TIconNames.EYE_SHOW : TIconNames.EYE_HIDE}
        />
      )}
      {...props}
    />
  );
};

export const TextArea = forwardRef<AntInput, TTextAreaProps>(
  (props: TTextAreaProps, ref) => {
    return <STextArea ref={ref} {...props} />;
  },
);

export const Group = (props: TGroupProps) => {
  return <SGroup {...props} />;
};
