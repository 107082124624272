import {
  integrationConverter,
  integrationParamConverter,
  asIntegrationSettingsConverter,
  RMMIntegrationConverter,
  credentialsConverter,
  RMMCompanyMappingsConverter,
  CalendarIntegrationConverter,
  connectGoogleCalendarIntegrationConverter,
  RMMSettingsConverter,
} from 'data/converters/integration.converters';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import {
  TASIntegration,
  TIntegrationParam,
  TIntegrationParamUi,
  TASIntegrationSettingsUi,
  TASIntegrationUi,
  TRMMIntegrationUI,
  TRMMIntegration,
  TCredentialsUI,
  TCredentials,
  TCredentialsPostUI,
  TRMMCompanyMappingsUI,
  TRMMCompanyMappings,
  TCalendarIntegrationUi,
  TCalendarIntegration,
  TConnectGoogleCalendarIntegrationUi,
  TDisconnectCalendarIntegrationUi,
  TRMMSettingsUpdateUi,
  outlookConnectionCallbackPostUi,
  TConnectOutlookIntegrationUi,
  TRMMLevelConnectUi,
} from 'data/types/integrations.types';

export const AS_INTEGRATION_URL = 'api/as-integrations';
export const RMM_INTEGRATION_URL = 'api/rmm-integrations';
export const LEVEL_INTEGRATION_URL = 'api/level-integration'
export const CALENDAR_INTEGRATION_URL = 'api/calendar-integration';

export const integrationsApi = authSplitApi('integrations', [
  'integrations',
  'RMMIntegrations',
  'credentials',
  'company_mappings',
  'calendar_integrations',
]).injectEndpoints({
  endpoints: build => ({
    getASIntegrations: build.query<TASIntegrationUi[], void>({
      query() {
        return {
          url: `${AS_INTEGRATION_URL}/integration-statuses`,
          method: 'GET',
        };
      },
      providesTags: ['integrations'],
      transformResponse: (data: TASIntegration[]) =>
        data?.map(item => integrationConverter.fromDb(item)),
    }),
    getRMMIntegrations: build.query<TRMMIntegrationUI[], void>({
      query() {
        return {
          url: `${RMM_INTEGRATION_URL}/integration-statuses`,
          method: 'GET',
        };
      },
      providesTags: ['RMMIntegrations'],
      transformResponse: (data: TRMMIntegration[]) =>
        data?.map(item => RMMIntegrationConverter.fromDb(item)),
    }),
    getCredentials: build.query<TCredentialsUI, void>({
      query() {
        return {
          url: `${RMM_INTEGRATION_URL}/credentials`,
          method: 'GET',
        };
      },
      providesTags: ['credentials'],
      transformResponse: (data: TCredentials) =>
        credentialsConverter.fromDb(data),
    }),
    connectRMMIntegration: build.mutation<void, TCredentialsPostUI>({
      query(data) {
        return {
          url: `${RMM_INTEGRATION_URL}/connect`,
          method: 'POST',
          body: credentialsConverter.toDb(data),
        };
      },
      invalidatesTags: ['RMMIntegrations', 'credentials', 'company_mappings'],
    }),
    connectLevelIntegration: build.mutation<TRMMLevelConnectUi, void>({
      query() {
        return {
          url: `${RMM_INTEGRATION_URL}/level/connect`,
          method: 'POST',
          body: {},
        };
      },
      invalidatesTags: ['RMMIntegrations'],
    }),
    updateRMMSettings: build.mutation<void, TRMMSettingsUpdateUi>({
      query(data) {
        const { id } = data;
        return {
          url: `${RMM_INTEGRATION_URL}/settings/${id}`,
          method: 'PUT',
          body: RMMSettingsConverter.toDb(data),
        };
      },
      invalidatesTags: ['RMMIntegrations'],
    }),
    disconnectRMMIntegration: build.mutation<void, string>({
      query(integrationId) {
        return {
          url: `${RMM_INTEGRATION_URL}/${integrationId}/disconnect`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['RMMIntegrations', 'credentials', 'company_mappings'],
    }),
    disconnectLevelIntegration: build.mutation<void, void>({
      query() {
        return {
          url: `${RMM_INTEGRATION_URL}/level/disconnect`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['RMMIntegrations', 'credentials', 'company_mappings'],
    }),
    getRMMCompanyMappings: build.query<TRMMCompanyMappingsUI, void>({
      query() {
        return {
          url: `${RMM_INTEGRATION_URL}/company-mappings`,
          method: 'GET',
        };
      },
      providesTags: ['company_mappings'],
      transformResponse: (data: TRMMCompanyMappings) =>
        RMMCompanyMappingsConverter.fromDb(data),
    }),
    synchronizeCompanies: build.mutation<void, void>({
      query() {
        return {
          url: `${RMM_INTEGRATION_URL}/synchronize-companies`,
          method: 'POST',
        };
      },
      invalidatesTags: ['company_mappings'],
    }),
    connectQuickbooks: build.mutation<{ url: string }, void>({
      query() {
        return {
          url: `${AS_INTEGRATION_URL}/add/quickbooks`,
          method: 'POST',
        };
      },
      invalidatesTags: ['integrations'],
    }),
    isQuickbooksConnected: build.query<TASIntegrationUi, TIntegrationParamUi>({
      query(param) {
        const params: TIntegrationParam = integrationParamConverter.toDb(param);
        return {
          url: `${AS_INTEGRATION_URL}/quickbooks/oauth2redirect-confirmed`,
          method: 'GET',
          ...(params && { params }),
        };
      },
      transformResponse: (data: TASIntegration) =>
        integrationConverter.fromDb(data),
    }),
    disconnectQuickbooks: build.mutation<void, string>({
      query(id) {
        return {
          url: `${AS_INTEGRATION_URL}/disconnect/quickbooks/${id}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['integrations'],
    }),
    connectXero: build.mutation<{ url: string }, void>({
      query() {
        return {
          url: `${AS_INTEGRATION_URL}/add/xero`,
          method: 'POST',
        };
      },
      invalidatesTags: ['integrations'],
    }),
    isXeroConnected: build.query<TASIntegrationUi, TIntegrationParamUi>({
      query(param) {
        const params: TIntegrationParam = integrationParamConverter.toDb(param);
        return {
          url: `${AS_INTEGRATION_URL}/xero/oauth2redirect-confirmed`,
          method: 'GET',
          ...(params && { params }),
        };
      },
      transformResponse: (data: TASIntegration) =>
        integrationConverter.fromDb(data),
    }),
    disconnectXero: build.mutation<void, string>({
      query(id) {
        return {
          url: `${AS_INTEGRATION_URL}/disconnect/xero/${id}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['integrations'],
    }),
    updateASIntegrationSettings: build.mutation<void, TASIntegrationSettingsUi>(
      {
        query(body) {
          const { id, ...settings } = asIntegrationSettingsConverter.toDb(body);
          return {
            url: `${AS_INTEGRATION_URL}/settings/${id}/update`,
            method: 'PATCH',
            body: settings,
          };
        },
        invalidatesTags: ['integrations'],
      },
    ),
    getCalendarIntegrations: build.query<TCalendarIntegrationUi[], void>({
      query() {
        return {
          url: `${CALENDAR_INTEGRATION_URL}/integration-statuses`,
          method: 'GET',
        };
      },
      providesTags: ['calendar_integrations'],
      transformResponse: (data: TCalendarIntegration[]) =>
        data?.map(item => CalendarIntegrationConverter.fromDb(item)),
    }),
    connectGoogleCalendarIntegration: build.mutation<
      void,
      TConnectGoogleCalendarIntegrationUi
    >({
      query(body) {
        return {
          url: `${CALENDAR_INTEGRATION_URL}/connect/google-calendar`,
          method: 'POST',
          body: connectGoogleCalendarIntegrationConverter.toDb(body),
        };
      },
      invalidatesTags: ['calendar_integrations'],
    }),
    disconnectCalendarIntegration: build.mutation<
      void,
      TDisconnectCalendarIntegrationUi
    >({
      query(body) {
        const { id } = body;
        return {
          url: `${CALENDAR_INTEGRATION_URL}/${id}/disconnect`,
          method: 'POST',
        };
      },
      invalidatesTags: ['calendar_integrations'],
    }),
    connectOutlookIntegration: build.mutation<
      {
        redirectUrl: string;
      },
      TConnectOutlookIntegrationUi
    >({
      query(body) {
        return {
          url: `${CALENDAR_INTEGRATION_URL}/connect/outlook-calendar`,
          method: 'POST',
          body: {
            type: 'Outlook Calendar',
            fromEmailAddress: body.fromEmailAddress
          },
        };
      },
      transformResponse: (data: { url: string }) => ({ redirectUrl: data.url }),
    }),
    outlookConnectionConfirm: build.query<
      void,
      outlookConnectionCallbackPostUi
    >({
      query(params) {
        return {
          url: `${CALENDAR_INTEGRATION_URL}/outlook-callback`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetASIntegrationsQuery,
  useConnectQuickbooksMutation,
  useDisconnectQuickbooksMutation,
  useIsQuickbooksConnectedQuery,
  useConnectXeroMutation,
  useDisconnectXeroMutation,
  useIsXeroConnectedQuery,
  useUpdateASIntegrationSettingsMutation,
  useGetRMMIntegrationsQuery,
  useConnectRMMIntegrationMutation,
  useConnectLevelIntegrationMutation,
  useLazyGetCredentialsQuery,
  useDisconnectRMMIntegrationMutation,
  useDisconnectLevelIntegrationMutation,
  useGetRMMCompanyMappingsQuery,
  useSynchronizeCompaniesMutation,
  useGetCalendarIntegrationsQuery,
  useConnectGoogleCalendarIntegrationMutation,
  useDisconnectCalendarIntegrationMutation,
  useUpdateRMMSettingsMutation,
  useConnectOutlookIntegrationMutation,
  useLazyOutlookConnectionConfirmQuery,
} = integrationsApi;
