import { TAvailableFilter } from 'components/FilterComponents/FilterComponents.type';
import { ETableNames } from 'components/Table/Table.types';
import { Colors } from 'core/CssVariables';
import {
  AllTasksPath,
  TaskFilterNames,
  TaskFilters,
  TTaskStatus,
} from 'data/types/task.type';

export type TTaskTabs = {
  label: string;
  path: AllTasksPath;
  availabelFilters: TAvailableFilter<TaskFilters, TaskFilterNames>[];
  hasBulkAction: boolean;
  tableName: ETableNames;
};

export type TTaskStatusMapperColor = {
  [key in TTaskStatus]: Colors;
};

export const taskFilterConverter = (filters: {
  [key in TaskFilters]?: string[];
}) => {
  const taskFilterToDB = {
    ...(filters?.creationDate?.[0] && {
      'creationDate.from': filters?.creationDate?.[0],
    }),
    ...(filters?.creationDate?.[1] && {
      'creationDate.to': filters?.creationDate?.[1],
    }),
    ...(filters?.statuses?.length && {
      [TaskFilters.STATUS]: filters?.statuses,
    }),
    ...(filters?.assigneeIds?.length && {
      [TaskFilters.ASSIGNED]: filters?.assigneeIds,
    }),
    ...(filters?.reporterIds?.length && {
      [TaskFilters.REPORTER]: filters?.reporterIds,
    }),
    ...(filters?.companyIds?.length && {
      [TaskFilters.COMPANY]: filters?.companyIds,
    }),
    ...(filters?.overdue?.length && {
      [TaskFilters.OVERDUE]: true,
    }),
  };
  return taskFilterToDB;
};

export type TFilterProps = {
  onSelected: (selectedQueues: string[]) => void;
  selectedValues: string[];
  path?: keyof typeof AllTasksPath;
};
