import React from 'react';

import useForm from 'hooks/useForm';
import Modal from 'components/Modal/Modal';
import { ModalWidthEnum } from 'components/Modal/Modal.types';
import { useCreateProjectMutation } from 'redux/apiSlices/project.slice';
import { TProjectPostDataUi } from 'data/types/project.type';
import { toast } from 'components/Notification';
import { useAppSelector } from 'redux/store';
import { changeProjectAddModal } from 'redux/slices';
import { useDispatch } from 'react-redux';

import { AddProjectForm } from './AddProjectForm';

const AddProjectModal: React.FC = () => {
  const dispatch = useDispatch();

  const { addProjectModal } = useAppSelector(state => ({
    addProjectModal: state.appSettings.addProjectModal,
  }));
  const { form, setCustomFieldValue } = useForm();

  const [createProject, { isLoading }] = useCreateProjectMutation();

  const handleClosePopup = () => {
    dispatch(changeProjectAddModal(false));
  };

  const handleSubmitAddNewProject = async () => {
    try {
      const formValues = (await form.validateFields()) as TProjectPostDataUi;
      try {
        await createProject(formValues).unwrap();
        toast({
          description: 'Project has been created successfully',
          title: 'Creating project',
          type: 'success',
        });
        handleClosePopup();
      } catch (error) {
        toast({
          description: (error as any)?.data.message,
          title: 'Creating project',
          type: 'error',
        });
      }
    } catch (error) {
      if ((error as any)?.errorFields.length) {
        form.scrollToField((error as any)?.errorFields[0].name[0], {
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <Modal
      onOk={handleSubmitAddNewProject}
      visible={addProjectModal}
      onCancel={handleClosePopup}
      title="Create new project"
      okText="Create project"
      cancelText="Cancel"
      destroyOnClose
      confirmLoading={isLoading}
      size={ModalWidthEnum.Medium}
    >
      <AddProjectForm form={form} setCustomFieldValue={setCustomFieldValue} />
    </Modal>
  );
};

export { AddProjectModal };
