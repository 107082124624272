import { TRMMCompanyMappingsUI } from 'data/types/integrations.types';

const mappingCompanyA = {
  companyId: 'companyId123',
  zestCompanyName: 'Company A',
  rmmCompanyName: 'Company A',
  notMappedDevicesCount: 3,
  mappedDevicesCount: 5,
};

const mockedMappingData: TRMMCompanyMappingsUI = {
  notMappedZestCompanies: ['Company 1', 'Company 2'],
  notMappedNinjaCompanies: ['Company 3'],
  companies: [mappingCompanyA],
};

export { mappingCompanyA, mockedMappingData };
