import styled from 'styled-components';
import { Col } from 'components/Grid/Grid';

const SScrollableContainer = styled(Col)<{ table?: boolean }>`
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  padding-right: 8px;
`;
export default SScrollableContainer;
