import { TAuthConverter } from 'data/types';
import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import {
  TProjectProgress,
  TProjectProgressUi,
  TPrimaryResource,
  TPrimaryResourceUi,
  TProject,
  TProjectComment,
  TProjectCommenter,
  TProjectCommenterUi,
  TProjectCommentUi,
  TProjectDetails,
  TProjectDetailsUi,
  TProjectPostData,
  TProjectPostDataUi,
  TProjectReporter,
  TProjectReporterUi,
  TProjectUi,
  TTotalTimeEntries,
  TTotalTimeEntriesUi,
  TTaskBasedEstimatedHoursUi,
  TTaskBasedEstimatedHours,
  ProjectFiltersEnum,
  TProjectSpecialFlagsUi,
  TProjectSpecialFlags,
  EProjectSpecialFlagsUi,
  EProjectSpecialFlags,
  TProjectItemsForInvoiceFilterUi,
  TProjectItemsForInvoiceFilter,
  TProjectCompactUi,
  TProjectCompact,
} from 'data/types/project.type';
import { currencyFormatter, getDateTimeFormats } from 'helpers/utils';
import moment from 'moment';
import { companyConverter } from './company.converter';
import { attachmentsGetConverter, GetFirstLetters } from './general.converters';
import {
  materialConverter,
  materialTotalsConverter,
} from './material.converter';
import { estimatedHoursConverter, phasesConverter } from './phase.converters';
import { commentAdditionalRecipientsConverter } from './commentAdditionalRecipients.converter';

export const projectPrimaryResourceConverter: TFetchConverter<
  TPrimaryResourceUi,
  TPrimaryResource
> = {
  fromDb: data => ({
    id: data.id,
    name: data.name,
    photoUrl: data.photoUrl,
    firstLetters: GetFirstLetters.fromDb(data.name),
  }),
};

const projectReporterConverter: TFetchConverter<
  TProjectReporterUi,
  TProjectReporter
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber || '',
      firstLetters: GetFirstLetters.fromDb(data.name),
      isVip: data.isVip,
      phoneExtension: data.phoneExtension,
      jobTitle: data.jobTitle,
      personalAddress: data.personalAddress,
      cellPhoneNumber: data.cellPhoneNumber || '',
    };
  },
};

const totalTimeEntriesConverter: TFetchConverter<
  TTotalTimeEntriesUi,
  TTotalTimeEntries
> = {
  fromDb: data => {
    return {
      HOURS: data.HOURS,
      MINUTES: data.MINUTES,
    };
  },
};

const projectProgressConverter: TFetchConverter<
  TProjectProgressUi,
  TProjectProgress
> = {
  fromDb: data => {
    return {
      startDate: data.startDate && moment(data.startDate).format('MMMM DD'),
      endDate: data.endDate && moment(data.endDate).format('MMMM DD'),
      progressPercentage: data.progressPercentage,
      totalTimeEntries: totalTimeEntriesConverter.fromDb(data.totalTimeEntries),
    };
  },
};

const taskBasedEstimatedHoursConverter: TFetchConverter<
  TTaskBasedEstimatedHoursUi,
  TTaskBasedEstimatedHours
> = {
  fromDb: data => {
    return {
      HOURS: data.HOURS,
      MINUTES: data.MINUTES,
    };
  },
};
const projectSpecialFlagsConverter: TFetchConverter<
  TProjectSpecialFlagsUi,
  TProjectSpecialFlags
> = {
  fromDb: data => {
    return {
      invoicePaid: data.invoicePaid,
      invoiceSent: data.invoiceSent,
      unreadComments: data.unreadComments,
    };
  },
};
export const projectCompactConverter: TFetchConverter<
TProjectCompactUi,
TProjectCompact
> = {
  fromDb: data => {
    return {
      id: data.id,
      title: data.title,
      startTime: data.startTime,
      companyShortName: data.companyShortName,
      companyName: data.companyName,
      companyLogoUrl: data.companyLogoUrl,
      companyFirstLetters: GetFirstLetters.fromDb(data.companyName),
      billingMethod: data.billingMethod,
      primaryResource: projectPrimaryResourceConverter.fromDb(
        data.primaryResource,
      ),
      estimatedHours: estimatedHoursConverter.fromDb(data.estimatedHours),
      status: data.status,
      budget: currencyFormatter(data.budget),
      number: data.number,
      specialFlags: projectSpecialFlagsConverter.fromDb(data.specialFlags),
    }
  }
};

export const projectConverter: TAuthConverter<
  TProjectPostDataUi,
  TProjectPostData,
  TProjectUi,
  TProject
> = {
  toDb: (data: TProjectPostDataUi): TProjectPostData => {
    const convertedObj: TProjectPostData = {
      billingMethod: data.billingMethod,
      companyId: data.companyId,
      description: data.description,
      endTime: data.endTime,
      estimatedHours: data.estimatedHours,
      primaryResourceId: data.primaryResourceId,
      startTime: data.startTime,
      title: data.title,
      reporterId: data.reporterId,
    };
    return convertedObj;
  },
  fromDb: (data: TProject): TProjectUi => {
    return {
      billingMethod: data.billingMethod,
      budget: currencyFormatter(data.budget),
      company: companyConverter.fromDb(data.company),
      description: data.description,
      endTime: data.endTime,
      estimatedHours: estimatedHoursConverter.fromDb(data.estimatedHours),
      id: data.id,
      primaryResource: projectPrimaryResourceConverter.fromDb(
        data.primaryResource,
      ),
      startTime: data.startTime,
      status: data.status,
      title: data.title,
      number: data.number,
      reporter: data.reporter && projectReporterConverter.fromDb(data.reporter),
      creationTime: moment(data.creationTime).format(
        getDateTimeFormats().dateTimeFormat,
      ),
      progress: projectProgressConverter.fromDb(data.progress),
      taskBasedEstimatedHours: taskBasedEstimatedHoursConverter.fromDb(
        data.taskBasedEstimatedHours,
      ),
      specialFlags: projectSpecialFlagsConverter.fromDb(data.specialFlags),
    };
  },
};

export const projectDetailsConverter: TFetchConverter<
  TProjectDetailsUi,
  TProjectDetails
> = {
  fromDb: data => {
    return {
      project: projectConverter.fromDb(data.project),
      attachments: attachmentsGetConverter.fromDb(data.attachments),
      materials: data.materials.map(material =>
        materialConverter.fromDb(material),
      ),
      phases: data?.phases
        ? data.phases.map(item => phasesConverter.fromDb(item))
        : [],
      materialTotals: materialTotalsConverter.fromDb(data.materialTotals),
    };
  },
};

export const projectCommenterConverter: TFetchConverter<
  TProjectCommenterUi,
  TProjectCommenter
> = {
  fromDb: data => {
    return {
      fullName: data.fullName,
      photoUrl: data.photoUrl,
      userId: data.userId,
      firstLetters: GetFirstLetters.fromDb(data.fullName),
    };
  },
};

export const projectCommentConverter: TFetchConverter<
  TProjectCommentUi,
  TProjectComment
> = {
  fromDb: data => {
    return {
      commenter: projectCommenterConverter.fromDb(data.commenter),
      creationTime: data.creationTime,
      edited: data.edited,
      id: data.id,
      lastModifiedTime: data.lastModifiedTime,
      text: data.text,
      projectId: data.projectId,
      replyToCustomer: data.replyToCustomer,
      fromCustomer: data.fromCustomer,
      unread: data.unread,
      additionalRecipients: commentAdditionalRecipientsConverter.fromDb(data.additionalRecipients),
      isHtml: data.isHtml
    };
  },
};

export const projectSpecialFlagsEnumConverter: TCreateConverter<
  EProjectSpecialFlagsUi,
  EProjectSpecialFlags
> = {
  toDb: data => {
    const status: { [key in EProjectSpecialFlagsUi]: EProjectSpecialFlags } = {
      [EProjectSpecialFlagsUi.HAS_UNREAD_COMMENT]:
        EProjectSpecialFlags.HAS_UNREAD_COMMENT,
      [EProjectSpecialFlagsUi.INVOICE_SENT]: EProjectSpecialFlags.INVOICE_SENT,
      [EProjectSpecialFlagsUi.INVOICE_PAID]: EProjectSpecialFlags.INVOICE_PAID,
    };
    return status[data];
  },
};

export const projectFilterConverter = (filters: {
  [key in ProjectFiltersEnum]?: string[];
}) => {
  const projectFilterToDB = {
    ...(filters?.companyIds?.length && {
      [ProjectFiltersEnum.COMPANY]: filters?.companyIds,
    }),
    ...(filters?.primaryResourceIds?.length && {
      [ProjectFiltersEnum.PRIMARY_RESOURCE]: filters?.primaryResourceIds,
    }),
    ...(filters?.statuses?.length && {
      [ProjectFiltersEnum.STATUS]: filters?.statuses,
    }),
    ...(filters?.billingMethods?.length && {
      [ProjectFiltersEnum.BILLING_METHODS]: filters?.billingMethods,
    }),
    ...(filters?.creationDate?.[0] && {
      'creationDate.from': filters?.creationDate?.[0],
    }),
    ...(filters?.creationDate?.[1] && {
      'creationDate.to': filters?.creationDate?.[1],
    }),
    ...(filters?.specialFlags?.length && {
      [ProjectFiltersEnum.SPECIAL_FLAGS]: filters.specialFlags.map(flag =>
        projectSpecialFlagsEnumConverter.toDb(flag as EProjectSpecialFlagsUi),
      ),
    }),
  };
  return projectFilterToDB;
};

export const projectItemsForInvoiceFilterConverter: TCreateConverter<
  TProjectItemsForInvoiceFilterUi,
  TProjectItemsForInvoiceFilter
> = {
  toDb: data =>
    data && {
      ...(data.timeEntriesPeriod?.[0] && {
        timeEntriesFrom: data.timeEntriesPeriod?.[0],
      }),
      ...(data.timeEntriesPeriod?.[1] && {
        timeEntriesTo: data.timeEntriesPeriod?.[1],
      }),
      includeMaterials: data.includeMaterials,
      includeTimeEntries: data.includeTimeEntries,
    },
};
