export enum EASIntegrationNamesUi {
  QUICKBOOKS = 'Quickbooks',
  XERO = 'Xero',
}

export enum EASIntegrationNames {
  QUICKBOOKS = 'Quickbooks',
  XERO = 'Xero',
}
export enum ERMMIntegrationsTypes {
  NINJA = 'NINJA_ONE',
  LEVEL = 'LEVEL',
  ADDIGY = 'ADDIGY',
}
export enum ERMMIntegrationsTypesUI {
  NINJA = 'Ninja',
  LEVEL = 'Level',
  ADDIGY = 'Addigy',
}
export enum ECalendarIntegrationsTypesUi {
  GOOGLE = 'Google Calendar',
  MICROSOFT_OUTLOOK = 'Outlook Calendar',
}
export enum ECalendarIntegrationsTypes {
  GOOGLE = 'Google Calendar',
  MICROSOFT_OUTLOOK = 'Outlook Calendar',
}
export enum EDocumentationIntegrationsTypesUi {
  HUDU = 'Hudu',
  IT_GLUE = 'IT Glue',
}
export enum ENinjaRmmRegion {
  EUROPE = 'EUROPE',
  USA = 'USA',
  AUSTRALIA = 'AUSTRALIA',
  CANADA = 'CANADA',
}
export enum ENinjaRmmRegionUI {
  EUROPE = 'EUROPE',
  USA = 'USA',
  AUSTRALIA = 'AUSTRALIA',
  CANADA = 'CANADA',
}

export type TASIntegrationSettingsUi = {
  id: string;
  allowCreditCardPayment: boolean;
  allowBankTransferPayment: boolean;
  allowAutoSendOnExport: boolean;
};
export type TASIntegrationSettings = {
  id: string;
  allowCreditCardPayment: boolean;
  allowBankTransferPayment: boolean;
  allowAutoSendOnExport: boolean;
};

export type TASIntegrationUi = {
  id?: string;
  type: EASIntegrationNamesUi;
  connected: boolean;
  settings: TASIntegrationSettingsUi;
};

export type TASIntegration = {
  id?: string;
  type: EASIntegrationNames;
  connected: boolean;
  settings: TASIntegrationSettings;
};

export type TIntegrationParamUi = {
  state: string | null;
  realmId: string | null;
  code: string | null;
};

export type TIntegrationParam = {
  state: string;
  realmId: string;
  code: string;
} | null;

export type TRMMSettings = {
  id: string;
  webhookUri: string;
  ticketProcessorEnabled: boolean;
  syncInterval: number;
};
export type TRMMSettingsUI = {
  id: string;
  webhookUri: string;
  ticketProcessorEnabled: boolean;
  syncInterval: number;
};

export type TRMMSettingsUpdate = {
  syncInterval: number;
  ticketProcessorEnabled: boolean;
};
export type TRMMSettingsUpdateUi = {
  id: string;
  syncInterval: number;
  ticketProcessorEnabled: boolean;
};

export type TRMMLevelConnectUi = {
  apiKey: string;
}

export type TRMMIntegration = {
  id?: string;
  connected: boolean;
  type: ERMMIntegrationsTypes;
  settings?: TRMMSettings;
};
export type TRMMIntegrationUI = {
  id?: string;
  connected: boolean;
  type: ERMMIntegrationsTypesUI;
  settings?: TRMMSettingsUI;
};
export type TCredentials = {
  clientId: string;
  region: ENinjaRmmRegion;
};
export type TCredentialsUI = {
  clientId: string;
  region: ENinjaRmmRegion;
};

export type TCredentialsPost = {
  clientId: string;
  clientSecret: string;
  region: ENinjaRmmRegion;
};
export type TCredentialsPostUI = {
  clientId: string;
  clientSecret: string;
  region: ENinjaRmmRegionUI;
};
export type TRMMCompany = {
  companyId: string;
  zestCompanyName: string;
  rmmCompanyName: string;
  mappedDevicesCount: number;
  notMappedDevicesCount: number;
};
export type TRMMCompanyUI = {
  companyId: string;
  zestCompanyName: string;
  rmmCompanyName: string;
  mappedDevicesCount: number;
  notMappedDevicesCount: number;
};
export type TRMMCompanyMappings = {
  companies: TRMMCompany[];
  notMappedZestCompanies: string[];
  notMappedNinjaCompanies: string[];
};
export type TRMMCompanyMappingsUI = {
  companies: TRMMCompanyUI[];
  notMappedZestCompanies: string[];
  notMappedNinjaCompanies: string[];
};
export type TCalendarIntegrationUi = {
  id?: string;
  connected: boolean;
  type: ECalendarIntegrationsTypesUi;
  csTenantId?: string;
};
export type TConnectGoogleCalendarIntegrationUi = {
  csTenantId: string;
};
export type TConnectOutlookIntegrationUi = {
  fromEmailAddress: string;
};

export type TConnectGoogleCalendarIntegration = {
  type: ECalendarIntegrationsTypes.GOOGLE;
  csTenantId: string;
};

export type TDisconnectCalendarIntegrationUi = {
  id: string;
};
export type TCalendarIntegration = {
  id?: string;
  connected: boolean;
  type: ECalendarIntegrationsTypes;
  csTenantId?: string;
};
export type TDocumentationIntegrationUi = {
  id?: string;
  connected: boolean;
  type: EDocumentationIntegrationsTypesUi;
};

export type outlookConnectionCallbackPostUi = {
  tenant: string | null;
  scope: string | null;
  state: string | null;
  ['admin_consent']: boolean;
};
