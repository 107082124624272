import { Checkbox } from 'antd';
import styled, { ThemeProps, css } from 'styled-components';
import { TCheckbox } from 'components/Checkbox/types';
import { Colors, FontWeights } from 'core/CssVariables';
import { FC } from 'react';
import { TThemeProps } from 'theme/theme';

const SCheckbox = styled<FC<TCheckbox>>(Checkbox)`
  & .ant-checkbox-inner {
    border-radius: 2px;
    border-color: ${({ theme }) => theme.table.checkboxBorderColor};
    border-width: 1px;
    background-color: transparent;
  }
  &:hover .ant-checkbox-inner,
  &:focus + .ant-checkbox-inner {
    border-color: ${Colors.InfoColor};
  }
  .ant-checkbox-checked {
    &::after {
      border-color: ${Colors.InfoColor};
    }

    .ant-checkbox-inner {
      background-color: ${Colors.InfoColor};
      border-color: ${Colors.InfoColor};
    }
  }
  .ant-checkbox + span {
    color: ${({ theme }) => theme.table.checkboxLabelColor};
    font-weight: ${FontWeights.Regular};
  }
  ${({ lineThrough, checked }) =>
    lineThrough &&
    checked &&
    css`
      .ant-checkbox + span {
        text-decoration-line: line-through;
        color: ${Colors.InfoColor};
        font-weight: ${FontWeights.Regular};
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      .ant-checkbox + span {
        color: ${({ theme }: ThemeProps<TThemeProps>) =>
          theme.paragraph.disableColor};
      }
      .ant-checkbox-disabled {
        .ant-checkbox-inner {
          border-color: ${({ theme }: ThemeProps<TThemeProps>) =>
            theme.paragraph.disableColor} !important;
        }
      }
    `}
`;
export default SCheckbox;
