import {
  itGoalFilterConverter,
  itGoalsConverter,
} from 'data/converters/ItGoal.converter';
import { pagableDataConverter } from 'data/converters/pagableData.converters';
import {
  EItGoalStatus,
  TItGoal,
  TItGoalPost,
  TItGoalPostUi,
  TItGoalUi,
} from 'data/types/ItGoal.type';
import {
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'data/types/pagableData.type';
import { authSplitApi } from 'redux/helpers/slice.helpers';

const IT_GOAL_URL = 'api/company-it-goals';

export const itGoalApi = authSplitApi('itGoalApi', ['itGoals']).injectEndpoints(
  {
    endpoints: build => ({
      createItGoal: build.mutation<TItGoalPost, TItGoalPostUi>({
        query(body) {
          return {
            url: IT_GOAL_URL,
            method: 'POST',
            body: itGoalsConverter.toDb(body),
          };
        },
        invalidatesTags: ['itGoals'],
      }),
      getItGoals: build.query<
        TPageableDataWithContentUi<TItGoalUi[]>,
        {
          page: number;
          pageSize: number;
          sort?: string;
          filters?: any;
          companyIds?: string[];
        }
      >({
        query({ page, pageSize, sort, filters, companyIds }) {
          const urlWithParams = IT_GOAL_URL.concat(
            `?page=${page}&size=${pageSize}`,
          );
          return {
            url: urlWithParams,
            method: 'GET',
            params: {
              ...(sort && { sort }),
              ...(companyIds && { companyIds }),
              ...(filters && { ...itGoalFilterConverter(filters) }),
            },
          };
        },
        providesTags: ['itGoals'],
        transformResponse: (data: TPageableDataWithContent<TItGoal[]>) => {
          return {
            ...pagableDataConverter.fromDb(data),
            content: data?.content?.length
              ? data.content.map(itGoal => itGoalsConverter.fromDb(itGoal))
              : [],
          };
        },
      }),
      editItGoalDescription: build.mutation<
        TItGoalUi,
        { id: string; value: string }
      >({
        query({ id, value }) {
          return {
            url: `${IT_GOAL_URL}/${id}/description`,
            method: 'PATCH',
            body: { value },
          };
        },
        invalidatesTags: ['itGoals'],
      }),
      editItGoalStatus: build.mutation<
        TItGoalUi,
        { id: string; value: EItGoalStatus }
      >({
        query({ id, value }) {
          return {
            url: `${IT_GOAL_URL}/${id}/status`,
            method: 'PATCH',
            body: { value },
          };
        },
        invalidatesTags: ['itGoals'],
      }),
      editItGoalYearAndQuarter: build.mutation<
        TItGoalUi,
        { id: string; value: string }
      >({
        query({ id, value }) {
          return {
            url: `${IT_GOAL_URL}/${id}/year-and-quarter`,
            method: 'PATCH',
            body: { value },
          };
        },
        invalidatesTags: ['itGoals'],
      }),
      deleteItGoalById: build.mutation<void, { id: string }>({
        query({ id }) {
          return {
            url: `${IT_GOAL_URL}/${id}`,
            method: 'DELETE',
          };
        },
        invalidatesTags: ['itGoals'],
      }),
    }),
    overrideExisting: false,
  },
);

export const {
  useCreateItGoalMutation,
  useGetItGoalsQuery,
  useEditItGoalDescriptionMutation,
  useEditItGoalStatusMutation,
  useEditItGoalYearAndQuarterMutation,
  useDeleteItGoalByIdMutation,
} = itGoalApi;
