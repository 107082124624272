import { rest } from 'msw';
import { CONTACT_URL } from 'redux/apiSlices/contact.slice';
import {
  contact1,
  contact2,
  contact3,
  location1,
} from 'test/mocks/data/locatonsMockedData';
import {
  TContactsBulkChangeActiveStatusUi,
  TContactsBulkChangeManagedStatusUi,
  TContactsBulkUpdateLocationUi,
} from 'data/types/contact.type';
import { pageableDataWithEmptyContent } from '../data/general.data';
import {
  mockedCompactContacts,
  mockedCompactContactsMapping,
  mockedCompactEmptyContacts,
  mockedPagableContactsMapping,
  mockedPageableContacts,
} from '../data/contact.data';
import { companyAMockedShortData } from '../data/company.data';

export const contactHandlers = [
  rest.get(`${CONTACT_URL}`, (req, res, ctx) => {
    const companiesParam = req.url.searchParams.get('companies');
    if (companiesParam === 'no_company_found') {
      return res(ctx.status(200), ctx.json(pageableDataWithEmptyContent));
    }
    if (companiesParam === companyAMockedShortData.id) {
      return res(ctx.status(200), ctx.json(mockedPagableContactsMapping));
    }
    return res(ctx.status(200), ctx.json(mockedPageableContacts));
  }),
  rest.get(`${CONTACT_URL}/compact`, (req, res, ctx) => {
    const companiesParam = req.url.searchParams.get('companies');
    if (companiesParam === 'no_company_found') {
      return res(ctx.status(200), ctx.json(mockedCompactEmptyContacts));
    }
    if (companiesParam === companyAMockedShortData.id) {
      return res(ctx.status(200), ctx.json(mockedCompactContactsMapping));
    }
    return res(ctx.status(200), ctx.json(mockedCompactContacts));
  }),
  rest.post(`${CONTACT_URL}/bulk-update-location`, (req, res, ctx) => {
    const requestBody: TContactsBulkUpdateLocationUi =
      req.body as TContactsBulkUpdateLocationUi;
    if (requestBody.locationId === location1.id) {
      return res(
        ctx.status(200),
        ctx.json({
          updatedContacts: [contact1, contact2, contact3],
          failedIds: [],
        }),
      );
    }
    if (requestBody.locationId === null) {
      return res(
        ctx.status(400),
        ctx.json({ message: 'You handled null case and error case!' }),
      );
    }
    return res(ctx.status(400), ctx.json({ message: 'Unhandled case' }));
  }),
  rest.post(`${CONTACT_URL}/bulk-update-active`, (req, res, ctx) => {
    const requestBody: TContactsBulkChangeActiveStatusUi =
      req.body as TContactsBulkChangeActiveStatusUi;
    if (requestBody.active === true && requestBody.contactIds.length) {
      return res(
        ctx.status(200),
        ctx.json({
          updatedContacts: [contact1, contact2],
          failedIds: [],
        }),
      );
    }
    if (requestBody.active === false && requestBody.contactIds.length === 1) {
      return res(
        ctx.status(200),
        ctx.json({
          updatedContacts: [contact1],
          failedIds: [],
        }),
      );
    }
    if (!requestBody.contactIds.length) {
      return res(
        ctx.status(400),
        ctx.json({ message: 'You need to select contacts' }),
      );
    }
    return res(ctx.status(400), ctx.json({ message: 'Unhandled case' }));
  }),
  rest.post(`${CONTACT_URL}/bulk-update-managed`, (req, res, ctx) => {
    const requestBody: TContactsBulkChangeManagedStatusUi =
      req.body as TContactsBulkChangeManagedStatusUi;
    if (requestBody.managed === true && requestBody.contactIds.length) {
      return res(
        ctx.status(200),
        ctx.json({
          updatedContacts: [contact1, contact2],
          failedIds: [],
        }),
      );
    }
    if (requestBody.managed === false && requestBody.contactIds.length === 1) {
      return res(
        ctx.status(200),
        ctx.json({
          updatedContacts: [contact1],
          failedIds: [],
        }),
      );
    }
    if (!requestBody.contactIds.length) {
      return res(
        ctx.status(400),
        ctx.json({ message: 'You need to select contacts' }),
      );
    }
    return res(ctx.status(400), ctx.json({ message: 'Unhandled case' }));
  }),
];
